export const SIGNUP = {
    SPASH: '',
}

export const ARTIST = {
    ARTIST_HOME: '/Artist/Home',
    ARTIST_PORTFOLIO: '/Artist/Portfolio',
    ARTIST_SET_SCHEDULE: '/Artist/SetSchedule',
    ARTIST_ADD_SERVICE: '/Artist/AddService',
    ARTIST_EDIT_SERVICE: '/Artist/EditService',
    ARTIST_VIEW_APPOINTMENTS: '/Artist/ViewAppointments',
    ARTIST_VIEW_APPOINTMENT: '/Aritst/ViewAppointment',
    ARTIST_CREATE_CUSTOM_SERVICE: '/Artist/CreateCustomService',
    ARTIST_EDIT_CUSTOM_SERVICE : '/Artist/EditCustomService',
    ARTIST_BOOK: '/Artist/Book',
    ARTIST_VIEW_CLIENT: '/Artist/ViewClient',
    ARTIST_VIEW_BALANCE: '/Artist/ViewBalance',
    ARTIST_TO_SCHEDULING: '/Artist/ToScheduling',
    ARTIST_TO_UPGRADE: '/Artist/ToUpgrade',
    ARTIST_TO_UPGRADE_ID: '/Artist/UpgradeId',
    ARTIST_STATS: '/Artist/Stats',
    ARTIST_DELETE_PROFILE: '/Artist/DeleteProfile'
}

export const CLIENT = {
    CLIENT_VIEW_ARTIST: '/Client/ViewArtist',
    CLIENT_BOOK: '/Client/Book',
    CLIENT_VIEW_APPOINTMENT: '/Client/ViewAppointment',
    CLIENT_PAYMENT_INFO: '/Client/PaymentInfo',
    CLIENT_DELETE_PROFILE: '/Client/DeleteProfile'
}