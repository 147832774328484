import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Body,
    EasyInput,
    BrandButton,
    ModalRowBack,
    SettingsTitle,
    ModalLoader,
    ModalSuccess
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { parseDocs } from '../util/FirestoreUtil';

class UpdateArtistType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            artistType: props.profile.artistType,
            loading: false,
            success: false
        }
    }

    startUpdateArtistType = async () => {
        this.setState({ loading: true });
        await this.updateArtistType();
        await this.updateImages();
        this.setState({ success: true, loading: false });
    }

    updateArtistType = async () => {
        const db = getFirestore();
        const userRef = doc(db, `users/${this.props.profile.id}`);
        await updateDoc(userRef, {
            artistType: this.state.artistType
        });
        await this.updateImages();
    }

    updateImages = async () => {
        const db = getFirestore();
        const imagesRef = collection(db, 'images');
        const w = where('artistId', '==', this.props.profile.id);
        const q = query(imagesRef, w);
        const snap = await getDocs(q).then();
        const { results: images } = parseDocs(snap);
        for (let i = 0 ; i < images.length; i++) {
            const image = images[i];
            const imageRef = doc(db, `images/${image.id}`);
            await updateDoc(imageRef, {
                artistType: this.state.artistType
            });
        }
    }

    onChange = (artistType) => {
        this.setState({ artistType });
    }

    canSave = () => {
        if (this.props.profile.artistType !== this.state.artistType) {
            return true;
        }
        return false;
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={this.props.onBack}
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Artist Type'
            />
        )
    }

    renderInput = () => {
        return (
            <EasyInput 
                value={this.state.artistType}
                onChange={this.onChange}
                placeholder='Artist Type'
                label='Artist Type'
            />
        )
    }

    renderSaveButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                disabled={!this.canSave()}
                onClick={this.startUpdateArtistType}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderInput()}
                {this.renderSaveButton()}
            </Body>
        )
    }

    renderLoader = () => {
        return (
            <ModalLoader 
                message='Updating Artist Type...'
            />
        )
    }

    renderSuccess = () => {
        return (
            <ModalSuccess 
                message='Artist Type Updated'
                onClick={this.props.onBack}
            />
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.success) {
            return this.renderSuccess();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

UpdateArtistType.propTypes = {
    profile: PropTypes.object,
    onBack: PropTypes.func,
};

UpdateArtistType.defaultProps = {
    profile: {},
    onBack: () => {}
};

export default UpdateArtistType;