export const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
}

export const isInStandaloneMode = () => {
    if (('standalone' in window.navigator) && (window.navigator.standalone)) {
        return true;
    }
    return false;
}

export const showPWADialog = () => {
    if (isIos() && !isInStandaloneMode()) {
        return true;
    }
    return false;
}

export const isFirefox = () => {
    if(navigator.userAgent.toLowerCase().indexOf('FxiOS'.toLowerCase()) > -1){
        return true;
   }
   return false;
}

export const isChrome = () => {
    if(navigator.userAgent.toLowerCase().indexOf('CriOS'.toLowerCase()) > -1){
        return true;
   }
   return false;
}

export const isEdge = () => {
    if(navigator.userAgent.toLowerCase().indexOf('EdgiOS'.toLowerCase()) > -1){
        return true;
   }
   return false;
}

export const isOpera = () => {
    if(navigator.userAgent.toLowerCase().indexOf('OPT'.toLowerCase()) > -1){
        return true;
   }
   return false;
}

export const isDuckDuckGo = () => {
    if(navigator.userAgent.toLowerCase().indexOf('DuckDuckGo'.toLowerCase()) > -1){
        return true;
   }
   return false;
}

export const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    } else {
        return false;
    }
}