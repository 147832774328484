import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body, 
    ModalRowBack, 
    ModalTitle, 
    BrandInput, 
    BrandButton, 
    ModalError,
    ModalSuccess,
    ModalLoader
} from '.';
import './styles/ResetPasswordForm.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: false,
            sent: false,
            loading: false
        }
    }

    componentWillUnmount = () => {
        this.setState({ 
            email: '',
            error: false,
            sent: false,
            loading: false
        })
    }

    emailChange = (email) => {
        this.setState({ email });
    }

    resetError = () => {
        this.setState({ error: false });
    }

    resetEmail = async () => {
        const auth = getAuth();
        this.setState({ loading: true });
        try {
            await sendPasswordResetEmail(auth, this.state.email).then();
            this.setState({ sent: true, loading: false });
        } catch {
            this.setState({ error: true, loading: false });
        }
    }

    canSend = () => {
        if (this.state.email && this.state.email.includes('@')) {
            return true;
        }
        return false;
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={this.props.onBack}
            />
        )
    }

    renderTitle = () => {
        return (
            <ModalTitle 
                title='Reset Password'
                alignLeft
                sub={false}
            />
        )
    }

    renderMessage = () => {
        return (
            <p className='RPF-Message'>Enter the email associated with your account and we'll send an email with instructions to reset your password.</p>
        )
    }

    renderInput = () => {
        return (
            <BrandInput 
                label='Email'
                style={styles.input}
                value={this.state.email}
                onChange={this.emailChange}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                <Body>
                    {this.renderBack()}
                    {this.renderTitle()}
                    {this.renderMessage()}
                    {this.renderInput()}
                </Body>
                <BrandButton 
                    label='Send Instructions'
                    style={BOTTOM_BUTTON}
                    disabled={!this.canSend()}
                    onClick={this.resetEmail}
                />
            </Body>
        )
    }

    renderError = () => {
        return (
            <ModalError 
                message="We were not able to send the instructions to reset your email. Check your email to make sure it's correct"
                onClick={this.resetError}
            />
        )
    }

    renderSuccess = () => {
        return (
            <ModalSuccess 
                message='We have sent password recover instructions to your email.'
                onClick={this.props.onBack}
            />
        )
    }

    renderLoader = () => {
        return ( 
            <ModalLoader 
                message='Sending Instructions...'
            />
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.error) {
            return this.renderError();
        }
        if (this.state.sent) {
            return this.renderSuccess();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    input: {
        width: '80%',
        marginTop: 25
    }
}

ResetPasswordForm.propTypes = {
    onBack: PropTypes.func,
};

ResetPasswordForm.defaultProps = {
    onBack: () => {}
};

export default ResetPasswordForm;