import React from 'react';
import PropTypes from 'prop-types';
import './styles/BigTitle.css';

const BigTitle = ({ title, style }) => {
    return (
        <h1 style={style} className='BT-Title'>{title}</h1>
    )
}

BigTitle.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
};

BigTitle.defaultProps = {
    title: '',
    style: {}
}

export default BigTitle;