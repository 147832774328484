import React from 'react';
import PropTypes from 'prop-types';
import './styles/TabButton.css';

const TabButton = ({ onClick, selected, label, style }) => {
    return (
        <div 
            className={`TabButton-Tab ${selected && 'TabButton-Selected'}`}
            onClick={onClick}
            style={style}
        >
            <h3>{label}</h3>
        </div>
    )
}

TabButton.propTypes = {
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.object
};

TabButton.defaultProps = {
    onClick: () => {},
    selected: false,
    label: '',
    style: {}
};

export default TabButton;
