import React from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal,
    Body,
    BrandButton
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
 
const NoCardModal = ({ open, onClose, title, onClick }) => {
    return (
        <BottomUpModal
            open={open}
            onClose={onClose}
            height={.3}
            title={title}
        >
            <Body>

            </Body>
            <BrandButton 
                label='Add Card'
                style={BOTTOM_BUTTON}
                onClick={onClick}
            />
        </BottomUpModal>
    )
}

NoCardModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onClick: PropTypes.func
};

NoCardModal.defaultProps = {
    open: false,
    onClose: () => {},
    onClick: () => {}
}

export default NoCardModal;