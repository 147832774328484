import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { 
    BottomUpModal, 
    BasicInfoForm,
    ProfileTypeForm,
    ResetPasswordForm,
    SignInForm,
    ArtistTypeForm,
    ArtistInfoForm,
    StripeInfoForm,
    ModalLoader,
    Body,
    ModalError,
    PaymentDetailsForm,
    SalonInfoForm,
    ArtistCardForm
} from '.';
import './styles/SignInModal.css';
import { 
    createArtistProfileForConnect, 
    createArtistProfileForSub, 
    createClientProfile
} from '../actions/authActions';
import { fetchClientProfile } from '../actions/clientActions';
import { 
    createCreateConnectAccountData, 
    createSubAccountData } from '../util/StripeUtil';
import axios from 'axios';
import { CREATE_CONNECT_ACCOUNT2, CREATE_SUB_ACCOUNT } from '../constants/ArgendaServerURLS';
import { buildProfileImageUrl, deleteImage } from '../util/ImageUtil';
import { ARTIST } from '../constants/ClientRoutes';
import { 
    ARTIST as ARTIST_PROFILE_TYPE, 
    CLIENT as CLIENT_PROFILE_TYPE, 
    SALON as SALON_PROFILE_TYPE 
} from '../constants/ProfileTypes';

const FORGET_PASSWORD = 'FORGET_PASSWORD';
const PROFILE_TYPE = 'PROFILE_TYPE';
const ARTIST_TYPE = 'ARTIST_TYPE";'
const BASIC_INFO = 'BASIC_INFO';
const ARTIST_INFO = 'ARTIST_INFO';
const SALON_INFO = 'SALON_INFO';
const STRIPE_INFO = 'STRIPE_INFO';
const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
const CLIENT_SIGN_UP = 'CLIENT_SIGN_UP';
const CLIENT_SIGN_UP_ERROR = 'CLIENT_SIGN_UP_ERROR';
const SCHEDULE_SIGN_UP_ERROR = 'SCHEDULE_SIGN_UP_ERROR';
const SCHEDULE_SIGN_UP = 'SCHEDULE_SIGN_UP';
const PAYMENT_SIGN_UP_ERROR = 'PAYMENT_SIGN_UP_ERROR';
const PAYMENT_SIGN_UP = 'PAYMENT_SIGN_UP';
const ARTIST_CARD_FORM = 'ARTIST_CARD_FORM';

class SignInModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screenToRender: '',
            type: '',
            isPayments: false,
            email: '',
            name: '',
            password: '',
            phone: '',
            profileImage: null,
            artistType: '',
            salonName: '',
            salonAddress: null,
            personalAddress: null,
            cardTokens: [],
            dob: '',
            ssn: '',
            frontID: null,
            backID: null,
            loading: false,
            error: false,
        }
    }

    signUpClient = async () => {
        this.setState({ screenToRender: CLIENT_SIGN_UP });
        try {
            await createClientProfile({ ...this.state });
            this.props.fetchClientProfile();
            this.onClose();
        } catch {
            this.setState({ screenToRender: CLIENT_SIGN_UP_ERROR });
        }
    }

    signUpScheduleArtist = async () => {
        this.setState({ screenToRender: SCHEDULE_SIGN_UP });
        try {
            const createSubPack = createSubAccountData({ ...this.state });
            const res = await axios.post(CREATE_SUB_ACCOUNT, createSubPack);
            const stripeInfo = res.data;
            await createArtistProfileForSub({...this.state}, stripeInfo);
            window.location.hash = ARTIST.ARTIST_HOME;
            this.onClose();
        } catch (ex) {
            console.clear();
            console.log(ex);
            this.setState({ screenToRender: SCHEDULE_SIGN_UP_ERROR });
        }
    }

    signUpPaymentArtist = async () => {
        this.setState({ screenToRender: PAYMENT_SIGN_UP });
        try {
            const createConnectPack = await createCreateConnectAccountData({ ...this.state });
            const res = await axios.post(CREATE_CONNECT_ACCOUNT2, createConnectPack);
            const stripeInfo = res.data;
            deleteImage(createConnectPack.frontIDRefPath);
            deleteImage(createConnectPack.backIDRefPath);
            await createArtistProfileForConnect({ ...this.state }, stripeInfo);
            window.location.hash = ARTIST.ARTIST_HOME;
            this.onClose();
        } catch (ex) {
            this.setState({ screenToRender: PAYMENT_SIGN_UP_ERROR });
        }
    }

    profileTypeChange = (type) => {
        this.setState({ type });
        if (type === ARTIST_PROFILE_TYPE) {
            this.navToArtistType();
        }
        if (type === CLIENT_PROFILE_TYPE) {
            this.navToBasicInfo();
        }
        if (type === SALON_PROFILE_TYPE) {
            this.navToBasicInfo();
        }
    }

    artistTypeChange = (isPayments) => {
        this.setState({ isPayments });
        if (isPayments) {
            this.navToPaymentDetails();
        } else {
            this.navToBasicInfo();
        }
    }

    artistCardChange = (cardTokens) => {
        this.setState({ cardTokens });
        if (this.state.isPayments) {
            this.navToStripeInfo();
        } else {
            window.setTimeout(this.signUpScheduleArtist, 1);
        }
    }

    toggleLoading = () => {
        this.setState({ loading: !this.state.loading });
    }

    basicInfoChange = ({ email, name, password, phone, profileImage }) => {
        this.setState({
            email,
            name,
            password,
            phone,
            profileImage
        });
        if (this.state.type === ARTIST_PROFILE_TYPE) {
            this.navToArtistInfo();
        } else if (this.state.type === SALON_PROFILE_TYPE) {
            this.navToSalonInfo();
        } else {
            window.setTimeout(this.signUpClient, 1);
        }
    }

    stripeInfoChange = ({ dob, personalAddress, backID, frontID, ssn }) => {
        this.setState({
            dob,
            personalAddress,
            backID,
            frontID,
            ssn
        });
        window.setTimeout(this.signUpPaymentArtist, 1);
    }

    artistInfoChange = ({ artistType, salonName, salonAddress }) => {
        this.setState({
            artistType,
            salonName,
            salonAddress,
        });
        this.navToArtistCardForm();
    }

    basicInfoBack = () => {
        if (this.state.type === ARTIST_PROFILE_TYPE) {
            if (this.state.isPayments) {
                this.navToPaymentDetails();
            } else {
                this.navToArtistType();
            }
        } else {
            this.navToProfileType();
        }
    }

    onClose = () => {
        this.setState({
            isPayments: false,
            email: '',
            name: '',
            password: '',
            phone: '',
            profileImage: null,
            artistType: '',
            salonName: '',
            salonAddress: null,
            personalAddress: null,
            cardTokens: [],
            dob: '',
            ssn: '',
            frontID: null,
            backID: null,
            loading: false,
            error: false,
            screenToRender: ''
        });
        this.props.onClose();
    }

    navToBasicInfo = () => {
        this.setState({ screenToRender: BASIC_INFO });
    }

    navToResetPassword = () => {
        this.setState({ screenToRender: FORGET_PASSWORD });
    }

    navToProfileType = () => {
        this.setState({ screenToRender: PROFILE_TYPE });
    }

    navToPaymentDetails = () => {
        this.setState({ screenToRender: PAYMENT_DETAILS });
    }

    navToSalonInfo = () => {
        this.setState({ screenToRender: SALON_INFO });
    }

    navToArtistType = () => {
        this.setState({ screenToRender: ARTIST_TYPE });
    }

    navToArtistInfo = () => {
        this.setState({ screenToRender: ARTIST_INFO });
    }

    navToStripeInfo = () => {
        this.setState({ screenToRender: STRIPE_INFO });
    }

    navToArtistCardForm = () => {
        this.setState({ screenToRender: ARTIST_CARD_FORM });
    }

    navToBody = () => {
        this.setState({ screenToRender: '' });
    }

    handleError = () => {
        if (this.state.screenToRender === CLIENT_SIGN_UP_ERROR) {
            this.navToBasicInfo();
        }
        if (this.state.screenToRender === SCHEDULE_SIGN_UP_ERROR) {
            this.navToArtistInfo();
        }
        if (this.state.screenToRender === PAYMENT_SIGN_UP_ERROR) {
            this.navToStripeInfo();
        }
    }

    renderBody = () => {
        return (
            <SignInForm 
                forgetPassword={this.navToResetPassword}
                signUp={this.navToProfileType}
                close={this.props.onClose}
                onSignIn={this.props.onSignIn}
            />
        )
    }

    renderProfileType = () => {
        return (
            <ProfileTypeForm 
                onBack={this.navToBody}
                onChange={this.profileTypeChange}
            />
        )
    }

    renderBasicForm = () => {
        return (
            <BasicInfoForm 
                onBack={this.basicInfoBack}
                onChange={this.basicInfoChange}
                onClose={this.onClose}
                isArtist={this.state.type === ARTIST_PROFILE_TYPE ? true : false}
                isSalon={this.state.type === SALON_PROFILE_TYPE ? true : false}
                email={this.state.email}
                password={this.state.password}
                phone={this.state.phone}
                name={this.state.name}
                profileImage={this.state.profileImage}
            />
        )
    }

    renderArtistType = () => {
        return (
            <ArtistTypeForm 
                onChange={this.artistTypeChange}
                onBack={this.navToProfileType}
            />
        )
    }

    renderArtistInfo = () => {
        return (
            <ArtistInfoForm 
                onBack={this.navToBasicInfo}
                salonName={this.state.salonName}
                salonAddress={this.state.salonAddress}
                artistType={this.state.artistType}
                onChange={this.artistInfoChange}
            />
        )
    }

    renderArtistCardForm = () => {
        return (
            <ArtistCardForm 
                onBack={this.navToArtistInfo}
                title='Card Info'
                isPayments={this.state.isPayments}
                onChange={this.artistCardChange}
            />
        )
    }

    renderStripeInfo = () => {
        return (
            <StripeInfoForm 
                onBack={this.navToArtistInfo}
                onChange={this.stripeInfoChange}
            />
        )
    }

    renderSalonInfo = () => {
        return (
            <SalonInfoForm 
                onBack={this.navToBasicInfo}
            />
        )
    }

    renderResetPassword = () => {
        return (
            <ResetPasswordForm 
                onBack={this.navToBody}
            />
        )
    }

    renderLoading = () => {
        return (
            <Body>
                <ModalLoader 
                    message='Creating Profile. Sit tight, this may take a minute...'
                />
            </Body>
        )
    }

    renderError = () => {
        return (
            <Body>
                <ModalError 
                    message='There was a problem creating your account. Make sure your information is correct and try again.'
                    onClick={this.handleError}
                />
            </Body>
        )
    }

    renderPaymentDetails = () => {
        return (
            <PaymentDetailsForm 
                onNext={this.navToBasicInfo}
                onBack={this.navToArtistType}
            />
        )
    }

    renderDecideBody = () => {
        switch (this.state.screenToRender) {
            case FORGET_PASSWORD:
                return this.renderResetPassword();
            case PROFILE_TYPE:
                return this.renderProfileType();
            case ARTIST_TYPE:
                return this.renderArtistType();
            case STRIPE_INFO:
                return this.renderStripeInfo();
            case ARTIST_INFO:
                return this.renderArtistInfo();
            case ARTIST_CARD_FORM:
                return this.renderArtistCardForm();
            case SALON_INFO:
                return this.renderSalonInfo();
            case BASIC_INFO:
                return this.renderBasicForm();
            case PAYMENT_DETAILS:
                return this.renderPaymentDetails();
            case CLIENT_SIGN_UP:
            case SCHEDULE_SIGN_UP:
            case PAYMENT_SIGN_UP:
                return this.renderLoading();
            case CLIENT_SIGN_UP_ERROR:
            case SCHEDULE_SIGN_UP_ERROR:
            case PAYMENT_SIGN_UP_ERROR:
                return this.renderError();
            default:
                return this.renderBody();
        }
    }

    decideBackground = () => {
        return buildProfileImageUrl(window.screen.width, window.screen.height, '', '');
    }

    render = () => {
        const { open } = this.props;
        return (
            <BottomUpModal
                open={open}
                onClose={this.onClose}
                height={.90}
                image={this.decideBackground()}
                darken
            >
                {this.renderDecideBody()}
            </BottomUpModal>
        )
    }
}

SignInModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSignIn: PropTypes.func,
};

SignInModal.defaultProps = {
    open: false,
    onClose: () => {},
    onSignIn: () => {},
};

export default connect(null, { fetchClientProfile })(SignInModal);