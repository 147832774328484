import React from 'react'
import PropTypes from 'prop-types';
import './styles/Service.css';
import { buildImageUrl } from '../util/ImageUtil';
import { convertMinutesToHoursAndMinutesReadable } from '../util/TimeUtil';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const Service = ({ service, onClick, overrideHeight = 280, selected = false }) => {

    const click = () => {
        onClick(service);
    }

    const getDuration = () => {
        if (service.duration.isDouble) {
            const total = parseInt(service.duration.doubleStart) + parseInt(service.duration.doubleBreak) + parseInt(service.duration.doubleEnd);
            return convertMinutesToHoursAndMinutesReadable(total);
        }
        return convertMinutesToHoursAndMinutesReadable(parseInt(service.duration.singleStart));
    }

    const renderSelected = () => {
        if (selected) {
            return (
                <div className='Service-SelectedContainer'>
                    <IoIosCheckmarkCircle className='Service-Check' />
                </div>
            )
        }
    }

    const width = window.screen.width - Math.ceil(window.screen.width * 0.05);
    const url = buildImageUrl(service.artistId, service.image, width, overrideHeight);
    const readableTime = getDuration();
    return (
        <div 
            className='Service-Container'
            onClick={click}
            style={{ height: overrideHeight }}
        >
            <img src={url} style={{ height: overrideHeight }} className='Service-Image' />
            <div className='Service-RowContainer'>
                <div className='Service-InfoContainer'>
                    <h3 className='Service-Name'>{service.name}</h3>
                    <h4 className='Service-Duration'>{readableTime}</h4>
                </div>
                <div className='Service-PriceContainer'>
                    <h3 className='Service-Price'>{`$${service.price}`}</h3>
                </div>
            </div>
            {renderSelected()}
        </div>
    )
}

Service.propTypes = {
    service: PropTypes.object,
    onClick: PropTypes.func,
    overrideHeight: PropTypes.number,
    selected: PropTypes.bool,
};

Service.defaultProps = {
    service: {},
    onClick: () => {},
    overrideHeight: 280,
    selected: false
}

export default Service;
