import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { 
    Body, 
    ImageBackground, 
    ListWrapper, 
    RowCalendar,
    TransparentHeader,
    Appointment,
    BrandButton
} from '../../components';
import { 
    artistPropChange, 
    fetchAppointmentHash, 
    fetchAppointments 
} from '../../actions/artistActions';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { ARTIST } from '../../constants/ClientRoutes';
import { collection, getFirestore, where, query, getDocs, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { parseDocs } from '../../util/FirestoreUtil';

class Appointments extends Component {

    componentDidMount = () => {
        this.props.fetchAppointmentHash();
        this.deleteOldAppointments();
    }

    getTodaysTimeAtMidnight = () => {
        const currentDate = new Date();
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const day = currentDate.getDate();
        const todaysTimeAtMidnight = new Date(year, month, day).getTime();
        return todaysTimeAtMidnight;
    }

    buildOldAppointmentsQuery = (db) => {
        const todaysTimeAtMidnight = this.getTodaysTimeAtMidnight();
        const ref = collection(db, `appointments`);
        const w1 = where('artistId', '==', this.props.profile.id);
        const w2 = where('unixTime', '<', todaysTimeAtMidnight);
        const q = query(ref, w1, w2);
        return  q;
    }

    deleteOldAppointment = async (db, appointment) => {
        const ref = doc(db, `appointments/${appointment.id}`);
        await deleteDoc(ref);
    }

    deleteOldAppointments = async () => {
        const db = getFirestore();
        const q = this.buildOldAppointmentsQuery(db);
        const snap = await getDocs(q).then();
        const { results } = parseDocs(snap);
        for (let i = 0; i < results.length; i++) {
            const appointment = results[i];
            await this.deleteOldAppointment(db, appointment);
        }
    }

    back = () => {
        window.history.back();
    }

    navToBook = () => {
        window.location.hash = ARTIST.ARTIST_BOOK;
    }

    selectDay = (day) => {
        if (this.props.appointmentListener) {
            this.props.appointmentListener();
        }
        if (day) {
            this.props.fetchAppointments(day);
        } else {
            this.props.artistPropChange('appointments', [])
            this.props.artistPropChange('selectedDay', day);
        }

    }
    
    navToAppointment = (appointment) => {
        window.location.hash = `${ARTIST.ARTIST_VIEW_APPOINTMENT}/${appointment.id}`;
    }

    renderAppointment = (appointment, index) => {
        return (
            <Appointment 
                key={index}
                appointment={appointment}
                onClick={this.navToAppointment}
            />
        )
    }

    render = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        const appointments = _.orderBy(this.props.appointments, 'unixTime', 'asc');
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    title='My Appointments'
                    showBack
                    onBack={this.back}
                />
                <Body>
                    <RowCalendar 
                        scheduleHash={this.props.scheduleHash}
                        vacationHash={this.props.vacationHash}
                        appointmentHash={this.props.appointmentHash}
                        onChange={this.selectDay}
                        selectedDay={this.props.selectedDay}
                    />
                    <ListWrapper>
                        {appointments.map(this.renderAppointment)}
                    </ListWrapper>
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Book Appointment'
                    onClick={this.navToBook}
                />
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        appointmentHash, 
        appointmentListener,
        appointments, 
        profile,
        selectedDay 
    } = state.artist;
    const { scheduleHash, vacationHash } = state.schedule;
    return {
        appointmentHash,
        appointments,
        profile,
        scheduleHash,
        appointmentListener,
        vacationHash,
        selectedDay
    }
}

export default connect(mapStateToProps, { 
    artistPropChange,
    fetchAppointmentHash,
    fetchAppointments
})(Appointments);
