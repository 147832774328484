import React from 'react';
import PropTypes from 'prop-types';
import { 
    FlexRowButton,
    ImageInput,
    Placeholder
} from '../components';
import { parseFile } from '../util/ImageUtil';
import './styles/IdInput.css';

const IdInput = ({ onChange, value, placeholder, style, id }) => {

    const change = (files) => {
        const id = parseFile(files[0]);
        onChange(id);
    }

    const openInput = () => {
        document.getElementById(id).click();
    }

    const renderImageInput = () => {
        return (
            <ImageInput 
                id={id}
                onChange={change}
            />
        )
    }

    const renderPlaceholder = () => {
        return (
            <Placeholder placeholder={placeholder} />
        )
    }

    const renderImage = () => {
        return (
            <img 
                src={value.url}
                className='IdInput-Image'
                onClick={openInput}
            />
        )
    }

    const renderDecide = () => {
        if (value) {
            return renderImage();
        }
        return renderPlaceholder();
    }

    return (
        <FlexRowButton
            onClick={openInput}
            style={style}
        >
            {renderImageInput()}
            {renderDecide()}
        </FlexRowButton>
    )
}

IdInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string
};

IdInput.defaultProps = {
    onChange: () => {},
    value: null,
    placeholder: '',
    style: {},
    id: ''
}

export default IdInput;
