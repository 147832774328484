export const generateDurations = () => {
    const durations = [];
    for (let i = 5; i <= 600; i+=5) {
        if (i >= 60) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const label = generateLabel(hours, minutes);
            const option = {
                label,
                value: i
            }
            durations.push(option);
        } else {
            const option = {
                label: `${i} mins`,
                value: i
            }
            durations.push(option);
        }
    }
    return durations;
}

const generateLabel = (hours, minutes) => {
    if (hours > 0 && minutes === 0) {
        if (hours > 1) {
            return `${hours} hrs`;
        }
        return `${hours} hr`;
    }
    if (hours > 1) {
        return `${hours} hrs ${minutes} mins`;
    }
    if (hours === 0) {
        return `${minutes} mins`
    }
    return `${hours} hr ${minutes} mins`;
}

export const generateLabelForMins = (mins) => {
    const min = mins % 60;
    const hrs = Math.floor(mins / 60);
    const label = generateLabel(hrs, min);
    return label;
}