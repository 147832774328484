import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    BrandButton,
    ModalRowBack,
    SettingsTitle,
    EasyInput
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { getAuth, signInWithEmailAndPassword, updatePassword } from '@firebase/auth';

class UpdateClientPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newPass: '',
            currentPass: '',
            confPass: '',
            errorMessage: ''
        }
    }

    save = async () => {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, this.props.profile.email, this.state.currentPass);
            await updatePassword(auth.currentUser, this.state.newPass);
            this.props.onBack();
        } catch (ex) {
            this.setState({ errorMessage: 'Invalid Password', newPass: '', currentPass: '' });
        }
    }

    canSave = () => {
        if (this.state.newPass === this.state.confPass && this.state.currentPass) {
            return true;
        }
        return false;
    }

    newChange = (newPass) => {
        this.setState({ newPass })
    }

    currentChange = (currentPass) => {
        this.setState({ currentPass });
    }

    confChange = (confPass) => {
        this.setState({ confPass });
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Update Password'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Password'
            />
        )
    }

    renderCurrent = () => {
        return (
            <EasyInput 
                value={this.state.currentPass}
                onChange={this.currentChange}
                type='password'
                placeholder='Current Password'
                label='Current Password'
            />
        )
    }

    renderNew = () => {
        return (
            <EasyInput 
                value={this.state.newPass}
                onChange={this.newChange}
                type='password'
                placeholder='New Password'
                label='New Password'
            />
        )
    }

    renderConfirm = () => {
        return (
            <EasyInput 
                value={this.state.confPass}
                onChange={this.confChange}
                type='password'
                label='Confirm New Password'
                placeholder='Confirm New Password'

            />
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.save}
                disabled={!this.canSave()}
            />
        )
    }

    renderError = () => {
        if (this.state.errorMessage) {
            return (
                <SettingsTitle 
                    title={this.state.errorMessage}
                    error
                />
            )
        }
    }

    render = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderError()}
                {this.renderCurrent()}
                {this.renderNew()}
                {this.renderConfirm()}
                {this.renderSave()}
            </Body>
        )
    }
}

UpdateClientPassword.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object
};

UpdateClientPassword.defaultProps = {
    onBack: () => {},
    profile: {}
}

export default UpdateClientPassword;