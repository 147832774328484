import React from 'react';
import { ArgendaLogo } from '../components';
import './styles/DesktopDialog.css';

const DesktopDialog = ({ }) => {
    return (
        <div className='DD-Container'>
            <ArgendaLogo width={window.screen.width * 0.3} />
            <h1 className='DD-Header'>Argenda is a mobile only app. To use Argenda, use on your mobile device.</h1>
        </div>
    )
}

export default DesktopDialog;