//auth action types
export const PROFILE_TYPE_CHANGE = 'PROFILE_TYPE_CHANGE';
export const AUTH_PROP_CHANGE = 'AUTH_PROP_CHANGE';

//artist action types
export const ARTIST_PROP_CHANGE = 'ARTIST_PROP_CHANGE';
export const ARTIST_FETCH_PROFILE = 'ARTIST_FETCH_PROFILE';
export const ARTIST_FETCH_IMAGES = 'ARTIST_FETCH_IMAGES';
export const ARTIST_FETCH_MORE_IMAGES = 'ARTIST_FETCH_MORE_IMAGES';
export const ARTIST_FETCH_APPOINTMENT_HASH = 'ARTIST_FETCH_APPOINTMENT_HASH';
export const ARTIST_FETCH_APPOINTMENTS = 'ARTIST_FETCH_APPOINTMENTS';
export const ARTIST_FETCH_POLICY_HASH = 'ARTIST_FETCH_POLICY_HASH';
export const ARTIST_FETCH_NOTIFICATION_HASH = 'ARTIST_FETCH_NOTIFICATION_HASH';
export const ARTIST_FETCH_NOTI = 'ARTIST_FETCH_NOTI';
export const ARTIST_CLEAR = 'ARTIST_CLEAR';
export const ARTIST_SETUP_PAGE_CHANGE = 'ARTIST_SETUP_PAGE_CHANGE';

//schedule action types
export const ARTIST_SCHEDULE_FETCH = 'ARTIST_SCHEDULE_FETCH';
export const ARTIST_SCHEDULE_PROP_CHANGE = 'ARTIST_SCHEDULE_PROP_CHANGE';
export const ARTIST_INIT_DAY_SELECTED = 'ARTIST_INIT_DAY_SELECTED';
export const ARTIST_RESET_SCHEDULE = 'ARTIST_RESET_SCHEDULE';
export const ARTIST_CLEAR_SCHEDULE = 'ARTIST_CLEAR_SCHEDULE';
export const ARTIST_CLEAR_BREAK = 'ARTIST_CLEAR_BREAK';

//service actions
export const ARTIST_FETCH_SERVICES = 'ARTIST_FETCH_SERVICES';
export const ARTIST_SERVICE_PROP_CHANGE = 'ARTIST_SERVICE_PROP_CHANGE';
export const ARTIST_SERVICE_CHANGE = 'ARTIST_SERVICE_CHANGE';
export const ARTIST_FETCH_CUSTOM_SERVICES = 'ARTIST_FETCH_CUSTOM_SERVICES';
export const ARTIST_CLEAR_SERVICE = 'ARTIST_CLEAR_SERVICE';

//client actions
export const CLIENT_PROP_CHANGE = 'CLIENT_PROP_CHANGE';
export const CLIENT_FETCH_PROFILE = 'CLIENT_FETCH_PROFILE';
export const CLIENT_FETCH_APPOINTMENTS = 'CLIENT_FETCH_APPOINTMENTS';
export const CLIENT_FETCH_NOTIS = 'CLIENT_FETCH_NOTIS';
export const CLIENT_CLEAR = 'CLIENT_CLEAR';
export const CLIENT_PURGE = 'CLIENT_PURGE';

//facing actions
export const FACING_PROP_CHANGE = 'FACING_PROP_CHANGE';
export const FACING_CLOSE_SEARCH = 'FACING_CLOSE_SEARCH';
export const FACING_PILL_SEARCH = 'FACING_PILL_SEARCH';
export const FACING_PILL_PAGE = 'FACING_PILL_PAGE';
