import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/Calendar.css';
import { buildCalendarData, convertMonthNumberToString, calcMinDate } from '../util/DateUtil';


class Calendar extends Component {

    selectDay = (day) => {
        const minUnixTime = calcMinDate();
        if (day.unixTime >= minUnixTime) {
            this.props.onSelect(day);
        }
    }

    decideDayClass = (day, selected) => {
        const minUnixTime = calcMinDate();
        if (day.unixTime < minUnixTime) {
            return 'Calendar-DayTitle Calendar-Disabled';
        }
        if (selected) {
            return 'Calendar-DayTitle Calendar-Selected';
        }
        return 'Calendar-DayTitle';
    }

    renderTopDays = () => {
        return (
            <div className='Calendar-TopDayRow'>
                <div className='Calendar-TopDay'>
                    <h4>Sun</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Mon</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Tue</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Wed</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Thur</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Fri</h4>
                </div>
                <div className='Calendar-TopDay'>
                    <h4>Sat</h4>
                </div>
            </div>
        )
    }

    renderDay = (day, index) => {
        const selected = this.props.vacationHash[day.dateString] ? true : false;
        return (
            <div 
                className='Calendar-Day'
                key={index}
                onClick={() => this.selectDay(day)}
            >
                <h3 className={this.decideDayClass(day, selected)}>{index + 1}</h3>
            </div>
        )
    }

    renderCustomSpacer = (startingDayIndex) => {
        if (startingDayIndex > 0) {
            const widthPerDay = window.screen.width / 7;
            const customWidth = startingDayIndex * widthPerDay;
            return (
                <div
                    className='Calendar-Spacer'
                    style={{ width: customWidth }}
                />
            )
        }
    }

    renderMonth = (month, index) => {
        const startingDayIndex = month.days[0].day;
        return (
            <div 
                key={index} 
                className='Calendar-Month'
            >
                <p className='Calendar-MonthTitle'>{`${convertMonthNumberToString(month.month)} ${month.year}`}</p>
                <div className='Calendar-MonthRow'>
                    {this.renderCustomSpacer(startingDayIndex)}
                    {month.days.map(this.renderDay)}
                </div>
            </div>
        )
    }

    render = () => {
        const calendarData = buildCalendarData(4);
        return (
            <div className='Calendar-Container'>
                {this.renderTopDays()}
                <div className='Calendar-Body'>
                    <div className='Calendar-InnerBody'>
                        {calendarData.map(this.renderMonth)}
                    </div>

                </div>
            </div>
        )
    }
}

Calendar.propTypes = {
    onSelect: PropTypes.func,
    vacationHash: PropTypes.object
}

Calendar.defaultProps = {
    onSelect: () => {},
    vacationHash: {}
}

export default Calendar;