import React, { Component } from 'react';
import { 
    FlexRow,
    FlexRowButton,
    BrandButton,
    BottomUpModal
} from '../components';
import PropTypes from 'prop-types';
import './styles/RepeatControl.css';
import { ACTIVE_LABEL, BOTTOM_BUTTON, INACTIVE_LABEL, TIME_LABEL } from '../universalStyles/Styles';
import { convertWebTimeStringToReadable } from '../util/TimeUtil';
import { readifyDay } from '../util/DayUtil';
import ButtonLabel from './ButtonLabel';
import { 
    FRI, 
    MON, 
    SAT, 
    SUN, 
    THUR, 
    TUE, 
    WED,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
} from '../constants/Days';

class RepeatControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            repeatHash: props.repeatHash
        }
    }

    componentDidUpdate = () => {
        if (!this.props.open && Object.keys(this.state.repeatHash).length > 0) {
            this.setState({ repeatHash: {} });
        }
    }

    save = () => {
        this.props.onSave(this.state.repeatHash);
        this.props.onClose();
    }

    selectDay = (day) => {
        if (day !== this.props.daySelected) {
            const repeatHash = { ...this.state.repeatHash };
            if (repeatHash[day]) {
                delete repeatHash[day];
            } else {
                repeatHash[day] = true;
            }
            this.setState({ repeatHash });
        }

    }

    decideBorderStyle = (selected, daySelected, left) => {
        if (left) {
            if (selected || daySelected) {
                return { ...styles.marginRight, ...styles.activeBorder };
            }
            return styles.marginRight;
        }
        if (selected || daySelected) {
            return styles.activeBorder;
        }
        return {};
    }

    readifySchedule = (schedule) => {
        const messages = [];
        if (schedule) {
            if (schedule.breakStart && schedule.breakEnd) {
                messages.push(`${convertWebTimeStringToReadable(schedule.start)} - ${convertWebTimeStringToReadable(schedule.breakStart)}`);
                messages.push(`${convertWebTimeStringToReadable(schedule.breakEnd)} - ${convertWebTimeStringToReadable(schedule.end)}`);
            } else {
                messages.push(`${convertWebTimeStringToReadable(schedule.start)} - ${convertWebTimeStringToReadable(schedule.end)}`);
            }
        }

        return messages;
    }

    decideSchedule = (day, selected) => {
        if (selected) {
            return this.readifySchedule(this.props.schedule)
        } else {
            return this.readifySchedule(this.props.scheduleHash[day]);
        }
    }

    renderDecideSchedule = (day, selected) => {
        const messages = this.decideSchedule(day, selected);
        if (messages.length > 0) {
            if (messages.length === 1) {
                return (
                    <div className='RepeatControl-TimeContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={selected ? { ...ACTIVE_LABEL, ...TIME_LABEL } : { ...INACTIVE_LABEL, ...TIME_LABEL}}
                        />
                    </div>
                )
            } else {
                return (
                    <div className='RepeatControl-TimeContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={selected ? { ...ACTIVE_LABEL, ...TIME_LABEL } : { ...INACTIVE_LABEL, ...TIME_LABEL}}
                        />                        
                        <ButtonLabel 
                            label={messages[1]}
                            style={selected ? { ...ACTIVE_LABEL, ...TIME_LABEL } : { ...INACTIVE_LABEL, ...TIME_LABEL}}
                        />
                    </div>
                )
            }
        }
        return null;
    }
    
    renderMonday = () => {
        const selected = this.state.repeatHash[MONDAY] ? true : false;
        const daySelected = (MONDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, true);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(MONDAY)}
            >
                <ButtonLabel 
                    label={MON}
                />
                {this.renderDecideSchedule(MONDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    renderTuesday = () => {
        const selected = this.state.repeatHash[TUESDAY] ? true : false;
        const daySelected = (TUESDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, false);
        return (
            <FlexRowButton
                onClick={() => this.selectDay(TUESDAY)}
                style={borderStyle}
            >
                <ButtonLabel 
                    label={TUE}
                />
                {this.renderDecideSchedule(TUESDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    renderWendesday = () => {
        const selected = this.state.repeatHash[WEDNESDAY] ? true : false;
        const daySelected = (WEDNESDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, true);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(WEDNESDAY)}
            >
                <ButtonLabel 
                    label={WED}
                />
                {this.renderDecideSchedule(WEDNESDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    renderThursday = () => {
        const selected = this.state.repeatHash[THURSDAY] ? true : false;
        const daySelected = (THURSDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, false);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(THURSDAY)}
            >
                <ButtonLabel 
                    label={THUR}
                />
                {this.renderDecideSchedule(THURSDAY, selected || daySelected)}
            </FlexRowButton>
        ) 
    }
    
    renderFriday = () => {
        const selected = this.state.repeatHash[FRIDAY] ? true : false;
        const daySelected = (FRIDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, true);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(FRIDAY)}
            >
                <ButtonLabel 
                    label={FRI}
                />
                {this.renderDecideSchedule(FRIDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    renderSaturday = () => {
        const selected = this.state.repeatHash[SATURDAY] ? true : false;
        const daySelected = (SATURDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, false);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(SATURDAY)}
            >
                <ButtonLabel 
                    label={SAT}
                />
                {this.renderDecideSchedule(SATURDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    renderSunday = () => {
        const selected = this.state.repeatHash[SUNDAY] ? true : false;
        const daySelected = (SUNDAY === this.props.daySelected);
        const borderStyle = this.decideBorderStyle(selected, daySelected, false);
        return (
            <FlexRowButton
                style={borderStyle}
                onClick={() => this.selectDay(SUNDAY)}
            >
                <ButtonLabel 
                    label={SUN}
                />
                {this.renderDecideSchedule(SUNDAY, selected || daySelected)}
            </FlexRowButton>
        )
    }

    render = () => {
        return (
            <BottomUpModal 
                onClose={this.props.onClose}
                title={`Select the days you want to repeat ${readifyDay(this.props.daySelected)}'s schedule for.`}
                open={this.props.open}
                image={this.props.image}
                darken
                height={.85}
            >
                <FlexRow>
                    {this.renderMonday()}
                    {this.renderTuesday()}
                </FlexRow>
                <FlexRow>
                    {this.renderWendesday()}
                    {this.renderThursday()}
                </FlexRow>
                <FlexRow>
                    {this.renderFriday()}
                    {this.renderSaturday()}
                </FlexRow>
                <FlexRow>
                    {this.renderSunday()}
                </FlexRow>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Save'
                    onClick={this.save}
                />
            </BottomUpModal>
        )
    }
}

const styles = {
    marginRight: {
        marginRight: 10
    },
    innactiveLabel: {
        color: 'rgba(255, 255, 255, 0.2)',
    },
    activeLabel: {
        color: '#fff'
    },
    activeBorder: {
        borderColor: '#fff'
    },
}

RepeatControl.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    daySelected: PropTypes.string,
    schedule: PropTypes.object,
    scheduleHash: PropTypes.object,
    onSave: PropTypes.func,
    repeatHash: PropTypes.object,
    image: ''
}

RepeatControl.defaultProps = {
    open: false,
    onClose: () => {},
    daySelected: '',
    schedule: {},
    scheduleHash: {},
    onSave: () => {},
    repeatHash: {},
    image: ''
}

export default RepeatControl;