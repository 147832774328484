import { FACING_CLOSE_SEARCH, FACING_PILL_PAGE, FACING_PILL_SEARCH, FACING_PROP_CHANGE } from "../actionTypes/actionTypes"
import { searchImages } from "../util/AlgoliaUtil";

export const facingPropChange = (prop, value) => {
    return { type: FACING_PROP_CHANGE, payload: { prop, value }};
}

export const facingCloseSearch = () => {
    return { type: FACING_CLOSE_SEARCH };
}

export const facingPillSearch = (pillSearch, coords = null) => {
    return async (dispatch) => {
        const images = await searchImages(pillSearch, coords, 0, calcImagesToFetch());
        dispatch({ 
            type: FACING_PILL_SEARCH,
            payload: { images, pillSearch } 
        });
    }
}

export const facingPillPage = (pillSearch, coords = null, page, images) => {
    return async (dispatch) => {
        const newImages = await searchImages(pillSearch, coords, page, calcImagesToFetch());
        const result = [...images, ...newImages];
        dispatch({ 
            type: FACING_PILL_PAGE,
            payload:{
                images: result,
                imagePage: page
            } 
        })
    }
}

const calcImagesToFetch = () => {
    const width = window.screen.width / 3;
    const rows = Math.ceil(window.screen.height / width);
    return rows * 3;
}