import React from 'react';
import PropTypes from 'prop-types';
import './styles/MeridControl.css';

const MeridControl = ({ isAm, onChange }) => {

    const selectAm = () => {
        onChange(true);
    }

    const selectPm = () => {
        onChange(false);
    }

    const calcWidth = () => {
        const width = (window.screen.width / 2.3);
        return width;
    }

    const styleOuterContainer = (isLeft) => { 
        if (isLeft) {
            return { marginRight: 15, width: calcWidth() + 6, height: 46 }
        }
        return { width: calcWidth() + 6, height: 46 }
    }

    const styleInnerContainer = () => {
        return { width: calcWidth(), height: 40, left: 3, top: 3 }
    }

    const decideContainerClass = (isLeft) => {
        if (isLeft) {
            if (isAm) {
                return 'MC-TabContainer MC-ContainerSelected';
            }
            return 'MC-TabContainer';
        }
        if (!isAm) {
            return 'MC-TabContainer MC-ContainerSelected';
        }
        return 'MC-TabContainer';
    }

    const decideTabClass = (isLeft) => {
        if (isLeft) {
            if (isAm) {
                return 'MC-Tab MC-TabSelected';
            }
            return 'MC-Tab';
        }
        if (!isAm) {
            return 'MC-Tab MC-TabSelected';
        }
        return 'MC-Tab';
    }

    return (
        <div className='MC-Container'>
            <div onClick={selectAm} className={decideContainerClass(true)} style={styleOuterContainer(true)}>
                <div style={styleInnerContainer()} className={decideTabClass(true)}>
                    <h3 className='MC-Label'>AM</h3>
                </div>
            </div>   
            <div onClick={selectPm} className={decideContainerClass(false)} style={styleOuterContainer(false)}>
                <div style={styleInnerContainer()} className={decideTabClass(false)}>
                    <h3 className='MC-Label'>PM</h3>
                </div>
            </div>   
        </div>
    )
}

MeridControl.propTypes = {
    isAm: PropTypes.bool,
    onChange: PropTypes.func,
};

MeridControl.defaultProps = {
    isAm: false,
    onChange: () => {}
}

export default MeridControl;