import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, ListWrapper } from '.';
import ReactLoading from 'react-loading';
import { searchPlaces2 } from '../util/GooglePlacesUtil';
import './styles/AddressPickerModal.css'

let timer = '';

class AddressPickerModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            coords: null,
            addresses: [],
            loading: false
        }
    }

    componentDidMount = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { timeout: 1000 });
        }
    }

    getCoordsError = (error) => {
        console.log(error);
    }

    getCoords = (event) => {
        this.setState({
            coords: {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude
            }
        });
    }

    search = async () => {
        const addresses = await searchPlaces2(this.state.search, this.state.coords);
        this.setState({ addresses, loading: false });
    }

    searchChange = (e) => {
        this.setState({ search: e.target.value });
    }

    onKeyUp = () => {
        //if a timer exists clear it
        if (timer) {
            clearTimeout(timer);
        }
        //create a new timer for 1500 ms, when timer runs out, run search
        timer = setTimeout(this.search, 700);
    }

    onKeyDown = () => {
        this.setState({ loading: true });
        if (timer) {
            clearTimeout(timer);
        }
    }

    selectAddress = (addressData) => {
        console.log(addressData);
        const address = {
            address: addressData.formatted_address,
            _geoloc: addressData.geometry.location
        }
        this.props.onChange(address);
        this.props.onClose();
    }

    renderLoader = () => {
        return (
            <div className='AddressPickerModal-Loading'>
                <ReactLoading 
                    type='spokes'
                    color={'rgba(255, 255, 255, 0.3)'}
                />
            </div>
        )
    }

    renderAddress = (address, index) => {
        return (
            <div 
                key={index}
                className='AddressPickerModal-Address'
                onClick={() => this.selectAddress(address)}
            >
                <p>{address.formatted_address}</p>
            </div>
        )
    }

    renderAddresses = () => {
        return (
            <div className='AddressPickerModal-List'>
                <ListWrapper>
                    {this.state.addresses.map(this.renderAddress)}
                </ListWrapper>
            </div>
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderAddresses();
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='Find Your Address'
            >
                <div className='AddressPickerModal-Container'>
                    <input 
                        className='AddressPickerModal-Search'
                        placeholder='Search Address...'
                        value={this.state.search}
                        onChange={this.searchChange}
                        onKeyDown={this.onKeyDown}
                        onKeyUp={this.onKeyUp}
                        ref={ref => this.input = ref}
                    />
                    {this.renderDecide()}
                </div>
            </BottomUpModal>
        )
    }
}

AddressPickerModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func
}

AddressPickerModal.deafultProps = {
    open: false,
    onClose: () => {},
    onChange: () => {}
}

export default AddressPickerModal;