import React from 'react';
import PropTypes from 'prop-types';
import './styles/BrandTabButton.css';

const BrandTabButton = ({ onClick, label, selected }) => {

    const calcWidth = () => {
        const width = (window.screen.width / 2.3);
        return width;
    }

    const styleOuterContainer = () => { 
        return { width: calcWidth() + 6, height: 46 }
    }

    const styleInnerContainer = () => {
        return { width: calcWidth(), height: 40, left: 3, top: 3 }
    }

    const decideContainerClass = () => {
        if (selected) {
            return 'BTB-TabContainer BTB-ContainerSelected';
        }
        return 'BTB-TabContainer';
    }

    const decideTabClass = () => {
        if (selected) {
            return 'BTB-Tab BTB-TabSelected';
        }
        return 'BTB-Tab';
        
    }

    return (
        <div 
            onClick={onClick}
            className={decideContainerClass()}
            style={styleOuterContainer()}
        >
            <div
                className={decideTabClass()}
                style={styleInnerContainer()}
            >
                <h3 className='BTB-Label'>{label}</h3>
            </div>
        </div>
    )
}

BrandTabButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    selected: PropTypes.bool,
    style: PropTypes.object
};

BrandTabButton.defaultProps = {
    onClick: () => {},
    label: '',
    selected: false,
    style: {}
}

export default BrandTabButton;