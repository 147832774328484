import React from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    FlexRow,
    FlexRowButton,
    ButtonSubLabel,
    ModalRowBack,
    SettingsTitle,
    BigTitle
} from '../components';
import { APPOINTMENT_SUBSCRIPTION_PRICE, ARGENDA_PLUS_STRIPE_FEE, SCHEDULING_SUBSCRIPTION_PRICE, STRIPE_PERCENTAGE } from '../constants/Prices';

const ArtistTypeForm = ({ onBack, onChange }) => {

    const scheduleClick = () => {
        onChange(false);
    }

    const paymentClick = () => {
        onChange(true);
    }

    const renderScheduling = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={scheduleClick}>
                    <SettingsTitle title='Just Scheduling' />
                    <ButtonSubLabel subLabel={`$${SCHEDULING_SUBSCRIPTION_PRICE}/month`} />
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderPayments = () => {
        return (
            <FlexRow>
                <FlexRowButton style={{ marginBottom: 30 }} onClick={paymentClick}>
                    <SettingsTitle title='Scheduling + Payments' />
                    <ButtonSubLabel subLabel={`$${APPOINTMENT_SUBSCRIPTION_PRICE}/month and $${ARGENDA_PLUS_STRIPE_FEE.toFixed(2)} + ${(STRIPE_PERCENTAGE * 100)}% per appointment.`} />               
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={onBack}
            />
        )
    }

    return (
        <Body>
            {renderBack()}
            <BigTitle title='What type of account would you like?' />
            {renderScheduling()}
            {renderPayments()}
        </Body>
    )
}

ArtistTypeForm.propTypes = {
    onBack: PropTypes.func, 
    onChange: PropTypes.func,
};

ArtistTypeForm.defaultProps = {
    onBack: () => {},
    onChange: () => {}
}

export default ArtistTypeForm;