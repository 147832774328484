import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground, 
    TransparentHeader,
    Body,
    ButtonLabel,
    Bullet,
    PriceLabel,
    BrandButton,
    BottomUpModal,
    CardModal
} from '../../components';
import { artistPropChange  } from '../../actions/artistActions';
import { ARTIST } from '../../constants/ClientRoutes';
import { 
    APPOINTMENT_SUBSCRIPTION_PRICE, 
    ARGENDA_PLUS_STRIPE_FEE, 
    STRIPE_PERCENTAGE 
} from '../../constants/Prices';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { calcStripeFee } from '../../util/StripeUtil';

const EXAMPLE_COST = 45;

class Upgrade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            exampleOpen: false,
            cardOpen: false
        }
    }

    back = () => {
        window.history.back();
    }

    toggleCard = () => {
        this.setState({ cardOpen: !this.state.cardOpen });
    }

    cardChange = (tokens) => {
        this.props.artistPropChange('upgradeTokens', tokens);
        this.navToUpgradeId();
    }
    
    toggleExample = () => {
        this.setState({ exampleOpen: !this.state.exampleOpen });
    }

    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }

    continue = () => {
        this.toggleOpen();
        this.toggleCard();
    }

    navToUpgradeId = () => {
        window.location.hash = ARTIST.ARTIST_TO_UPGRADE_ID;
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                title='Upgrade'
                onBack={this.back}
            />
        )
    }
    
    renderBody = () => {
        return (
            <Body>
                <ButtonLabel 
                    style={styles.margin15}
                    label={`Are you sure you want to upgrade to Scheduling + Payments?`}
                />
                <PriceLabel 
                    label={`$${APPOINTMENT_SUBSCRIPTION_PRICE}/month and $${ARGENDA_PLUS_STRIPE_FEE} + ${(STRIPE_PERCENTAGE * 100)}% per appointment.`}
                    onClick={this.toggleExample}
                />
                <Bullet 
                    style={styles.margin20}
                    label='Allow your clients to pay you through Argenda'                
                />
                <Bullet 
                    style={styles.margin15}
                    label='Get paid direct deposits straight into your account via a Debit Card.'
                />
                <Bullet 
                    style={styles.margin15}
                    label='Get annual info for tax deductions.'
                />
                <Bullet 
                    style={styles.margin15}
                    label='Get monthly dashboards to see your growth.'
                />
                <Bullet 
                    style={styles.margin15}
                    label='No Show and Cancellation Protection'                
                />
            </Body>
        )
    }

    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Continue'
                onClick={this.toggleOpen}
            />
        )
    }

    renderFinePrint = () => {
        return (
            <BottomUpModal
                open={this.state.open}
                onClose={this.toggleOpen}
                height={0.7}
                title='The Fine Print'
            >
                <Body>
                    <Bullet 
                        label='Your first payout typically becomes available after 7 business days. This delay allows Argenda to mitigate the risks inherent in providing credit services.'
                    />
                    <Bullet 
                        label='When a client pays you, the payment goes into a pending balance.'
                        style={styles.margin15}
                    />
                    <Bullet 
                        label='It takes 2 days from the transaction date for the pending amount to become available.'
                        style={styles.margin15}
                    />
                    <Bullet 
                        label='Once an amount becomes available, it is instantly paid out and direct deposited into your bank account via Debit Card.'
                        style={styles.margin15}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Continue'
                    onClick={this.continue}
                />
            </BottomUpModal>
        )
    }

    renderExample = () => {
        return (
            <BottomUpModal
                open={this.state.exampleOpen}
                onClose={this.toggleExample}
                title='For Example'
                height={0.25}
            >
                <ButtonLabel 
                    label={`If an appointment costs $${EXAMPLE_COST}, the Aregenda fee will be $${((calcStripeFee(EXAMPLE_COST)) / 100).toFixed(2)}.`}
                    style={styles.example}
                />
            </BottomUpModal>
        )
    }

    renderCard = () => {
        return (
            <CardModal
                open={this.state.cardOpen}
                onClose={this.toggleCard}
                enforceDebit
                tokenCount={2}
                showPreview
                onChange={this.cardChange}
                message='To continue you must supply a debit card.'
            />
        )
    }

    render = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return (
            <ImageBackground
                image={url}
                darken
            >
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderButton()}
                {this.renderFinePrint()}
                {this.renderExample()}
                {this.renderCard()}
            </ImageBackground>
        )
    }
}

const styles = {
    margin20: {
        marginTop: 20
    },
    margin15: {
        marginTop: 15
    },
    example: {
        width: '75%'
    }
}


const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile };
}

export default connect(mapStateToProps, { artistPropChange })(Upgrade);