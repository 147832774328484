import React from 'react';
import PropTypes from 'prop-types';
import './styles/MapMarker.css';

const MapMarker = ({ profile }) => {
    return (
        <div 
            className='MapMarker-Marker'
        >   
            <h5 className='MapMarker-SalonName'>{profile.salonName}</h5>
            <p className='MapMarker-Address'>{profile.address}</p>
        </div>
    )
}

MapMarker.propTypes = {
    profile: PropTypes.object,
};

MapMarker.defaultProps = {
    profile: {}
}

export default MapMarker;