import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StripeId, Body, Loader } from '../../components';
import { UPGRADE_TO_PAYMENTS } from '../../constants/ArgendaServerURLS';
import { ARTIST } from '../../constants/ClientRoutes';
import { buildProfileImageUrl, deleteImage } from '../../util/ImageUtil';
import { createCreateConnectAccountData } from '../../util/StripeUtil';

class UpgradeIdInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            error: false,
        }
    }
    
    componentDidMount = () => {

    }

    onBack = () => {
        window.history.back();
    }

    buildConnectAccountPack = (idPack) => {
        const { profile, upgradeTokens: cardTokens } = this.props;
        const { 
            dob,
            personalAddress,
            frontId: frontID,
            backId: backID,
            ssn,
        } = idPack;
        const {
            email,
            phone,
            name
        } = profile;
        return {
            frontID,
            backID,
            personalAddress,
            dob,
            email,
            name,
            ssn,
            phone,
            cardTokens
        }
    }

    buildUpgradePack = (createConnectPack) => {
        return {
            connectData: createConnectPack.connectData,
            customerId: this.props.profile.stripeCustomerId,
            stripeSubId: this.props.profile.stripeSubId,
            userId: this.props.profile.id,
            frontUrl: createConnectPack.frontUrl,
            backUrl: createConnectPack.backUrl,
            customerCardToken: createConnectPack.customerCardToken
        }
    }

    deleteOldIds = (createConnectPack) => {
        deleteImage(createConnectPack.frontIDRefPath);
        deleteImage(createConnectPack.backIDRefPath);
    }

    upgrade = async (idPack) => {
        try {
            this.setState({ loading: true });
            const pack = this.buildConnectAccountPack(idPack);
            const createConnectPack = await createCreateConnectAccountData(pack);
            const upgradePack = this.buildUpgradePack(createConnectPack);
            await axios.post(UPGRADE_TO_PAYMENTS, upgradePack);
            this.deleteOldIds(createConnectPack);
            this.setState({ success: true });
        } catch (ex) {
            console.log(ex);
        }
    }

    decideMessage = () => {
        if (this.state.success) {
            return 'Account Upgraded';
        }
        if (this.state.error) {
            return 'Something went wrong.';
        }
        return 'Upgrading Your Account...';
    }

    navToHome = () => {
        window.location.hash = ARTIST.ARTIST_HOME;
    }

    renderLoader = () => {
        return (
            <Loader 
                open={this.state.loading}
                message={this.decideMessage()}
                error={this.state.error}
                success={this.state.success}
                onClick={this.navToHome}
            />
        )
    }

    render = () => {
        const image = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage)
        return (
            <Body relative>
                <StripeId 
                    image={image}
                    onBack={this.onBack}
                    title='Identification'
                    buttonLabel='Upgrade'
                    next={this.upgrade}
                />
                {this.renderLoader()}
            </Body>

        )
    }
}

const mapStateToProps = (state) => {
    const { profile, upgradeTokens } = state.artist;
    return { profile, upgradeTokens };
}

export default connect(mapStateToProps, { })(UpgradeIdInfo);