import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    BrandButton, 
    Header, 
    ImageBackground, 
    Loader, 
    TimeSlotPicker, 
    TransparentHeader,
    ServicePicker,
    PolicyModal,
    SlotButton,
    ServiceButton,
    ConflictModal
} from '../../components';
import { withRouter } from "react-router-dom";
import { 
    addDoc, 
    collection, 
    doc, 
    getDoc, 
    getFirestore, 
} from 'firebase/firestore';
import { 
    buildImageUrl, 
    buildProfileImageUrl 
} from '../../util/ImageUtil';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { 
    doesAppointmentConflict, 
    updateAppointmentHashForArtist, 
    updateBookStats
} from '../../util/AppointmentUtil';
import { CANCEL_FEE, NO_SHOW_FEE, fetchPolicy, APPOINTMENT, IN_APP } from '../../constants/Policies';
import { fetchNotiHash, handleNoti } from '../../util/NotiUtil';
import { ARTIST_APPOINTMENT } from '../../constants/NotiTypes';
import { convertUnixTimeToReadable } from '../../util/TimeUtil';
import { ARTIST } from '../../constants/ClientRoutes';

class Book extends Component {

    constructor(props) {
        super(props);
        this.state = {
            artist: null,
            service: null,
            timeSlotOpen: false,
            serviceOpen: false,
            slot: null,
            booking: false, 
            policyHash: {},
            notiHash: {},
            policyOpen: false,
            hasConflict: false,
            success: false,
        }
    }

    componentDidMount = async () => {
        await this.loadArtistAndService();
    }

    onBack = () => {
        window.history.back();
    }

    toggleConflict = () => {
        this.setState({ hasConflict: !this.state.hasConflict });
    }
    
    canBook = () => {
        if (this.state.slot && this.state.service) {
            return true;
        }
        return false;
    }

    buildAppointment = (service, slot, artist, profile, tos) => {
        return {
            service,
            startTime: slot.slot.startTime,
            endTime: slot.slot.endTime,
            day: slot.selectedDay.dateString,
            unixTime: slot.unixTime,
            slot: slot,
            artistId: artist.id,
            artistName: artist.name,
            artistPhone: artist.phone,
            clientId: profile.id,
            clientName: profile.name,
            clientPhone: profile.phone,
            tos
        }
    }

    bookAppointment = async (tos = {}) => {
        this.setState({ booking: true });
        const { service, slot, artist } = this.state;
        const { profile } = this.props;
        const appointment = this.buildAppointment(service, slot, artist, profile, tos);
        const doesConflict = await doesAppointmentConflict(appointment);
        if (!doesConflict) {
            const db = getFirestore();
            const ref = collection(db, 'appointments');
            const apptRef = await addDoc(ref, appointment);
            await updateAppointmentHashForArtist(artist.id, appointment.day);
            await updateBookStats(appointment, artist, profile);
            const method = (this.state.notiHash && this.state.notiHash[APPOINTMENT]) ? this.state.notiHash[APPOINTMENT] : IN_APP;
            const preview = `${profile.name} booked an appointment with you.`
            const desc = `${profile.name} booked an appointment with you on ${convertUnixTimeToReadable(slot.unixTime, true)}.`
            const link = `${ARTIST.ARTIST_VIEW_APPOINTMENT}/${apptRef.id}`;
            await handleNoti(artist.phone, desc, preview, ARTIST_APPOINTMENT, artist.id, method, link);
            this.setState({ success: true });
        } else {
            this.setState({ booking: false })
            this.toggleConflict();
        }
    }

    togglePolicy = () => {
        this.setState({ policyOpen: !this.state.policyOpen });
    }

    decideBookClick = async () => {
        if (this.state.policyHash) {
            const cancelFee = parseFloat(this.state.policyHash[CANCEL_FEE]);
            const noShowFee = parseFloat(this.state.policyHash[NO_SHOW_FEE]);
            if (cancelFee > 0 || noShowFee > 0) {
                this.togglePolicy();
            } else {
                await this.bookAppointment();
            }
        } else {
            await this.bookAppointment();
        }
        
    }

    hasConflictRestart = () => {
        this.setState({ slot: null, hasConflict: false });
        this.toggleTimeSlot();
    }

    toggleTimeSlot = () => {
        this.setState({ timeSlotOpen: !this.state.timeSlotOpen });
    }

    toggleServicePicker = () => {
        this.setState({ serviceOpen: !this.state.serviceOpen });
    }

    serviceChange = (service) => {
        this.setState({ service, slot: null });
    }

    slotChange = (slot) => {
        this.setState({ slot });
    }

    loadArtistAndService = async () => {
        const { artistId, serviceId } = this.props.match.params;
        let artist = null;
        const db = getFirestore();
        const artistRef = doc(db, `users/${artistId}`);
        const artistSnap = await getDoc(artistRef).then();
        artist = { ...artistSnap.data(), id: artistSnap.id };
        const policyHash = await fetchPolicy(artistId);
        const notiHash = await fetchNotiHash(artistId);
        if (serviceId) {
            const serviceRef = doc(db, `users/${artistId}/services/${serviceId}`);
            const serviceSnap = await getDoc(serviceRef).then();
            const service = {
                ...serviceSnap.data(),
                id: serviceSnap.id
            };
            const customServiceRef = doc(db, `users/${artistId}/services/${serviceId}/custom/${this.props.profile.id}`);
            const customServiceSnap = await getDoc(customServiceRef).then();
            if (customServiceSnap.exists()) {
                const customService = {
                    ...customServiceSnap.data(),
                    id: customServiceSnap.id,
                    image: service.image,
                }
                this.setState({ service: customService });
            } else {
                this.setState({ service });
            }
        }
        this.setState({ artist, policyHash, notiHash });
    }

    renderTimeSlotPicker = () => {
        return ( 
            <TimeSlotPicker 
                open={this.state.timeSlotOpen}
                onClose={this.toggleTimeSlot}
                artistId={this.props.match.params.artistId}
                service={this.state.service}
                onChange={this.slotChange}
            />
        )
    }

    renderServicePicker = () => {
        return (
            <ServicePicker 
                open={this.state.serviceOpen}
                artistId={this.props.match.params.artistId}
                onClose={this.toggleServicePicker}
                onChange={this.serviceChange}
                clientId={this.props.profile.id}
            />
        )
    }

    renderSlotButton = () => {
        if (this.state.service) {
            return (
                <SlotButton 
                    slot={this.state.slot}
                    onClick={this.toggleTimeSlot}
                />
            )
        }
    }

    renderServiceButton = () => {
        return (
            <ServiceButton 
                service={this.state.service}
                onClick={this.toggleServicePicker}
            />
        )
    }

    renderBookButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Book Appointment'
                disabled={!this.canBook()}
                onClick={this.decideBookClick}
            />
        )
    }

    decideLoaderMessage = () => {
        if (this.state.booking) {
            if (this.state.success) {
                return 'Appointment Booked.';
            }
            return 'Booking Appointment...';
        }
    }

    renderBookLoader = () => {
        return (
            <Loader 
                message={this.decideLoaderMessage()}
                open={this.state.booking}
                success={this.state.success}
                onClick={this.onBack}
            />
        )
    }

    renderPolicy = () => {
        if (this.state.artist && this.state.service && this.state.slot) {
            return (
                <PolicyModal 
                    open={this.state.policyOpen}
                    onClose={this.togglePolicy}
                    artistName={this.state.artist.name}
                    service={this.state.service}
                    slot={this.state.slot}
                    policyHash={this.state.policyHash}
                    onAccept={this.bookAppointment}
                />
            )
        }
    }

    renderConflictModal = () => {
        return (
            <ConflictModal 
                open={this.state.hasConflict}
                slot={this.state.slot}
                onClick={this.hasConflictRestart}
            />
        )
    }

    renderContent = () => {
        const url = this.state.service ? buildImageUrl(this.state.artist.id, this.state.service.image, window.screen.width, window.screen.height) : buildProfileImageUrl(window.screen.width, window.screen.height, this.state.artist.id, this.state.artist.profileImage);
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    title={`Book with ${this.state.artist.name}`}
                    showBack
                    onBack={this.onBack}
                    showSettings
                    onSettings={() => {}}
                />
                {this.state.serivce && <Header title='Date and Time' />}
                {this.renderSlotButton()}
                <Header title='Service' style={styles.margin} />
                {this.renderServiceButton()}
                {this.renderTimeSlotPicker()}
                {this.renderServicePicker()}
                {this.renderBookButton()}
                {this.renderBookLoader()}
                {this.renderPolicy()}
                {this.renderConflictModal()}
            </ImageBackground>
        )
    }
    
    renderLoader = () => {
        return (
            <Loader message='Loading...' />
        )
    }

    renderDecide = () => {
        if (this.state.artist) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    margin: {
        marginTop: 15,
        marginBottom: 15
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.client;
    return { profile }
}

export default withRouter(connect(mapStateToProps, { })(Book));
