import React from 'react';
import PropTypes from 'prop-types';
import './styles/TimeRow.css';

const TimeRow = ({ timeString, firstSelected, secondSelected, thirdSelected, fourthSelected, isAm }) => {

    return (
        <div className='TR-Container'>
            <h1 className='TR-Time'>{timeString[0]}</h1>
            <h1 className='TR-Time'>{timeString[1]}</h1>
            <h1 className='TR-Time'>:</h1>
            <h1 className='TR-Time'>{timeString[2]}</h1>
            <h1 className='TR-Time'>{timeString[3]}</h1>
        </div>
    )
}

TimeRow.propTypes = {
    timeString: PropTypes.string,
    firstSelected: PropTypes.bool,
    secondSelected: PropTypes.bool,
    thirdSelected: PropTypes.bool,
    fourthSelected: PropTypes.bool,
    isAm: false
};

TimeRow.defaultProps = {
    timeString: '1230',
    firstSelected: false,
    secondSelected: false,
    thirdSelected: false,
    fourthSelected: false,
    isAm: false
}

export default TimeRow;