import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, BrandButton, MapMarker } from '../components';
import GoogleMapReact from 'google-map-react';
import './styles/SalonModal.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { GOOGLE_MAP_API_KEY } from '../secret/secret';

class SalonModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coords: null
        }
    }

    componentDidMount = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { timeout: 1000 });
        }
    }

    getCoordsError = (error) => {
        console.log(error);
    }

    getCoords = (event) => {
        this.setState({
            coords: {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude
            }
        });
    }

    getDirections = () => {
        window.location.href = `http://maps.google.com/maps?saddr=${this.state.coords.latitude},${this.state.coords.longitude}&daddr=${this.props.coords.lat},${this.state.props.coords.lng}`
    }

    renderMap = () => {
        const { coords, address, salonName } = this.props;
        return (
            <div className='SalonModal-Container'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                    defaultCenter={{
                        lat: coords.lat,
                        lng: coords.lng,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                >
                    <div 
                        className='SalonModal-Marker'
                        lat={coords.lat}
                        lng={coords.lng}
                    >
                        <h5 className='SalonModal-SalonName'>{salonName}</h5>
                        <p className='SalonModal-Address'>{address}</p>
                    </div>
                </GoogleMapReact>
            </div>
        )
    }

    renderButton = () => {
        if (this.state.corods) {
            return (
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Get Directions'
                    onClick={this.getDirections}
                />
            )
        }

    }

    render = () => {
        const { open, salonName, onClose } = this.props;
        return (
            <BottomUpModal
                open={open}
                onClose={onClose}
                title={salonName}
                height={.7}
            >

                {this.renderMap()}
                {this.renderButton()}
            </BottomUpModal>
        )
    }

}

SalonModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    address: PropTypes.string,
    coords: PropTypes.object,
    salonName: PropTypes.string
};

SalonModal.defaultProps = {
    open: false,
    onClose: () => {},
    address: '',
    coords: null,
    salonName: ''
}

export default SalonModal;