import React from 'react';
import PropTypes from 'prop-types';
import './styles/ButtonLabel.css';

const ButtonLabel = ({ label, style, className }) => {
    return (
        <h4 style={style} className={`ButtonLabel-label ${className}`}>{label}</h4>
    )
}

ButtonLabel.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
};

ButtonLabel.defaultProps= {
    label: '',
    style: {},
    className: ''
}

export default ButtonLabel;