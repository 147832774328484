import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, BrandButton, CustomService, Scroll, SearchBar } from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/CustomServiceModal.css';
import { ARTIST } from '../constants/ClientRoutes';

class CustomServiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
        }
    }

    searchChange = (search) => {
        this.setState({ search });
    }

    navToEditCustomService = (service) => {
        window.location.hash = `${ARTIST.ARTIST_EDIT_CUSTOM_SERVICE}/${service.artistId}/${service.serviceId}/${service.client.id}`;
    }
    
    renderCustomService = (service, index) => {
        return (
            <CustomService 
                service={service}
                key={index}
                onClick={this.navToEditCustomService}
            />
        )
    }

    renderCustomServices = () => {
        return (
            <Scroll>
                {this.props.customServices.map(this.renderCustomService)}
            </Scroll>
        )
    }

    renderEmpty = () => {
        return ( 
            <div className='CustomServiceModal-EmptyContainer'>
                <h2 className='CustomServiceModal-EmptyTitle'>What is Client Customization?</h2>
                <h4 className='CustomServiceModal-Description'>You can customize the duration and price of this service per each client. This allows you to make your schedule more efficient and to better cater to the needs of your clients.</h4>
            </div>
        )
    }

    renderDecide = () => {
        if (this.props.customServices.length > 0) {
            return this.renderCustomServices();
        }
        return this.renderEmpty();
    }

    renderSearchBar = () => {
        if (this.props.customServices.length > 0) {
            return (
                <SearchBar 
                    value={this.state.search}
                    onChange={this.searchChange}
                    placeholder={this.props.service.customizations > 0 ? `Search ${this.props.service.customizations} Client Customizations...` : 'Search Client Customizations'}
                    style={styles.marginBottom}
                />
            )
        }
    }

    render = () => {
        return (
            <BottomUpModal
                title={this.props.service.customizations > 0 ? `${this.props.service.customizations} Client Customizations` : 'Client Customization'}
                height={.9}
                onClose={this.props.onClose}
                open={this.props.open}
            >
                {this.renderSearchBar()}
                {this.renderDecide()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Add a Client Customization'
                    onClick={this.props.addCustom}
                />
            </BottomUpModal>
        )
    }
}

const styles = {
    marginBottom: {
        marginBottom: 15
    }
}

CustomServiceModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    addCustom: PropTypes.func,
    customServices: PropTypes.array,
    service: PropTypes.object
};

CustomServiceModal.deafultProps = {
    open: false,
    onClose: () => {},
    addCustom: () => {},
    customServices: [],
    service: {}
}

export default CustomServiceModal;