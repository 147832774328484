import React from 'react';
import PropTypes from 'prop-types';
import './styles/TimeInput.css';

const TimeInput = ({ value, onChange, passRefUp, title }) => {

    const change = (e) => {
        console.log(e.target.value);
        onChange(e.target.value);
    }

    return (
        <input 
            value={value}
            onChange={change}
            ref={passRefUp}
            className='TimeInput-Input'
            type='time'
            title={title}
        />
    )
}

TimeInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    passRefUp: PropTypes.func
};

TimeInput.defalutProps = {
    value: '',
    onChange: () => {},
    passRefUp: () => {}
}

export default TimeInput;