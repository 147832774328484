import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import './styles/Option.css';

const Option = ({ label, icon, backgroundColor, onClick, showArrow, rightLabel, badgeCount }) => {

    const renderArrow = () => {
        if (showArrow) {
            return (
                <BsChevronRight className='Option-Arrow' />
            )
        }
    }

    const renderRightLabel = () => {
        if (rightLabel) {
            return (
                <h5 className='Option-RightLabel'>{rightLabel}</h5>
            )
        }

    }

    const renderBadge = () => {
        if (badgeCount > 0) {
            return (
                <div className='Option-Badge'>
                    <p className='Option-Count'>{badgeCount}</p>
                </div>
            )
        }
    }

    return (
        <div 
            onClick={onClick} 
            className='Option-Container'
        >
            <div 
                className='Option-IconSquare'
                style={{ backgroundColor }}
            >
                {icon}
            </div>
            <div className='Option-LabelContainer'>
                <h4>{label}</h4>
                <div className='Option-RightContainer'>
                    {renderRightLabel()}
                    {renderArrow()}
                    {renderBadge()}
                </div>
            </div>
        </div>
    )
}

Option.propTypes = {

    icon: PropTypes.node,
    backgroundColor: PropTypes.string,
    rightLabel: PropTypes.string,
    label: PropTypes.string,
    
    onClick: PropTypes.func,
    showArrow: PropTypes.bool,
    badgeCount: PropTypes.number
};

Option.defaultProps = {
    icon: null,

    label: '',
    backgroundColor: '',
    rightLabel: '',

    onClick: () => {},
    showArrow: false,
    badgeCount: 0
}

export default Option;