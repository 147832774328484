import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { NotiIcon } from '.';
import './styles/Noti2.css';
import { getTimeSince } from '../util/TimeUtil';
import { deleteDoc, doc, getFirestore } from '@firebase/firestore';

class Noti2 extends Component {

    handleNoti =  async() => {
        const db = getFirestore();
        const prefix = this.props.isClient ? 'clients' : 'users';
        const ref = doc(db, `${prefix}/${this.props.noti.destId}/notifications/${this.props.noti.id}`);
        await deleteDoc(ref);
        if (this.props.noti.link) {
            window.location.hash = this.props.noti.link;
        }

    }

    renderIcon = () => {
        return (
            <div className='N2-IconContainer'>
                <NotiIcon type={this.props.noti.type} />
            </div>
        )
    }

    renderStamp = () => {
        const stamp = getTimeSince(this.props.noti.time);
        return (
            <p className='N2-Stamp'>{stamp}</p>
        )
    }

    renderContent = () => {
        return (
            <div className='N2-Content'>
                <h5 className='N2-Title'>{this.props.noti.title}</h5>
                <p className='N2-Desc'>{this.props.noti.desc}</p>
                {this.renderStamp()}
            </div>
        )
    }

    render = () => {
        return (
            <div 
                className='N2-Container'
                onClick={this.handleNoti}
            >
                {this.renderIcon()}
                {this.renderContent()}
            </div>
        )
    }
}

Noti2.propTypes = {
    noti: PropTypes.object,
    isClient: PropTypes.bool,
};

Noti2.defaultProps = {
    noti: {},
    isClient: false
}

export default Noti2;