import React from 'react';
import PropTypes from 'prop-types';
import { 
    FlexRow,
    FlexRowButton,
    Placeholder,
    InputLabel
} from '../components';
import './styles/DateInput.css';

const DateInput = ({ value, onChange, placeholder }) => {

    let inputRef = null;

    const change = (e) => {
        onChange(e.target.value);
    }

    const renderPlaceholder = () => {
        if (!value) {
            return (
                <div className='DI-InputLabel'>
                    <Placeholder placeholder={placeholder} />
                </div>
            )
        }
    }

    const renderInputLabel = () => {
        if (value) {
            return (
                <InputLabel label={placeholder} />
            )
        }
    }

    const renderInput = () => {
        return (
            <input 
                type='date'
                placeholder={placeholder}
                ref={ref => inputRef = ref}
                value={value}
                onChange={change}
                className='DI-Input'
            />
        )
    }

    const focusInput = () => {
        if (inputRef) {
            inputRef.focus();
        }
    }

    return (
        <FlexRow>
            <FlexRowButton 
                onClick={focusInput}
                relative
            >
                {renderInput()}
                {renderPlaceholder()}
                {renderInputLabel()}
            </FlexRowButton>
        </FlexRow>
    )
}

DateInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

DateInput.defaultProps = {
    value: '',
    onChange: () => {},
    placeholder: ''
};

export default DateInput;