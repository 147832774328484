import { 
    ARTIST_FETCH_SERVICES,
    ARTIST_SERVICE_PROP_CHANGE,
    ARTIST_SERVICE_CHANGE,
    ARTIST_FETCH_CUSTOM_SERVICES,
    ARTIST_CLEAR_SERVICE
} from '../actionTypes/actionTypes';
import { 
    getFirestore, 
    collection, 
    onSnapshot, 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { parseDocs } from '../util/FirestoreUtil';

export const clearService = () => {
    return { type: ARTIST_CLEAR_SERVICE };
}

export const fetchServices = () => {
    return async (dispatch) => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const servicesRef = collection(db, `users/${id}/services`);
        const listener = onSnapshot(servicesRef, snap => {
            const { results: services } = parseDocs(snap);
            const cantDeleteImageHash = {};
            for (let i = 0; i < services.length; i++) {
                const service = services[i];
                cantDeleteImageHash[service.image] = true;
            }
            dispatch({ type: ARTIST_FETCH_SERVICES, payload: {
                services,
                cantDeleteImageHash,
                listener
            }});
        })
    }
}

export const fetchCustomServices = (service) => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = collection(db, `users/${id}/services/${service.id}/custom`);
        const customUnsub = onSnapshot(ref, (snap) => {
            const { results: customServices, hash: customHash } = parseCustomServices(snap, service);
            dispatch({ 
                type: ARTIST_FETCH_CUSTOM_SERVICES,
                payload: {
                    customServices,
                    customUnsub,
                    customHash
                }
            });
        })
    }
}

export const serviceChange = (service, prop, value) => {
    const serviceCopy = { ...service };
    serviceCopy[prop] = value;
    return { type: ARTIST_SERVICE_CHANGE, payload: serviceCopy };
}

export const servicePropChange = (prop, value) => {
    return { type: ARTIST_SERVICE_PROP_CHANGE, payload: { prop, value }}
}

const parseCustomServices = (snap, service) => {
    const results = [];
    const hash = {}
    for (let i = 0; i < snap.docs.length; i++) {
        const doc = snap.docs[i];
        const custom = {
            ...doc.data(),
            id: doc.id,
            image: service.image
        }
        hash[custom.client.id] = true;
        results.push(custom);
    }
    return { results, hash };
}