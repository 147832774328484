export const firebaseConfig = {
    apiKey: "AIzaSyDbcfQcKEPbQBWhM6uNH5ZJVvy2IC4F8YY",
    authDomain: "argenda-44ea6.firebaseapp.com",
    databaseURL: "https://argenda-44ea6.firebaseio.com",
    projectId: "argenda-44ea6",
    storageBucket: "argenda-44ea6.appspot.com",
    messagingSenderId: "693612333057",
    appId: "1:693612333057:web:41c93fc9677ea96d6b9800",
    measurementId: "G-BJB5N0KSFT"
};

export const STRIPE_TEST_PUBLISH_KEY = 'pk_test_7j2RherDxfgeQxm1nfBSIxzz006IzmViT1';
export const STRILE_LIVE_PUBLISH_KEY = 'pk_live_eQlAnJph0E2XDAiNDfeH0lvz00MmIoEdsp';
export const GOOGLE_MAP_API_KEY = 'AIzaSyBsAz_Edho62BKYn-JYNaffWg2csJ0s5fU';

export const ALGOLIA_ADMIN_KEY = 'd85cefe5e807c7044752691b743bcc23';
export const ALGOLIA_APP_ID = '5MC7N7PERG'