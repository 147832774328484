import React from 'react';
import PropTypes from 'prop-types';
import './styles/DurationControl.css';

const DurationControl = ({ onChange, value, passRefUp, label, style }) => {

    const generateLabel = (hours, minutes) => {
        if (hours > 0 && minutes === 0) {
            if (hours > 1) {
                return `${hours} hrs`;
            }
            return `${hours} hr`;
        }
        if (hours > 1) {
            return `${hours} hrs ${minutes} mins`;
        }
        return `${hours} hr ${minutes} mins`;
    }

    const generateDurations = () => {
        const durations = [];
        for (let i = 5; i <= 600; i+=5) {
            if (i >= 60) {
                const hours = Math.floor(i / 60);
                const minutes = i % 60;
                const label = generateLabel(hours, minutes);
                const option = {
                    label,
                    value: i
                }
                durations.push(option);
            } else {
                const option = {
                    label: `${i} mins`,
                    value: i
                }
                durations.push(option);
            }
        }
        return durations;
    }

    const renderDuration = (duration, index) => {
        return (
            <option
                key={index}
                value={duration.value}
            >
                {duration.label}
            </option>
        )
    }

    const change = (e) => {
        onChange(e.target.value);
    }

    return (
        <div 
            className='DurationControl-Wrapper'
            style={style}
        >

            <select
                ref={(ref) => passRefUp(ref)}
                value={value}
                className='DurationControl-Container'
                onChange={change}
                title={label}
            >
                {generateDurations().map(renderDuration)}
            </select>
            <p className='DurationControl-Label'>{label}</p>
        </div>

    )
}

DurationControl.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    passRefUp: PropTypes.func,
    label: PropTypes.string,
    style: PropTypes.object
};

DurationControl.defaultProps = {
    onChange: () => {},
    value: {},
    passRefUp: () => {},
    label: '',
    style: {},
}

export default DurationControl;