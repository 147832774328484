import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, BrandButton, Card, CardInput } from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import BigTitle from './BigTitle';

const ArtistCardForm = ({ onBack, onChange, isPayments, title, subTitle }) => {

    const [cardTokens, setCardTokens] = useState([]);

    const resetCard = () => {
        setCardTokens([]);
    }

    const renderDecide = () => {
        if (cardTokens.length > 0) {
            return renderCard();
        }
        return renderCardInput();
    }

    const change = () => {
        onChange(cardTokens);
    }

    const tokensChange = (cardTokens) => {
        setCardTokens(cardTokens);
    }

    const renderCard = () => {
        return (
            <Body>
                <BigTitle title='Your Card Info' />
                <Body center>
                    <Card 
                        card={cardTokens[0].card}
                        width={window.screen.width * 0.8}
                    />
                    <BrandButton 
                        label='Change Card'
                        style={{ marginTop: 20 }}
                        onClick={resetCard}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label={isPayments ? 'Next' : 'Sign Up'}
                    onClick={change}
                />
            </Body>
        )
    }

    const renderCardInput = () => {
        return (
            <Body>
                <CardInput
                    enforceDebit={isPayments}
                    onChange={tokensChange}
                    title={title}
                    subTitle={subTitle}
                    onBack={onBack}
                />
            </Body>
        )
    }

    return renderDecide();
}

ArtistCardForm.propTypes = {
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    isPayments: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

ArtistCardForm.defaultProps = {
    onBack: () => {},
    onChange: () => {},
    isPayments: false,
    title: '',
    subTitle: ''
};

export default ArtistCardForm;