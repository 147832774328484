import { doc, getDoc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { Component } from 'react';
import axios from 'axios';
import { BsArrowLeftRight, BsPersonFill, BsX } from 'react-icons/bs';
import { IoCall, IoChatbubbleOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { 
    BottomUpModal,
    BrandButton,
    CancelModal,
    Empty,
    Header, 
    ImageBackground, 
    Loader, 
    Option, 
    PayModal, 
    PolicyModal, 
    ServiceButton, 
    SlotButton, 
    TimeSlotPicker, 
    TransparentHeader,
    PaidOverlay,
} from '../../components';
import { ARTIST, CLIENT } from '../../constants/ClientRoutes';
import { 
    BOTTOM_BUTTON, 
    OPTION_CANCEL, 
    OPTION_NO_SHOW, 
    OPTION_RESCHEDULE 
} from '../../universalStyles/Styles';
import { 
    decrementAppointmentHashForArtist, 
    updateAppointmentHashForArtist, 
    updateRescheduleStats 
} from '../../util/AppointmentUtil';
import { buildImageUrl, buildProfileImageUrl } from '../../util/ImageUtil';
import './styles/ViewAppointment.css';
import { FETCH_CUSTOMER_CARD } from '../../constants/ArgendaServerURLS';
import { convertDateStringToReadable } from '../../util/DateUtil';
import { convertNumberToReadable, convertUnixTimeToReadable } from '../../util/TimeUtil';
import { fetchNotiHash, handleNoti } from '../../util/NotiUtil';
import { CANCEL_FEE, NO_SHOW_FEE, RESCHEDULE } from '../../constants/Policies';
import { ARTIST_RESCHEDULE } from '../../constants/NotiTypes';

class ViewAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointment: null,
            artist: null,
            rescheduleOpen: false,
            settingsOpen: false,
            rescheduleSlot: null,
            policyOpen: false,
            cancelOpen: false,
            policyHash: {},
            payOpen: false,
            card: null,
            none: false,
            resLoading: false,
            resSuccess: false,
            listener: null
        }
    }

    componentDidMount = async () => {
        this.startListener();
    }
     
    componentWillUnmount = () => {
        if (this.state.listener) {
            this.state.listener();
        }
    }

    startListener = async () => {
        const { appointmentId } = this.props.match.params;
        const db = getFirestore();
        const ref = doc(db, `appointments/${appointmentId}`);
        const listener = onSnapshot(ref, async snap => {
            if (snap.exists()) {
                const appointment = { ...snap.data(), id: snap.id };
                if (!this.state.artist) {
                    const artistId = appointment.artistId;
                    const policyRef = doc(db, `users/${artistId}/policies/policies`);
                    const policySnap = await getDoc(policyRef).then();
                    const artistRef = doc(db, `users/${artistId}`);
                    const artistSnap = await getDoc(artistRef).then();
                    const fetchCardPack = {
                        customerId: this.props.profile.customerId,
                        cardId: this.props.profile.cardId
                    }
                    const policyHash = policySnap.data();
                    const artist = { ...artistSnap.data(), id: artistSnap.id };
                    if (artist.stripeAccountId) {
                        const res = await axios.post(FETCH_CUSTOMER_CARD, fetchCardPack);
                        this.setState({ 
                            appointment, 
                            policyHash, 
                            artist,
                            card: res.data,
                            listener
                        });
                    } else {
                        this.setState({
                            appointment,
                            policyHash,
                            artist,
                            listener
                        });
                    }
                } else {
                    this.setState({ appointment, listener });
                }
            } else {
                this.setState({ none: true });
            }
        });
    }

    togglePay = () => {
        this.setState({ payOpen: !this.state.payOpen });
    }

    payEnd = () => {
        this.togglePay();
        this.back();
    }
    
    openSettings = () => {
        this.setState({ settingsOpen: true });
    }

    openCancel = () => {
        this.setState({ cancelOpen: true, settingsOpen: false });
    }

    closeCancel = () => {
        this.setState({ cancelOpen: false });
    }

    rescheduleSlotChange = (rescheduleSlot) => {
        this.setState({ rescheduleSlot });
    }

    navToArtistProfile = () => {
        window.location.hash = `${CLIENT.CLIENT_VIEW_ARTIST}/${this.state.appointment.artistId}`;
    }

    closeSettings = () => {
        this.setState({ settingsOpen: false });
    }

    openReschedule = () => {
        this.setState({ rescheduleOpen: true, settingsOpen: false });
    }

    finishCancelAppointment = () => {
        this.closeCancel();
        this.back();
    }

    closeReschedule = () => {
        this.setState({ rescheduleOpen: false });
    }

    openPolicy = () => {
        if (!this.state.policyHash) {
            this.reschedule(null);
        } else {
            const cancelFee = parseFloat(this.state.policyHash[CANCEL_FEE]);
            const noShowFee = parseFloat(this.state.policyHash[NO_SHOW_FEE]);
            if (cancelFee > 0 || noShowFee > 0) {
                this.setState({ policyOpen: true, settingsOpen: false });
            } else {
                this.reschedule(null);
            }
        }
    }

    closePolicy = () => {
        this.setState({ policyOpen: false });
    }

    back = () => {
        window.history.back();
    }

    reschedule = async (tos = {}) => {
        this.setState({ resLoading: true });
        const db = getFirestore();
        const oldDay = this.state.appointment.day;
        const appointment = {
            ...this.state.appointment,
            slot: this.state.rescheduleSlot,
            startTime: this.state.rescheduleSlot.slot.startTime,
            endTime: this.state.rescheduleSlot.slot.endTime,
            day: this.state.rescheduleSlot.selectedDay.dateString,
            unixTime: this.state.rescheduleSlot.unixTime,
            tos
        }
        const ref = doc(db, `appointments/${this.state.appointment.id}`);
        await updateDoc(ref, appointment);
        await decrementAppointmentHashForArtist(appointment.artistId, oldDay);
        await updateRescheduleStats(appointment, appointment.artistId, appointment.clientId);
        await updateAppointmentHashForArtist(appointment.artistId, appointment.day);
        const preview = `${this.state.appointment.clientName} rescheduled an appointment with you.`;
        const desc = `${this.state.appointment.clientName} rescheduled an appointment with you. ${convertUnixTimeToReadable(this.state.appointment.unixTime)} => ${convertUnixTimeToReadable(appointment.unixTime)}`;
        const notiHash = await fetchNotiHash(this.state.appointment.artistId);
        const method = notiHash[RESCHEDULE];
        const link = `${ARTIST.ARTIST_VIEW_APPOINTMENT}/${this.state.appointment.id}`;
        await handleNoti(this.state.appointment.artistPhone, desc, preview, ARTIST_RESCHEDULE, this.state.appointment.artistId, method, link);
        this.setState({ resSuccess: true });
    }

    renderCancel = () => {
        return (
            <CancelModal 
                open={this.state.cancelOpen}
                onClose={this.closeCancel}
                appointment={this.state.appointment}
                artist={this.state.artist}
                client={this.props.profile}
                card={this.state.card}
                onCancel={this.finishCancelAppointment}
            />
        )
    }

    renderSlotButton = () => {
        return (
            <SlotButton 
                slot={this.state.appointment.slot}
                onClick={this.openReschedule}
                rescheduleMode
                rescheduledSlot={this.state.rescheduleSlot}
            />
        )
    }

    renderServiceButton = () => {
        return (
            <ServiceButton 
                service={this.state.appointment.service}
            />
        )
    }

    renderReschedule = () => {
        return (
            <TimeSlotPicker 
                open={this.state.rescheduleOpen}
                onClose={this.closeReschedule}
                artistId={this.state.appointment.artistId}
                service={this.state.appointment.service}
                onChange={this.rescheduleSlotChange}
            />
        )
    }

    renderRescheduleButton = () => {
        if (this.state.rescheduleSlot) {
            return (
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Reschedule'
                    onClick={this.openPolicy}
                />
            )
        }
    }

    renderBottomMargin = () => {
        return (
            <div style={styles.marginBottom} />
        )
    }

    renderPayButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Pay for Appointment'
                onClick={this.togglePay}
            />
        )
    }

    renderBottom = () => {
        if (this.state.rescheduleSlot) {
            return this.renderRescheduleButton();
        }
        if (this.state.artist.stripeAccountId && this.state.artist.stripeEnabled && !this.state.appointment.paid && !this.state.appointment.noShow) {
            return this.renderPayButton();
        }
        return this.renderBottomMargin();
    }

    renderCancelOption = () => {
        const now = new Date().getTime();
        if (now < this.state.appointment.unixTime) {
            return (
                <Option 
                    label='Cancel Appointment'
                    icon={<BsX />}
                    backgroundColor={OPTION_CANCEL}
                    onClick={this.openCancel}
                />
            )
        }
    }

    renderSettings = () => {
        return (
            <BottomUpModal
                open={this.state.settingsOpen}
                onClose={this.closeSettings}
                title='Appointment Options'
                height={.5}
            >
                {this.renderCancelOption()}
                <Option 
                    label='Reschedule'
                    icon={<BsArrowLeftRight />}
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.openReschedule}
                />
                <Option 
                    label={`View ${this.state.appointment.artistName.split(' ')[0]}'s Profile`}
                    icon={<BsPersonFill />}
                    backgroundColor={OPTION_NO_SHOW}
                    onClick={this.navToArtistProfile}
                />
                <a 
                    href={`tel:+1${this.state.appointment.artistPhone}`}
                    className='ViewAppointment-Text'
                >
                    <Option 
                        label={`Call ${this.state.appointment.artistName.split(' ')[0]}`}
                        icon={<IoCall />}
                        backgroundColor={OPTION_RESCHEDULE}
                    />
                </a>
                <a 
                    href={`sms:+1${this.state.appointment.artistPhone}`}
                    className='ViewAppointment-Text'
                >
                    <Option 
                        label={`Text ${this.state.appointment.artistName.split(' ')[0]}`}
                        icon={<IoChatbubbleOutline />}
                        backgroundColor={OPTION_RESCHEDULE}
                    />
                </a>
            </BottomUpModal>
        )
    }

    renderPolicy = () => {
        if (this.state.rescheduleSlot) {
            return (
                <PolicyModal 
                    open={this.state.policyOpen}
                    onClose={this.closePolicy}
                    artistName={this.state.appointment.artistName}
                    service={this.state.appointment.service}
                    slot={this.state.rescheduleSlot}
                    policyHash={this.state.policyHash}
                    onAccept={this.reschedule}
                />
            )
        }
    }

    renderPayModal = () => {
        if (this.state.artist.stripeAccountId) {
            return (
                <PayModal 
                    open={this.state.payOpen}
                    onClose={this.togglePay}
                    payEnd={this.payEnd}
                    price={parseFloat(this.state.appointment.service.price)}
                    card={this.state.card}
                    client={this.props.profile}
                    service={this.state.appointment.service}
                    artist={this.state.artist}
                    appointment={this.state.appointment}
                    clientDescription={`Payment for ${this.state.appointment.service.name} with ${this.state.artist.name} on ${convertDateStringToReadable(this.state.appointment.slot.selectedDay.dateString, true)} at ${convertNumberToReadable(this.state.appointment.slot.slot.startTime)}.`}
                    artistDescription={`Payment for ${this.state.appointment.service.name} with ${this.props.profile.name} on ${convertDateStringToReadable(this.state.appointment.slot.selectedDay.dateString, true)} at ${convertNumberToReadable(this.state.appointment.slot.slot.startTime)}.`}
                />
            )
        }

    }

    renderPaidOverlay = () => {
        if (this.state.appointment.paid || this.state.appointment.noShow) {
            return (
                <PaidOverlay 
                    time={this.state.appointment.paidTime}
                    amount={this.state.appointment.paidAmount}
                    showBack
                    noShow={this.state.appointment.noShow}
                    curve={false}
                    onBack={this.back}
                />
            )
        }
    }

    renderContent = () => {
        const url = buildImageUrl(this.state.appointment.artistId, this.state.appointment.service.image, window.screen.width, window.screen.height);
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    showBack
                    showSettings
                    onBack={this.back}
                    onSettings={this.openSettings}
                    title={`Appt with ${this.state.appointment.artistName}`}
                />
                <Header title='Date and Time' />
                {this.renderSlotButton()}
                <Header title='Service' style={styles.margin} />
                {this.renderServiceButton()}
                {this.renderReschedule()}
                {this.renderBottom()}
                {this.renderSettings()}
                {this.renderCancel()}
                {this.renderPayModal()}
                {this.renderPolicy()}
                {this.renderRescheduleLoader()}
                {this.renderPaidOverlay()}
            </ImageBackground>           
        )
    }

    decideLoadingMessage = () => {
        if (this.state.resLoading) {
            if (this.state.resSuccess) {
                return 'Appointment Rescheduled.';
            }
            return 'Rescheduling Appointment...';
        }
    }

    renderRescheduleLoader = () => {
        return (
            <Loader 
                open={this.state.resLoading}
                success={this.state.resSuccess}
                message={this.decideLoadingMessage()}
                onClick={this.back}
            />
        )
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading Appointment...'
                open
            />
        )
    }

    renderNoAppointment = () => {
        return (
            <ImageBackground
                image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}
                darken
            >
                <TransparentHeader 
                    showBack
                    showSettings
                    onBack={this.back}
                    onSettings={this.openSettings}
                    title={`Appointment`}
                />
                <Empty 
                    message='This appointment does not exists anymore'
                />
            </ImageBackground>

        )
    }

    renderDecide = () => {
        if (this.state.none) {
            return this.renderNoAppointment();
        }
        if (this.state.appointment) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    margin: {
        marginTop: 15,
        marginBottom: 15
    },
    marginBottom: {
        marginBottom: 30
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.client;
    return { profile }
}

export default withRouter(connect(mapStateToProps, {

})(ViewAppointment));