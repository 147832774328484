import React from 'react';
import PropTypes from 'prop-types';
import './styles/InfoRow.css';

const InfoRow = ({ profile, toggleHours, toggleMap }) => {
    return (
        <div className='InfoRow-Row'>
            <h3 onClick={toggleMap} className='InfoRow-Info'>{profile.salonName}</h3>
            <div className='InfoRow-Sep' />
            <h3 onClick={toggleHours} className='InfoRow-Info'>Hours</h3>
            <div className='InfoRow-Sep' />
            <a className='InfoRow-Call' href={`tel:+1${profile.phone}`}>
                <h3 className='InfoRow-Info'>Call</h3>
            </a>
        </div>
    )
}

InfoRow.propTypes = {
    profile: PropTypes.object,
    toggleHours: PropTypes.func,
    toggleMap: PropTypes.func
};

InfoRow.defaultProps = {
    profile: {},
    toggleHours: () => {},
    toggleMap: () => {}
};

export default InfoRow;
