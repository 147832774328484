import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    ImageBackground,
    TransparentHeader,
    Body,
    FlexButton,
    InputLabel,
    FlexRow,
    Placeholder,
    FlexRowButton,
    SSNINput,
    BrandButton,
    AddressPickerModal,
    Value
} from '../components';
import { parseBlob } from '../util/ImageUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/StripeId.css';

class StripeId extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dob: '',
            addressOpen: false,
            personalAddress: null,
            frontId: null,
            backId: null,
            ssn: ''
        }
    }

    next = () => {
        const { dob, personalAddress, frontId, backId, ssn  } = this.state;
        const pack = {
            dob,
            personalAddress,
            frontId,
            backId,
            ssn
        }
        this.props.next(pack);
    }

    canNext = () => {
        const { dob, personalAddress, frontId, backId, ssn  } = this.state;
        if (dob && personalAddress && frontId && backId && ssn) {
            return true;
        }
        return false;
    }

    toggleAddress = () => {
        this.setState({ addressOpen: !this.state.addressOpen });
    }

    dobChange = (e) => {
        this.setState({ dob: e.target.value });
    }

    addressChange = (personalAddress) => {
        this.setState({ personalAddress });
    }

    pickFrontID = () => {
        document.getElementById('frontIDPicker').click();
    }

    pickBackID = () => {
        document.getElementById('backIDPicker').click();
    }

    ssnChange = (ssn) => {
        this.setState({ ssn });
    }

    frontIdChange = (e) => {
        const frontId = parseBlob(e);
        this.setState({ frontId });
    }

    backIdChange = (e) => {
        const backId = parseBlob(e);
        this.setState({ backId });
    }

    openDateDialog = () => {
        this.dateInputRef.focus();
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                onBack={this.props.onBack}
                title={this.props.title}
            />
        )
    }

    renderDatePlaceholder = () => {
        if (!this.state.dob) {
            return (
                <div className='StripeId-AbsoluteLabel'>
                    <Placeholder placeholder='Date of Birth...' />
                </div>
            )
        }
    }

    renderDateInput = () => {
        return (
            <FlexButton onClick={this.openDateDialog}>
                <div className='StripeId-FlexButtonInnerContainer'>
                    <input 
                        type='date'
                        placeholder='Date of Birth'
                        ref={ref => this.dateInputRef = ref}
                        className='StripeId-DateInput'
                        value={this.state.dob}
                        onChange={this.dobChange}
                    />
                    {this.renderDatePlaceholder()}
                    {this.state.dob && <InputLabel label='Date of Birth' />}
                </div>
            </FlexButton>
        )
    }

    renderAddress = () => {
        if (this.state.personalAddress) {
            return (
                <Value value={this.state.personalAddress.address} />
            )
        }
        return (
            <Placeholder placeholder='Personal Address...' />
        )
    }

    renderPersonalAddressInput = () => {
        return (
            <FlexButton onClick={this.toggleAddress}>
                {this.renderAddress()}
            </FlexButton>
        )
    }

    renderFrontId = () => {
        if (this.state.frontId) {
            return (
                <img 
                    src={this.state.frontId.url}
                    className='StripeId-Image'
                    onClick={this.pickFrontID}
                />
            )
        }
        return (
            <Placeholder placeholder='Front Drivers License' />
        )
    }

    renderBackId = () => {
        if (this.state.backId) {
            return (
                <img 
                    src={this.state.backId.url}
                    className='StripeId-Image'
                    onClick={this.pickBackID}
                />
            )
        }
        return (
            <Placeholder placeholder='Back Drivers License' />
        )
    }

    renderIdRow = () => {
        return (
            <FlexRow style={styles.row}>
                <FlexRowButton
                    style={styles.rowButton}
                    onClick={this.pickFrontID}
                >
                    {this.renderFrontId()}
                </FlexRowButton>
                <FlexRowButton onClick={this.pickBackID}>
                    {this.renderBackId()}
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSocialInput = () => {
        return (
            <SSNINput 
                onChange={this.ssnChange}
            />
        )
    }

    renderFrontIdPicker = () => {
        return (
            <input 
                type='file'
                id='frontIDPicker'
                className='StripeId-ImagePicker'
                onChange={this.frontIdChange}
            />
        )
    }

    renderBackIdPicker = () => {
        return (
            <input 
                type='file'
                id='backIDPicker'
                className='StripeId-ImagePicker'
                onChange={this.backIdChange}
            />
        )
    }

    renderButton = () => {
        return (
            <BrandButton  
                style={BOTTOM_BUTTON}
                label={this.props.buttonLabel}
                disabled={!this.canNext()}
                onClick={this.next}
            />
        )
    }

    renderBody = () => {
        return (
            <Body>
                {this.renderDateInput()}
                {this.renderPersonalAddressInput()}
                {this.renderIdRow()}
                {this.renderSocialInput()}
                {this.renderFrontIdPicker()}
                {this.renderBackIdPicker()}
            </Body>
        )
    }

    renderAddressModal = () => {
        return (
            <AddressPickerModal 
                open={this.state.addressOpen}
                onClose={this.toggleAddress}
                onChange={this.addressChange}
            />
        )
    }

    render = () => {
        return (
            <ImageBackground
                image={this.props.image}
                darken
            >
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderButton()}
                {this.renderAddressModal()}
            </ImageBackground>
        )
    }
}

const styles = {
    row: {
        height: 150
    },
    rowButton: {
        marginRight: 10
    }
}

StripeId.propTypes = {
    onBack: PropTypes.func,
    image: PropTypes.string,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    next: PropTypes.func
};

StripeId.defaultProps = {
    onBack: () => {},
    image: '',
    title: '',
    buttonLabel: '',
    next: () => {}
}

export default StripeId;