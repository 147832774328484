import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/BrandInput.css';

class BrandInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLabel: false,
            focus: false
        }
    }

    componentDidUpdate = (prevProps) => {
        const { value } = this.props;
        const { value: prevValue } = prevProps;
        if (!value && prevValue) {
            this.setState({ showLabel: false });
        }
    }

    change = (e) => {
        this.props.onChange(e.target.value);
    }

    onFocus = () => {
        this.setState({ focus: true });
        if (!this.state.showLabel) {
            this.setState({ showLabel: true });
        }
    }

    blur = () => {
        this.setState({ focus: false });
        if (!this.props.value) {
            this.setState({ showLabel: false });
        }
    }   

    decidePlaceholderClass = () => {
        if (this.state.focus) {
            return 'BI-InputPlaceholder BI-Label BI-ActiveLabel';
        }
        if (this.state.showLabel || this.props.value) {
            return 'BI-InputPlaceholder BI-Label';
        }
        return 'BI-InputPlaceholder';
    }

    render = () => {
        return (
            <div style={this.props.style} className='BI-InputContainer'>
                <input 
                    className='BI-Input'
                    value={this.props.value}
                    onChange={this.change}
                    onFocus={this.onFocus}
                    onBlur={this.blur}
                    type={this.props.type}
                />
                <p className={this.decidePlaceholderClass()}>{this.props.label}</p>
            </div>
        )
    }
}

BrandInput.propTypes = {
    onChange: PropTypes.func,   
    value: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string,
};

BrandInput.defaultProps = {
    onChange: () => {},
    value: '',
    label: '',
    style: {},
    type: 'email',
}

export default BrandInput;