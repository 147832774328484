import { 
    ARTIST_PROP_CHANGE, 
    ARTIST_FETCH_PROFILE, 
    ARTIST_FETCH_IMAGES,
    ARTIST_FETCH_MORE_IMAGES,
    ARTIST_FETCH_APPOINTMENT_HASH,
    ARTIST_FETCH_APPOINTMENTS,
    ARTIST_FETCH_POLICY_HASH,
    ARTIST_FETCH_NOTIFICATION_HASH,
    ARTIST_FETCH_NOTI,
    ARTIST_CLEAR,
    ARTIST_SETUP_PAGE_CHANGE
} from "../actionTypes/actionTypes";
import { 
    getFirestore, 
    onSnapshot, 
    doc, 
    collection, 
    addDoc, 
    orderBy, 
    limit, 
    query, 
    startAfter, 
    getDocs,
    updateDoc,
    where,
    increment,
    deleteDoc
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { 
    getImageAmountToFetch, 
    uploadImageAndGetURL ,
    deleteImage
} from "../util/ImageUtil";
import { parseDocs } from "../util/FirestoreUtil";
import axios from "axios";
import { NOTIFY_CLIENTS_OF_ADDRESS_CHANGE } from "../constants/ArgendaServerURLS";

export const artistPropChange = (prop, value) => {
    return { type: ARTIST_PROP_CHANGE, payload: { prop, value }};
};

export const clearArtist = () => {
    return { type: ARTIST_CLEAR }
}

export const fetchNotifications = () => {
    return async (dispatch) => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const ref = collection(db, `users/${id}/notifications`);
        const q = query(ref, orderBy('time', 'desc'));
        const notiListener = onSnapshot(q, (snap) => {
            const { results: notis } = parseDocs(snap);
            dispatch({ type: ARTIST_FETCH_NOTI, payload: { notiListener, notis }});
        })
    }
}

export const setupPageChange = (welcomeLeft, scheduleLeft, imagesLeft, servicesLeft) => {
    return {
        type: ARTIST_SETUP_PAGE_CHANGE,
        payload: {
            welcomeLeft,
            scheduleLeft,
            imagesLeft,
            servicesLeft
        }
    }
}

export const getProfile = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const docRef = doc(db, `users`, id);
        const profileListener = onSnapshot(docRef, profileSnap => {
            const profile = { ...profileSnap.data(), id: profileSnap.id };
            dispatch({ type: ARTIST_FETCH_PROFILE, payload: {
                profile,
                profileListener,
                shouldRender: true
            }});
        })
    }
}

export const getPolicyHash = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = doc(db, `users/${id}/policies/policies`);
        const policyUnsub = onSnapshot(ref, snap => {
            const policyHash = snap.data();
            dispatch({ type: ARTIST_FETCH_POLICY_HASH, payload: {
                policyHash,
                policyUnsub
            }})
        })
    }
}

export const getNotificationHash = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = doc(db, `users/${id}/notificationHash/notificationHash`);
        const notificationHashUnsub = onSnapshot(ref, snap => {
            const notificationHash = snap.data() ? snap.data() : {};
            dispatch({ 
                type: ARTIST_FETCH_NOTIFICATION_HASH,
                payload: {
                    notificationHash,
                    notificationHashUnsub
                }
            })
        })
    }
}

export const fetchAppointmentHash = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const docRef = doc(db, `users/${id}/appointmentHash/appointmentHash`);
        const appointmentHashListener = onSnapshot(docRef, snap => {
            const appointmentHash = snap.data();
            dispatch({ 
                type: ARTIST_FETCH_APPOINTMENT_HASH, 
                payload: {
                    appointmentHash,
                    appointmentHashListener
                }
            });
        })
    }
}

export const fetchAppointments = (day) => {
    return async (dispatch) => {
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'selectedDay', value: day }});
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = collection(db, `appointments`);
        const w1 = where('day', '==', day.dateString);
        const w2 = where('artistId', '==', id);
        const q = query(ref, w1, w2);
        const appointmentListener = onSnapshot(q, snap => {
            const { results: appointments } = parseDocs(snap);
            dispatch({ 
                type: ARTIST_FETCH_APPOINTMENTS,
                payload: {
                    appointments,
                    appointmentListener
                }
            })
        })
    }
}

export const setVisible = (visible = false) => {
    return async () => {
        const auth = getAuth();
        const db = getFirestore();
        const ref = doc(db, `users/${auth.currentUser.uid}`);
        await updateDoc(ref, {
            visible: visible
        });
    }
}

export const uploadImages = (images, profile) => {
    return async (dispatch) => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressOpen', value: true }});
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const imageRef = `images/${image.blob.name}`;
            const docToAdd = {
                imageName: image.blob.name,
                dateAdded: new Date().getTime(),
                _geoloc: profile._geoloc,
                artistId: id,
                artistType: profile.artistType,
                artistName: profile.name,
                artistProfileImage: profile.profileImage,
                salonName: profile.salonName,
                address: profile.address
            }
            dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressMessage', value: `Uploading image ${i + 1} of ${images.length}` }});
            await uploadImageAndGetURL(image, imageRef);
            const imagesRef = collection(db, `images`);
            await addDoc(imagesRef, docToAdd);
        } 
        const userRef = doc(db, `users/${id}`);
        await updateDoc(userRef, {
            imageCount: increment(images.length)
        });
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressOpen', value: false }});
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressMessage', value: '' }});
    }
}

export const deleteImages = (images, profile) => {
    return async (dispatch) => {
        const db = getFirestore();
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressOpen', value: true }});
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const storageRef = `images/${image.imageName}`;
            dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressMessage', value: `Deleting image ${i + 1} of ${images.length}` }});
            await deleteImage(storageRef);
            const dbRef = doc(db, `images/${image.id}`);
            await deleteDoc(dbRef);
        }
        const userRef = doc(db, `users/${profile.id}`);
        await updateDoc(userRef, {
            imageCount: increment(-images.length)
        });
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressOpen', value: false }});
        dispatch({ type: ARTIST_PROP_CHANGE, payload: { prop: 'imageProgressMessage', value: '' }});
    }
}

export const uploadProfileImage = (image, oldImage) => {
    return async () => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const imageRef = `${id}/profileImage/${image.blob.name}`
        const oldRef = `${id}/profileImage/${oldImage}`;
        await uploadImageAndGetURL(image, imageRef);
        const ref = doc(db, `users/${id}`);
        await updateDoc(ref, {
            profileImage: image.blob.name
        });
        await deleteImage(oldRef);

    }
}

export const fetchFirstImages = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const imagesToFetch = getImageAmountToFetch();
        const db = getFirestore();
        const imagesRef = collection(db, `images`);
        const limitBy = limit(imagesToFetch);
        const order = orderBy('dateAdded', 'desc');
        const w = where('artistId', '==', id);
        const imageQuery = query(imagesRef, w, order, limitBy);
        const imageListener = onSnapshot(imageQuery, snap => {
            const { results: images, lastDoc: imagesCursor } = parseDocs(snap);
            dispatch({
                type: ARTIST_FETCH_IMAGES,
                payload: {
                    images,
                    imagesCursor,
                    noMoreImages: images.length < imagesToFetch ? true : false,
                    imageListener
                }
            })
        })
    }
}

export const fetchMoreImages = (imageCursor) => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const imagesToFetch = getImageAmountToFetch();
        const db = getFirestore();
        const imagesRef = collection(db, `images`);
        const limitBy = limit(imagesToFetch);
        const order = orderBy('dateAdded', 'desc');
        const w = where('artistId', '==', id);
        const start = startAfter(imageCursor);
        const q = query(imagesRef, w, order, limitBy, start);
        const snap = await getDocs(q);
        const { results: images, lastDoc: imagesCursor } = parseDocs(snap);
        dispatch({
            type: ARTIST_FETCH_MORE_IMAGES,
            payload: {
                images,
                imagesCursor,
                noMoreImages: images.length < imagesToFetch ? true : false
            }
        })
    }
}

export const saveAddress = (addressPack) => {
    return async () => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const userRef = doc(db, `users/${id}`);
        await updateDoc(userRef, {
            _geoloc: addressPack.address.location,
            address: addressPack.address.address,
            salonName: addressPack.salonName,
            suite: addressPack.suite
        });
        const imagesRef = collection(db, 'images');
        const w = where('artistId', '==', id);
        const q = query(imagesRef, w);
        const snap = await getDocs(q).then();
        const { results: images } = parseDocs(snap);
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const imageRef = doc(db, `images/${image.id}`);
            await updateDoc(imageRef, {
                _geoloc: addressPack.address.location
            });
        }
        /*
        await axios.post(NOTIFY_CLIENTS_OF_ADDRESS_CHANGE, {
            artistId: id,
            salonName: addressPack.salonName,
            address: addressPack.address
        });
        */
    }
}