import React, { Component } from 'react';
import { 
    ImageBackground,
    SettingsTitle,
    TransparentHeader,
    Body,
    EasyInput,
    BrandButton,
    ModalLoader,
    ModalSuccess
} from '../../components';
import { connect } from 'react-redux';
import {  purgeClient } from '../../actions/clientActions';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import axios from 'axios';
import { DELETE_CUSTOMER_ACCOUNT } from '../../constants/ArgendaServerURLS';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { deleteUser, getAuth } from 'firebase/auth';
import { SIGNUP } from '../../constants/ClientRoutes';
import { buildProfileImageUrl } from '../../util/ImageUtil';

class DeleteProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
            success: false
        }
    }

    onChange = (value) => {
        this.setState({ value });
    }

    back = () => {
        window.history.back();
    }

    navToSplash = () => {
        window.location.hash = SIGNUP.SPASH;
    }

    startDeleteProfile = async () => {
        this.setState({ loading: true });
        try {
            axios.post(DELETE_CUSTOMER_ACCOUNT, { customer: this.props.profile.customerId });
            await this.deleteNotificationHash();
            await this.deleteClient();
            await this.deleteUser();
            this.props.purgeClient();
            this.setState({ loading: false, success: true });
        } catch (ex) {
            await this.deleteNotificationHash();
            await this.deleteClient();
            await this.deleteUser();
            this.props.purgeClient();
            this.setState({ loading: false, success: true });
        }

    }

    deleteNotificationHash = async () => {
        const db = getFirestore();
        const hashRef = doc(db, `clients/${this.props.profile.id}/notificationHash/notificationHash`);
        await deleteDoc(hashRef);
    }

    deleteClient = async () => {
        const db = getFirestore();
        const clientRef = doc(db, `clients/${this.props.profile.id}`);
        await deleteDoc(clientRef);
    }

    deleteUser = async () => {
        const auth = getAuth();
        await deleteUser(auth.currentUser);
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                title='Delete Profile'
                onBack={this.back}
            />
        )
    }

    renderDecide = () => {
        if (this.props.appointments.length > 0) {
            return this.renderAppointmentNoDeleteBody();
        }
    }

    renderAppointmentNoDeleteBody = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                {this.renderHeader()}
                <Body center>
                    <SettingsTitle 
                        title={`To delete your profile, you need to first cancel your ${this.props.appointments.length} appointments first.`}
                    />
                </Body>
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                <ModalLoader 
                    message='Deleting Profile...'
                />
            </ImageBackground>

        )
    }
    
    renderSuccess = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                <ModalSuccess 
                    message='Profile Deleted.'
                    onClick={this.navToSplash}
                />
            </ImageBackground>

        )
    }

    renderContent = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                {this.renderHeader()}
                <Body>
                    <SettingsTitle 
                        title={`To delete your profile type "${this.props.profile.name}"`}
                    />
                    <EasyInput 
                        value={this.state.value}
                        onChange={this.onChange}
                        placeholder={`Type ${this.props.profile.name}`}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Delete Profile'
                    disabled={this.props.profile.name !== this.state.value}
                    onClick={this.startDeleteProfile}
                />
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.props.appointments.length > 0) {
            return this.renderAppointmentNoDeleteBody();
        }
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.success) {
            return this.renderSuccess();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

const mapStateToProps = (state) => {
    const { profile, appointments } = state.client;
    return { profile, appointments };
}

export default connect(mapStateToProps, { purgeClient })(DeleteProfile);