import React from 'react';
import PropTypes from 'prop-types';
import './styles/Error.css';

const Error = ({ error, style }) => {
    return (
        <h4 className='E-Error' style={style}>{error}</h4>
    )
}

Error.propTypes = {
    error: PropTypes.string,
    style: PropTypes.object,
};

Error.defaultProps = {
    error: '',
    style: {}
}

export default Error;