import React from 'react';
import PropTypes from 'prop-types';
import './styles/Value.css';

const Value = ({ value, style }) => {
    return (
        <p style={style} className='Value-value'>{value}</p>
    )
}

Value.propTypes = {
    value: PropTypes.string,
    style: PropTypes.object
}

Value.defaultProps = {
    value: '',
    style: {}
}

export default Value;