import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Body, CardModal, ImageBackground, ModalTitle } from '.';
import './styles/BottomUpModal.css';

class BottomUpModal extends Component {

    constructor(props) {
        super(props);
        const top = this.decideTop();
        this.state = {
            top: top,
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open: prevOpen } = prevProps;
        const { open } = this.props;
        if (!open && prevOpen) {
            this.setState({ top: this.decideTop() });
            this.modalRef.style.transition = 'all 0.5s';
        }
    }

    takeAwayTransition = () => {
        this.modalRef.style.transition = 'all 0s';
    }

    decideTop = () => {
        const height = this.decideHeight();
        const top = window.screen.height - height - 50;
        return top;
    }

    touchMove = (e) => {
        if (this.bodyRef && this.bodyRef.contains(e.target) && !this.props.disableClose) {
            this.takeAwayTransition()
            const top = e.touches[0].clientY;
            this.setState({ top })
        }
    }
    
    handleClick = (e) => {
        if (e && e.target && e.target.id === 'overlay' && !this.props.disableClose) {
            this.props.onClose();
        }
    }

    touchEnd = (e) => {
        if (this.bodyRef && this.bodyRef.contains(e.target) && !this.props.disableClose) {
            this.props.onClose()
        }
    }

    decideHeight = () => {
        return this.props.height * window.screen.height;
    }

    decideStyle = () => {
        const height = this.decideHeight();
        if (this.props.open) {
            return { top: this.state.top, height }
        }
        const top = window.screen.height + 100
        return {
            top,
            height, 
        }
    }

    renderTitle = () => {
        return (
            <div 
                ref={ref => this.bodyRef = ref}
                className='BottomUpModal-TitleContainer'
            >
                <ModalTitle sub title={this.props.title} />
                <div className='BottomUpModal-DragBar' />
            </div>
        )
    }

    renderBody = () => {
        return (
            <div 
                className={`BottomUpModal-Body ${this.props.open && 'BottomUpModal-Open'}`}
                style={this.decideStyle()}
                ref={ref => this.modalRef = ref}
                id={this.props.modalId}
            >
                {this.renderTitle()}
                {this.props.children}
            </div>
        )
    }

    renderImageBody = () => {
        return (
            <div 
                className={`BottomUpModal-Body ${this.props.open && 'BottomUpModal-Open'}`}
                style={this.decideStyle()}
                ref={ref => this.modalRef = ref}
                id={this.props.modalId}
            >
                <ImageBackground
                    image={this.props.image}
                    darken={this.props.darken}
                    style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
                >
                    {this.renderTitle()}
                    <Body>
                        {this.props.children}
                    </Body>
                </ImageBackground>
            </div>
        )
    }

    renderDecide = () => {
        if (this.props.image) {
            return this.renderImageBody();
        }
        return this.renderBody();
    }
    
    render = () => {
        return (
            <div 
                className={`BottomUpModal-Overlay ${this.props.open && 'BottomUpModal-Open'}`}
                onClick={this.handleClick}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
                id='overlay'
            >
                {this.renderDecide()}
            </div>
        )
    }
}

BottomUpModal.propTypes = {
    open: PropTypes.bool,
    height: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func,
    disableClose: PropTypes.bool,
    modalId: PropTypes.string,
    image: PropTypes.string,
    darken: PropTypes.bool,
};

BottomUpModal.defaultProps = {
    open: false,
    height: .9,
    title: '',
    children: null,
    onClose: () => {},
    disableClose: false,
    image: '',
    darken: false
}

export default BottomUpModal;