import React, { Component } from 'react';
import { phone } from 'phone';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    BrandButton,
    ModalLoader,
    ModalError,
    EasyInput,
    ProfileImageInput,
    BigTitle
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { createClientProfile } from '../actions/authActions';

const INVALID_EMAIL = 'Firebase: Error (auth/invalid-email).;';
const INVALID_PASSWORD = 'Firebase: Password should be at least 6 characters (auth/weak-password).';

class BasicInfoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: props.email,
            password: props.password,
            phone: props.phone,
            name: props.name,
            profileImage: props.profileImage,
            loading: false,
            errorMessage: '',

        }
    }

    basicInfoChange = () => {
        this.props.onChange({
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            name: this.state.name,
            profileImage: this.state.profileImage
        });
    }

    signUp = async () => {
        this.setState({ loading: true });
        const pack = {
            email: this.state.email,
            password: this.state.password,
            phone: this.state.phone,
            name: this.state.name
        }
        try {
            await createClientProfile(pack);
            this.setState({ loading: false });
            this.props.onClose();
        } catch (ex) {
            console.clear();
            let errorMessage = '';
            if (ex.message == INVALID_EMAIL) {
                errorMessage = 'Your email is invalid.';
            }
            if (ex.message == INVALID_PASSWORD) {
                errorMessage = 'Your password must be at least 6 characters long.';
            }
            this.setState({ loading: false, errorMessage });
        }
    }

    resetError = () => {
        this.setState({ errorMessage: '' });
    }

    canSignUp = () => {
        const { email, name, password } = this.state;
        const isNameValid = (name && name.split(' ').length >= 2) ? true : false;
        const isEmailValid = email ? true : false;
        const isPhoneValid = phone(this.state.phone).isValid;
        const isPassValid = password.length >= 6 ? true: false;
        if (isNameValid && isEmailValid && isPhoneValid && isPassValid) {
            return true;
        }
        return false;
    }

    imageChange = (profileImage) => {
        this.setState({ profileImage });
    }

    nameChange = (name) => {
        this.setState({ name });
    }

    emailChange = (email) => {
        this.setState({ email });
    }

    phoneChange = (phone) => {
        this.setState({ phone });
    }

    passChange = (password) => {
        this.setState({ password });
    }

    renderEmail = () => {
        return (
            <EasyInput 
                value={this.state.email}
                onChange={this.emailChange}
                placeholder='Email'
                label='Email'
            />
        )
    }

    renderPhone = () => {
        return (
            <EasyInput 
                value={this.state.phone}
                onChange={this.phoneChange}
                placeholder='Phone #'
                label='Phone #'
                type='tel'
            />
        )
    }

    renderPassword = () => {
        return (
            <EasyInput 
                value={this.state.password}
                onChange={this.passChange}
                type='password'
                placeholder='password'
                label='password'
            />
        )
    }

    renderProfileImage = () => {
        if (this.props.isArtist) {
            return (
                <ProfileImageInput 
                    value={this.state.profileImage}
                    onChange={this.imageChange}
                />
            )
        }
    }

    renderName = () => {
        return (
            <EasyInput 
                value={this.state.name}
                onChange={this.nameChange}
                placeholder='First and Last Name'
                label='Name'
            />
        )
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={this.props.onBack}
            />
        )
    }

    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label={(this.props.isArtist || this.props.isSalon) ? 'Next' : 'Sign up'}
                disabled={!this.canSignUp()}
                onClick={this.basicInfoChange}
            />
        )
    }

    renderClientButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Sign Up'
                disabled={!this.canSignUp()}
                onClick={this.signUp}
            />
        )
    }

    renderLoading = () => {
        return (
            <ModalLoader 
                message='Creating Profile...'
            />
        )
    }

    renderError = () => {
        return (
            <ModalError 
                message={this.state.errorMessage}
                onClick={this.resetError}
            />
        )
    }

    renderTitle = () => {
        return (
            <BigTitle 
                title={'Your Info'}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                <Body>
                    {this.renderTitle()}
                    {this.renderProfileImage()}
                    {this.renderName()}
                    {this.renderPhone()}
                    {this.renderEmail()}
                    {this.renderPassword()}
                </Body>
                {this.renderButton()}
            </Body>
        )
    }

    renderDecideContent = () => {
        if (this.state.errorMessage) {
            return this.renderError();
        }
        if (this.state.loading) {
            return this.renderLoading();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecideContent();
    }
}

BasicInfoForm.propTypes = {
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    isArtist: PropTypes.bool,
    onChange: PropTypes.func,
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    profileImage: PropTypes.object,
    isSalon: PropTypes.bool,
};

BasicInfoForm.defaultProps = {
    onClose: () => {},
    onBack: () => {},
    isArtist: false,
    onChange: () => {},
    email: '',
    name: '',
    password: '',
    phone: '',
    profileImage: null,
    isSalon: false
};

export default BasicInfoForm;