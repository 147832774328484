import React from 'react';
import PropTypes from 'prop-types';
import './styles/Charge.css';
import { convertUnixTimeToReadable } from '../util/TimeUtil';

const Charge = ({ charge, onClick }) => {

    const click = () => {
        onClick(charge);
    }

    return (
        <div 
            className='Charge-Container'
            onClick={click}
            id={charge.id}
        >
            <h2 className='Charge-Amount'>{`$${(charge.amount / 100).toFixed(2)}`}</h2>
            <h5 className='Charge-Desc'>{charge.description}</h5>
            <h5 className='Charge-Created'>{convertUnixTimeToReadable(charge.created * 1000)}</h5>
        </div>
    )
}

Charge.propTypes = {
    charge: PropTypes.object,
    onClick: PropTypes.func,
};

Charge.defaultProps = {
    charge: {},
    onClick: () => {},
}

export default Charge;