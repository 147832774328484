import React from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal } from '.';
import './styles/ScheduleModal.css';
import { FRIDAY, MONDAY, SATURDAY, SUNDAY, THURSDAY, TUESDAY, WED, WEDNESDAY } from '../constants/Days';
import { convertWebTimeStringToReadable } from '../util/TimeUtil';
import { convertNumberToDay } from '../util/DayUtil';

const ScheduleModal = ({ open, scheduleHash, onClose }) => {

    const renderHours = (day) => {
        if (scheduleHash[day]) {
            return `${convertWebTimeStringToReadable(scheduleHash[day].start)}-${convertWebTimeStringToReadable(scheduleHash[day].end)}`
        }
        return 'CLOSED';
    }


    const decideClass = (day) => {
        const date = new Date();
        const today = date.getDay();
        const _day = convertNumberToDay(today);
        if (_day === day) {
            return 'ScheduleModal-Today';
        }
        return '';
    }

    return (
        <BottomUpModal
            title='Schedule'
            onClose={onClose}
            open={open}
            height={.35}
        >
            <div className='ScheduleModal-Container'>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(MONDAY)}>Monday</h4>
                    <h4 className={decideClass(MONDAY)}>{renderHours(MONDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(TUESDAY)}>Tuesday</h4>
                    <h4 className={decideClass(TUESDAY)}>{renderHours(TUESDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(WEDNESDAY)}>Wednesday</h4>
                    <h4 className={decideClass(WEDNESDAY)}>{renderHours(WEDNESDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(THURSDAY)}>Thursday</h4>
                    <h4 className={decideClass(THURSDAY)}>{renderHours(THURSDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(FRIDAY)}>Friday</h4>
                    <h4 className={decideClass(FRIDAY)}>{renderHours(FRIDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(SATURDAY)}>Saturday</h4>
                    <h4 className={decideClass(SATURDAY)}>{renderHours(SATURDAY)}</h4>
                </div>
                <div className='ScheduleModal-Row'>
                    <h4 className={decideClass(SUNDAY)}>Sunday</h4>
                    <h4 className={decideClass(SUNDAY)}>{renderHours(SUNDAY)}</h4>
                </div>
            </div>
        </BottomUpModal>
    )
}

ScheduleModal.propTypes = {
    open: PropTypes.bool,
    scheduleHash: PropTypes.object,
    onClose: PropTypes.func
};

ScheduleModal.defaultProps = {
    open: false,
    scheduleHash: {},
    onClose: () => {}
}

export default ScheduleModal;