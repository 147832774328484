import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/PriceButton.css';

class PriceButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false,
        }
    }

    focus = () => {
        if (!this.props.disabled) {
            this.setState({ focused: true });
        }
    }

    defocus = () => {
        if (this.state.focused) {
            this.setState({ focused: false });
        }
    }

    unfocus = () => {
        window.setTimeout(this.defocus, 100);
    }

    onClick = () => {
        if (!this.props.disabled) {
            this.props.onClick(this.props.value);
        }
    }

    decideClass = () => {
        if (this.state.focused) {
            return 'PriceButton-Container PriceButton-Focus';
        }
        if (this.props.disabled) {
            return 'PriceButton-Container PriceButton-Disabled';
        }
        return 'PriceButton-Container';
    }

    render = () => {
        return (
            <div 
                className={this.decideClass()}
                onTouchStart={this.focus}
                onTouchEnd={this.unfocus}
                onClick={this.onClick}
                style={this.props.style}
            >
                <h2>{this.props.label}</h2>
            </div>
        )
    }
}

PriceButton.propTypes = {
    style: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

PriceButton.defaultProps = {
    style: {},
    label: '',
    value: '',
    onClick: () => {},
    disabled: false
}

export default PriceButton;