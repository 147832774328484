import React from 'react';
import PropTypes from 'prop-types';
import './styles/ArtistTop.css';

const ArtistTop = ({ children }) => {
    return (
        <div className='ArtistTop-Container'>
            {children}
        </div>
    )
}

ArtistTop.propTypes = {
    children: PropTypes.node
}

ArtistTop.defaultProps = {
    children: null
}

export default ArtistTop;