import React from 'react';
import './styles/ModalTitle.css';
import PropTypes from 'prop-types';

const ModalTitle = ({ title, style, alignLeft, sub }) => {
    if (sub) {
        return (
            <h4 style={style} className={`ModalTitle-Title ${alignLeft && 'ModalTitle-Left'}`}>{title}</h4>
        )
    }
    return (
        <h2 style={style} className={`ModalTitle-Title ${alignLeft && 'ModalTitle-Left'}`}>{title}</h2>
    )
}

ModalTitle.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    alignLeft: PropTypes.bool,
    sub: PropTypes.bool,
};

ModalTitle.defaultProps = {
    title: '',
    style: {},
    alignLeft: false,
    sub: true
}

export default ModalTitle;