import { convertMonthNumberToString } from "./DateUtil";
import { convertNumberToDay } from "./DayUtil";

const MINUTES_IN_HOUR = 60;
export const MS_IN_YEAR = 31556926000;
export const MS_IN_MONTH = 2629743833.3;
export const MS_IN_WEEK = 604800000;
export const MS_IN_DAY = 86400000;
export const MS_IN_HOUR = 3600000;
export const MS_IN_MIN = 60000;


//this converts a web time string to a number thats compatible with comparison operators
//e.g. 13:30 => 1330
export const convertWebTimeStringToNumber = (webTimeString) => {
    const timeSplit = webTimeString.split(':');
    const hourString = timeSplit[0];
    const minuteString = timeSplit[1];
    const hours = parseInt(hourString) * 100;
    const minutes = parseInt(minuteString);
    return hours + minutes;
}

//this converts a web time string to a string thats UI friendly
//e.g. 13:30 => 1:30 pm
export const convertWebTimeStringToReadable = (webTimeString) => {
    const timeSplit = webTimeString.split(':');
    const hourString = timeSplit[0];
    const minuteString = timeSplit[1];
    const hours = parseInt(hourString);
    let readableHours = hours;
    let meridian = '';
    if (hours >= 12) {
        meridian = 'PM';
        if (hours > 12) {
            readableHours = hours - 12;
        }
    } else {
        meridian = 'AM';
    }
    if (parseInt(minuteString) === 0) {
        return `${readableHours} ${meridian}`;
    }
    return `${readableHours}:${minuteString} ${meridian}`;
}

//e.g. unixtimeInMS => 5:30 pm on Tuesday October 30, 2021
export const convertUnixTimeToReadable = (unixTime, indcludeDayOfWeek = false) => {
    const date = new Date(unixTime);
    const hours24 = date.getHours();
    let hours12;
    if (hours24 === 0) {
        hours12 = 12;
    } else if (hours24 > 0 && hours24 < 13) {
        hours12 =  hours24;
    } else {
        hours12 = hours24 % 12;
    }
    const minutes = date.getMinutes();
    const minutesString = minutes.toString().length === 2 ? minutes.toString() : `0${minutes.toString()}`;
    const meridian = hours24 >= 12 ? 'pm' : 'am';
    const dayOfMonth = date.getDate();
    const dayOfWeek = convertNumberToDay(date.getDay());
    const readableDayOfWeek = `${dayOfWeek[0].toUpperCase()}${dayOfWeek.substring(1, dayOfWeek.length).toLowerCase()}`
    const month = convertMonthNumberToString(date.getMonth());
    const year = date.getFullYear();
    if (indcludeDayOfWeek) {
        return `${hours12}:${minutesString} ${meridian} ${readableDayOfWeek} ${month} ${dayOfMonth}, ${year}`;
    }
    return `${hours12}:${minutesString} ${meridian} ${month} ${dayOfMonth}, ${year}`;


}

export const convertNumberToReadable = (numberTime) => {
    let preHour = Math.floor(numberTime / 100);
    let hour;
    if (preHour >= 13) {
        hour = preHour - 12;
    } else {
        hour = preHour;
    }
    const minutes = numberTime % 100;
    const meridian = preHour >= 12 ? 'PM' : 'AM';
    if (minutes === 0) {
        return `${hour} ${meridian}`;
    } 
    return `${hour}:${minutes} ${meridian}`;
}

export const convertMinutesToHoursAndMinutesReadable = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours === 0) {
        return `${mins} mins`
    }
    if (hours === 1) {
        if (mins > 0) {
            return `${hours} hr ${mins} mins`;
        }
        return `${hours} hr`;
    }
    if (hours > 1) {
        if (mins > 0) {
            return `${hours} hrs ${mins} mins`;
        }
    }
    return `${hours} hrs`;
}

export const getReadableFromDuration = (duration) => {
    let singleStart = '';
    let doubleStart = '';
    let doubleEnd = '';
    let doubleBreak = '';
    if (duration.doubleBreak) {
        doubleStart = convertMinutesToHoursAndMinutesReadable(duration.doubleStart);
        doubleBreak = convertMinutesToHoursAndMinutesReadable(duration.doubleBreak);
        doubleEnd = convertMinutesToHoursAndMinutesReadable(duration.doubleEnd);
    }
    singleStart = convertMinutesToHoursAndMinutesReadable(duration.singleStart);
    return {
        singleStart,
        doubleStart,
        doubleBreak,
        doubleEnd
    }
}

export const convertScheduleToNumberTimes = (schedule) => {
    let breakStart = 0;
    let breakEnd = 0;
    const start = convertWebTimeStringToNumber(schedule.start);
    const end = convertWebTimeStringToNumber(schedule.end);
    if (schedule.breakStart) {
        breakStart = convertWebTimeStringToNumber(schedule.breakStart);
        breakEnd = convertWebTimeStringToNumber(schedule.breakEnd);
    }
    return {
        start,
        end,
        breakStart,
        breakEnd
    }
}

export const addTime = (time, timeToAdd) => {
    const hours = Math.floor(time / 100) * 100;
    const hoursToAdd = Math.floor(timeToAdd / MINUTES_IN_HOUR) * 100;
    const minutesToAdd = timeToAdd % MINUTES_IN_HOUR;
    const minutes = time % 100;
    const finalMinutes = ((minutesToAdd + minutes) % MINUTES_IN_HOUR);
    const finalHours = (Math.floor((minutesToAdd + minutes) / MINUTES_IN_HOUR) * 100) + hours + hoursToAdd;
    return finalHours + finalMinutes;
}

export const getActualAppointmentTimeInUNIX = (unixDateMidnight, startTime) => {
    const hoursToAdd = Math.floor(startTime / 100);
    const minutesToAdd = startTime % 100;
    const msInHoursToAdd = hoursToAdd * MS_IN_HOUR;
    const msInMinsToAdd = minutesToAdd * MS_IN_MIN;
    return unixDateMidnight + msInHoursToAdd + msInMinsToAdd;
}

export const subDaysFromUnixTime = (unixTime, daysToSub) => {
    const MS_TO_SUB = daysToSub * MS_IN_DAY;
    return unixTime - MS_TO_SUB;
}

export const getTimeSince = (unixTime) => {
    const now = new Date().getTime();
    const delta = now - unixTime;
    if (delta < MS_IN_MIN) {
        return 'Just Now';
    }
    if (delta < MS_IN_HOUR) {
        const minutes = Math.ceil(delta / MS_IN_MIN);
        return `${minutes}m ago`;
    }
    if (delta < MS_IN_DAY) {
        const hours = Math.ceil(delta / MS_IN_HOUR);
        return `${hours}h ago`;
    }
    if (delta < MS_IN_WEEK) {
        const days = Math.ceil(delta / MS_IN_DAY);
        return `${days}d ago`;
    }
    if (delta < MS_IN_MONTH) {
        const weeks = Math.ceil(delta / MS_IN_WEEK);
        return `${weeks}w ago`;
    }
    if (delta < MS_IN_YEAR) {
        const months = Math.ceil(delta / MS_IN_MONTH);
        return `${months}m ago`;
    }
    if (delta >= MS_IN_YEAR) {
        const years = Math.ceil(delta / MS_IN_MONTH);
        return `${years}y ago`;
    }
}