import React from 'react';
import PropTypes from 'prop-types';
import { 
    FaCcVisa,
    FaCcAmex,
    FaCcDinersClub,
    FaCcDiscover,
    FaCcMastercard,
    FaCcJcb
} from 'react-icons/fa';
import './styles/Card.css';

const ASPECT_RATIO = 1.586

const VISA = 'Visa';
const AMEX = 'American Express';
const DISCOVER = 'Discover';
const DINERS = 'Diners Club';
const JCB = 'JCB';
const MASTERCARD = 'MasterCard';

const Card = ({ width, card, style }) => {

    const renderChip = () => {
        return (
            <div className='Card-GoldChip'>
                <div className='Card-GoldChipSector'>
                    <div className='Card-SideWire'>

                    </div>
                </div>
                <div className='Card-GoldChipSector'>
                    <div className='Card-ChipCenterWire'>

                    </div>
                </div>
                <div className='Card-GoldChipSector'>
                    <div className='Card-SideWire'>

                    </div>
                </div>
            </div>
        )
    }

    const renderNumber = () => {
        return (
            <div className='Card-CardNumberRow'>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div style={{ marginLeft: 10 }}  className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                
                <div style={{ marginLeft: 10 }}  className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <div className='Card-Char'>

                </div>
                <h5 className='Card-Last4'>
                    {card.last4}
                </h5>
            </div>
        )
    }

    const renderName = () => {
        return (
            <h5 className='Card-Name'>
                {card.name}
            </h5>
        )
    }

    const renderExpRow = () => {
        return (
            <div className='Card-ExpRow'>
                <p className='Card-ValidThru'>VALID THRU</p>
                <h6 className='Card-Exp'>{`${card.exp_month}/${card.exp_year.toString().substring(2, 4)}`}</h6>
            </div>
        )
    }

    const renderVisa = () => {
        return (
            <FaCcVisa className='Card-Brand' />
        )
    }

    const renderAmex = () => {
        return (
            <FaCcAmex className='Card-Brand' />
        )
    }

    const renderDiscover = () => {
        return (
            <FaCcDiscover className='Card-Brand' />
        )
    }

    const renderDiners = () => {
        return (
            <FaCcDinersClub className='Card-Brand' />
        )
    }

    const renderMastercard = () => {
        return (
            <FaCcMastercard className='Card-Brand' />
        )
    }

    const renderJCB = () => {
        return (
            <FaCcJcb className='Card-Brand' />
        )
    }

    const renderBrand = () => {
        console.log(card.brand);
        switch (card.brand) {
            case VISA:
                return renderVisa();
            case AMEX:
                return renderAmex();
            case DISCOVER:
                return renderDiscover();
            case DINERS:
                return renderDiners();
            case JCB:
                return renderJCB();
            case MASTERCARD:
                return renderMastercard();
            default:
                return renderVisa();
        }
    }

    const height = width / ASPECT_RATIO;
    return (
        <div style={{ ...style, width, height }} className='Card-Container'>
            <div className='Card-InnerContainer'>
                {renderChip()}
                {renderNumber()}
                {renderExpRow()}
                {renderName()}
                {renderBrand()}
            </div>
        </div>
    )
}

Card.propTypes = {
    card: PropTypes.object,
    width: PropTypes.number,
    style: PropTypes.object
};

Card.defaultProps = {
    card: {},
    width: 0,
    style: {}
}

export default Card;