import React from 'react';
import PropTypes from 'prop-types';
import './styles/BrandButton2.css';

const BrandButton2 = ({ label, onClick, style }) => {
    return (
        <div 
            className='BB2-Outer' 
            style={style}
            onClick={onClick}
        >
            <div className='BB2-Inner'>
                <p>{label}</p>
            </div>
        </div>
    )
}

BrandButton2.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

BrandButton2.defaultProps = {
    label: '',
    onClick: () => {},
    style: {}
};

export default BrandButton2;