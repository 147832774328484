import React from 'react';
import PropTypes from 'prop-types';
import './styles/Scroll.css';

const Scroll = ({ children, id, style }) => {
    return (
        <div className='Scroll-Container' style={style}>
            <div id={id} className='Scroll-InnerContainer'>
                {children}
            </div>
        </div>
    )
}

Scroll.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
};

Scroll.defaultProps = {
    children: null,
    style: {}
}

export default Scroll;