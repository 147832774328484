import React from 'react';
import PropTypes from 'prop-types';
import { 
    BigTitle,
    ArgendaLogo,
    Body,
    BrandButton,
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const WelcomeSetup = ({ onNext }) => {
    return (
        <Body>
            <BigTitle title='Welcome to' />
            <ArgendaLogo 
                width={window.screen.width / 1.3} 
                style={styles.logo}
            />
            <Body center>
                <BigTitle title="Let's set up your account." />
            </Body>
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Get Started'
                onClick={onNext}
            />
        </Body>
    )
}

const styles = {
    logo: {
        marginTop: 20
    }
}

WelcomeSetup.propTypes = {
    onNext: PropTypes.func,
};

WelcomeSetup.defaultProps = {
    onNext: () => {}
};

export default WelcomeSetup;