import { doc, getFirestore, setDoc, updateDoc, increment } from 'firebase/firestore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground, 
    TransparentHeader, 
    FlexRow,
    FlexRowButton,
    Value,
    InputLabel,
    ClientPicker,
    PriceControl,
    DurationPicker, 
    BrandButton
} from '../../components';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { buildImageUrl } from '../../util/ImageUtil';
import { convertMinutesToHoursAndMinutesReadable } from '../../util/TimeUtil';

class CreateCustomService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            duration: { ...this.props.service.duration },
            price: this.props.service.price,
            clientOpen: false,
            priceOpen: false,
            durationOpen: false,
        }
    }

    onBack = () => {
        window.history.back();
    }

    canSave = () => {
        if (this.state.price > 0 && this.state.duration && this.state.client) {
            return true;
        }
        return false;
    }

    toggleClientOpen = () => {
        this.setState({ clientOpen: !this.state.clientOpen });
    }

    togglePriceOpen = () => {
        this.setState({ priceOpen: !this.state.priceOpen });
    }
    
    toggleDurationOpen = () => {
        this.setState({ durationOpen: !this.state.durationOpen });
    }

    clientChange = (client) => {
        this.setState({ client });
    }

    durationChange = (duration) => {
        this.setState({ duration });
    }

    priceChange = (price) => {
        this.setState({ price });
    }

    save = async () => {
        const { price, duration, client } = this.state;
        const { service, profile } = this.props;
        const db = getFirestore();
        const ref = doc(db, `users/${profile.id}/services/${service.id}/custom/${client.objectID}`);
        const custom = {
            price,
            duration,
            client: {
                name: client.name,
                id: client.objectID
            },
            artistId: profile.id,
            name: service.name,
            serviceId: service.id,
        }
        const serviceRef = doc(db, `users/${profile.id}/services/${service.id}`);
        await updateDoc(serviceRef, {
            customizations: increment(1)
        });
        await setDoc(ref, custom);
        this.onBack();
    }

    renderPriceControl = () => {
        return (
            <PriceControl 
                open={this.state.priceOpen}
                onClose={this.togglePriceOpen}
                price={this.state.price}
                onSave={this.priceChange}
            />
        )
    }

    renderDurationControl = () => {
        return (
            <DurationPicker 
                open={this.state.durationOpen}
                onClose={this.toggleDurationOpen}
                duration={this.state.duration}
                onSave={this.durationChange}
            />
        )
    }

    renderClientControl = () => {
        return (
            <ClientPicker 
                open={this.state.clientOpen}
                onClose={this.toggleClientOpen}
                artistId={this.props.profile.id}
                onChange={this.clientChange}
                customHash={this.props.customHash}
            />
        )
    }

    renderClientButton = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleClientOpen}>
                    {this.state.client && <Value value={this.state.client.name} />}
                    <InputLabel label='Pick a Client' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderEmptyDuration = () => {
        return (
            <FlexRow>
                <FlexRowButton
                    onClick={this.toggleDurationOpen}
                >
                    <InputLabel label='Duration' />
                </FlexRowButton>
            </FlexRow>

        )
    }

    renderSingleDuration = () => {
        return ( 
            <FlexRow>
                <FlexRowButton
                    onClick={this.toggleDurationOpen}
                >
                    <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.singleStart)} />
                    <InputLabel label='Duration' />
                </FlexRowButton>
            </FlexRow>

        )
    }

    renderDoubleDuration = () => {
        return (
            <FlexRow>
                <FlexRowButton
                    onClick={this.toggleDurationOpen}
                >
                    <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleStart)} />
                    <InputLabel label='Initial Time' />
                    <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleBreak)} />
                    <InputLabel label='Inbetween Time' />
                    <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleEnd)} />
                    <InputLabel label='Finishing Up' />
                </FlexRowButton>
            </FlexRow>

        )
    }

    renderDecideDuration = () => {
        if (!this.state.duration) {
            return this.renderEmptyDuration();
        }
        if (this.state.duration.isDouble) {
            return this.renderDoubleDuration()
        }
        return this.renderSingleDuration();
    }

    renderPrice = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.togglePriceOpen}>
                    {this.state.price > 0 && <Value value={`$${this.state.price.toString()}`} />}
                    <InputLabel label='Price' />
                </FlexRowButton>
            </FlexRow>

        )
    }

    render = () => {
        const url = buildImageUrl(this.props.profile.id, this.props.service.image, window.screen.width, window.screen.height);
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    title={`Customize ${this.props.service.name}`}
                    onBack={this.onBack}
                />
                {this.renderClientButton()}
                {this.renderDecideDuration()}
                {this.renderPrice()}
                {this.renderDurationControl()}
                {this.renderPriceControl()}
                {this.renderClientControl()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Save Customization'
                    disabled={!this.canSave()}
                    onClick={this.save}
                />
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    const { 
        service, 
        services, 
        customHash 
    } = state.service;
    return { 
        profile,
        service,
        services,
        customHash
    }
}

export default connect(mapStateToProps, {})(CreateCustomService);