import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ImageBackground,
    TransparentHeader,
    Card,
    Body,
    ModalTitle,
    ListWrapper,
    Loader,
    Charge,
    Empty,
    CardModal,
    BigTitle
} from '../../components';
import { saveCard } from '../../actions/clientActions';
import axios from 'axios';
import { 
    FETCH_CHARGES, 
    FETCH_CUSTOMER_CARD 
} from '../../constants/ArgendaServerURLS';
import BrandButton from '../../components/BrandButton';
import { THRESHOLD } from '../../constants/ScrollThreshold';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { buildProfileImageUrl } from '../../util/ImageUtil';

class PaymentInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card: null,
            charges: [],
            lastCharge: null,
            hasMoreCharges: true,
            cardInputOpen: false,
            noCard: false
        }
    }

    componentDidMount = async () => {
        await this.fetchCard();
        await this.fetchCharges();
        window.addEventListener('touchend', this.onScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchend', this.onScroll);
    }

    toggleCardInput = () => {
        this.setState({ cardInputOpen: !this.state.cardInputOpen });
    }

    onScroll = async (e) => {
        if (this.state.charges.length > 0 && !this.state.noCard) {
            const query = document.getElementById(this.state.lastCharge);
            const lastChargeTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + THRESHOLD;
            if (lastChargeTop < threshold) {
                await this.fetchCharges();
            }
        }
    }

    saveCard = (tokens) => {
        this.props.saveCard(this.props.profile, tokens);
        this.setState({ card: tokens[0].card });
    }

    fetchCharges = async () => {
        if (this.state.hasMoreCharges) {
            const fetchChargesPack = {
                customerId: this.props.profile.customerId,
                startingAfter: this.state.lastCharge ? this.state.lastCharge : null,
                limit: 15
            }
            const res = await axios.post(FETCH_CHARGES, fetchChargesPack);
            const hasMoreCharges = res.data.has_more;
            const charges = res.data.data;
            const newCharges = [...this.state.charges, ...charges];
            let lastCharge = null;
            if (charges.length > 0) {
                lastCharge = charges[charges.length - 1].id;
            }
            this.setState({
                hasMoreCharges,
                charges: newCharges,
                lastCharge
            });
        }
    }

    fetchCard = async () => {
        const fetchCardPack = {
            customerId: this.props.profile.customerId,
            cardId: this.props.profile.cardId
        }
        try {
            const res = await axios.post(FETCH_CUSTOMER_CARD, fetchCardPack);
            this.setState({ card: res.data });
        } catch (ex) {
            this.setState({ noCard: true });
        }
    }

    onBack = () => {
        window.history.back();
    }

    renderCard = () => {
        if (this.state.card) {
            return (
                <Card 
                    card={this.state.card} 
                    width={window.screen.width * .85}
                    style={styles.card}
                />
            )
        }
    }

    renderCharge = (charge, index) => {
        return (
            <Charge key={index} charge={charge} />
        )
    }

    renderChargeEmpty = () => {
        return (
            <Empty 
                message="You don't have any Payments yet."
            />
        )
    }

    renderDecideCharges = () => {
        if (this.state.charges.length > 0) {
            return this.state.charges.map(this.renderCharge)
        }
        return this.renderChargeEmpty();
    }

    renderPaymentsTitle = () => {
        if (this.state.charges.length > 0) {
            return (
                <ModalTitle title='Payments' />
            )
        }
    }

    renderCardModal = () => {
        return (
            <CardModal
                onClose={this.toggleCardInput}
                message={this.state.noCard ? 'Add a card' : 'Chane your card'}
                open={this.state.cardInputOpen}
                onChange={this.saveCard}
                image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}
            />
        )
    }

    renderPaymentsBody = () => {
        return (
            <Body relative>
                <ListWrapper>
                    {this.renderCard()}
                    <BrandButton 
                        label='Change Card'
                        style={styles.changeCard}
                        onClick={this.toggleCardInput}
                    />
                    {this.renderPaymentsTitle()}
                    {this.renderDecideCharges()}
                </ListWrapper>
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.card) {
            return this.renderContent();
        }
        if (this.state.noCard) {
            return this.renderNoCard();
        }
        return this.renderLoader()
    }

    renderNoCard = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                <TransparentHeader 
                    title='Card and Payments'
                    onBack={this.onBack}
                />
                <Body center>
                    <BigTitle 
                        title="You don't have a card on file yet."
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label="Add a Card"
                    onClick={this.toggleCardInput}
                />
                {this.renderCardModal()}
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <Loader message='Loading...' />
        )
    }

    renderContent = () => {
        return (
            <ImageBackground darken image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}>
                <TransparentHeader 
                    title='Card and Payments'
                    onBack={this.onBack}
                />
                {this.renderPaymentsBody()}
                {this.renderCardModal()}
            </ImageBackground>
        )
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    card: {
        marginTop: 20
    },
    changeCard: {
        width: '85%', 
        marginTop: 10, 
        marginBottom: 15, 
        borderRadius: 0
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.client;
    return {
        profile
    }
}

export default connect(mapStateToProps, { saveCard })(PaymentInfo);