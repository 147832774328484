import React from 'react';
import PropTypes from 'prop-types';
import './styles/TypeIndicator.css';

const TypeIndicator = ({ stripeAccountId, style }) => {
    return (
        <div className='TI-Outer'>
            <div className='TI-Inner'>
                <h5>{stripeAccountId ? 'Payments + Scheduling' : 'Just Scheduling'}</h5>
            </div>
        </div>
    )
}

TypeIndicator.propTypes = {
    stripeAccountId: PropTypes.string,
};

TypeIndicator.defaultProps = {
    stripeAccountId: ''
};

export default TypeIndicator;