import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/ArtistForRow.css';
import { buildProfileImageUrl } from '../util/ImageUtil';

class ArtistForRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0
        }
    }

    componentDidMount = () => {
        const query = document.getElementById('imageContainer');
        const dimen = query.getBoundingClientRect();
        this.setState({
            width: dimen.width,
            height: dimen.height
        });
    }

    onClick = () => {
        this.props.onClick(this.props.artist);
    }

    renderName = () => {
        return (
            <h5 className='AFR-Name'>{this.props.artist.name}</h5>
        )
    }

    renderRateContainer = () => {
        return (
            <div className='AFR-RateContainer'>

            </div>
        )
    }

    render = () => {
        const url = buildProfileImageUrl(this.state.width, this.state.height, this.props.artist.objectID, this.props.artist.profileImage);
        return (
            <div 
                id={this.props.artist.objectID} 
                className='AFR-Container'
                onClick={this.onClick}
            >
                <div 
                    id='imageContainer' 
                    className='AFR-ImageContainer'
                >
                    <img className='AFR-Image' src={url} />
                </div>
                {this.renderName()}
            </div>
        )
    }
}

ArtistForRow.propTypes = {
    artist: PropTypes.object,
    onClick: PropTypes.func,
};

ArtistForRow.defaultProps = {
    artist: {},
    onClick: () => {}
}

export default ArtistForRow;