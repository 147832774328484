import React, { Component } from 'react';
import { BrandButton, BrandTabButton, FlexRow, PriceButton, TabButtonRow } from '.';
import PropTypes from 'prop-types';
import './styles/DigitalInput.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

class DigitalInput extends Component {

    constructor(props) {
        super(props);
        const { time, am: initAm } = this.initValue(props.value);
        this.state = {
            value: time,
            am: initAm
        }
    }

    componentWillUnmount = () => {
        this.setState({
            value: '',
            am: true
        });
    }

    initValue = (value) => {
        if (value) {
            const parts = value.split(':');
            const hour = parseInt(parts[0]);
            const minutes = parts[1];
            let am = false;
            if (hour === 12) {
                am = false;
                return { time: '1200', am: false };
            }
            if (hour > 12) {
                const adjustedHour = hour - 12;
                if (adjustedHour.toString().length === 1) {
                    const time = `0${adjustedHour}${minutes}`;
                    return { time, am: false };
                } else {
                    const time = `${adjustedHour}${minutes}`;
                    return { time, am: false };
                }
            } else {
                return { time: value.replace(':', ''), am: true }
            }
        }
        return { time: '', am: true }
    }

    isEnabled = (number) => {
        if (this.state.value.length === 0) {
            if (number === 0 || number === 1) {
                return true;
            }
            return false;
        }
        if (this.state.value.length === 1) {
            if (this.state.value[0] == 0){
                if (number >= 1 && number <= 9) {
                    return true;
                }
                return false;
            }
            if (this.state.value[0] == 1) {
                if (number >= 0 && number <= 2) {
                    return true;
                }
                return false;
            }
        }
        if (this.state.value.length === 2) {
            if (number >= 0 && number <= 5) {
                return true;
            }
            return false;
        }
        if (this.state.value.length === 3) {
            return true;
        }
    }

    selectAm = () => {
        this.setState({ am: true });
    }

    selectPm = () => {
        this.setState({ am: false });
    }

    onChange = (number) => {
        let value = this.state.value;
        value = `${value}${number.toString()}`;
        this.setState({ value });
    }

    reset = () => {
        this.setState({ value: '' });
    }

    onSave = () => {
        const left = this.state.value.substring(0, 2);
        const right = this.state.value.substring(2);
        if (this.state.am) {
            this.props.onSave(`${left}:${right}`);
        } else {
            const hour = parseInt(left);
            let newLeft = '';
            if (hour !== 12) {
                const adjustedHour = hour + 12;
                newLeft = `${adjustedHour}`;
            } else {
                newLeft = `12`;
            }
            this.props.onSave(`${newLeft}:${right}`);
        }
    }

    renderFirstRow = () => {
        return (
            <FlexRow>
                <PriceButton 
                    label={1}
                    value={1}
                    style={styles.marginRight}
                    disabled={!this.isEnabled(1)}
                    onClick={this.onChange}
                />
                <PriceButton 
                    label={2}
                    value={2}
                    style={styles.marginRight}
                    disabled={!this.isEnabled(2)}
                    onClick={this.onChange}
                />
                <PriceButton 
                    label={3}
                    value={3}
                    disabled={!this.isEnabled(3)}
                    onClick={this.onChange}
                />
            </FlexRow>
        )
    }

    renderSecondRow = () => {
        return (
            <FlexRow>
                <PriceButton 
                    label={4}
                    value={4}
                    style={styles.marginRight}
                    disabled={!this.isEnabled(4)}
                    onClick={this.onChange}
                />
                <PriceButton 
                    label={5}
                    value={5}
                    style={styles.marginRight}
                    onClick={this.onChange}
                    disabled={!this.isEnabled(5)}
                />
                <PriceButton 
                    label={6}
                    value={6}
                    disabled={!this.isEnabled(6)}
                    onClick={this.onChange}
                />
            </FlexRow>
        )
    }

    renderThirdRow = () => {
        return (
            <FlexRow>
                <PriceButton 
                    label={7}
                    value={7}
                    style={styles.marginRight}
                    disabled={!this.isEnabled(7)}
                    onClick={this.onChange}
                />
                <PriceButton 
                    label={8}
                    value={8}
                    style={styles.marginRight}
                    disabled={!this.isEnabled(8)}
                    onClick={this.onChange}
                />
                <PriceButton 
                    label={9}
                    value={9}
                    disabled={!this.isEnabled(9)}
                    onClick={this.onChange}
                />
            </FlexRow>
        )
    }

    renderFourthRow = () => {
        return (
            <FlexRow>
                <PriceButton 
                    label={0}
                    value={0}
                    disabled={!this.isEnabled(0)}
                    onClick={this.onChange}
                    style={{ ...styles.marginRight, flex: 2 }}
                />
                <PriceButton 
                    label='C'
                    onClick={this.reset}
                />
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save Time'
                disabled={this.state.value.length !== 4}
                onClick={this.onSave}
            />
        )
    }

    renderTabs = () => {
        return (
            <TabButtonRow>
                <BrandTabButton 
                    label='AM'
                    selected={this.state.am}
                    onClick={this.selectAm}
                />
                <BrandTabButton 
                    label='PM'
                    selected={!this.state.am}
                    onClick={this.selectPm}
                />
            </TabButtonRow>
        )
    }

    renderTime = () => {
        const meridian = this.state.am ? 'am' : 'pm';
        if (!this.state.value) {
            return `__:__ ${meridian}`
        }
        if (this.state.value.length === 1) {
            return `${this.state.value}_:__ ${meridian}`;
        }
        if (this.state.value.length === 2) {
            return `${this.state.value}:__ ${meridian}`;
        }
        if (this.state.value.length === 3) {
            return `${this.state.value[0]}${this.state.value[1]}:${this.state.value[2]}_ ${meridian}`;
        }
        if (this.state.value.length === 4) {
            return `${this.state.value[0]}${this.state.value[1]}:${this.state.value[2]}${this.state.value[3]} ${meridian}`
        }
    }

    render = () => {
        return (
            <div 
                className='DI-Container'
                style={this.props.style}
            >
                <h1 className='DI-Value'>{this.renderTime()}</h1>
                {this.renderTabs()}
                {this.renderFirstRow()}
                {this.renderSecondRow()}
                {this.renderThirdRow()}
                {this.renderFourthRow()}
                {this.renderSave()}
            </div>
        )
    }
}

const styles = {
    marginRight: {
        marginRight: 5
    }
}

DigitalInput.propTypes = {
    style: PropTypes.object,
    value: PropTypes.string,
};

DigitalInput.defaultProps = {
    style: {},
    value: ''
};

export default DigitalInput;