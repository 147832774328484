import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    FlexRow,
    FlexRowButton,
    ButtonLabel,
    Calendar,
    BrandButton,
    ModalRowBack,
    Body
} from '.';
import {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
    MON,
    TUE,
    WED,
    THUR,
    FRI,
    SAT,
    SUN
} from '../constants/Days';
import {
    ACTIVE_BUTTON,
    ACTIVE_LABEL,
    BOTTOM_BUTTON,
    INACTIVE_LABEL,
    MARGIN_RIGHT,
    TIME_LABEL
} from '../universalStyles/Styles';
import { convertWebTimeStringToReadable } from '../util/TimeUtil';
import './styles/ArtistScheduleModal.css';

const Schedule = ({ scheduleHash, selectDay, onClose, vacationHash, saveVacation, hideVacation }) => {
    const [showVacation, setVacation] = useState(false);
    const [localVacationHash, setLocalVacationHash] = useState(vacationHash);

    const selectVacayDay = (day) => {
        let _hash = { ...localVacationHash };
        if (_hash[day.dateString]) {
            delete _hash[day.dateString];
        } else {
            _hash = { ..._hash, [day.dateString]: day };
        }
        setLocalVacationHash(_hash)
    }

    const saveVacay = () => {
        saveVacation(localVacationHash);
        onClose();
    }

    const toggleVacation = () => {
        setVacation(!showVacation);
    }

    const readifySchedule = (schedule) => {
        const messages = [];
        if (schedule.breakStart && schedule.breakEnd) {
            messages.push(`${convertWebTimeStringToReadable(schedule.start)} - ${convertWebTimeStringToReadable(schedule.breakStart)}`);
            messages.push(`${convertWebTimeStringToReadable(schedule.breakEnd)} - ${convertWebTimeStringToReadable(schedule.end)}`);
        } else {
            messages.push(`${convertWebTimeStringToReadable(schedule.start)} - ${convertWebTimeStringToReadable(schedule.end)}`);
        }
        return messages;
    }
    
    const renderMonday = () => {
        const active = scheduleHash[MONDAY] ? true : false;
        const schedule = scheduleHash[MONDAY] ? scheduleHash[MONDAY] : null;
        const buttonStyle = active ? { ...MARGIN_RIGHT, ...ACTIVE_BUTTON } : MARGIN_RIGHT;
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton 
                style={buttonStyle}
                onClick={() => selectDay(MONDAY, schedule)}
            >
                <ButtonLabel 
                    label={MON}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>

                }
            </FlexRowButton>
        )
    }

    const renderTuesday = () => {
        const active = scheduleHash[TUESDAY] ? true : false;
        const schedule = scheduleHash[TUESDAY] ? scheduleHash[TUESDAY] : null;
        const buttonStyle = active ? ACTIVE_BUTTON : {};
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton
                onClick={() => selectDay(TUESDAY, schedule)}
                style={buttonStyle}
            >
                <ButtonLabel 
                    label={TUE}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderWednesday = () => {
        const active = scheduleHash[WEDNESDAY] ? true : false;
        const schedule = scheduleHash[WEDNESDAY] ? scheduleHash[WEDNESDAY] : null;
        const buttonStyle = active ? { ...MARGIN_RIGHT, ...ACTIVE_BUTTON } : MARGIN_RIGHT;
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton 
                style={buttonStyle}
                onClick={() => selectDay(WEDNESDAY, schedule)}
            >
                <ButtonLabel 
                    label={WED}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderThursday = () => {
        const active = scheduleHash[THURSDAY] ? true : false;
        const schedule = scheduleHash[THURSDAY] ? scheduleHash[THURSDAY] : null;
        const buttonStyle = active ? ACTIVE_BUTTON : {};
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton
                onClick={() => selectDay(THURSDAY, schedule)}
                style={buttonStyle}
            >
                <ButtonLabel 
                    label={THUR}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderFriday = () => {
        const active = scheduleHash[FRIDAY] ? true : false;
        const schedule = scheduleHash[FRIDAY] ? scheduleHash[FRIDAY] : null;
        const buttonStyle = active ? { ...MARGIN_RIGHT, ...ACTIVE_BUTTON } : MARGIN_RIGHT;
        const labelStyle = active ? styles.activeLabel : styles.inactiveLabel;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton 
                style={buttonStyle}
                onClick={() => selectDay(FRIDAY, schedule)}
            >
                <ButtonLabel 
                    label={FRI}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderSaturday = () => {
        const active = scheduleHash[SATURDAY] ? true : false;
        const schedule = scheduleHash[SATURDAY] ? scheduleHash[SATURDAY] : null;
        const buttonStyle = active ? ACTIVE_BUTTON : {};
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton
                onClick={() => selectDay(SATURDAY, schedule)}
                style={buttonStyle}
            >
                <ButtonLabel 
                    label={SAT}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderSunday = () => {
        const active = scheduleHash[SUNDAY] ? true : false;
        const schedule = scheduleHash[SUNDAY] ? scheduleHash[SUNDAY] : null;
        const buttonStyle = active ? ACTIVE_BUTTON : {};
        const labelStyle = active ? ACTIVE_LABEL : INACTIVE_LABEL;
        const messages = active ? readifySchedule(schedule) : [];
        return (
            <FlexRowButton 
                onClick={() => selectDay(SUNDAY, schedule)}
                style={buttonStyle}
            >
                <ButtonLabel 
                    label={SUN}
                    style={labelStyle}
                />
                { messages.length === 2 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                        <ButtonLabel 
                            label={messages[1]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
                {
                    messages.length === 1 &&
                    <div className='ASM-BreakContainer'>
                        <ButtonLabel 
                            label={messages[0]}
                            style={TIME_LABEL}
                        />
                    </div>
                }
            </FlexRowButton>
        )
    }

    const renderVacay = () => {
        return (
            <FlexRowButton
                style={styles.vacayButton}
                onClick={toggleVacation}
            >
                <ButtonLabel 
                    label='VACATION'
                    style={styles.inactiveLabel}
                    className='ArtistScheduleModal-VacayLabel'
                />
            </FlexRowButton>
        )
    }

    const renderVacayButton = () => {
        if (!hideVacation) {
            return (
                <FlexRow>
                    {renderVacay()}
                </FlexRow>
            )
        }
    }

    const renderHours = () => {
        return (
            <Body>
                <FlexRow>
                    {renderMonday()}
                    {renderTuesday()}
                </FlexRow>
                <FlexRow>
                    {renderWednesday()}
                    {renderThursday()}
                </FlexRow>
                <FlexRow>
                    {renderFriday()}
                    {renderSaturday()}
                </FlexRow>
                <FlexRow>
                    {renderSunday()}
                </FlexRow>
                {renderVacayButton()}
            </Body>
        )
    }

    const renderVacation = () => {
        return (
            <Body>
                {renderBack()}
                <Calendar 
                    vacationHash={localVacationHash}
                    onSelect={selectVacayDay}
                />
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Save Vacation'
                    onClick={saveVacay}
                />
            </Body>
        )
    }

    const renderBack = () => {
        return (
            <ModalRowBack 
                title='Back To Hours'
                onBack={toggleVacation}
            />
        )
    }

    const renderDecide = () => {
        if (showVacation) {
            return renderVacation();
        }
        return renderHours();
    }

    return renderDecide();
}

const styles = {
    bottom: {
        marginBottom: 20
    },
    right: {
        marginRight: 8
    },
    inactiveLabel: {
        fontSize: 18,
        color: 'rgba(255, 255, 255, 0.2)',
        marginTop: 10
    },
    activeLabel: {
        color: 'rgba(255, 255, 255, 1)'
    },
    activeButton: {
        borderColor: 'rgba(255, 255, 255, 0.8)'
    }, 
    timeLabel: {
        fontSize: 14
    },
    vacayButton: {
        borderColor: '#FF3466'
    }
}


Schedule.propTypes = {
    scheduleHash: PropTypes.object,
    vacationHash: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    saveVacation: PropTypes.func,
    selectDay: PropTypes.func,
    hideVacation: PropTypes.bool,
};

Schedule.defaultProps = {
    scheduleHash: {},
    vacationHash: {},
    open: false,
    onClose: () => {},
    saveVacation: () => {},
    selectDay: () => {},
    hideVacation: false
};

export default Schedule;
