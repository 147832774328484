import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ArtistTop,
    Bio, 
    BrandButton, 
    FullPhotoModal, 
    Header, 
    ImageBackground, 
    ImagesContainer, 
    InfoRow, 
    Loader, 
    PortfolioImage,
    ProfileImage, 
    SalonModal, 
    ScheduleModal, 
    Scroll, 
    Service, 
    SignInModal, 
    StatsRow, 
    TransparentHeader,
    CardModal,
    ModalLoader,
    TypeIndicator
} from '../../components';
import { withRouter } from "react-router-dom";
import { 
    collection, 
    doc, 
    getDoc, 
    getDocs, 
    getFirestore, 
    limit, 
    orderBy, 
    query,
    startAfter,
    setDoc,
    increment,
    updateDoc,
    deleteDoc,
    onSnapshot,
    where
} from '@firebase/firestore';
import { saveCard } from '../../actions/clientActions';
import { parseDocs } from '../../util/FirestoreUtil';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import './styles/ArtistProfile.css';
import { CLIENT } from '../../constants/ClientRoutes';
import { THRESHOLD } from '../../constants/ScrollThreshold';
import { DISLIKE, LIKE } from '../../constants/likes';
import { fetchNotiHash, handleNoti } from '../../util/NotiUtil';
import { REVIEW } from '../../constants/Policies';
import { ARTIST_REVIEW } from '../../constants/NotiTypes';
import { incrementViews } from '../../util/AppointmentUtil';

const INITIAL_IMAGES = 9;
const MORE_IMAGES = 15;

class ArtistProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            scheduleHash: null,
            services: [],
            images: [],
            lastImage: null,
            shouldRender: false,
            mapOpen: false,
            scheduleOpen: false,
            photoOpen: false,
            imageIndex: 0,
            noMoreImages: false,
            noCardModalOpen: false,
            signInOpen: false,
            loading: false,
            didLike: false,
            didDislike: false,
            bookUrl: ''
        }
    }

    componentDidMount = async () => {
        const { artistId } = this.props.match.params;
        window.addEventListener('touchend', this.onScroll);
        this.fetchProfile(artistId);
        incrementViews(artistId);
    }

    componentDidUpdate = async (prevProps, prevState) => {
        const { profile: prevProfile } = prevState;
        const { profile } = this.state;
        if (prevProfile === null && profile !== null) {
            await this.fetchInfo(profile.id);
        }
        const { profile: clientProfile } = this.props;
        const { profile: prevClientProfile } = prevProps;
        if (!prevClientProfile && clientProfile) {
            if (this.state.bookUrl) {
                window.location.hash = this.state.bookUrl;
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchend', this.onScroll);
    }

    likePressed = async () => {
        if (this.props.profile) {
            if (this.state.didDislike) {
                await this.undislikeArtist();
            }
            if (this.state.didLike) {
                await this.unlikeArtist();
            } else {
                await this.likeArtist();
            }
        }
    }

    dislikePressed = async () => {
        if (this.props.profile) {
            if (this.state.didLike) {
                await this.unlikeArtist();
            }
            if (this.state.didDislike) {
                await this.undislikeArtist();
            } else {
                await this.dislikeArtist();
            }
        }
    }

    likeArtist = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.state.profile.id}/ratings/${this.props.profile.id}`);
        await setDoc(ref, {
            type: LIKE,
            name: this.state.profile.name,
            time: new Date().getTime(),
        });
        const updateRef = doc(db, `users/${this.state.profile.id}`);
        await updateDoc(updateRef, {
            likes: increment(1)
        });
        const notiHash = await fetchNotiHash(this.state.profile.id);
        const desc = `${this.props.profile.name} liked you.`;
        const preview = desc;
        const method = notiHash[REVIEW];
        await handleNoti(this.state.profile.phone, desc, preview, ARTIST_REVIEW, this.state.profile.id, method, '');
        this.setState({ didLike: true });
    }

    unlikeArtist = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.state.profile.id}/ratings/${this.props.profile.id}`);
        await deleteDoc(ref);
        const updateRef = doc(db, `users/${this.state.profile.id}`);
        await updateDoc(updateRef, {
            likes: increment(-1)
        });
        this.setState({ didLike: false });
    }

    dislikeArtist = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.state.profile.id}/ratings/${this.props.profile.id}`);
        await setDoc(ref, {
            type: DISLIKE,
            name: this.state.profile.name,
            time: new Date().getTime(),
        });
        const updateRef = doc(db, `users/${this.state.profile.id}`);
        await updateDoc(updateRef, {
            dislikes: increment(1)
        });
        const notiHash = await fetchNotiHash(this.state.profile.id);
        const desc = `${this.props.profile.name} disliked you.`;
        const preview = desc;
        const method = notiHash[REVIEW];
        await handleNoti(this.state.profile.phone, desc, preview, ARTIST_REVIEW, this.state.profile.id, method, '');
        this.setState({ didDislike: true });
    }

    undislikeArtist = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.state.profile.id}/ratings/${this.props.profile.id}`);
        await deleteDoc(ref);
        const updateRef = doc(db, `users/${this.state.profile.id}`);
        await updateDoc(updateRef, {
            dislikes: increment(-1)
        });
        this.setState({ didDislike: false })
    }

    onScroll = async (e) => {
        const lastImageId = `image-${this.state.images.length -1}`;
        const query = document.getElementById(lastImageId);
        if (query) {
            const lastImageTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + THRESHOLD;
            if (lastImageTop <= threshold) {
                await this.fetchMoreImages();
            }
        }

    }

    toggleLoading = () => {
        this.setState({ loading: !this.state.loading });
    }

    toggleNoCardModal = () => {
        this.setState({ noCardModalOpen: !this.state.noCardModalOpen });
    }

    toggleSignInModal = () => {
        this.setState({ signInOpen: !this.state.signInOpen });
    }

    toggleMap = () => {
        this.setState({ mapOpen: !this.state.mapOpen });
    }

    toggleSchedule = () => {
        this.setState({ scheduleOpen: !this.state.scheduleOpen });
    }

    openPhoto = ({ index }) => {
        this.setState({ imageIndex: index });
        window.setTimeout(() => {
            this.setState({ photoOpen: true })
        }, 10);
    }

    imageIndexChange = (imageIndex) => {
        if (imageIndex < this.state.images.length) {
            this.setState({ imageIndex });
        }
    }

    closePhoto = () => {
        this.setState({ photoOpen: false });
    }

    parseServices = async (docs) => {
        const { artistId } = this.props.match.params;
        const services = [];
        const db = getFirestore();
        for (let i = 0; i < docs.length; i++) {
            const service = { 
                ...docs[i].data(),
                id: docs[i].id
            }
            if (this.props.profile) {
                const ref = doc(db, `users/${artistId}/services/${service.id}/custom/${this.props.profile.id}`);
                const snap = await getDoc(ref);
                if (snap.exists()) {
                    const customService = {
                        ...snap.data(),
                        image: service.image,
                        id: service.id
                    }
                    services.push(customService);
                } else {
                    services.push(service);
                }
            } else {
                services.push(service);
            }
        }
        return services;
    }

    fetchProfile = (artistId) => {
        const db = getFirestore();
        const profileRef = doc(db, `users/${artistId}`);
        const listener = onSnapshot(profileRef, snap => {
            const profile = { ...snap.data(), id: snap.id };
            this.setState({ profile, shouldRender: true  });
            if (this.props.profile) {
                const ratingRef = doc(db, `users/${artistId}/ratings/${this.props.profile.id}`);
                getDoc(ratingRef).then(ratingSnap => {
                    this.setState({ 
                        listener,         
                        didLike: (ratingSnap.exists() && ratingSnap.data().type === LIKE) ? true : false,
                        didDislike:  (ratingSnap.exists() && ratingSnap.data().type === DISLIKE) ? true : false,
                    });
                });
            } else {
                this.setState({
                    profile,
                    didLike: false,
                    didDislike: false,
                    shouldRender: true
                });
            }
        });

    }

    fetchInfo = async (artistId) => {
        const db = getFirestore();
        const scheduleRef = doc(db, `users/${artistId}/schedule/schedule`);
        const servicesRef = collection(db, `users/${artistId}/services`);
        const imagesRef = collection(db, `images`);
        const scheduleSnap = await getDoc(scheduleRef).then();
        //fetching schedule
        const scheduleHash = scheduleSnap.data().scheduleHash;
        //fetching services
        const servicesSnap = await getDocs(servicesRef).then();
        const services = await this.parseServices(servicesSnap.docs);
        const imagesQuery = query(imagesRef, limit(INITIAL_IMAGES), orderBy('dateAdded', 'desc'), where('artistId', '==', artistId));
        //fetch images
        const imagesSnap = await getDocs(imagesQuery).then();
        const { results: images, lastDoc: lastImage } = parseDocs(imagesSnap);
        this.setState({ 
            scheduleHash,
            services,
            images,
            lastImage,
            shouldRender: true,
            noMoreImages: images.length < INITIAL_IMAGES ? true : false,
        });
    }

    fetchMoreImages = async () => {
        if (!this.state.noMoreImages) {
            this.toggleLoading();
            const db = getFirestore();
            const artistId = this.props.match.params.artistId;
            const imagesRef = collection(db, `images`);
            const limitBy = limit(MORE_IMAGES);
            const order = orderBy('dateAdded', 'desc');
            const w = where('artistId', '==', artistId);
            const start = startAfter(this.state.lastImage);
            const q = query(imagesRef, order, limitBy, w, start);
            const snap = await getDocs(q).then();
            const { results: images, lastDoc: lastImage } = parseDocs(snap);
            const newImages = [ ...this.state.images, ...images ];
            this.setState({
                images: newImages,
                lastImage,
                noMoreImages: images.length < MORE_IMAGES ? true : false
            });
            this.toggleLoading();
        }

    }

    saveCard = (tokens) => {
        this.props.saveCard(this.props.profile, tokens);
        window.location.hash = this.state.bookUrl;
    }

    back = () => {
        window.history.back();
    }

    navToBook = (service) => {
        const url = `${CLIENT.CLIENT_BOOK}/${this.props.match.params.artistId}/${service.id}`;
        if (this.props.profile) {
            if (this.state.profile.stripeAccountId) {
                if (this.props.profile.cardId) {
                    window.location.hash = url;
                } else {
                    this.toggleNoCardModal();
                    this.setState({ bookUrl: url });
                }
            } else {
                window.location.hash = url;
            }
        } else {
            this.toggleSignInModal();
            this.setState({ bookUrl: url });
        }
    }

    navToBookWithoutService = () => {
        const url = `${CLIENT.CLIENT_BOOK}/${this.props.match.params.artistId}`;
        if (this.props.profile) {
            if (this.state.profile.stripeAccountId) {
                if (this.props.profile.cardId) {
                    window.location.hash = url;
                } else {
                    this.toggleNoCardModal();
                    this.setState({ bookUrl: url });
                }
            } else {
                window.location.hash = url;
            }
        } else {
            this.toggleSignInModal();
            this.setState({ bookUrl: url });
        }
    }

    renderEmpty = () => {
        return (
            <Loader 
                message='Loading...'
            />
        )
    }

    renderProfilePicture = () => {
        return (
            <ProfileImage 
                id={this.props.match.params.artistId}
                image={this.state.profile.profileImage}
                size={Math.ceil(window.screen.width / 3)}
            />
        )
    }

    renderService = (service, index) => {
        return (
            <Service 
                key={index}
                service={service}
                onClick={this.navToBook}
            />
        )
    }

    renderImage = (image, index) => {
        return (
            <PortfolioImage 
                key={index}
                image={image}
                id={this.props.match.params.artistId}
                onClick={this.openPhoto}
                index={index}
                elementId={`image-${index}`}
            />
        )
    }

    renderMap = () => {
        return (
            <SalonModal 
                open={this.state.mapOpen}
                onClose={this.toggleMap}
                salonName={this.state.profile.salonName}
                address={this.state.profile.address}
                coords={this.state.profile._geoloc}
            />
        )
    }

    renderPhotoModal = () => {
        return (
            <FullPhotoModal 
                open={this.state.photoOpen}
                onClose={this.closePhoto}
                images={this.state.images}
                imageIndex={this.state.imageIndex}
                id={this.props.match.params.artistId}
                imageIndexChange={this.imageIndexChange}
                fetchMoreImages={this.fetchMoreImages}
            />
        )
    }

    renderSchedule = () => {
        return (
            <ScheduleModal 
                open={this.state.scheduleOpen}
                onClose={this.toggleSchedule}
                scheduleHash={this.state.scheduleHash ? this.state.scheduleHash : {}}
            />
        )
    }

    renderBookButton = () => {
        return (
            <BrandButton 
                label='Book Appointment'
                style={BOTTOM_BUTTON}
                onClick={this.navToBookWithoutService}
            />
        )
    }

    renderBio = () => {
        if (this.state.profile.bio) {
            return (
                <Bio profile={this.state.profile} />
            )
        }
    }

    renderArtistTop = () => {
        return (
            <ArtistTop>
                {this.renderProfilePicture()}
                <Header style={styles.topBottom} title={this.state.profile.artistType} />
                <TypeIndicator stripeAccountId={this.state.profile.stripeAccountId} />
                {this.renderBio()}
                <InfoRow 
                    profile={this.state.profile}
                    toggleMap={this.toggleMap}
                    toggleHours={this.toggleSchedule}
                />
                <StatsRow 
                    profile={this.state.profile}
                    toggleLikes={this.likePressed}
                    toggleDislikes={this.dislikePressed}
                    didLike={this.state.didLike}
                    didDislike={this.state.didDislike}
                />
            </ArtistTop>
        )
    }

    renderServices = () => {
        if (this.state.services.length > 0) {
            return this.state.services.map(this.renderService);
        }
        return (
            <ModalLoader 
                message='Loading Services...'
                style={styles.serviceLoader}
            />
        )

    }

    renderImages = () => {
        return (
            <ImagesContainer>
                {this.state.images.map(this.renderImage)}
            </ImagesContainer>
        )
    }

    renderNoCardModal = () => {
        return (
            <CardModal 
                message={`${this.state.profile.name.split(' ')[0]} uses Argenda Payments. To book an appointment, you must have a payment method.`}
                open={this.state.noCardModalOpen}
                onClose={this.toggleNoCardModal}
                onChange={this.saveCard}
            />
        )
    }

    renderSignInModal = () => {
        return (
            <SignInModal 
                open={this.state.signInOpen}
                onClose={this.toggleSignInModal}
            />
        )
    }

    renderServicesTitle = () => {
        if (this.state.services.length > 0) {
            return (
                <Header style={styles.topBottom} title='Services' />
            )
        }
    }

    renderPortfolioTitle = () => {
        if (this.state.images.length > 0) {
            return (
                <Header style={styles.bottom} title='Portfolio' />
            )
        }
    }

    renderContent = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.match.params.artistId, this.state.profile.profileImage);
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    showBack
                    title={this.state.profile.name}
                    onBack={this.back}
                    showSettings={false}
                    loading={this.state.loading}
                />
                <Scroll>
                    {this.renderArtistTop()}
                    {this.renderServicesTitle()}
                    {this.renderServices()}
                    {this.renderPortfolioTitle()}
                    {this.renderImages()}
                </Scroll>
                {this.renderMap()}
                {this.renderSchedule()}
                {this.renderBookButton()}
                {this.renderPhotoModal()}
                {this.renderNoCardModal()}
                {this.renderSignInModal()}
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.state.shouldRender) {
            return this.renderContent();
        }
        return this.renderEmpty();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    topBottom: {
        marginBottom: 10,
        marginTop: 15,
    },
    bottom: {
        marginBottom: 15
    },
    serviceLoader: {
        width: '100%', height: 300
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.client;
    return { profile }
}

export default withRouter(connect(mapStateToProps, { saveCard })(ArtistProfile));