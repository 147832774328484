import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    FlexRow,
    FlexRowButton,
    TransparentInput, 
    ModalRowBack,
    SettingsTitle,
    SettingsLoader,
    BrandButton,
} from '../components';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import axios from 'axios';
import { 
    UPDATE_PHONE_FOR_CONNECT_ACCOUNT, 
    UPDATE_PHONE_FOR_CUSTOMER_ACCOUNT 
} from '../constants/ArgendaServerURLS';

class UpdatePhone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            phone: props.profile.phone
        }
    }

    phoneChange = (phone) => {
        this.setState({ phone });
    }

    focusPhone = () => {
        if (this.phoneRef) {
            this.phoneRef.focus();
        }
    }


    savePhone = async () => {
        this.setState({ loading: true });
        try {
            if (this.props.profile.stripeAccountId) {
                //connect account, update accordingly
                const pack = {
                    stripeAccountId: this.props.profile.stripeAccountId,
                    stripeCustomerId: this.props.profile.stripeCustomerId,
                    id: this.props.profile.id,
                    phone: this.state.phone
                }
                await axios.post(UPDATE_PHONE_FOR_CONNECT_ACCOUNT, pack);
            } else {
                const pack = {
                    stripeCustomerId: this.props.profile.stripeCustomerId,
                    id: this.props.profile.id,
                    phone: this.state.phone
                }
                await axios.post(UPDATE_PHONE_FOR_CUSTOMER_ACCOUNT, pack);
            }
            this.setState({ success: true });
        } catch (ex) {
            console.log(ex);
        }
    }

    renderLoader = () => {
        return (
            <SettingsLoader 
                message={this.state.success ? 'Phone updated' : 'Updating Phone...'}
                success={this.state.success}
                onClick={this.props.onBack}
            />
        )
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Update Phone #'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Phone #'
            />
        )
    }

    renderPhone = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusPhone}>
                    <TransparentInput 
                        value={this.state.phone}
                        onChange={this.phoneChange}
                        passRefUp={ref => this.phoneRef = ref}
                        placeholder='Phone #'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                disabled={!this.state.phone}
                onClick={this.savePhone}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderPhone()}
                {this.renderSave()}
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

UpdatePhone.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object
};

UpdatePhone.defaultProps = {
    onBack: () => {},
    profile: {}
}

export default UpdatePhone;