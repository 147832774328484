import React from 'react';
import PropTypes from 'prop-types';
import './styles/ImageInput.css';

const ImageInput = ({ onChange, id, multiple }) => {

    const change = (e) => {
        onChange(e.target.files);
    }

    return (
        <input 
            type='file'
            className='II-Input'
            id={id}
            onChange={change}
            multiple={multiple}
        />
    )
}

ImageInput.propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    multiple: PropTypes.bool,
};

ImageInput.defaultProps = {
    onChange: () => {},
    id: '',
    multiple: false
};

export default ImageInput;