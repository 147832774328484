import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/Noti.css';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import NotiIcon from './NotiIcon';
import { getTimeSince } from '../util/TimeUtil';

class Noti extends Component {

    componentWillUnmount = () => {
        this.setShown();
    }

    setShown = async () => {
        const db = getFirestore();
        const prefix = this.props.isClient ? 'clients' : 'users';
        const ref = doc(db, `${prefix}/${this.props.noti.destId}/notifications/${this.props.noti.id}`);
        await updateDoc(ref, {
            shown: true
        });
    }

    handleNoti = () => {
        this.props.dismissDrawer();
        if (this.props.noti.link) {
            window.location.hash = this.props.noti.link;
        }
    }

    renderIcon = () => {
        return (
            <div className='N-IconSection'>
                <NotiIcon type={this.props.noti.type} />
            </div>
        )
    }

    renderSection = () => {
        return (
            <div className='N-ContentSection'>
                <h5 className='N-Title'>{this.props.noti.title}</h5>
                <p className='N-Desc'>{this.props.noti.preview}</p>
                {this.renderStamp()}
            </div>
        )
    }

    renderStamp = () => {
        const stamp = getTimeSince(this.props.noti.time);
        return (
            <p className='N-Stamp'>{stamp}</p>
        )
    }

    render = () => {
        return (
            <div 
                className={'N-Container'}
                onClick={this.handleNoti}
            >
                {this.renderIcon()}
                {this.renderSection()}
            </div>
        )
    }
}

Noti.propTypes = {
    noti: PropTypes.object,
    onClick: PropTypes.func,
    dismissDrawer: PropTypes.func,
    isClient: PropTypes.bool
};

Noti.defaultProps = {
    noti: {},
    onClick: () => {},
    dismissDrawer: () => {},
    isClient: false
}

export default Noti;