import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, Carousel } from '.';
import { buildImageUrl } from '../util/ImageUtil';
import './styles/FullPhotoModal.css';

class FullPhotoModal extends Component {

    componentDidUpdate = () => {
        if (this.props.imageIndex === this.props.images.length - 2) {
            this.props.fetchMoreImages();
        } 
    }

    imageChange = (index) => {
        this.props.imageIndexChange(index)
    }

    renderImage = (index, left) => {
        const width = window.screen.width;
        const height = window.screen.height * 0.6;
        const url = buildImageUrl(this.props.id, this.props.images[index].imageName, width, height);
        return (
            <img 
                src={url}
                className='FullPhotoModal-Image'
                id={index}
                style={{ height, left, marginTop: (window.screen.height - height) / 4 }}
            />
        )
    }

    renderCarousel = () => {
        if (this.props.open) {
            return (
                <Carousel 
                    currentIndex={this.props.imageIndex}
                    indexChange={this.props.imageIndexChange}
                    length={this.props.images.length}
                    renderItem={this.renderImage}
                />
            )
        }
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={.90}
            >
                {this.renderCarousel()}
            </BottomUpModal>
        )
    }
}

FullPhotoModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    images: PropTypes.array,
    imageIndex: 0,
    id: PropTypes.string,
    imageIndexChange: PropTypes.func,
    fetchMoreImages: PropTypes.func
};

FullPhotoModal.defaultProps = {
    open: false,
    onClose: () => {},
    images: [],
    imageIndex: 0,
    id: '',
    imageIndexChange: () => {},
    fetchMoreImages: () => {}
}

export default FullPhotoModal;