import React from 'react';
import PropTypes from 'prop-types';
import { 
    Body, 
    TransparentHeader, 
    Schedule, 
    BrandButton, 
    BigTitle
} from '.';
import { ARTIST } from '../constants/ClientRoutes';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const HoursSetup = ({ onNext, scheduleHash, onBack }) => {

    const navToDay = (day) => {
        window.location.hash = `${ARTIST.ARTIST_SET_SCHEDULE}/${day}`;
    }

    const renderNext = () => {
        if (Object.keys(scheduleHash).length > 0) {
            return (
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Set up Portfolio'
                    onClick={onNext}
                />
            )
        }
    }

    return (
        <Body>
            <TransparentHeader 
                showBack
                onBack={onBack}
                title='Hours'
            />
            <BigTitle 
                title="Let's set up your hours."
            />
            <Schedule 
                scheduleHash={scheduleHash}
                hideVacation
                selectDay={navToDay}
            />
            {renderNext()}
        </Body>
    )
}

HoursSetup.propTypes = {
    onNext: PropTypes.func,
    scheduleHash: PropTypes.object,
    onBack: PropTypes.func,
};

HoursSetup.defaultProps = {
    onNext: () => {},
    scheduleHash: {},
    onBack: () => {}
};

export default HoursSetup;