import React from 'react';
import PropTypes from 'prop-types';
import { BigTitle, Body } from '.';
import { BsFillEmojiFrownFill } from 'react-icons/bs';
import './styles/Empty.css';

const Empty = ({ message }) => {
    return (
        <Body center>
            <BsFillEmojiFrownFill className='E-Icon' />
            <BigTitle style={{ marginTop: 15 }} title={message} />
        </Body>
    )
}

Empty.propTypes = {
    message: PropTypes.string,
};

Empty.defaultProps = {
    message: ''
}

export default Empty;