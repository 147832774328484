import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { 
    AddressPicker2, 
    BottomUpModal, 
    BrandButton, 
    Body, 
    MapMarker, 
    ModalRowBack 
} from '.';
import GoogleMapReact from 'google-map-react';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { GOOGLE_MAP_API_KEY } from '../secret/secret';
import './styles/ArtistAddressModal.css';
class ArtistAddressModal extends Component { 

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            salonName: '',
            suite: '',
            mapLeft: 0,
            searchLeft: window.screen.width,
            confirmLeft: window.screen.width * 2
        }
    }

    saveAddress = () => {
        this.props.saveAddress({
            address: this.state.address,
            salonName: this.state.salonName,
            suite: this.state.suite
        });
        this.navToMap();
    }

    navToSearch = () => {
        this.setState({ 
            mapLeft: -window.screen.width,
            searchLeft: 0,
            confirmLeft: window.screen.width
        });
    }

    navToConfirm = () => {
        this.setState({
            mapLeft: -window.screen.width * 2,
            searchLeft: -window.screen.width,
            confirmLeft: 0
        });
    }

    navToMap = () => {
        this.setState({
            mapLeft: 0,
            searchLeft: window.screen.width,
            confirmLeft: window.screen.width * 2
        })
    }

    salonChange = (e) => {
        this.setState({ salonName: e.target.value });
    }

    suiteChange = (e) => {
        this.setState({ suite: e.target.value });
    }

    selectAddress = (address) => {
        this.setState({ address });
        window.setTimeout(this.navToConfirm, 100);
    }

    renderMap = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.mapLeft }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                    defaultCenter={{ lat: this.props.profile._geoloc.lat, lng: this.props.profile._geoloc.lng }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                >
                    <MapMarker 
                        profile={this.props.profile}
                        lat={this.props.profile._geoloc.lat}
                        lng={this.props.profile._geoloc.lng}
                    />
                </GoogleMapReact>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Change Address'
                    onClick={this.navToSearch}
                />
            </Body>
        )
    }

    renderConfirmMap = () => {
        if (this.state.address) {
            return (
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                    defaultCenter={{ lat: this.state.address.location.lat, lng: this.state.address.location.lng }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={15}
                >
                    <MapMarker 
                        profile={{ salonName: this.state.salonName, address: this.state.address.address }}
                        lat={this.state.address.location.lat}
                        lng={this.state.address.location.lng}
                    />
                </GoogleMapReact>
            )
        }
    }

    renderConfirm = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.confirmLeft }}
            >
                <ModalRowBack 
                    title='Back To Search'
                    onBack={this.navToSearch}
                />
                <input 
                    className='ArtistAddressModal-Input'
                    value={this.state.salonName}
                    placeholder={`What's the name of this location?`}
                    onChange={this.salonChange}
                />
                <input 
                    className='ArtistAddressModal-Input'
                    value={this.state.suite}
                    placeholder={`Is there a suite/studio number?`}
                    onChange={this.suiteChange}
                />
                {this.renderConfirmMap()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Confirm'
                    disabled={!this.state.salonName}
                    onClick={this.saveAddress}
                />
            </Body>
        )
    }

    renderSearch = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.searchLeft }}
            >
                <AddressPicker2 
                    onBack={this.navToMap}
                    onChange={this.selectAddress}
                    navBack={false}
                />
            </Body>
        )
    }

    onClose = () => {
        this.navToMap();
        this.props.onClose();
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.onClose}
            >
                <Body relative>
                    {this.renderMap()}
                    {this.renderSearch()}
                    {this.renderConfirm()}
                </Body>
            </BottomUpModal>
        )
    }
}

ArtistAddressModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    profile: PropTypes.object,
    new: PropTypes.bool,
    saveAddress: PropTypes.func
};

ArtistAddressModal.defaultProps = {
    open: false,
    onClose: () => {},
    profile: {},
    new: false,
    saveAddress: () => {}
}

export default ArtistAddressModal;