import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import './styles/BrandButton.css';

const BrandButton = ({ label, onClick, disabled, style, loading }) => {

    const decideClass = () => {
        if (disabled) {
            return 'BrandButton-Button BrandButton-Disable';
        }
        return 'BrandButton-Button';
    }

    const buttonClick = () => {
        if (!disabled) {
            onClick();
        }
    }

    const renderButton = () => {
        return (
            <button 
                className={decideClass()}
                onClick={buttonClick}
                style={style}
            >
                <p>{label}</p>
            </button>
        )
    }

    const renderLoader = () => {
        return (
            <div 
                className='BrandButton-Row'
                style={style}
            >
                <ReactLoading 
                    type='spokes'
                    height={45}
                    width={45}
                />
            </div>
        )
    }

    const renderDecide = () => {
        if (loading) {
            return renderLoader();
        }
        return renderButton();
    }

    return renderDecide();
}

BrandButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    reverse: PropTypes.bool,
    loading: PropTypes.bool,
};

BrandButton.defaultProps = {
    label: '',
    onClick: () => {},
    disabled: false,
    style: {},
    reverse: false,
    loading: false
}

export default BrandButton;