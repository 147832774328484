import React, { Component } from 'react';
import './styles/SetSchedule.css';
import { getAuth } from 'firebase/auth';
import { 
    schedulePropChange, 
    saveSchedule,
    resetSchedule,
    closeSchedule,
    initDaySelected,
    clearBreak
} from '../../actions/scheduleActions';
import { connect } from 'react-redux';
import { 
    AreYouSure,
    BottomUpModal,
    BrandButton,
    BreakControl,
    ButtonLabel,
    ClockModal,
    FlexRow,
    FlexRowButton,
    ImageBackground, 
    Loader, 
    RepeatControl, 
    TransparentHeader, 
    Value,
    Option
} from '../../components';
import { ARTIST } from '../../constants/ClientRoutes';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { readifyDay, reverseTranslateDay } from '../../util/DayUtil';
import { BOTTOM_BUTTON, INPUT_LABEL, OPTION_CANCEL } from '../../universalStyles/Styles';
import { 
    convertWebTimeStringToNumber, 
    convertWebTimeStringToReadable 
} from '../../util/TimeUtil';
import { IoIosClock } from 'react-icons/io';
import { MdLunchDining } from 'react-icons/md';
import { withRouter } from "react-router-dom";

class SetSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closeOpen: false,
            repeatOpen: false,
            breakOpen: false,
            startOpen: false,
            endOpen: false,
            settingsOpen: false,
            clearBreakOpen: false
        }
    }

    componentDidMount = () => {
        const { day } = this.props.match.params;
        const { scheduleHash } = this.props;
        const schedule = scheduleHash[day] ? scheduleHash[day] : {}
        this.props.initDaySelected(day, schedule);
    }

    toggleStart = () => {
        this.setState({ startOpen: !this.state.startOpen });
    }

    openClearBreak = () => {
        this.toggleSettings();
        this.toggleClearBreak();
    }

    toggleClearBreak = () => {
        this.setState({ clearBreakOpen: !this.state.clearBreakOpen });
    }

    toggleEnd = () => {
        this.setState({ endOpen: !this.state.endOpen });
    }
    
    toggleSettings = () => {
        this.setState({ settingsOpen: !this.state.settingsOpen });
    }

    toggleClose = () => {
        this.setState({ closeOpen: !this.state.closeOpen });
    }

    toggleBreak = () => {
        this.setState({ breakOpen: !this.state.breakOpen });
    }

    toggleRepeat = () => {
        this.setState({ repeatOpen: !this.state.repeatOpen });
    }

    closeSchedule = () => {
        this.props.closeSchedule(this.props.daySelected, this.props.scheduleHash);
        this.back();
    }

    clearBreak = () => {
        this.props.clearBreak(this.props.daySelected, this.props.scheduleHash);
        this.toggleClearBreak();
    }

    saveRepeatHash = (repeatHash) => {
        this.props.schedulePropChange('repeatHash', repeatHash);
    }

    saveBreak = ({ breakStart, breakEnd }) => {
        this.props.schedulePropChange('breakStart', breakStart);
        this.props.schedulePropChange('breakEnd', breakEnd);
    }

    getBackgroundImage = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return url;
    }

    back = () => {
        window.history.back();
        this.props.resetSchedule();
    }

    canSave = () => {
        const { start, end, breakStart, breakEnd } = this.props;
        const numberStart = convertWebTimeStringToNumber(start);
        const numberEnd = convertWebTimeStringToNumber(end);
        const numberBreakStart = convertWebTimeStringToNumber(breakStart);
        const numberBreakEnd = convertWebTimeStringToNumber(breakEnd);
        const hasData = (start && end);
        const hasBreak = (breakStart && breakEnd);
        const valid = (numberEnd > numberStart);
        const validBreak = (numberBreakEnd > numberBreakStart);
        if (hasData && valid) {
            if (hasBreak) {
                if (validBreak) {
                    //does the break take place between the start and end?
                    return (numberBreakStart > numberStart && numberBreakEnd < numberEnd);
                }
                return false;
            }
            return true;
        }
        return false;
    }

    save = () => {
        const saveSchedule = {
            start: this.props.start,
            end: this.props.end,
            repeatHash: this.props.repeatHash,
            breakStart: this.props.breakStart,
            breakEnd: this.props.breakEnd,
            daySelected: this.props.daySelected,
            scheduleHash: this.props.scheduleHash,
            scheduleExists: this.props.scheduleExists
        }
        this.props.saveSchedule(saveSchedule);
        this.back();
    }

    focusStartRef = () => {
        this.startRef.focus();
    }

    focusEndRef = () => {
        this.endRef.focus();
    }

    navToRepeatSchedule = () => {
        window.location.hash = ARTIST.ARTIST_REPEAT_SCHEDULE;
    }

    navToSetBreak = () => {
        window.location.hash = ARTIST.ARTIST_SET_BREAK;
    }

    startChange = (start) => {
        this.props.schedulePropChange('start', start);
    }

    endChange = (end) => {
        this.props.schedulePropChange('end', end);
    }

    convertDaysToAbbrev = (days) => {
        const abbrevs = [];
        for (let i = 0; i < days.length; i++) {
            const day = days[i];
            const abbrev = reverseTranslateDay(day);
            abbrevs.push(abbrev);
        }
        return abbrevs;
    }

    openClose = () => {
        this.toggleSettings();
        this.toggleClose();
    }

    renderRepeat = (day, index) => {
        const useComma = index < Object.keys(this.props.repeatHash).length - 1;
        const labelString = `${day}${useComma ? ', ' : ''}`
        return (
            <ButtonLabel 
                label={labelString}
                key={index}
            />
        )
    }

    renderRepeatButton = () => {
        const abbrevs = this.convertDaysToAbbrev(Object.keys(this.props.repeatHash));
        if (this.canSave()) {
            return (
                <FlexRow>
                    <FlexRowButton onClick={this.toggleRepeat}>
                        {abbrevs.length > 0 &&
                            <div className='SetSchedule-RepeatValueContainer'>
                                {abbrevs.map(this.renderRepeat)}
                            </div>
                        }
                        <ButtonLabel 
                            label='Repeat Schedule'
                            style={INPUT_LABEL}
                        />
                    </FlexRowButton>
                </FlexRow>
            )
        }
    }

    renderStartValue = () => {
        if (this.props.start) {
            return (
                <Value value={convertWebTimeStringToReadable(this.props.start)} />
            )
        }
    }

    renderStart = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleStart}>
                    {this.renderStartValue()}
                    <ButtonLabel 
                        label='When do you start work?'
                        style={INPUT_LABEL}
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderEndValue = () => {
        if (this.props.end) {
            return (
                <Value value={convertWebTimeStringToReadable(this.props.end)} />
            )
        }
    }

    renderEnd = () => {
        if (this.props.start) {
            return (
                <FlexRow>
                    <FlexRowButton onClick={this.toggleEnd}>
                        {this.renderEndValue()}
                        <ButtonLabel 
                            label='When do you leave work?'
                            style={INPUT_LABEL}
                        />
                    </FlexRowButton>
                </FlexRow>
            )
        }

    }

    renderBreakButton = () => {
        if (this.canSave()) {
            return (
                <FlexRow>
                    <FlexRowButton onClick={this.toggleBreak}>
                        {(this.props.breakStart && this.props.breakEnd) &&
                            <p className='SetSchedule-BreakLabel'>{`${convertWebTimeStringToReadable(this.props.breakStart)} - ${convertWebTimeStringToReadable(this.props.breakEnd)}`}</p>
                        }
                        <ButtonLabel 
                            label='Break'
                            style={INPUT_LABEL}
                        />
                    </FlexRowButton>
                </FlexRow>
            )
        }

    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                disabled={!this.canSave()}
                onClick={this.save}
            />
        )
    }

    renderCloseModal = () => {
        return (
            <AreYouSure 
                title={`Are you sure you want to close your schedule for ${readifyDay(this.props.daySelected)}?`}
                confirmMessage={`Confirm you want to close your schedule for ${readifyDay(this.props.daySelected)} by typing:`}
                onClose={this.toggleClose}
                open={this.state.closeOpen}
                valueToType={readifyDay(this.props.daySelected)}
                accept={this.closeSchedule}
            />
        )
    }

    renderClearBreakModal = () => {
        return (
            <AreYouSure 
                open={this.state.clearBreakOpen}
                onClose={this.toggleClearBreak}
                valueToType={readifyDay(this.props.daySelected)}
                title={`Are you sure you want to clear your break for ${readifyDay(this.props.daySelected)}?`}
                confirmMessage={`Confirm you want to clear your break for ${readifyDay(this.props.daySelected)} by typing:`}
                accept={this.clearBreak}
            />
        )
    }

    renderRepeatControl = () => {
        return (
            <RepeatControl 
                open={this.state.repeatOpen}
                onClose={this.toggleRepeat}
                daySelected={this.props.daySelected}
                schedule={{
                    start: this.props.start,
                    end: this.props.end,
                    breakStart: this.props.breakStart,
                    breakEnd: this.props.breakEnd
                }}
                scheduleHash={this.props.scheduleHash}
                repeatHash={this.props.repeatHash}
                onSave={this.saveRepeatHash}
                image={this.getBackgroundImage()}
            />
        )
    }

    renderBreakControl = () => {
        return (
            <BreakControl 
                open={this.state.breakOpen}
                onClose={this.toggleBreak}
                onSave={this.saveBreak}
                daySelected={this.props.daySelected}
                schedule={{
                    start: this.props.start,
                    end: this.props.end,
                    breakStart: this.props.breakStart,
                    breakEnd: this.props.breakEnd
                }}
                image={this.getBackgroundImage(0)}
            />
        )
    }

    renderStartInput = () => {
        return (
            <ClockModal 
                title='When do you start work?'
                onSave={this.startChange}
                onClose={this.toggleStart}
                value={this.props.start}
                open={this.state.startOpen}
                image={this.getBackgroundImage()}
            />
        )
    }

    renderEndInput = () => {
        return (
            <ClockModal 
                title='When do you finish work?'
                onSave={this.endChange}
                onClose={this.toggleEnd}
                value={this.props.end}
                open={this.state.endOpen}
                copy={1}
                image={this.getBackgroundImage()}
            />
        )
    }

    renderSettingsModal = () => {
        return (
            <BottomUpModal
                open={this.state.settingsOpen}
                onClose={this.toggleSettings}
                height={0.3}
                title='Options'
                image={this.getBackgroundImage()}
                darken
            >
                <Option 
                    label={`Close ${readifyDay(this.props.daySelected)}`}
                    onClick={this.openClose}
                    icon={<IoIosClock />}
                    backgroundColor={OPTION_CANCEL}
                />
                <Option 
                    label='Clear Break'
                    backgroundColor={OPTION_CANCEL}
                    icon={<MdLunchDining />}
                    onClick={this.openClearBreak}
                />
            </BottomUpModal>
        )
    }

    renderHeader = () => {
        return (
            <TransparentHeader
                title={`Edit ${readifyDay(this.props.daySelected)}'s Schedule`}
                onBack={this.back}
                showDelete={!(this.props.breakStart && this.props.breakEnd)}
                onDelete={this.toggleClose}
                showSettings={(this.props.breakStart && this.props.breakEnd)}
                onSettings={this.toggleSettings}
            />
        )
    }

    renderContent = () => {
        const id = getAuth().currentUser.uid;
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, id, this.props.profile.profileImage)
        return (
            <ImageBackground
                image={url}
                darken
            >
                {this.renderHeader()}
                {this.renderStart()}
                {this.renderEnd()}
                {this.renderBreakButton()}
                {this.renderRepeatButton()}
                {this.renderSave()}
                {this.renderCloseModal()}
                {this.renderRepeatControl()}
                {this.renderBreakControl()}
                {this.renderStartInput()}
                {this.renderEndInput()}
                {this.renderSettingsModal()}
                {this.renderClearBreakModal()}
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading...'
            />
        )
    }

    renderDecide = () => {
        if (this.props.daySelected) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const mapStateToProps = (state) => {
    const { 
        scheduleHash, 
        daySelected, 
        start, 
        end, 
        repeatHash,
        breakStart,
        breakEnd,
        scheduleExists,
        dayExists
    } = state.schedule;
    const { profile } = state.artist;
    return { 
        profile, 
        scheduleHash, 
        daySelected,
        start,
        end,
        repeatHash,
        breakStart,
        breakEnd,
        scheduleExists,
        dayExists
    };
}

export default withRouter(connect(mapStateToProps, { 
    schedulePropChange,
    saveSchedule,
    resetSchedule,
    closeSchedule,
    initDaySelected,
    clearBreak
})(SetSchedule));