import React from 'react';
import { Provider } from 'react-redux';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_TEST_PUBLISH_KEY } from './secret/secret';
import { initializeApp } from "firebase/app";
import store from './reducers/store';
import './App.css';
import { firebaseConfig } from './secret/secret';
import Router from './screens/Router';

const app = initializeApp(firebaseConfig);

const stripePromise = loadStripe(STRIPE_TEST_PUBLISH_KEY);

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
          <Router />
      </Provider>
    </Elements>
  );
}

export default App;
