import React, { Component } from 'react';
import { 
    TransparentHeader,
    ImageBackground,
    Scroll,
    Stat
} from '../../components';
import { connect } from 'react-redux';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { 
    collection, 
    getDoc, 
    getDocs, 
    getFirestore, 
    orderBy, 
    query, 
    doc,
    limit,
    startAfter
} from '@firebase/firestore';
import { parseDocs } from '../../util/FirestoreUtil';
import './styles/MonthStats.css';

const INITIAL_LIMIT = 3;
const MORE_LIMIT = 8;

class MonthStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            currentStat: null,
            noMoreStats: false,
            statCursor: null,
            loading: false
        }
    }

    componentDidMount = () => {
        this.fetchStats();
        this.fetchCurrentStat();
        window.addEventListener('touchend', this.onScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchend', this.onScroll);
    }

    onScroll = (e) => {
        if (this.state.stats.length > 0) {
            const lastStatId = this.state.stats[this.state.stats.length - 1].id;
            const query = document.getElementById(lastStatId);
            const lastStatTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + 250;
            if (lastStatTop <= threshold && !this.state.noMoreStats) {
                this.fetchMoreStats();
            }
        }
    }

    fetchCurrentStat = async () => {
        const db = getFirestore();
        const { year, month } = this.getCurrentMonthAndYear();
        const id = `${month}-${year}`;
        const ref = doc(db, `users/${this.props.profile.id}/stats/${id}`);
        const snap = await getDoc(ref).then();
        const currentStat = { ...snap.data(), id: snap.id };
        this.setState({ currentStat });
    }

    fetchStats = async () => {
        const db = getFirestore();
        const ref = collection(db, `users/${this.props.profile.id}/stats`);
        const o = orderBy('order', 'asc');
        const l = limit(INITIAL_LIMIT);
        const q = query(ref, o, l);
        const snap = await getDocs(q).then();
        const { results: stats, lastDoc: statCursor } = parseDocs(snap);
        this.setState({ 
            stats, 
            statCursor,
            noMoreStats: stats.length < INITIAL_LIMIT 
        });
    }

    fetchMoreStats = async () => {
        this.setState({ loading: true });
        const db = getFirestore();
        const ref = collection(db, `users/${this.props.profile.id}/stats`);
        const o = orderBy('order', 'asc');
        const l = limit(MORE_LIMIT);
        const s = startAfter(this.state.statCursor);
        const q = query(ref, o, l, s);
        const snap = await getDocs(q).then();
        const { results: stats, lastDoc: statCursor } = parseDocs(snap);
        this.setState({ 
            stats: [...this.state.stats, ...stats], 
            statCursor,
            noMoreStats: stats.length < MORE_LIMIT,
            loading: false
        });
    }

    back = () => {
        window.history.back();
    }

    getCurrentMonthAndYear = () => {
        const date = new Date();
        return { month: date.getMonth(), year: date.getFullYear() };
    }

    getTimeToLookFor = () => {
        const { year, month } = this.getCurrentMonthAndYear();
        return new Date(year, month).getTime();
    }

    isSelected = (stat) => {
        const parts = stat.id.split('-');
        const month = parseInt(parts[0]);
        const year = parseInt(parts[1]);
        const { month: currentMonth, year: currentYear } = this.getCurrentMonthAndYear();
        if (month == currentMonth && year == currentYear) {
            return true;
        }
        return false;
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                onBack={this.back}
                title='Analytics'
                loading={this.state.loading}
            />
        )
    }

    renderStat = (stat, index) => {
        const timeToLookFor = this.getTimeToLookFor();
        if (stat.order !== timeToLookFor) {
            return (
                <Stat 
                    stat={stat}
                    key={index}
                    selected={this.isSelected(stat)}
                />
            )
        }
    }

    renderCurrentStat = () => {
        if (this.state.currentStat) {
            return (
                <div className='MS-Container'>
                    <Stat 
                        selected
                        stat={this.state.currentStat}
                    />
                    <h3 className='MS-CurrentMonth'>Current Month</h3>
                </div>
            )    
        }        
    }

    render = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return (
            <ImageBackground
                image={url}
                darken
            >
                {this.renderHeader()}
                {this.renderCurrentStat()}
                <Scroll id='scroll'>
                    {this.state.stats.map(this.renderStat)}
                </Scroll>
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile }
}

export default connect(mapStateToProps, {})(MonthStats);