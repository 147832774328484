import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
    BigTitle, 
    TransparentHeader, 
    Body, 
    SubTitle, 
    Scroll, 
    FAB, 
    Service, 
    BrandButton 
} from '.';
import { ARTIST } from '../constants/ClientRoutes';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';


class ServiceSetup extends Component {

    navToAddService = () => {
        window.location.hash = ARTIST.ARTIST_ADD_SERVICE;
    }

    completeSetup = () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}`);
        updateDoc(ref, {
            finishedSetup: true
        });
    }

    navToEditService = (service) => {
        window.location.hash= `${ARTIST.ARTIST_EDIT_SERVICE}/${service.id}`;
    }

    renderDecideBody = () => {
        if (this.props.services.length === 0) {
            return this.renderNoServices();
        }
        return this.renderServicesContent();
    }

    renderService = (service, index) => {
        return (
            <Service 
                key={index}
                service={service}
                onClick={this.navToEditService}
            />
        )
    }

    renderServicesContent = () => {
        return (
            <Body>
                <Body>
                    <Scroll>
                        {this.props.services.map(this.renderService)}
                    </Scroll>
                </Body>
                <FAB 
                    onClick={this.navToAddService}
                    style={{ bottom: 100 }}
                />
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Go To Profile'
                    disabled={this.props.services.length === 0}
                    onClick={this.completeSetup}
                />
            </Body>
        )
    }

    renderNoServices = () => {
        return (
            <Body>
                <SubTitle 
                    title='Add a service e.g. "Haircut"'
                />
                <FAB 
                    onClick={this.navToAddService}
                />
            </Body>
        )
    }

    render = () => {
        return (
            <Body>
                <TransparentHeader 
                    onBack={this.props.onBack}
                    title='Servies'
                />
                <BigTitle 
                    title="Let's set up your Services."
                />
                {this.renderDecideBody()}
            </Body>
        )
    }
}

const mapStateToProps = (state) => {
    const { services } = state.service;
    const { profile } = state.artist;
    return { services, profile }
}

ServiceSetup.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

ServiceSetup.defaultProps = {
    onBack: () => {},
    onNext: () => {}
};

export default connect(mapStateToProps, {})(ServiceSetup);