import React from 'react';
import PropTypes from 'prop-types'
import './styles/Header.css';


const Header = ({ title, style = {}, onClick }) => {
    return (
        <h3 
            style={style} 
            className='Header-Title'
            onClick={onClick}
        >
            {title}
        </h3>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
}

Header.defaultProps = {
    title: '',
    style: {},
    onClick: () => {}
}

export default Header;