import { 
    getFirestore, 
    doc, 
    getDoc, 
    setDoc, 
    increment, 
    updateDoc,
    onSnapshot
} from 'firebase/firestore';
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword, 
    signOut
} from 'firebase/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AUTH_PROP_CHANGE, CLIENT_FETCH_PROFILE, PROFILE_TYPE_CHANGE } from "../actionTypes/actionTypes"
import { createCreateConnectAccountData, createSubAccountData } from '../util/StripeUtil';
import { CREATE_CONNECT_ACCOUNT2, CREATE_SUB_ACCOUNT, UPLOAD_CONNECT_ID } from '../constants/ArgendaServerURLS';
import { deleteImage, uploadImageAndGetURL } from '../util/ImageUtil';
import { ARTIST, CLIENT, SIGNUP } from '../constants/ClientRoutes';
import { NO_SHOW_FEE, CANCEL_FEE, DEFAULT_CLIENT_NOTI_HASH, DEFAULT_NOTIFICATION_HASH } from '../constants/Policies';

export const profileTypeChange = (isArtist) => {
    return { type: PROFILE_TYPE_CHANGE, payload: isArtist };
}

export const authPropChange = (prop, value) => {
    return {
        type: AUTH_PROP_CHANGE,
        payload: {
            prop,
            value
        }
    }
}

//creates a connect artist account
export const createConnectAccount = (props) => {
    return async (dispatch) => {
        //toggle loading screen
        dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: true }});
        try {
            //use auth props to create package to create stripe connect account
            const createConnectPack = await createCreateConnectAccountData(props);
            //create stripe connect account
            const res = await axios.post(CREATE_CONNECT_ACCOUNT2, createConnectPack);
            const stripeInfo = res.data;
            //uploading images
            //await axios.post(UPLOAD_CONNECT_ID, stripeInfo);
            //delete front id from database
            deleteImage(createConnectPack.frontIDRefPath);
            //delete back id from database
            deleteImage(createConnectPack.backIDRefPath);   
            //create the artist profile for connect in firestore
            await createArtistProfileForConnect(props, stripeInfo);
            //disable loading screen
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
            //navigate to artistHome
            window.location.hash = ARTIST.ARTIST_HOME;
        } catch (ex) {
            //something went wrong, disable loading screen
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
        }
    }
}

//creates a subscription artist account
export const createSubAccount = (props) => {
    return async (dispatch) => {
        //show loading screen
        dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: true }});
        try {
            //parse account data to create subscription account for artist
            const createSubPack = createSubAccountData(props);
            //create subscription and customer account via stripe API
            const res = await axios.post(CREATE_SUB_ACCOUNT, createSubPack);
            //grab stripe info from CREATE_SUB_ACCOUnt
            const stripeInfo = res.data;
            //create artist subscription account in firestore
            await createArtistProfileForSub(props, stripeInfo);
            //disable loading screen
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
            //navigate to artistHome
            window.location.hash = ARTIST.ARTIST_HOME;
        } catch (ex) {
            //something went wrong, disable loading screen
            console.log(ex);
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
        }
    }
}

//creates a client account
export const createClientAccount = (props) => {
    return async (dispatch) => {
        //show loading screen
        dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: true }});
        try {
            //create client profile in firestore
            await createClientProfile(props);
            //disable loading screen
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
            //navigate to client home
            window.location.hash = CLIENT.CLIENT_HONE;
        } catch (ex) {
            //something went wrong, disable loading screen
            console.log(ex);
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
        }
    }
}

//user logged in, find out if user is
//a artist or client and navigate them to artistHome
//or clientHome
export const userLoggedIn = (userId) => {
    return async (dispatch) => {
        window.setTimeout(async () => {
            const db = getFirestore();
            const typeRef = doc(db, `types`, userId);
            const userDoc = await getDoc(typeRef);
            if (userDoc.exists()) {
                if (userDoc.data().isArtist) {
                    window.location.hash = ARTIST.ARTIST_HOME;
                } else {
                    const db = getFirestore();
                    const ref = doc(db, `clients`, userId);
                    const profileListener = onSnapshot(ref, (snap) => {
                        const profile = { ...snap.data(), id: userId };
                        dispatch({
                            type: CLIENT_FETCH_PROFILE,
                            payload: {
                                profile,
                                profileListener,
                            }
                        })
                    });
                }
            }
        }, 500)
    }
}

//logs user out
export const userLoggedOut = () => {
    window.location.href = `/#`
}

//logs in with email and password
export const login = (email, password) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: true }});
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'signInError', value: false }});
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
            const id = getAuth().currentUser.uid;
            const db = getFirestore();
            const ref = doc(db, `types/${id}`);
            const type = await getDoc(ref).get().then().data();
            if (type.isArtist) {
                window.location.hash = ARTIST.ARTIST_HOME;
            }
        } catch (ex){
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'signInError', value: true }});
            dispatch({ type: AUTH_PROP_CHANGE, payload: { prop: 'loading', value: false }});
        }

    }
}

/*
    1. Creates artist account with firebase auth
    2. Uploads profile photo if one was selected
    3. Saves subsciption artist info for artist in firestore
    4. Indexes artist into types collection
    5. Increments user statisitics
*/
export const createArtistProfileForSub = async (authProps, stripeInfo) => {
    try {
        const auth = getAuth();
        //1. Creates artist account with firebase auth
        const userCredential = await createUserWithEmailAndPassword(auth, authProps.email, authProps.password);
        const userId = userCredential.user.uid;
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        const typeRef = doc(db, 'types', userId);
        //3. Saves subsciption artist info for artist in firestore
        await setDoc(userRef, {
            name: authProps.name,
            email: authProps.email,
            phone: authProps.phone,
            artistType: authProps.artistType,
            salonName: authProps.salonName,
            address: authProps.salonAddress.address,
            _geoloc: authProps.salonAddress.location,
            clients: 0,
            likes: 0,
            imageCount: 0,
            dislikes: 0,
            dateJoined: new Date().getTime(),
            stripeCustomerId: stripeInfo.stripeCustomerId,
            stripeSubId: stripeInfo.stripeSubId,
            visible: false,
            finishedSetup: false
        });
        if (authProps.profileImage) {
            //2. Uploads profile photo if one was selected
            await uploadImageAndGetURL(authProps.profileImage, `${userId}/profileImage/${authProps.profileImage.blob.name}`);
            await updateDoc(userRef, {
                profileImage: authProps.profileImage.blob.name
            });
        }
        //4. Indexes artist into types collection
        await setDoc(typeRef, {
            isArtist: true
        });
        const notiRef = doc(db, `users/${userId}/notificationHash/notificationHash`);
        await setDoc(notiRef, DEFAULT_NOTIFICATION_HASH);
        //5. Increments user statisitics
        await incrementUserStatistics(true, false, true, false);
    } catch (ex) {
        console.log(ex);
    }
}

/*
1. creates artist account for firebase auth
2. uploads profile photo if one exists
3. saves connect artist data to firestore
4. indexes artist into types collection
5. increments user statistics
*/
export const createArtistProfileForConnect = async (authProps, stripeInfo) => {
    try {
        const auth = getAuth();
        //1. creates artist account for firebaser auth
        const userCredential = await createUserWithEmailAndPassword(auth, authProps.email, authProps.password);
        const userId = userCredential.user.uid;
        if (authProps.profileImage) {
            //2. uploads profile photo if one exists
            await uploadImageAndGetURL(authProps.profileImage, `${userId}/profileImage/${authProps.profileImage.blob.name}`);
        }
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        const typeRef = doc(db, 'types', userId);
        //3. saves connect artist data to firestore
        await setDoc(userRef, {
            name: authProps.name,
            email: authProps.email,
            phone: authProps.phone,
            profileImage: authProps.profileImage ? authProps.profileImage.blob.name : '',
            artistType: authProps.artistType,
            salonName: authProps.salonName,
            address: authProps.salonAddress.address,
            _geoloc: authProps.salonAddress.location,
            stripeAccountId: stripeInfo.stripeAccountID,
            stripeCustomerId: stripeInfo.stripeCustomerID,
            stripeSubId: stripeInfo.stripeSubID,
            imageCount: 0,
            stripeEnabled: false,
            clients: 0,
            likes: 0,
            dislikes: 0,
            dateJoined: new Date().getTime(),
            visible: false,
            finishedSetup: false
        });
        //4. indexes artist into type collection
        await setDoc(typeRef, {
            isArtist: true
        });
        const policiesRef = doc(db, `users/${userId}/policies/policies`);
        await setDoc(policiesRef, {
            [NO_SHOW_FEE]: 0,
            [CANCEL_FEE]: 0
        })
        const notiRef = doc(db, `users/${userId}/notificationHash/notificationHash`);
        await setDoc(notiRef, DEFAULT_NOTIFICATION_HASH);
        //5. increments user statistics
        incrementUserStatistics(true, false, false, true);
    } catch (ex) {
        console.log(ex);
    }
}

/*
1. creates firebase client account for firebase auth
2. uploads client profile picture if one was selected
3. saves client data
4. indexes client info in type collection
5. increments user statistics
*/
export const createClientProfile = async (authProps) => {
    const auth = getAuth();
    //1. create a profile for firebase
    const userCred = await createUserWithEmailAndPassword(auth, authProps.email, authProps.password);
    const userId = userCred.user.uid;
    const db = getFirestore();
    const userRef = doc(db, 'clients', userId);
    const typeRef = doc(db, 'types', userId);
    const notiRef = doc(db, `clients/${userId}/notificationHash/notificationHash`);
    //3. saves client data
    await setDoc(userRef, {
        name: authProps.name,
        email: authProps.email,
        phone: authProps.phone,
        dateJoined: new Date().getTime(),
    });
    //saves default notification options for client
    await setDoc(notiRef, DEFAULT_CLIENT_NOTI_HASH);
    //4. indexes client info in type collection
    await setDoc(typeRef, {
        isArtist: false
    });
    //5. increments user statistics
    incrementUserStatistics(false, true, false, false);
}

//increments user statistics, for example how many artists, clients, scheduling artists, payment artists, etc
const incrementUserStatistics = async (isArtist, isClient, isSchedulingArtist, isPaymentArtist) => {
    const db = getFirestore();
    const metricRef = doc(db, 'metrics', 'metrics');
    await updateDoc(metricRef, {
        artists: isArtist ? increment(1) : increment(0),
        clients: isClient ? increment(1) : increment(0),
        schedulingArtists: isSchedulingArtist ? increment(1) : increment(0),
        paymentArtists: isPaymentArtist ? increment(1) : increment(0)
    });
}