import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { TransparentInput, InputLabel, FlexRowButton, FlexRow } from '.';

const SSN_LENGTH = 9;
const PII = 'pii';

const SSNINput = ({ onChange }) => {

    const stripe = useStripe();

    const [ssn, setSSN] = useState('');

    const ssnChange = async (ssn) => {
        setSSN(ssn);
        if (ssn.length == SSN_LENGTH) {
            const result = await stripe.createToken(PII, {
                personal_id_number: ssn
            });
            onChange(result.token);
        }
    }

    const renderInputLabel = () => {
        if (ssn) {
            return (
                <InputLabel label='Social Security #' />
            )
        }
    }

    return (
        <FlexRow>
            <FlexRowButton>
                <TransparentInput 
                    type='tel'
                    maxLength={9}
                    value={ssn}
                    onChange={ssnChange}
                    placeholder='Social Security #'
                />
                {renderInputLabel()}
            </FlexRowButton>
        </FlexRow>
    )
}

export default SSNINput;