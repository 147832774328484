import React from 'react';
import PropTypes from 'prop-types';
import './styles/FAB.css';
import { BsCameraFill, BsPlus, BsPlusCircle } from 'react-icons/bs';

const CAMERA = 'CAMERA';
const SERVICE = 'SERVICE';

const FAB = ({ onClick, style, type }) => {

    const renderCamera = () => {
        return (
            <BsCameraFill />
        )
    }

    const renderService = () => {
        return (
            <BsPlus />
        )
    }

    const renderDecideIcon = () => {
        switch (type) {
            case CAMERA:
                return renderCamera();
            case SERVICE:
                return renderService();
            default:
                return renderService();
        }
    }

    return (
        <div 
            className='FAB-Container'
            onClick={onClick}
            style={style}
        >
            <div className='FAB-InnerRelative'>
                <div className='FAB-InnerAbsolute'>
                    {renderDecideIcon()}
                </div>
            </div>
        </div>
    )
}

FAB.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string,
};

FAB.defaultProps = {
    onClick: () => {},
    style: {},
    type: ''
};

export default FAB;