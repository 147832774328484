import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, ListWrapper, Service } from '.';
import { collection, doc, getDocs, getFirestore, getDoc } from 'firebase/firestore';

class ServicePicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: []
        }
    }

    componentDidMount = () => {
        this.fetchServices();
    }

    parseServices = async (docs) => {
        const services = [];
        const db = getFirestore();
        for (let i = 0; i < docs.length; i++) {
            const service = {
                ...docs[i].data(),
                id: docs[i].id
            };
            if (this.props.clientId) { 
                const ref = doc(db, `users/${this.props.artistId}/services/${service.id}/custom/${this.props.clientId}`)
                const snap = await getDoc(ref).then();
                if (snap.exists()) {
                    const customService = {
                        ...snap.data(),
                        id: snap.id,
                        image: service.image
                    }
                    services.push(customService);
                } else {
                    services.push(service);
                }
            } else {
                services.push(service);
            }             
        }
        return services;
    }


    fetchServices = async () => {
        const db = getFirestore();
        const servicesRef = collection(db, `users/${this.props.artistId}/services`);
        const servicesSnap = await getDocs(servicesRef).then();
        const services = await this.parseServices(servicesSnap.docs);
        this.setState({ services });
    }

    selectService = (service) => {
        this.props.onChange(service);
        this.props.onClose();
    }

    renderService = (service, index) => {
        return (
            <Service 
                key={index}
                service={service}
                onClick={this.selectService}
            />
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='Pick a Service'
            >
                <ListWrapper>
                    {this.state.services.map(this.renderService)}
                </ListWrapper>
            </BottomUpModal>
        )
    }
}

ServicePicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    artistId: PropTypes.string,
    clientId: PropTypes.string,
    onChange: PropTypes.func,
};

ServicePicker.defaultProps = {
    open: false,
    onClose: () => {},
    artistId: '',
    clientId: '',
    onChange: () => {}
}

export default ServicePicker;