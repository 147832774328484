import algoliasearch from 'algoliasearch';
import { ALGOLIA_ADMIN_KEY, ALGOLIA_APP_ID } from '../secret/secret';
const client = algoliasearch('5MC7N7PERG', '6f4293c4d26bd678ff5c1bd59f186795');
const index = client.initIndex('users');
const imagesIndex = client.initIndex('images');

export const searchArtists = async (value, coords = null, hitsPerPage = 10) => {
    let hits = [];
    if (coords) {
        hits = await index.search(value, {
            aroundLatLng: `${coords.latitude}, ${coords.longitude}`,
            aroundRadius: 1000000,
            hitsPerPage,
            getRankingInfo: true
        }).then();
    } else {
        hits = await index.search(value, { hitsPerPage }).then();
    }
    const results = hits.hits;
    const filteredResults = [];
    for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if (result.visible) {
            filteredResults.push(result);
        }
    }
    return filteredResults;
}

export const searchImages = async (value, coords = null, page, hitsPerPage) => {
    let hits = [];
    if (coords) {
        hits = await imagesIndex.search(value, {
            aroundLatLng: `${coords.latitude}, ${coords.longitude}`,
            aroundRadius: 1000000,
            page,
            hitsPerPage,
            getRankingInfo: true
        }).then()
    } else {
        hits = await imagesIndex.search(value, { 
            hitsPerPage,
            page,
         }).then();
    }
    return hits.hits;
}

export const GetClientIndexForArtist = (artistId) => {
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_KEY);
    const index = searchClient.initIndex(artistId);
    return index;
}