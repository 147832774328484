import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    SettingsTitle,
    ModalRowBack,
    BrandButton,
    EasyInput
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { getFirestore, updateDoc, doc } from '@firebase/firestore';

class UpdateClientPhone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: props.profile.phone
        }
    }

    phoneChange = (phone) => {
        this.setState({ phone });
    }

    save = async () => {
        const db = getFirestore();
        const ref = doc(db, `clients/${this.props.profile.id}`);
        await updateDoc(ref, {
            phone: this.state.phone
        });
        this.props.onBack();
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Back'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Phone'
            />
        )
    }

    renderInput = () => {
        return (
            <EasyInput 
                value={this.state.phone}
                onChange={this.phoneChange}
                placeholder='Phone #'
                label='Phone #'
                type='tel'
            />
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                disabled={!this.state.phone}
                onClick={this.save}
            />
        )
    }

    render = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderInput()}
                {this.renderSave()}
            </Body>
        )
    }
}

UpdateClientPhone.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object
};

UpdateClientPhone.defaultProps = {
    onBack: () => {},
    profile: {}
};

export default UpdateClientPhone;