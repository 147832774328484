import React from 'react';
import PropTypes from 'prop-types';
import { Body, BottomUpModal, BrandButton, Service } from '.';
import './styles/ArtistChargeDetail.css';
import { convertUnixTimeToReadable } from '../util/TimeUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const ArtistChargeDetail = ({ open, charge, onClose }) => {

    const renderBody = () => {
        const total = charge.amount / 100;
        const fee = charge.application_fee_amount / 100;
        const net = total - fee;
        const service = JSON.parse(charge.metadata.serviceString);
        return (
            <Body>
                <div className='ArtistChargeDetail-Container'>
                    <p className='ArtistChargeDetail-Description'>{charge.description}</p>
                    <Service 
                        service={service}
                        overrideHeight={200}
                    />
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Client</h4>
                        <h4 className='ArtistChargeDetail-Value'>{charge.metadata.clientName}</h4>
                    </div>
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Price</h4>
                        <h4 className='ArtistChargeDetail-Value'>{`$${service.price}`}</h4>
                    </div>
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Tip</h4>
                        <h4 className='ArtistChargeDetail-Value'>{`+ $${charge.metadata.tip}`}</h4>
                    </div>
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Total</h4>
                        <h4 className='ArtistChargeDetail-Value'>{`$${total.toFixed(2)}`}</h4>
                    </div>
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Fee</h4>
                        <h4 className='ArtistChargeDetail-Value'>{`- $${fee}`}</h4>
                    </div>
                    <div className='ArtistChargeDetail-Row'>
                        <h4 className='ArtistChargeDetail-Label'>Net</h4>
                        <h4 className='ArtistChargeDetail-Value'>{`$${net}`}</h4>
                    </div>
                    <p className='ArtistChargeDetail-Date'>{convertUnixTimeToReadable(charge.created * 1000)}</p>
                </div>
                <BrandButton 
                    label={`View ${charge.metadata.clientName}'s Profile`}
                    style={BOTTOM_BUTTON}
                />
            </Body>
        )
    }

    const renderDecide = () => {
        if (charge) {
            return renderBody();
        }
    }

    const decideTitle = () => {
        if (charge) {
            return `${charge.metadata.type} Details`;
        }
        return '';
    }

    return (
        <BottomUpModal
            open={open}
            onClose={onClose}
            height={0.9}
            title={decideTitle()}
        >
            {renderDecide()}
        </BottomUpModal>
    )
}

ArtistChargeDetail.propTypes = {
    open: PropTypes.bool,
    charge: PropTypes.object,
    onClose: PropTypes.func
};

ArtistChargeDetail.defaultProps = {
    open: false,
    charge: {},
    onClose: () => {}
}

export default ArtistChargeDetail;