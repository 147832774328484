import React from 'react';
import PropTypes from 'prop-types';
import './styles/StatCircle.css';

const StatCircle = ({ count, label, color }) => {
    return (
        <div className='SC-Container'>
            <div 
                className='SC-Circle'
                style={{ borderColor: color }}
            >
                <h2>{count}</h2>
            </div>
            <h4 className='SC-Label'>{label}</h4>
        </div>
    )
}

StatCircle.propTypes = {
    count: PropTypes.bool,
    label: PropTypes.string,
    color: PropTypes.string
};

StatCircle.defaultProps = {
    count: 0,
    label: '',
    color: ''
}

export default StatCircle;