import React from 'react';
import PropTypes from 'prop-types';
import './styles/Pill.css';

const Pill = ({ pill, onClick, selected }) => {

    const click = () => {
        if (selected) {
            onClick('');
        } else {
            onClick(pill);
        }

    }

    return (
        <div 
            className={selected ? 'Pill-Outer Pill-OuterSelected' : 'Pill-Outer'}
            onClick={click}
        >
            <div className={selected ? 'Pill-Inner Pill-Selected' : 'Pill-Inner'}>
                <h4>{pill}</h4>
            </div>
        </div>
    )
}

Pill.propTypes = {
    pill: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool
};

Pill.defaultProps = {
    pill: {},
    onClick: () => {},
    selected: false
};

export default Pill;