import React from 'react';
import PropTypes from 'prop-types';
import './styles/GenOption.css';

const GenOption = ({ onClick, label, option }) => {

    const click = () => {
        onClick(option);
    }

    return (
        <div 
            className='GO-Container'
            onClick={click}
        >
            <h3>{label}</h3>
        </div>
    )
}

GenOption.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    option: PropTypes.object,
};

GenOption.defaultProps = {
    onClick: () => {},
    label: '',
    option: {}
};

export default GenOption;