import React from 'react';
import PropTypes from 'prop-types';
import { 
    Body, 
    ModalRowBack,
    FlexRow,
    FlexRowButton,
    BigTitle
} from '.';
import { ARTIST, CLIENT, SALON } from '../constants/ProfileTypes';

const ProfileTypeForm = ({ onChange, onBack }) => {

    const renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={onBack}
            />
        )
    }

    const clientClick = () => {
        onChange(CLIENT);
    }

    const artistClick = () => {
        onChange(ARTIST)
    }

    const salonClick = () => {
        onChange(SALON);
    }

    const renderClientButton = () => {
        return (
            <FlexRow>
                <FlexRowButton 
                    onClick={clientClick}
                >
                    <BigTitle title='Client' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderArtistButton = () => {
        return (
            <FlexRow>
                <FlexRowButton 
                    onClick={artistClick}
                >
                    <BigTitle title='Artist' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderSalonButton = () => {
        return (
            <FlexRow>
                <FlexRowButton
                    onClick={salonClick}
                >
                    <BigTitle title='Studio/Salon' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    return (
        <Body style={styles.artist}>
            {renderBack()}
            <BigTitle title='Which one are you?' />
            {renderClientButton()}
            {renderArtistButton()}
        </Body>
    )
}

const styles = {
    artist: {
        marginBottom: 30,
    }
}

ProfileTypeForm.propTypes = {
    onChange: PropTypes.func,
    onBack: PropTypes.func,
};

ProfileTypeForm.defaultProps = {
    onChange: () => {},
    onBack: () => {}
};

export default ProfileTypeForm;