import React from 'react';
import PropTypes from 'prop-types';
import './styles/ButtonSubLabel.css';

const ButtonSubLabel = ({ subLabel }) => {
    return (
        <p className='ButtonSubLabel-label'>{subLabel}</p>
    )
}

ButtonSubLabel.propTypes = {
    subLabel: PropTypes.string
}

export default ButtonSubLabel;