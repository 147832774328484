export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const VACATION = 'VACATION';

export const MON = 'MON';
export const TUE = 'TUE';
export const WED = 'WED';
export const THUR = 'THUR';
export const FRI = 'FRI';
export const SAT = 'SAT';
export const SUN = 'SUN';
