import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    FlexRow,
    AddressSearch,
    SSNINput,
    DateInput,
    AddressButton,
    ModalLoader,
    Scroll,
    Address,
    IdInput
} from '../components';
import BrandButton from './BrandButton';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { searchPlaces2 } from '../util/GooglePlacesUtil';

class StripeInfoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dob: props.dob,
            personalAddress: props.personalAddress,
            backID: props.backID,
            frontID: props.frontID,
            ssn: props.ssn,
            left: 0,
            addressLeft: window.screen.width,
            loading: false,
            addresses: [],
        }
    }

    onChange = () => {
        const { dob, personalAddress, backID, frontID, ssn } = this.state;
        this.props.onChange({ dob, personalAddress, backID, frontID, ssn });
    }

    canNext = () => {
        const { dob, personalAddress, backID, frontID, ssn } = this.state;
        if (dob && personalAddress && backID && frontID && ssn) {
            return true;
        }
        return false;
    }

    startLoading = () => {
        this.setState({ loading: true });
    }

    searchAddresses = async (search) => {
        const addresses = await searchPlaces2(search, null);
        this.setState({ addresses, loading: false });
    }

    openAddress = () => {
        this.setState({
            left: -window.screen.width,
            addressLeft: 0
        });
    }

    ssnChange = (ssn) => {
        this.setState({ ssn });
    }

    frontIdChange = (frontID) => {
        this.setState({ frontID });
    }

    backIdChange = (backID) => {
        this.setState({ backID });
    }

    closeAddress = () => {
        this.setState({
            left: 0,
            addressLeft: window.screen.width
        });
    }

    dobChange = (dob) => {
        this.setState({ dob });
    }

    addressChange = (personalAddress) => {
        this.setState({ personalAddress });
        this.closeAddress();
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={this.props.onBack}
            />
        )
    }

    renderAddressSearch = () => {
        return (
            <AddressSearch 
                onBack={this.closeAddress}
                onSearch={this.searchAddresses}
                startLoading={this.startLoading}
            />
        )
    }

    renderDateInput = () => {
        return (
            <DateInput 
                value={this.state.dob}
                onChange={this.dobChange}
                placeholder='Date Of Birth'
                label='Date of Birth'
            />
        )
    }

    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Create Account'
                onClick={this.onChange}
                disabled={!this.canNext()}
            />
        )
    }
    
    renderAddressButton = () => {
        return (
            <AddressButton 
                address={this.state.personalAddress}
                onChange={this.addressChange}
                placeholder='Personal Address'
                label='Peronsal Address'
                onClick={this.openAddress}
            />
        )
    }

    renderIdRow = () => {
        return (
            <FlexRow>
                <IdInput 
                    placeholder='Front Drivers Liscence'
                    onChange={this.frontIdChange}
                    value={this.state.frontID}
                    id={'input1'}
                    style={styles.leftButton}
                />
                <IdInput 
                    placeholder='Back Drivers Liscense'
                    onChange={this.backIdChange}
                    id={'input2'}
                    value={this.state.backID}
                    style={styles.rightButton}
                />
            </FlexRow>
        )
    }

    renderCreateButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Create Account'
                onClick={this.onChange}
                disabled={!this.canNext()}
            />
        )
    }

    renderBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.left }}
            >
                {this.renderBack()}
                {this.renderDateInput()}
                {this.renderAddressButton()}
                {this.renderIdRow()}
                {this.renderSocialInput()}
                {this.renderCreateButton()}
            </Body>
        )
    }

    renderSocialInput = () => {
        return (
            <SSNINput 
                onChange={this.ssnChange}
            />
        )
    }

    renderAddressLoading = () => {
        return (
            <ModalLoader message='Loading...' />
        )
    }

    renderAddress = (address, index) => {
        return (
            <Address 
                address={address}
                key={index}
                onClick={this.addressChange}
            />
        )
    }

    renderAddresses = () => {
        return (
            <Scroll>
                {this.state.addresses.map(this.renderAddress)}
            </Scroll>
        )
    }

    renderDecideAddressContent = () => {
        if (this.state.loading) {
            return this.renderAddressLoading();
        }
        return this.renderAddresses();
    }

    renderAddressBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.addressLeft }}
            >   
                {this.renderAddressSearch()}
                {this.renderDecideAddressContent()}
            </Body>
        )
    }

    render = () => {
        return (
            <Body relative>
                {this.renderBody()}
                {this.renderAddressBody()}
            </Body>
        )
    }
}

const styles = {
    leftButton: {
        marginRight: 5, 
        width: '48%'
    },
    rightButton: {
        width: '48%'
    }
}

StripeInfoForm.propTypes = {
    onChange: PropTypes.func,
    onBack: PropTypes.func,
    dob: PropTypes.string,
    personalAddress: PropTypes.object,
    frontID: PropTypes.object,
    backID: PropTypes.object,
    ssn: PropTypes.string
};

StripeInfoForm.defaultProps = {
    onChange: () => {},
    onBack: () => {},
    dob: '',
    personalAddress: null,
    frontID: null,
    backID: null,
    ssn: ''
};

export default StripeInfoForm;