import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal,
    BrandButton,
    ArtistPreview,
    Carousel,
    Body,
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/SelectedImageModal.css';
import { CLIENT } from '../constants/ClientRoutes';

class SelectedImageModal extends Component {

    navToArtist = () => [
        window.location.hash = `${CLIENT.CLIENT_VIEW_ARTIST}/${this.props.images[this.props.currentIndex].artistId}`
    ]

    renderViewProfile = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='View Profile'
                onClick={this.navToArtist}
            />
        )
    }

    renderArtistPreview = (index, left) => {
        return (
            <ArtistPreview 
                image={this.props.images[index]}
                style={{ left }}
                id={index}
                currentIndex={this.props.currentIndex}
                index={index}
            />
        )
    }

    renderCarousel = () => {
        if (this.props.open) {
            return (
                <Carousel 
                    currentIndex={this.props.currentIndex}
                    indexChange={this.props.indexChange}
                    length={this.props.images.length}
                    renderItem={this.renderArtistPreview}
                />
            )
        }
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={.9}
            >
                <Body>
                    {this.renderCarousel()}
                </Body>
                {this.renderViewProfile()}
            </BottomUpModal>
        )
    }
}

SelectedImageModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    images: PropTypes.object,
    currentIndex: PropTypes.number,
    indexChange: PropTypes.func,
};

SelectedImageModal.defaultProps = {
    open: false,
    onClose: () => {},
    images: [],
    currentIndex: 0,
    indexChange: () => {}
};

export default SelectedImageModal;