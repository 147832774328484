import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { TEST_CARD } from '../constants/ArgendaServerURLS';
import { ARGENDA_PLUS_STRIPE_FEE, STRIPE_PERCENTAGE } from '../constants/Prices';
import { uploadImageAndGetURL, deleteImage } from './ImageUtil';
import { parseAddressForIndividual, parseDOB } from './ParsingUtil';

//this method tests a card token with a 50 cent charge, but does not capture it, this is to verify that the card is valid
export const testCard = async (source) => {
    try {
        const charge = {
            amount: 50,
            currency: 'usd',
            source,
            capture: false
        }
        const res = await axios.post(TEST_CARD, { charge });
        return res.data;
    } catch (ex) {
        return false;
    }
}

export const createSubAccountData = (authProps) => {
    const customerData = {
        name: authProps.name,
        email: authProps.email,
        phone: authProps.phone,
    }
    return {
        customerData,
        customerCardToken: authProps.cardTokens[0].id
    }
}

/*
    frontID: string
    backID: string,
    personalAddress: object,
    dob: string,
    email: string,
    name: string,
    ssn: string,
    phone: string,
    cardTokens: array,
*/
export const createCreateConnectAccountData = async (authProps) => {
    const uniqueID = uuidv4();
    const frontIDRefPath = `${uniqueID}/frontID.jpg`;
    const backIDRefPath = `${uniqueID}/backID.jpg`;
    const frontUrl = await uploadImageAndGetURL(authProps.frontID, frontIDRefPath);
    const backUrl = await uploadImageAndGetURL(authProps.backID, backIDRefPath);
    const address = parseAddressForIndividual(authProps.personalAddress.address);
    const dob = parseDOB(authProps.dob);
    const connectData = {
        type: 'custom',
        country: 'US',
        email: authProps.email,
        capabilities: {
            card_payments: {requested: true},
            transfers: {requested: true},
        },
        business_type: 'individual',
        individual: {
            address,
            dob,
            email: authProps.email,
            first_name: authProps.name.split(' ')[0],
            last_name: authProps.name.split(' ')[1],
            id_number: authProps.ssn.id,
            phone: authProps.phone, 
        },
        external_account: authProps.cardTokens[0].id,
        business_profile: {
            mcc: '7230',
            url: 'Argenda.com'
        },
        tos_acceptance: {
            date: Math.floor(new Date().getTime() / 1000),
            ip: authProps.cardTokens[0].client_ip
        }
    }
    const customerData = {
        name: authProps.name,
        email: authProps.email,
        phone: authProps.phone,
    }
    return {
        connectData,
        customerData,
        frontUrl,
        backUrl,
        customerCardToken: authProps.cardTokens[1].id,
        frontIDRefPath,
        backIDRefPath
    };
}

export const calcStripeFee = (costInDollars) => {
    const ARGENDA_FEE = ARGENDA_PLUS_STRIPE_FEE * 100;
    const STRIPE_FEE = ((costInDollars * STRIPE_PERCENTAGE) * 100) + ARGENDA_FEE;
    return Math.ceil(STRIPE_FEE);
}

export const calcStripeFeeInDollars = (costInDollars) => {
    const ARGENDA_FEE = ARGENDA_PLUS_STRIPE_FEE;
    const STRIPE_FEE = (costInDollars * STRIPE_PERCENTAGE) + ARGENDA_FEE;
    return STRIPE_FEE.toFixed(2);
}