import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    BrandButton,
    DigitalInput,
    TimeRow,
    TransparentHeader,
    TabButtonRow,
    BrandTabButton
} from '.';
import './styles/ClockInput.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

//degrees
const FULL_DEGREES = 360;
const HALF_CIRCLE = 180;
const QUARTER_CIRCLE = 90;
const SHORT_ADJUST = 12;
const MIN_DEGREE = 6;
const HOUR_DEGREE = 30;

//hand height %
const BIG_HAND_HEIGHT = 0.65;
const SHORT_HAND_HEIGHT = 0.5;

class ClockInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bigHandRotate: this.initBigHandRotate(props.value),
            shortHandRotate: this.initShortHandRotate(props.value),
            bigHandSelected: false,
            shortHandSelected: false,
            center: {
                x: 0,
                y: 0
            },
            BIG_HAND: `BIG_HAND${props.copy}`,
            SHORT_HAND: `SHORT_HAND${props.copy}`,
            digitalSelected: false,
            amSelected: this.initMeridian(props.value),
        }
    }
    
    componentDidMount = () => {
        this.getCenterOfBigHand();
        window.addEventListener('touchstart', this.selectHand);
        window.addEventListener('touchend', this.deselectHand);
        window.addEventListener('touchmove', this.handleMove);
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('touchstart', this.selectHand);
        window.removeEventListener('touchend', this.deselectHand);
        window.removeEventListener('touchmove', this.handleMove);
    }

    toggleDigital = () => {
        this.setState({ digitalSelected: !this.state.digitalSelected });
    }

    selectAm = () => {
        this.setState({ amSelected: true });
    }

    selectPm = () => {
        this.setState({ amSelected: false });
    }

    initMeridian = (webTimeString) => {
        if (webTimeString) {
            const hour = parseInt(webTimeString.split(':')[0]);
            if (hour < 12) {
                return true;
            }
            return false;
        }
    }

    initBigHandRotate = (webTimeString) => {
        if (webTimeString) {
            const minute = parseInt(webTimeString.split(':')[1]);
            return minute * MIN_DEGREE;
        }
        return 0;
    }

    initShortHandRotate = (webTimeString) => {
        if (webTimeString) {
            const hour = parseInt(webTimeString.split(':')[0]);
            return hour * HOUR_DEGREE;
        }
        return 0;
    }

    getCenterOfBigHand = () => {
        window.setTimeout(() => {
            if (document.getElementById(this.state.BIG_HAND)) {
                const bigHand = document.getElementById(this.state.BIG_HAND).getBoundingClientRect();
                const center = {
                    x: (bigHand.left + bigHand.right) / 2, 
                    y: bigHand.bottom
                }
                this.setState({ center });
            }
        }, 500);
    }

    selectHand = (e) => {
        if (e.target.id === this.state.BIG_HAND) {
            this.setState({ bigHandSelected: true });
        }
        if (e.target.id === this.state.SHORT_HAND) {
            this.setState({ shortHandSelected: true });
        }
        if (this.state.hourSelected) {
            if (e.target.id !== this.state.HOUR_HEADER) {
                this.setState({ hourSelected: false });
            }
        }
        if (this.state.minuteSelected) {
            if (e.target.id !== this.state.MINUTE_HEADER) {
                this.setState({ minuteSelected: false });
            }
        }
    }

    deselectHand = () => {
        if (this.state.bigHandSelected) {
            this.setState({ bigHandSelected: false });
        }
        if (this.state.shortHandSelected) {
            this.setState({ shortHandSelected: false });
        }
    }

    calcMinAngle = (e) => {
        const deltaX = e.pageX - this.state.center.x;
        const deltaY = e.pageY - this.state.center.y;
        const rad = Math.atan2(deltaY, deltaX);
        const deg = rad * (HALF_CIRCLE / Math.PI);
        //add 90deg to rotate correctly
        const rotate = Math.ceil(deg) + QUARTER_CIRCLE;
        //round to nearest factor of 6, because each minute is 6 degrees
        const adjust = (Math.ceil(rotate / MIN_DEGREE)) * MIN_DEGREE;
        return adjust;
    }

    calcHourAngle = (e) => {
        const deltaX = e.pageX - this.state.center.x;
        const deltaY = e.pageY - this.state.center.y;
        const rad = Math.atan2(deltaY, deltaX);
        const deg = rad * (HALF_CIRCLE / Math.PI);
        //add 90deg to rotate correctly
        const rotate = Math.ceil(deg) + QUARTER_CIRCLE;
        return rotate;
    }

    calcHour = (hourDegrees) => {
        const hour = Math.floor(hourDegrees  / HOUR_DEGREE) % 12;
        let formattedHour = hour;
        if (hour === -3) {
            formattedHour = 9;
        }
        if (hour === - 2) {
            formattedHour = 10;
        }
        if (hour === -1) {
            formattedHour = 11;
        }
        if (hour === 0) {
            formattedHour = 12;
        }
        return formattedHour;
    }

    calcMinute = (minuteDegrees) => {
        let minute = minuteDegrees / MIN_DEGREE;
        if (minute < 0) {
            minute = minute + 60;
        }
        if (minute.toString().length === 1) {
            return `0${minute}`;
        }
        if (minute === 60) {
            return minute;
        }
        return minute;
    }

    calcTime = () => {
        const minute = this.calcMinute(this.state.bigHandRotate);
        const hour = this.calcHour(this.state.shortHandRotate);
        return `${hour}:${minute}`
    }

    handleMove = (e) => {
        if (this.state.bigHandSelected) {
            const bigHandRotate = this.calcMinAngle(e); 
            this.setState({ bigHandRotate });
        }
        if (this.state.shortHandSelected) {
            const shortHandRotate = this.calcHourAngle(e);
            const rotate = ((shortHandRotate * SHORT_ADJUST) % FULL_DEGREES);
            const bigHandRotate = rotate > 270 ? rotate - FULL_DEGREES : rotate;
            this.setState({ shortHandRotate, bigHandRotate });
        }
    }

    build24HourTimeString = (hour, minute) => {
        if (this.state.amSelected) {
            const hourString = hour.toString().length === 1 ? `0${hour}` : hour.toString();
            const minuteString = minute.toString().length === 1 ? `0${minute}` : minute.toString();
            return `${hourString}:${minuteString}`;
        }
        let hour24 = hour;
        if (hour < 12) {
            hour24 = hour + 12;
        }
        const minuteString = minute.toString().length === 1 ? `0${minute}` : minute.toString();
        const hourString = hour24.toString().length === 1 ? `0${hour24}` : hour24.toString();
        return `${hourString}:${minuteString}`;
    }

    onSave = () => {
        const hour = this.calcHour(this.state.shortHandRotate);
        const minute = this.calcMinute(this.state.bigHandRotate);
        const timeString = this.build24HourTimeString(hour, minute);
        this.props.onSave(timeString);
        this.props.onClose();
    }

    decideTickClass = (number) => {
        let degree = 0;
        if (number == 60) {
            degree = 0;
        } else if (number > 45) {
            degree = (number * 6) - FULL_DEGREES;
        } else {
            degree = number * 6;
        }
        const { shortHandRotate, bigHandRotate } = this.state;
        if (bigHandRotate == degree || shortHandRotate == degree) {
            return `CC-${number}Tick CC-HandSelected`;
        }
        return `CC-${number}Tick`;
    }

    decideNumberClass = (number) => {
        let degree = 0;
        if (number == 12) {
            degree = 0;
        } else if (number > 9) {
            degree = (number * 30) - FULL_DEGREES;
        } else {
            degree = number * 30;
        }
        const { shortHandRotate, bigHandRotate } = this.state;
        if (bigHandRotate == degree || this.calcHour(shortHandRotate) == number) {
            return `CC-Number${number} CC-NumberSelected`;
        }
        return `CC-Number${number}`;
    }

    renderTick = (tick, index) => {
        return (
            <div key={index} className={this.decideTickClass(tick)} />
        )
    }

    renderMinTick = () => {
        const ticks = [];
        for (let i = 1; i <= 60; i++) {
            ticks.push(i);
        }
        return ticks.map(this.renderTick)
    }

    renderNumber = (number, index) => {
        return (
            <h2 key={index} className={this.decideNumberClass(number)}>{number}</h2>
        )
    }

    renderNumbers = () => {
        const numbers = [];
        for (let i = 1; i <= 12; i++) {
            numbers.push(i);
        }
        return numbers.map(this.renderNumber)
    }

    renderClock = () => {
        const clock = {
            width: this.props.clockSize, 
            height: this.props.clockSize, 
            borderRadius: '50%'
        }
        const outerClock = {
            width: this.props.clockSize + 10, 
            height: this.props.clockSize + 10, 
            borderRadius: '50%'
        }
        return (
            <div style={outerClock}  className='CC-OuterClock'>
                <div  
                    className={'CC-Clock'}
                    style={clock}
                >
                    {this.renderMinTick()}
                    {this.renderNumbers()}
                    {this.renderShortHand()}
                    {this.renderBigHand()}
                    {this.renderMiddleCircle()}
                </div>
            </div>

        )
    }

    renderShortHand = () => {
        return (
            <div 
                className={this.state.shortHandSelected ? 'CC-ShortHand CC-HandSelected' : 'CC-ShortHand'}
                id={this.state.SHORT_HAND}
                style={{ 
                    transform: `rotateZ(${this.state.shortHandRotate}deg)`,
                    height: (this.props.clockSize / 2) * SHORT_HAND_HEIGHT
                }}
            >
                <div className='HandContainer'>
                    <div className={this.state.shortHandSelected ? 'CC-ShortHandPoint CC-HandSelected' : 'CC-ShortHandPoint'} />
                </div>
            </div>
        )
    }

    renderBigHand = () => {
        return (
            <div 
                className={this.state.bigHandSelected ? 'CC-BigHand CC-HandSelected' : 'CC-BigHand'}
                id={this.state.BIG_HAND}
                style={{ 
                    transform: `rotateZ(${this.state.bigHandRotate}deg)`,
                    height: (this.props.clockSize / 2) * BIG_HAND_HEIGHT
                }}
            >
                <div className='HandContainer'>
                    <div className={this.state.bigHandSelected ? 'CC-BigHandPoint CC-HandSelected' : 'CC-BigHandPoint'} />
                </div>
            </div>
        )
    }

    renderMiddleCircle = () => {
        return (
            <div className='CC-MiddleCircleOutline'>
                <div className={'CC-MiddleCircle'} />
            </div>
        )
    }

    buildTimeString = (hour, minute) => {
        const hourString = hour.toString().length === 1 ? `0${hour}` : hour.toString();
        const minuteString = minute.toString().length === 1 ? `0${minute}` : minute.toString();
        return `${hourString}${minuteString}`;
    }

    renderTimeRow = () => {
        const hour = this.calcHour(this.state.shortHandRotate);
        const minute = this.calcMinute(this.state.bigHandRotate);
        return (
            <TimeRow 
                timeString={this.buildTimeString(hour, minute)}
            />
        )
    }

    renderDecideInput = () => {
        if (this.state.digitalSelected) {
            return this.renderDigital();
        }
        return this.renderClock();
    }

    renderDigital = () => {
        return (
            <DigitalInput 
                style={{ height: this.props.clockSize }}
            />
        )
    }

    renderMeridControl = () => {
        return (
            <TabButtonRow>
                <BrandTabButton 
                    label='AM'
                    selected={this.state.amSelected}
                    onClick={this.selectAm}
                />
                <BrandTabButton 
                    label='PM'
                    selected={!this.state.amSelected}
                    onClick={this.selectPm}
                />
            </TabButtonRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.onSave}
            />
        )
    }

    renderHeader = () => {
        if (!this.props.modalMode) {
            return (
                <TransparentHeader 
                    title={this.props.title}
                    onBack={this.props.onClose}
                />
            )
        }
    }

    render = () => {
        return ( 
            <Body>
                {this.renderHeader()}
                <Body>
                    {this.renderTimeRow()}
                    {this.renderMeridControl()}
                    {this.renderDecideInput()}

                </Body>
                {this.renderSave()}
            </Body>

        )
    }
}

ClockInput.propTypes = {
    clockSize: PropTypes.number,
    onSave: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.string,
    copy: PropTypes.number,
    onClose: PropTypes.func,
    modalMode: PropTypes.bool,
};

ClockInput.defaultProps = {
    clockSize: 312.5,
    onSave: () => {},
    title: '',
    value: '',
    copy: 0,
    onClose: () => {},
    modalMode: false,
}

export default ClockInput;