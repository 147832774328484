import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    BottomUpModal,
    Appointment,
    Scroll,
    Body
} from '.';
import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { parseDocs } from '../util/FirestoreUtil';
import { ARTIST } from '../constants/ClientRoutes';

class CancelAppointmentsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: []
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open: prevOpen } = prevProps;
        const { open } = this.props;
        if (!prevOpen && open) {
            this.fetchAppointments();
        }
    }

    fetchAppointments = async () => {
        const db = getFirestore();
        const w = where('artistId', '==', this.props.profile.id);
        const o = orderBy('unixTime');
        const ref = collection(db, `appointments`);
        const q = query(ref, w, o);
        const snap = await getDocs(q);
        const { results: appointments } = parseDocs(snap);
        this.setState({ appointments });
    }

    navToAppointment = (appointment) => {
        window.location.hash = `${ARTIST.ARTIST_VIEW_APPOINTMENT}/${appointment.id}`;
    }

    renderAppointment = (appointment, index) => {
        return (
            <Appointment 
                appointment={appointment}
                key={index}
                onClick={this.navToAppointment}
            />
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='All of your future appointments'
                height={0.85}
            >
                <Body>
                    <Scroll>
                        {this.state.appointments.map(this.renderAppointment)}
                    </Scroll>
                </Body>
            </BottomUpModal>
        )
    }
}

CancelAppointmentsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    profile: PropTypes.object,
};

CancelAppointmentsModal.defaultProps = {
    open: false,
    onClose: () => {},
    profile: {}
};

export default CancelAppointmentsModal;