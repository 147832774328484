import React from 'react';
import PropTypes from 'prop-types';
import { 
    FlexRowButton,
    FlexRow,
    Value,
    Placeholder,
    InputLabel
} from '../components';

const AddressButton = ({ address, placeholder, onClick, label }) => {

    const renderPlaceholder = () => {
        if (!address) {
            return (
                <Placeholder placeholder={placeholder} />
            )
        }
    }

    const renderValue = () => {
        if (address) {
            return (
                <Value value={address.address} />
            )
        }
    }

    const renderLabel = () => {
        if (address) {
            return (
                <InputLabel 
                    style={styles.label}
                    label={label}
                />
            )
        }
    }

    return (
        <FlexRow>
            <FlexRowButton onClick={onClick}> 
                {renderValue()}
                {renderPlaceholder()}
                {renderLabel()}
            </FlexRowButton>
        </FlexRow>
    )
}

const styles = {
    label: {
        marginTop: 5
    }
}

AddressButton.propTypes = {
    address: PropTypes.object,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string
};

AddressButton.defaultProps = {
    address: null,
    placeholder: '',
    onClick: () => {},
    label: ''
};

export default AddressButton;