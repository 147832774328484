import { 
    ARTIST_PROP_CHANGE, 
    ARTIST_FETCH_PROFILE, 
    ARTIST_FETCH_IMAGES, 
    ARTIST_FETCH_MORE_IMAGES, 
    ARTIST_FETCH_APPOINTMENT_HASH,
    ARTIST_FETCH_APPOINTMENTS,
    ARTIST_FETCH_POLICY_HASH,
    ARTIST_FETCH_NOTIFICATION_HASH,
    ARTIST_FETCH_NOTI,
    ARTIST_CLEAR,
    ARTIST_SETUP_PAGE_CHANGE
} from "../actionTypes/actionTypes";

const INITIAL_STATE = {
    profile: null,
    profileListener: null,
    shouldRender: false,
    images: [],
    imagesCursor: null,
    noMoreImages: false,
    imageIndex: 0,
    imageListener: null,
    scrollTop: 0,
    mapOpen: false,
    appointmentHash: {},
    appointmentHashListener: null,
    appointments: [],
    appointmentListener: null,
    selectedDay: null,

    settingsOpen: false,
    appointmentPoliciesOpen: false,
    notificationPoliciesOpen: false,
    accountOpen: false,

    policyHash: {},
    policyUnsub: null,

    notificationHash: {},
    notificationHashUnsub: null,

    clientPickerOpen: false,

    upgradeTokens: [],

    notis: [],
    notiListener: null,
    notisOpen: false,

    welcomeLeft: 0,
    scheduleLeft: window.screen.width,
    imagesLeft: window.screen.width * 2,
    servicesLeft: window.screen.width * 3,

    imageProgressOpen: false,
    imageProgressMessage: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTIST_CLEAR:
            return INITIAL_STATE;
        case ARTIST_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value }
        case ARTIST_FETCH_NOTI:
            return { 
                ...state,
                notis: action.payload.notis,
                notiListener: action.payload.notiListener
            }
        case ARTIST_FETCH_PROFILE:
            return { 
                ...state,
                profile: action.payload.profile, 
                profileListener: action.payload.profileListener,
                shouldRender: action.payload.shouldRender
            }
        case ARTIST_FETCH_APPOINTMENTS:
            return {
                ...state,
                appointments: action.payload.appointments,
                appointmentListener: action.payload.appointmentListener
            }
        case ARTIST_FETCH_NOTIFICATION_HASH:
            return {
                ...state,
                notificationHash: action.payload.notificationHash,
                notificationHashUnsub: action.payload.notificationHashUnsub
            }
        case ARTIST_FETCH_APPOINTMENT_HASH:
            return {
                ...state,
                appointmentHash: action.payload.appointmentHash,
                appointmentHashListener: action.payload.appointmentHashListener
            }
        case ARTIST_FETCH_POLICY_HASH:
            return {
                ...state,
                policyHash: action.payload.policyHash,
                policyUnsub: action.payload.policyUnsub
            }
        case ARTIST_FETCH_IMAGES:
            return {
                ...state,
                images: action.payload.images,
                imagesCursor: action.payload.imagesCursor,
                noMoreImages: action.payload.noMoreImages,
                imageListener: action.payload.imageListener
            }
        case ARTIST_FETCH_MORE_IMAGES:
            return {
                ...state,
                images: [...state.images, ...action.payload.images],
                imagesCursor: action.payload.imagesCursor,
                noMoreImages: action.payload.noMoreImages
            }
        case ARTIST_SETUP_PAGE_CHANGE:
            return {
                ...state,
                welcomeLeft: action.payload.welcomeLeft,
                scheduleLeft: action.payload.scheduleLeft,
                imagesLeft: action.payload.imagesLeft,
                servicesLeft: action.payload.servicesLeft
            }
        default:
            return state;
    }
}