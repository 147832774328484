export const SEARCH_PLACES = 'https://us-central1-argenda-44ea6.cloudfunctions.net/searchPlaces';
export const TEST_CARD = 'https://us-central1-argenda-44ea6.cloudfunctions.net/testCard';
export const CREATE_CONNECT_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/createConnectAccount';
export const CREATE_CONNECT_ACCOUNT2 = 'https://us-central1-argenda-44ea6.cloudfunctions.net/createConnectAccount2';
export const CREATE_SUB_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/createSubAccount';
export const UPLOAD_CONNECT_ID = 'https://us-central1-argenda-44ea6.cloudfunctions.net/uploadConnectID';
export const CREATE_CUSTOMER_ACCOUNT_FOR_CLIENT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/createCustomerAccountForClient';
export const FETCH_CUSTOMER_CARD = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchCustomerCard';
export const MAKE_PAYMENT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/makePayment';
export const FETCH_CHARGES = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchCharges';
export const UPDATE_CUSTOMER_CARD = 'https://us-central1-argenda-44ea6.cloudfunctions.net/updateCardForCustomer';
export const FETCH_CONNECT_CARD_AND_BALANCE = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchConnectCardAndBalance';
export const FETCH_MORE_ARTIST_CHARGES = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchMoreArtistCharges';
export const FETCH_MORE_ARTIST_PAYOUTS = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchMoreArtistPayouts';
export const FETCH_BEFORE_ARTIST_CHARGES = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchBeforeArtistCharges';
export const FETCH_BEFORE_ARITST_PAYOUTS = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchBeforeArtistPayouts';
export const RUN_PAYOUT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/runPayout';
export const CHANGE_ARTIST_CONNECT_CARD = 'https://us-central1-argenda-44ea6.cloudfunctions.net/changeArtistConnectCard';
export const NOTIFY_CLIENTS_OF_ADDRESS_CHANGE = 'https://us-central1-argenda-44ea6.cloudfunctions.net/notifyClientsOfAddressChange';
export const FETCH_BALANCE = 'https://us-central1-argenda-44ea6.cloudfunctions.net/fetchBalance';
export const DOWNGRADE_TO_SCHEDULING = 'https://us-central1-argenda-44ea6.cloudfunctions.net/downgradeToScheduling';
export const DOWNGRADE_TO_SCHEDULING_AND_DELETE_CONNECT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/downgradeToSchedulingAndDeleteConnect';
export const UPGRADE_TO_PAYMENTS = 'https://us-central1-argenda-44ea6.cloudfunctions.net/upgradeToPayments';
export const DELETE_CONNECT_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/deleteConnectAccount';
export const DELETE_CUSTOMER_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/deleteCustomerAccount';
export const UPDATE_EMAIL_FOR_CONNECT_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/updateEmailForConnectAccount';
export const UPDATE_EMAIL_FOR_CUSTOMER_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/updateEmailForCustomerAccount';
export const UPDATE_PHONE_FOR_CONNECT_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/updatePhoneForConnectAccount';
export const UPDATE_PHONE_FOR_CUSTOMER_ACCOUNT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/updatePhoneForCustomerAccount';
export const SEND_TEXT = 'https://us-central1-argenda-44ea6.cloudfunctions.net/sendText';
export const DELETE_PAYMENT_ARTIST = 'https://us-central1-argenda-44ea6.cloudfunctions.net/deletePaymentArtist';