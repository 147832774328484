export const INPUT_LABEL = {
    fontSize: 16,
    color: 'rgba(255, 255, 255, 0.6)',
    marginTop: 10
}

export const INFO_LABEL = {
    fontSize: 16,
    color: 'rgba(255, 255, 255, 0.5)',
    marginTop: 10,
    width: '80%'
}

export const VALUE = {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff'
}

export const ERROR_INFO = {
    fontSize: 16,
    color: 'salmon',
    marginTop: 10,
    width: '80%'
}

export const BOTTOM_BUTTON = {
    width: '100%',
    borderRadius: 0,
    marginBottom: 20,
    marginTop: 20
}

export const ACTIVE_BUTTON = {
    borderColor: '#fff'
}

export const ACTIVE_LABEL = {
    color: '#fff'
}

export const INACTIVE_LABEL = {
    color: 'rgba(255, 255, 255, 0.2)'
}

export const MARGIN_RIGHT = {
    marginRight: 10
}

export const TIME_LABEL = {
    fontSize: 14
}

export const OPTION_CANCEL = 'rgb(235, 50, 50)';
export const OPTION_RESCHEDULE = 'rgb(76, 76, 250)';
export const OPTION_NO_SHOW = 'rgb(255, 173, 22)';