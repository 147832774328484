import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';

/*
    parses a e.target.file to an image object
    {
        url,
        blob
    }
*/
export const parseBlob = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    const image = {
        url,
        blob: e.target.files[0]
    };
    return image;
}

export const parseFile = (file) => {
    const url = URL.createObjectURL(file);
    const image = {
        url,
        blob: file
    };
    return image;
}

//parses a list of e.target.files to an image object
export const parseBlobs = (e) => {
    const images = [];
    for (let i = 0; i < e.target.files.length; i++) {
        const blob = e.target.files[i];
        const url = URL.createObjectURL(blob);
        images.push({
            blob,
            url
        });
    }
    return images;
}

export const parseFiles = (files) => {
    const images = [];
    for (let i = 0; i < files.length; i++) {
        const blob = files[i];
        const url = URL.createObjectURL(blob);
        images.push({
            blob,
            url
        });
    }
    return images;
}

//uploads an image object based of refPath
export const uploadImageAndGetURL = async (image, refPath) => {
    const db = getStorage();
    const imageRef = ref(db, refPath);
    await uploadBytes(imageRef, image.blob);
    const url = await getDownloadURL(imageRef);
    return url;
}   

//delete an image from specified path
export const deleteImage = async (refPath) => {
    const db = getStorage();
    const imageRef = ref(db, refPath);
    await deleteObject(imageRef);
}

//returns a resized profile image via imgix api
export const buildProfileImageUrl = (width, height, id, imageName, profileDefault = false) => {
    if (imageName) {
        return `https://argenda.imgix.net/${id}/profileImage/${imageName}?maskbg=0fff&fit=crop&&crop=faces&dpr=${window.devicePixelRatio}&fm=webp&w=${width}&h=${height}`;
    }
    if (profileDefault) {
        return `https://argenda.imgix.net/defaults/Placeholder.svg?maskbg=0fff&fit=crop&&crop=faces&dpr=${window.devicePixelRatio}&fm=webp&w=${width}&h=${height}`;
    }
    return `https://argenda.imgix.net/defaults/defaultBackground.png?maskbg=0fff&fit=crop&&crop=faces&dpr=${window.devicePixelRatio}&fm=webp&w=${width}&h=${height}`;
}

export const getLogo = (width, height) => {
    return `https://argenda.imgix.net/defaults/ArgendaLogo.png?maskbg=0fff&fit=crop&dpr=${window.devicePixelRatio}&fm=webp&w=${width}&h=${height}`;
}

//returns a resized portfolio image via imgix api
export const buildImageUrl = (id, imageName, width, height) => {
    const url = `https://argenda.imgix.net/images/${imageName}?maskbg=0fff&fit=crop&&crop=faces&dpr=${window.devicePixelRatio}&fm=webp&w=${width}&h=${height}`;
    return url;
}

//gets thumbnail size based on screen width
export const getThumbnailSize = () => {
    return Math.floor(window.screen.width / 3) - 4;
}

//get the number of images to fetch based on screen height
export const getImageAmountToFetch = () => {
    const imageHeight = getThumbnailSize();
    const rows = Math.floor(window.screen.height / imageHeight);
    const imagesPerRow = 3;
    return rows * imagesPerRow;
}   