import React, { Component } from 'react';
import { IoImage } from 'react-icons/io5';
import { connect } from 'react-redux';
import { 
    FlexRow,
    FlexRowButton,
    ImageBackground,
    InputLabel,
    TransparentHeader, 
    TransparentInput,
    Value,
    BrandButton,
    PriceControl,
    DurationPicker,
    PortfolioPicker,
    AreYouSure,
    Loader,
    BottomUpModal,
    Option,
    CustomServiceModal
} from '../../components';
import { 
    getFirestore,
    updateDoc,
    doc,
    deleteDoc
} from 'firebase/firestore';
import { 
    BOTTOM_BUTTON, 
    MARGIN_RIGHT, 
    OPTION_CANCEL, 
    OPTION_RESCHEDULE
} from '../../universalStyles/Styles';
import { 
    serviceChange, 
    servicePropChange, 
    fetchCustomServices 
} from '../../actions/serviceActions';
import { buildImageUrl } from '../../util/ImageUtil';
import { convertMinutesToHoursAndMinutesReadable } from '../../util/TimeUtil';
import { withRouter } from "react-router-dom";
import './styles/AddService.css';
import { BsFillPersonPlusFill, BsX } from 'react-icons/bs';
import { ARTIST } from '../../constants/ClientRoutes';


class EditService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            durationOpen: false,
            priceOpen: false,
            imageOpen: false,
            deleteOpen: false,
            optionsOpen: false,
            customServiceOpen: false,
        }
    }

    componentDidMount = () => {
        const { serviceId } = this.props.match.params;
        const service = this.props.services.filter(s => s.id === serviceId)[0];
        this.props.servicePropChange('service', service);
    }

    save = () => {
        const serviceId = this.props.service.id;
        const serviceToSave = {
            name: this.props.service.name,
            price: this.props.service.price,
            image: this.props.service.image,
            duration: this.props.service.duration,
            artistId: this.props.service.artistId
        }
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/services/${serviceId}`);
        updateDoc(ref, serviceToSave);
        this.onBack();
    }

    delete = () =>{
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/services/${this.props.service.id}`)
        deleteDoc(ref);
        this.onBack();
    }

    toggleDuration = () => {
        this.setState({ durationOpen: !this.state.durationOpen });
    }

    toggleImage = () => {
        this.setState({ imageOpen: !this.state.imageOpen });
    }

    togglePrice = () => {
        this.setState({ priceOpen: !this.state.priceOpen });
    }

    focusName = () => {
        this.nameRef.focus();
    }

    onBack = () => {
        if (this.props.customUnsub) {
            this.props.customUnsub();
        }
        window.history.back();
    }

    openCustomService = () => {
        this.setState({ optionsOpen: false });
        window.setTimeout(() => {
            this.props.servicePropChange('customServiceOpen', true);
            this.props.fetchCustomServices(this.props.service);
        }, 300);
    }

    closeCustomService = () => {
        this.props.servicePropChange('customServiceOpen', false);
    }

    toggleDelete = () => {
        this.setState({ deleteOpen: !this.state.deleteOpen });
    }

    openDelete = () => {
        this.setState({ optionsOpen: false });
        window.setTimeout(() => {
            this.setState({ deleteOpen: true });
        }, 300)
    }

    toggleOptions = () => {
        this.setState({ optionsOpen: !this.state.optionsOpen });
    }

    serviceNameChange = (name) => {
        this.props.serviceChange(this.props.service, 'name', name);
    }

    serviceDurationChange = (duration) => {
        this.props.serviceChange(this.props.service, 'duration', duration);
    }

    servicePriceChange = (price) => {
        this.props.serviceChange(this.props.service, 'price', price);
    }

    serviceImageChange = (image) => {
        this.props.serviceChange(this.props.service, 'image', image.imageName)
    }

    navToCreateCustomService = () => {
        window.location.hash = ARTIST.ARTIST_CREATE_CUSTOM_SERVICE;
    }

    renderTopButton = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusName}>
                    <TransparentInput 
                        placeholder='Service Name...'
                        value={this.props.service.name}
                        passRefUp={ref => this.nameRef = ref}
                        onChange={this.serviceNameChange}
                    />
                    <InputLabel 
                        label='Service Name'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderEmtpyDuration = () => {
        return (
            <FlexRowButton 
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <InputLabel label='Duration' />
            </FlexRowButton>
        )
    }

    renderSingleDuration = () => {
        return (
            <FlexRowButton
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <Value value={convertMinutesToHoursAndMinutesReadable(this.props.service.duration.singleStart)} />
                <InputLabel label='Duration' />
            </FlexRowButton>
        )
    }

    renderDoubleDuration = () => {
        return (
            <FlexRowButton
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <Value value={convertMinutesToHoursAndMinutesReadable(this.props.service.duration.doubleStart)} />
                <InputLabel 
                    label='Initial Time' 
                />
                <Value 
                    value={convertMinutesToHoursAndMinutesReadable(this.props.service.duration.doubleBreak)} 
                    style={styles.margin}
                />
                <InputLabel 
                    label='Inbetween Time' 
                />
                <Value 
                    value={convertMinutesToHoursAndMinutesReadable(this.props.service.duration.doubleEnd)} 
                    style={styles.margin}
                />
                <InputLabel 
                    label='Finishing up' 
                />
            </FlexRowButton>
        )
    }

    renderDecideDuration = () => {
        if (!this.props.service.duration) {
            return this.renderEmtpyDuration();
        }
        if (this.props.service.duration.isDouble) {
            return this.renderDoubleDuration();
        }
        return this.renderSingleDuration();
    }

    renderDecideImage = () => {
        if (this.props.service.image) {
            return this.renderImage();
        }
        return this.renderImageButton();
    }

    renderImage = () => {
        const width = Math.ceil(.94 * window.screen.width);
        const height = Math.ceil(.3333 * window.screen.height);
        const url = buildImageUrl(this.props.profile.id, this.props.service.image, width, height)
        return (
            <img 
                className='AddService-Image'
                src={url}
                onClick={this.toggleImage}
            />
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.save}
            />
        )
    }

    renderImageButton = () => {
        return (
            <FlexRow>
                <div 
                    className='AddService-ImageHolder'
                    onClick={this.toggleImage}
                >
                    <IoImage 
                        className='AddService-ImageIcon'
                    />
                    <InputLabel 
                        label='Pick an Image to describe this service'
                        style={{ marginTop: 15 }}
                    />
                </div>
            </FlexRow>
        )
    }

    renderPrice = () => {
        return (
            <FlexRowButton onClick={this.togglePrice}>
                {this.props.service.price > 0 ? <Value value={`$${this.props.service.price.toString()}`} /> : null }
                <InputLabel label='Price' />
            </FlexRowButton>
        )
    }

    renderBottomButtons = () => {
        return (
            <FlexRow style={{ flex: 1.6 }}>
                {this.renderDecideDuration()}
                {this.renderPrice()}
            </FlexRow>
        )
    }

    renderPriceControl = () => {
        const defaultUrl = buildImageUrl(this.props.profile.id, this.props.service.image, window.screen.width, window.screen.height)
        return (
            <PriceControl 
                open={this.state.priceOpen}
                onClose={this.togglePrice}
                onSave={this.servicePriceChange}
                price={parseInt(this.props.service.price)}
                image={defaultUrl}
            />  
        )
    }

    renderDurationPicker = () => {
        const defaultUrl = buildImageUrl(this.props.profile.id, this.props.service.image, window.screen.width, window.screen.height)
        return (
            <DurationPicker 
                open={this.state.durationOpen}
                onClose={this.toggleDuration}
                duration={this.props.service.duration}
                onSave={this.serviceDurationChange}
                image={defaultUrl}
            />
        )
    }

    renderPortfolioPicker = () => {
        return (
            <PortfolioPicker 
                open={this.state.imageOpen}
                onClose={this.toggleImage}
                onSave={this.serviceImageChange}
            />
        )
    }

    renderOptions = () => {
        return (
            <BottomUpModal
                open={this.state.optionsOpen}
                title='Service Options'
                onClose={this.toggleOptions}
                height={.3}
            >
                <Option 
                    label='Delete Service'
                    icon={<BsX />}
                    backgroundColor={OPTION_CANCEL}
                    onClick={this.openDelete}
                />
                <Option 
                    label={this.props.service.customizations > 0 ? `${this.props.service.customizations} Client Customizations` : 'Client Customization'}
                    icon={<BsFillPersonPlusFill />}
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.openCustomService}
                />
            </BottomUpModal>
        )
    }

    renderCustomServiceModal = () => {
        return (
            <CustomServiceModal 
                open={this.props.customServiceOpen}
                onClose={this.closeCustomService}
                customServices={this.props.customServices}
                addCustom={this.navToCreateCustomService}
                service={this.props.service}
            />
        )
    }

    renderAreYouSure = () => {
        return (
            <AreYouSure 
                open={this.state.deleteOpen}
                title={`Are you sure you want to delete ${this.props.service.name}?`}
                valueToType={this.props.service.name}
                confirmMessage={'Confirm you want to delete this service by typing:'}
                onClose={this.toggleDelete}
                accept={this.delete}
                buttonLabel='Delete Service'
            />
        )
    }

    renderDecide = () => {
        if (this.props.service) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    renderContent = () => {
        const defaultUrl = buildImageUrl(this.props.profile.id, this.props.service.image, window.screen.width, window.screen.height)
        return (
            <ImageBackground
                image={defaultUrl}
                darken
            >
                <TransparentHeader 
                    title='Edit Your Service'
                    onBack={this.onBack}
                    showSettings
                    onSettings={this.toggleOptions}
                />
                {this.renderTopButton()}
                {this.renderBottomButtons()}
                {this.renderDecideImage()}
                {this.renderPriceControl()}
                {this.renderDurationPicker()}
                {this.renderPortfolioPicker()}
                {this.renderAreYouSure()}
                {this.renderSave()}
                {this.renderOptions()}
                {this.renderCustomServiceModal()}
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading...'
            />
        )
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    margin: {
        marginTop: 10
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    const { 
        service,
        services, 
        customServiceOpen,
        customUnsub,
        customServices
    } = state.service;
    return { 
        profile, 
        service, 
        services, 
        customServiceOpen,
        customUnsub,
        customServices
    }
}

export default withRouter(connect(mapStateToProps, { serviceChange, servicePropChange, fetchCustomServices })(EditService));
