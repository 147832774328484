import axios from 'axios';
import { SEARCH_PLACES } from '../constants/ArgendaServerURLS';

export const searchPlaces2 = async (search, coords) => {
    const res = await axios.post(SEARCH_PLACES, {
        search: search.replace(/ /g, '+'),
        latitude: coords ? coords.latitude : '',
        longitude: coords ? coords.longitude : ''
    });
    console.log(res.data);
    return res.data;
}