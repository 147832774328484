import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal, 
    BrandButton, 
    Body,
    ModalLoader,
    ModalSuccess,
    ModalError,
    ModalInput
} from '.';
import './styles/NoShowModal.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { calcStripeFee, calcStripeFeeInDollars } from '../util/StripeUtil';
import { convertDateStringToReadable } from '../util/DateUtil';
import { convertNumberToReadable, convertUnixTimeToReadable } from '../util/TimeUtil';
import axios from 'axios';
import { MAKE_PAYMENT } from '../constants/ArgendaServerURLS';
import { incrementMonthStatAndClientStatForPayment } from '../util/AppointmentUtil';
import { fetchClientNotiHash, handleNoti } from '../util/NotiUtil';
import { NOSHOW } from '../constants/Policies';
import { CLIENT_NOSHOW } from '../constants/NotiTypes';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';

class NoShowModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading: false,
            success: false,
            error: false
        }
    }

    onChange = (value) => {
        this.setState({ value });
    }

    canCharge = () => {
        if (this.state.value === this.props.appointment.clientName) {
            return true;
        }
        return false;
    }

    resetError = () => {
        this.setState({ error: false });
    }

    startNoShow = async () => {
        await this.startCharge();
        await this.startNoti();
        await this.markAppointmentNoShow();
    }

    markAppointmentNoShow = async () => {
        const amount = this.props.appointment.tos.noShowFee;
        const db = getFirestore();
        const ref = doc(db, `appointments/${this.props.appointment.id}`);
        await updateDoc(ref, {
            noShow: true,
            paidTime: new Date().getTime(),
            paidAmount: amount
        });
    }

    getPaymentPack = () => {
        const costInDollars = this.props.appointment.tos.noShowFee;
        const fee = calcStripeFee(costInDollars);
        const amount = Math.ceil(costInDollars * 100);
        const paymentPack = {
            customerId: this.props.client.customerId,
            fee,
            clientDescription: `"No Show Fee" of $${costInDollars.toFixed(2)} for not showing up to your appointment on ${convertDateStringToReadable(this.props.appointment.day, true)} at ${convertNumberToReadable(this.props.appointment.slot.slot.startTime)}.`,
            artistDescription: `"No Show Fee" of $${costInDollars.toFixed(2)} because ${this.props.client.name} didn't show up for the appointment on ${convertDateStringToReadable(this.props.appointment.day, true)} at ${convertNumberToReadable(this.props.appointment.slot.slot.startTime)}.`,
            stripeAccountId: this.props.artist.stripeAccountId,
            artistId: this.props.artist.id,
            clientId: this.props.client.id,
            artistName: this.props.artist.name,
            clientName: this.props.client.name,
            type: 'No Show Fee',
            tip: 0,
            serviceString: JSON.stringify(this.props.appointment.service),
            amount,
            artistPhone: this.props.artist.phone,
            clientPhone: this.props.client.phone,
            artistMessage: `You just charged ${this.props.client.name} a "No Show Fee" of $${costInDollars.toFixed(2)} for not showing up for the appointment on ${convertDateStringToReadable(this.props.appointment.day, true)} at ${convertNumberToReadable(this.props.appointment.slot.slot.startTime)}.`,
            clientMessage: `${this.props.artist.name} just charged you a "No Show Fee" of $${costInDollars.toFixed(2)} for not showing up to your appointment on ${convertDateStringToReadable(this.props.appointment.day, true)} ${convertNumberToReadable(this.props.appointment.slot.slot.startTime)}. You agreed to ${this.props.artist.name}'s "No Show Policy" when you booked the appointment. ${convertUnixTimeToReadable(this.props.appointment.tos.acceptedDate)}`
        }
        return paymentPack;
    }

    startNoti = async () => {
        const costInDollars = this.props.appointment.tos.noShowFee;
        const notiHash = await fetchClientNotiHash(this.props.client.id);
        const method = notiHash[NOSHOW];
        const desc = `${this.props.artist.name} just charged you a no show fee of $${costInDollars} because you failed to show up for an appointment on ${convertUnixTimeToReadable(this.props.appointment.unixTime)}`;
        const preview = `${this.props.artist.name} just charged you a no show fee.`;
        const link = '';
        await handleNoti(this.props.client.phone, desc, preview, CLIENT_NOSHOW, this.props.client.id, method, link, true);
    }

    startCharge = async () => {
        this.setState({ loading: true });
        const costInDollars = this.props.appointment.tos.noShowFee;
        const feeInDollars = calcStripeFeeInDollars(costInDollars);
        const paymentPack = this.getPaymentPack();
        try {
            await axios.post(MAKE_PAYMENT, paymentPack);
            await incrementMonthStatAndClientStatForPayment(this.props.appointment, costInDollars,  feeInDollars);
            this.setState({ loading: false, success: true  });
        } catch (ex) {
            this.setState({ loading: false, error: true, success: false });
        }
    }

    renderDecide = () => {
        if (!this.state.success && !this.state.loading && !this.state.error) {
            return this.renderContent();
        }
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.success) {
            return this.renderSuccess();
        }
        if (this.state.error) {
            return this.renderError();
        }
    }

    renderContent = () => {
        if (this.props.appointment.tos.noShowFee) {
            return (
                <div className='NoShowModal-Container'>
                    <Body>
                        <h2 className='NoShowModal-Label'>No Show Fee</h2>
                        <h1 className='NoShowModal-NoShowFee'>{`$${this.props.appointment.tos.noShowFee.toFixed(2)}`}</h1>
                        <h4 className='NoShowModal-Info'>To charge the "No Show Fee" you must type the name of the client: <strong>{this.props.appointment.clientName}</strong></h4>
                        <ModalInput 
                            placeholder={this.props.appointment.clientName}
                            onChange={this.onChange}
                            value={this.state.value}
                        />
                    </Body>
                    <BrandButton 
                        style={BOTTOM_BUTTON}
                        label='Charge'
                        disabled={!this.canCharge()}
                        onClick={this.startNoShow}
                    />
                </div>
            )
        }
    }

    renderSuccess = () => {
        return (
            <ModalSuccess 
                message='No Show Fee Charged successfully.'
                onClick={this.props.onClose}
            />
        )
    }

    renderError = () => {
        return (
            <ModalError 
                message='Looks like something went wrong. Try again.'
                onClick={this.resetError}
            />
        )
    }

    renderLoader = () => {
        return (
            <ModalLoader message='Charging No Show Fee...' />
        )
    }


    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                height={0.5}
                onClose={this.props.onClose}
                disableClose={this.state.loading}
                image={this.props.image}
                darken
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

NoShowModal.propTypes = {
    open: PropTypes.bool,
    appointment: PropTypes.object,
    artist: PropTypes.object,
    client: PropTypes.object,
    onClose: PropTypes.func,
    image: PropTypes.string,
};

NoShowModal.defaultProps = {
    open: false,
    appointment: {},
    artist: {},
    client: {},
    onClose: () => {},
    image: ''
}

export default NoShowModal;