import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body, 
    Empty, 
    ListWrapper, 
    ModalRowBack, 
    Noti2, 
    SettingsTitle,
    BrandButton
} from '.';
import './styles/ArtistNotis.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { deleteDoc, doc, getFirestore, collection, getDocs } from '@firebase/firestore';

class ClientNotis extends Component {

    dismissAll = async () => {
        const db = getFirestore();
        const ref = collection(db, `clients/${this.props.profile.id}/notifications`);
        const snap = await getDocs(ref);
        for (let i = 0; i < snap.docs.length; i++) {
            const id = snap.docs[i].id;
            const ref = doc(db, `clients/${this.props.profile.id}/notifications/${id}`);
            deleteDoc(ref);
        }
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Notifications'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Notifications'
            />
        )
    }

    renderNoti = (noti, index) => {
        return (
            <Noti2 
                key={index}
                noti={noti}
                isClient
            />
        )
    }

    renderNotis = () => {
        return (
            <ListWrapper>
                {this.props.notis.map(this.renderNoti)}
            </ListWrapper>
        )
    }

    renderEmpty = () => {
        return (
            <Empty 
                message="You don't have any notifications."
            />
        )
    }

    renderDecide = () => {
        if (this.props.notis.length > 0) {
            return this.renderNotis();
        }
        return this.renderEmpty();
    }

    renderDismissButton = () => {
        if (this.props.notis.length > 0) {
            return (
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Dismiss All'
                    onClick={this.dismissAll}
                />
            )
        }
    }

    render = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderDecide()}
                {this.renderDismissButton()}
            </Body>
        )
    }
}

ClientNotis.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    notis: PropTypes.array
}

ClientNotis.defaultProps = {
    onBack: () => {},
    profile: {},
    notis: []
}

export default ClientNotis;