import React from 'react';
import PropTypes from 'prop-types';
import './styles/Body.css';

const Body = ({ children, relative, center, id, absolute, style }) => {


    return (
        <div 
            className={`Body-Container ${relative && 'Body-Relative'} ${absolute && 'Body-Absolute'} ${center && 'Body-Center'}`}
            id={id}
            style={style}
        >
            {children}
        </div>
    )
}

Body.propTypes = {
    children: PropTypes.node,
    relative: PropTypes.bool,
    center: PropTypes.bool,
    absolute: PropTypes.bool,
    id: PropTypes.string,
    style: PropTypes.object
};

Body.defaultProps = {
    children: null,
    relative: false,
    center: false,
    id: '',
    absolute: false,
    style: {}
}

export default Body;