import React from 'react';
import PropTypes from 'prop-types';
import './styles/TransparentInput.css';

const TransparentInput = ({ placeholder, value, onChange, type, style, passRefUp, maxLength }) => {

    const change = (e) => {
        onChange(e.target.value);
    }

    return (
        <input 
            className='TransparentInput-Input'
            placeholder={placeholder}
            value={value}
            onChange={change}
            type={type}
            style={style}
            ref={passRefUp}
            maxLength={maxLength}
        />
    )
}

TransparentInput.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,

    onChange: PropTypes.func,
    passRefUp: PropTypes.func,

    style: PropTypes.object,

    maxLength: PropTypes.number,
};

TransparentInput.defaultProps = {
    placeholder: '',
    value: '',
    type: '',

    onChange: () => {},
    passRefUp: () => {},

    style: {},

    maxLength: null,
}

export default TransparentInput;
