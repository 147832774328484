import { getFirestore, doc, deleteDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { 
    Header,
    ImageBackground, 
    Loader, 
    Service, 
    TransparentHeader,
    BottomUpModal,
    AreYouSure,
    Option,
    TimeSlotPicker,
    BrandButton,
    SlotButton,
    NoShowModal,
    CancelModal,
    Empty,
    PaidOverlay
} from '../../components';
import { updateDoc } from 'firebase/firestore';
import { 
    BOTTOM_BUTTON, 
    OPTION_CANCEL, 
    OPTION_NO_SHOW, 
    OPTION_RESCHEDULE 
} from '../../universalStyles/Styles';
import { BsX, BsArrowLeftRight, BsPersonDash, BsPersonFill } from 'react-icons/bs';
import { buildImageUrl } from '../../util/ImageUtil';
import './styles/ViewAppointment.css';
import { 
    decrementAppointmentHashForArtist, 
    updateAppointmentHashForArtist, 
    updateCancelStats, 
    updateRescheduleStats 
} from '../../util/AppointmentUtil';
import './styles/ViewAppointment.css';
import { IoCall, IoChatbubbleOutline } from 'react-icons/io5';
import { ARTIST, CLIENT } from '../../constants/ClientRoutes';
import { fetchClientNotiHash, handleNoti } from '../../util/NotiUtil';
import { RESCHEDULE, TEXT } from '../../constants/Policies';
import { CLIENT_RESCHEDULE } from '../../constants/NotiTypes';
import { convertUnixTimeToReadable } from '../../util/TimeUtil';

class ViewAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointment: null,
            client: null,
            optionsOpen: false,
            slot: null,
            cancelOpen: false,
            rescheduleOpen: false,
            noShowOpen: false,
            none: false,
            loading: false,
            success: false,
            listener:  null
        }
    }

    componentDidMount = async () => {
        await this.listenToAppointment();
    }

    componentWillUnmount = () => {
        if (this.state.listener) {
            this.state.listener();
        }
    }

    getBackgroundImage = () => {
        const url = buildImageUrl(this.state.appointment.artistId, this.state.appointment.service.image, window.screen.width, window.screen.height);
        return url;
    }

    openCancel = () => {
        this.setState({ cancelOpen: true, optionsOpen: false });
    }

    closeCancel = () => {
        this.setState({ cancelOpen: false });
    }

    openReschedule = () => {
        this.setState({ rescheduleOpen: true, optionsOpen: false });
    }

    navToClientProfile = () => {
        window.location.hash = `${ARTIST.ARTIST_VIEW_CLIENT}/${this.state.appointment.clientId}`;
    }

    closeReschedule = () => {
        this.setState({ rescheduleOpen: false });
    }

    slotChange = (slot) => {
        this.setState({ slot });
    }

    openNoShow = () => {
        this.setState({ noShowOpen: true, optionsOpen: false });
    }

    closeNoShow = () => {
        this.setState({ noShowOpen: false });
    }

    toggleOptions = () => {
        this.setState({ optionsOpen: !this.state.optionsOpen }) ;
    }

    cancelAppointment = async () => {
        const db = getFirestore();
        const ref = doc(db, `appointments/${this.state.appointment.id}`);
        await decrementAppointmentHashForArtist(this.props.profile.id, this.state.appointment.day);
        await updateCancelStats(this.state.appointment, this.props.profile.id, this.state.appointment.clientId)
        await deleteDoc(ref);
        this.onBack();
    }

    onBack = () => {
        window.history.back();
    }

    reschedule = async () => {
        this.setState({ loading: true });
        const db = getFirestore();
        const oldDay = this.state.appointment.day;
        const appointment = { 
            ...this.state.appointment, 
            slot: this.state.slot, 
            startTime: this.state.slot.slot.startTime, 
            unixTime: this.state.slot.unixTime,
            endTime: this.state.slot.slot.endTime,
            day: this.state.slot.selectedDay.dateString
        };
        const ref = doc(db, `appointments/${this.state.appointment.id}`);
        await updateDoc(ref, appointment);
        await decrementAppointmentHashForArtist(appointment.artistId, oldDay);
        await updateRescheduleStats(appointment, this.props.profile.id, appointment.clientId);
        await updateAppointmentHashForArtist(this.props.profile.id, appointment.day);
        let method = TEXT;
        if (appointment.clientId) {
            const notiHash = await fetchClientNotiHash(appointment.clientId);
            method = notiHash[RESCHEDULE];
        }
        const preview = `${appointment.artistName} reschedule an appointment with you.`;
        const desc = `${appointment.artistName} rescheduled an appointment with you. ${convertUnixTimeToReadable(this.state.appointment.unixTime)} => ${convertUnixTimeToReadable(appointment.unixTime)}`;
        const link = `${CLIENT.CLIENT_VIEW_APPOINTMENT}/${appointment.id}`;
        await handleNoti(appointment.clientPhone, desc, preview, CLIENT_RESCHEDULE, appointment.clientId, method, link, true);
        this.setState({ success: true });
    }

    listenToAppointment = async () => {
        const db = getFirestore();
        const ref = doc(db, `appointments/${this.props.match.params.appointmentId}`);
        const listener = onSnapshot(ref, async snap => {
            if (snap.exists()) {
                const appointment = { ...snap.data(), id: snap.id };
                if (!this.state.client && appointment.clientId) {
                    const clientRef = doc(db, `clients/${appointment.clientId}`);
                    const clientSnap = await getDoc(clientRef).then();
                    const client = { ...clientSnap.data(), id: clientSnap.id };
                    this.setState({ appointment, listener, client });
                } else {
                    this.setState({ appointment, listener })
                }
            } else {
                this.setState({ none: true });
            }
        });
    }

    renderSlot = () => {
        return (
            <SlotButton 
                slot={this.state.appointment.slot}
                rescheduleMode
                rescheduledSlot={this.state.slot}
                onClick={this.openReschedule}
                showEndTime
            />
        )
    }

    renderService = () => {
        const serviceHeight = window.screen.height * .35;
        return (
            <Service 
                service={this.state.appointment.service}
                overrideHeight={serviceHeight}
            />
        )
    }

    renderNoShowOption = () => {
        const now = new Date().getTime();
        if (now > this.state.appointment.unixTime && this.state.appointment.tos) {
            return (
                <Option 
                    label='Client No Show'
                    icon={<BsPersonDash />}
                    backgroundColor={OPTION_NO_SHOW}
                    onClick={this.openNoShow}
                />
                
            )
        }
    }

    renderViewProfileOption = () => {
        if (this.state.appointment.clientId) {
            return (
                <Option 
                    label={`View ${this.state.appointment.artistName.split(' ')[0]}'s Profile`}
                    icon={<BsPersonFill />}
                    backgroundColor={OPTION_NO_SHOW}
                    onClick={this.navToClientProfile}
                />
            )
        }
    }

    renderCallOption = () => {
        return (
            <a
                href={`tel:+1${this.state.appointment.clientPhone}`}
                className='ViewAppointment-Text'
            >
                <Option
                    label={`Call ${this.state.appointment.clientName.split(' ')[0]}`} 
                    icon={<IoCall />}
                    backgroundColor={OPTION_RESCHEDULE}
                />
            </a>
        )
    }

    renderTextOption = () => {
        return (
            <a
                href={`sms:+1${this.state.appointment.clientPhone}`}
                className='ViewAppointment-Text'
            >
                <Option
                    label={`Text ${this.state.appointment.clientName.split(' ')[0]}`} 
                    icon={<IoChatbubbleOutline />}
                    backgroundColor={OPTION_RESCHEDULE}
                />
            </a>
        )
    }

    renderCancelOption = () => {
        return (
            <Option 
                label='Cancel Appointment'
                icon={<BsX />}
                backgroundColor={OPTION_CANCEL}
                onClick={this.openCancel}
            />
        )
    }

    renderRescheduleOption = () => {
        return (
            <Option 
                label='Reschedule'
                icon={<BsArrowLeftRight />}
                backgroundColor={OPTION_RESCHEDULE}
                onClick={this.openReschedule}
            />
        )
    }

    renderOptions = () => {
        return (
            <BottomUpModal
                open={this.state.optionsOpen}
                onClose={this.toggleOptions}
                title='Appointment Options'
                height={.5}
            >
                {this.renderCancelOption()}
                {this.renderRescheduleButton()}
                {this.renderNoShowOption()}
                {this.renderViewProfileOption()}
                {this.renderCallOption()}
                {this.renderTextOption()}
            </BottomUpModal>
        )
    }

    renderCancel = () => {
        return (
            <CancelModal 
                open={this.state.cancelOpen}
                onClose={this.closeCancel}
                onCancel={this.onBack}
                appointment={this.state.appointment}
                artist={this.props.profile}
                client={this.state.client}
                isArtist
                image={this.getBackgroundImage()}
            />
        )
    }

    renderReschedule = () => {
        return (
            <TimeSlotPicker 
                open={this.state.rescheduleOpen}
                artistId={this.state.appointment.artistId}
                service={this.state.appointment.service}
                onClose={this.closeReschedule}
                onChange={this.slotChange}
                image={this.getBackgroundImage()}
            />
        )
    }

    renderBottomMargin = () => {
        return (
            <div style={styles.marginBottom} />
        )
    }

    renderBottom = () => {
        if (this.state.slot) {
            return this.renderRescheduleButton();
        }
        return this.renderBottomMargin();
    }

    renderRescheduleButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Reschedule'
                onClick={this.reschedule}
            />
        )
    }

    renderNoShowModal = () => {
        if (this.state.appointment.tos && this.state.appointment.tos.acceptedDate) {
            return (
                <NoShowModal 
                    open={this.state.noShowOpen}
                    appointment={this.state.appointment}
                    onClose={this.closeNoShow}
                    artist={this.props.profile}
                    client={this.state.client}
                    image={this.getBackgroundImage()}
                />
            )
        }
    }

    decideLoadingMessage = () => {
        if (this.state.loading) {
            if (this.state.success) {
                return 'Appointment Rescheduled.';
            }
            return 'Rescheduling Appointment...';
        }
    }

    renderLoader2 = () => {
        return (
            <Loader 
                open={this.state.loading}
                success={this.state.success}
                message={this.decideLoadingMessage()}
                onClick={this.onBack}
            />
        )
    }

    renderPaidOverlay = () => {
        if (this.state.appointment.paid || this.state.appointment.noShow) {
            return (
                <PaidOverlay 
                    time={this.state.appointment.paidTime}
                    showBack
                    amount={this.state.appointment.paidAmount}
                    onBack={this.onBack}
                    curve={false}
                    noShow={this.state.appointment.noShow}
                />
            )
        }
    }

    renderContent = () => {
        return (
            <ImageBackground
                image={this.getBackgroundImage()}
                darken
            >
                <TransparentHeader 
                    title={`Appt with ${this.state.appointment.clientName}`}
                    showBack
                    onBack={this.onBack}
                    showSettings
                    onSettings={this.toggleOptions}
                />
                <Header title='Date and Time' />
                {this.renderSlot()}
                <Header title='Service' style={styles.margin} />
                {this.renderService()}
                {this.renderReschedule()}
                {this.renderBottom()}
                {this.renderOptions()}
                {this.renderCancel()}
                {this.renderNoShowModal()}
                {this.renderLoader2()}
                {this.renderPaidOverlay()}
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading Appointment...'
            />
        )
    }

    renderNoAppointment = () => {
        return (
            <ImageBackground darken image={this.getBackgroundImage()}>
                <TransparentHeader 
                    title={`Appointment`}
                    showBack
                    onBack={this.onBack}
                    showSettings
                />
                <Empty 
                    message='This appointment does not exists anymore'
                />
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.state.none) {
            return this.renderNoAppointment();
        }
        if (this.state.appointment) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    margin: {
        marginTop: 15,
        marginBottom: 15
    },
    marginBottom: {
        marginBottom: 30
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile };
}


export default withRouter(connect(mapStateToProps, { })(ViewAppointment));