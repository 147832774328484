import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchNotifications, artistPropChange } from '../actions/artistActions';
import { Noti, NotiGroup } from '.';
import './styles/ArtistNotiSystem.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const SECONDS = 5000;
const NOTI = 90;
const INITIAL = 2000;

class ArtistNotiSystem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
            unshown: [],
            height: 0
        }
    }

    componentDidMount = () => {
        window.setTimeout(this.authListener, SECONDS);
    }

    authListener = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, user => {
            if (user) {
                this.props.fetchNotifications();
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        const { notis } = this.props;
        const { notis: prevNotis } = prevProps;
        const unshown = this.getUnshown(notis);
        const prevUnshown = this.getUnshown(prevNotis);
        if (unshown.length > prevUnshown.length) {
            const height = unshown.length * NOTI;
            this.setState({ unshown, height });
            this.initiateOpen();
            window.setTimeout(this.closeDrawer, SECONDS);
        }
    }

    initiateOpen = () => {
        window.setTimeout(this.openDrawer, 100);
    }

    openDrawer = () => {
        this.setState({ drawerOpen: true });
        window.setTimeout(this.closeDrawer, SECONDS);
    }

    closeDrawerAndOpenNotis = () => {
        this.closeDrawer();
        if (this.props.notis.length > 0) {
            this.props.artistPropChange('settingsOpen', true);
            this.props.artistPropChange('notisOpen', true);
        } else {
            this.props.artistPropChange('settingsOpen', true);
        }
    }

    getUnshown = (notis) => {
        const unshown = [];
        for (let i = 0; i < notis.length; i++) {
            const noti = notis[i];
            if (!noti.shown) {
                unshown.push(noti);
            }
        }
        return unshown;
    }

    closeDrawer = () => {
        this.setState({ drawerOpen: false, unshown: [] });
    }

    decideDrawerClass = () => {
        if (this.state.drawerOpen) {
            return 'ANS-DrawerClose ANS-DrawerOpen';
        }
        return 'ANS-DrawerClose';
    }

    renderNoti = (noti, index) => {
        return (
            <Noti 
                key={index}
                noti={noti}
                dismissDrawer={this.closeDrawer}
            />
        )
    }

    renderNotis = () => {
        if (this.state.unshown.length > 0) {
            return this.state.unshown.map(this.renderNoti)
        }
    }

    renderNotiGroup = () => {
        return (
            <NotiGroup 
                notis={this.state.unshown}
                onClick={this.closeDrawerAndOpenNotis}
            />
        )
    }

    renderDecideNotis = () => {
        if (this.state.unshown.length > 0) {
            if (this.state.unshown.length > 1) {
                return this.renderNotiGroup();
            }
            return this.renderNotis();
        }

    }

    decideHeight = () => {
        if (this.state.unshown.length === 0) {
            return 0;
        }
        return -40;
    }

    render = () => {
        return (
            <div 
                className={'ANS-DrawerClose'}
                style={{ height: this.state.height, top: this.state.drawerOpen ? this.decideHeight() : -this.state.height }}
            >
                {this.renderDecideNotis()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { notis, profile, notiListener } = state.artist;
    return { notis, profile, notiListener };
}

export default connect(mapStateToProps, {
    fetchNotifications,
    artistPropChange
})(ArtistNotiSystem);