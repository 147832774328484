import React from 'react';
import PropTypes from 'prop-types';
import './styles/FlexRow.css';

const FlexRow = ({ children, style }) => {
    return (
        <div style={style} className='FlexRow-Container'>
            {children}
        </div>
    )
}

FlexRow.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
}

export default FlexRow;