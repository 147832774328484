import React from 'react';
import PropTypes from 'prop-types';
import { Body, BrandButton, SettingsTitle } from '.';
import ReactLoading from 'react-loading';
import { 
    IoCheckmarkCircleOutline, 
} from 'react-icons/io5'
import './styles/SettingsLoader.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const SettingsLoader = ({ message, success, onClick }) => {

    const renderSuccess = () => {
        return (
            <Body center>
                <Body center>
                    <IoCheckmarkCircleOutline 
                        className='SL-Success'
                    />
                    <SettingsTitle title={message} />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    onClick={onClick}
                    label='Okay'
                />
            </Body>
        )
    }

    const renderContent = () => {
        return (
            <Body center>
                <ReactLoading 
                    type='spokes'
                    color='#FF3466'
                    width={100}
                    height={100}
                />
                <SettingsTitle title={message} />
            </Body>
        )
    }

    const renderDecide = () => {
        if (success) {
            return renderSuccess();
        }
        return renderContent();
    }

    return renderDecide();
}

SettingsLoader.propTypes = {
    message: PropTypes.string,
    success: PropTypes.bool,
    onClick: PropTypes.func
};

SettingsLoader.defaultProps = {
    message: '',
    success: false,
    onClick: () => {}
}

export default SettingsLoader;