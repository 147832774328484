  
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    HashRouter,
    Switch,
    Route,
} from 'react-router-dom';
import { userLoggedIn, userLoggedOut } from '../actions/authActions';
import { ArtistNotiSystem, ClientNotiSystem, DesktopDialog, ImageProgress, PWA } from '../components';
//auth screens
import { ARTIST, CLIENT, SIGNUP } from '../constants/ClientRoutes';
import './Router.css';

//artist screens
import { 
    ArtistHome, 
    Portfolio, 
    Appointments,
    ViewAppointment,
    SetSchedule,
    AddService,
    EditService,
    CreateCustomService,
    EditCustomService,
    Book as ArtistBook,
    ViewClient,
    Balance,
    ToScheduling,
    Upgrade,
    UpgradeIdInfo,
    MonthStats,
    DeleteProfile as ArtistDeleteProfile
} from './artistScreens';
import { 
    ArtistProfile, 
    Book, 
    ViewAppointment as ClientViewAppointment,
    PaymentInfo as ClientPaymentInfo,
    DeleteProfile as ClientDeleteProfile,
    Intro
} from './clientScreens';
import { isMobile, showPWADialog } from '../util/DeviceUtil';

class Router extends Component {

    renderArgenda = () => {
        return (
            <HashRouter>
                <div className='Router-Container'>
                    {this.renderSwitch()}
                    {this.props.profile && <ArtistNotiSystem /> }
                    {this.props.profile && <ImageProgress /> }
                    {this.props.clientProfile && <ClientNotiSystem />}
                </div>
            </HashRouter>
        )
    }

    renderIOSPWADialog = () => {
        return (
            <PWA />
        )
    }

    renderSwitch = () => {
        return (
            <Switch>
                <Route exact path={`${CLIENT.CLIENT_DELETE_PROFILE}`}>
                    <ClientDeleteProfile />
                </Route>
                <Route exact path={`${CLIENT.CLIENT_PAYMENT_INFO}`}>
                    <ClientPaymentInfo />
                </Route>
                <Route path={`${CLIENT.CLIENT_VIEW_APPOINTMENT}/:appointmentId`}>
                    <ClientViewAppointment />
                </Route>
                <Route path={`${CLIENT.CLIENT_BOOK}/:artistId/:serviceId`}>
                    <Book />
                </Route>
                <Route path={`${CLIENT.CLIENT_BOOK}/:artistId`}>
                    <Book />
                </Route>
                <Route path={`${CLIENT.CLIENT_VIEW_ARTIST}/:artistId`}>
                    <ArtistProfile />
                </Route>
                <Route exact path={ARTIST.ARTIST_DELETE_PROFILE}>
                    <ArtistDeleteProfile />
                </Route>
                <Route exact path={ARTIST.ARTIST_TO_UPGRADE_ID}>
                    <UpgradeIdInfo />
                </Route>
                <Route exact path={ARTIST.ARTIST_TO_UPGRADE}>
                    <Upgrade />
                </Route>
                <Route exact path={ARTIST.ARTIST_TO_SCHEDULING}>
                    <ToScheduling />
                </Route>
                <Route exact path={ARTIST.ARTIST_BOOK}>
                    <ArtistBook />
                </Route>
                <Route path={ARTIST.ARTIST_VIEW_BALANCE}>
                    <Balance />
                </Route>
                <Route path={ARTIST.ARTIST_STATS}> 
                    <MonthStats />
                </Route>
                <Route path={`${ARTIST.ARTIST_VIEW_CLIENT}/:clientId`}>
                    <ViewClient />
                </Route>
                <Route path={`${ARTIST.ARTIST_EDIT_CUSTOM_SERVICE}/:artistId/:serviceId/:clientId`}>
                    <EditCustomService />
                </Route>
                <Route exact path={`${ARTIST.ARTIST_CREATE_CUSTOM_SERVICE}`}>
                    <CreateCustomService />
                </Route>
                <Route exact path={`${ARTIST.ARTIST_VIEW_APPOINTMENT}/:appointmentId`}>
                    <ViewAppointment />
                </Route>
                <Route exact path={ARTIST.ARTIST_VIEW_APPOINTMENTS}>
                    <Appointments />
                </Route>
                <Route exact path={`${ARTIST.ARTIST_EDIT_SERVICE}/:serviceId`}>
                    <EditService />
                </Route>
                <Route exact path={ARTIST.ARTIST_ADD_SERVICE}>
                    <AddService />
                </Route>
                <Route exact path={`${ARTIST.ARTIST_SET_SCHEDULE}/:day`}>
                    <SetSchedule />
                </Route>
                <Route exact path={ARTIST.ARTIST_PORTFOLIO}>
                    <Portfolio />
                </Route>
                <Route exact path={ARTIST.ARTIST_HOME}>
                    <ArtistHome />
                </Route>
                <Route exact path={SIGNUP.SPASH}>
                    <Intro />
                </Route>
            </Switch>
        )
    }

    renderDesktopDialog = () => {
        return (
            <DesktopDialog />
        )
    }

    renderDecide = () => {
        if (!isMobile()) {
            return this.renderDesktopDialog();
        }
        if (showPWADialog()) {
            return this.renderIOSPWADialog();
        }
        return this.renderArgenda();
    }

    render = () => {
        return this.renderDecide();
    }
}

const mapSateToProps = (state) => {
    const { profile } = state.artist;
    const { profile: clientProfile } = state.client;
    return { profile, clientProfile }
}

export default connect(mapSateToProps, { userLoggedIn, userLoggedOut })(Router);