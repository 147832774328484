import React from 'react';
import PropTypes from 'prop-types';
import './styles/ListWrapper.css';

const ListWrapper = ({ children, style }) => {
    return (
        <div style={style} className='ListWrapper-Body'>
            <div className='ListWrapper-Container'>
                {children}
            </div>
        </div>

    )
}

ListWrapper.propTypes = {
    style: PropTypes.object
};

ListWrapper.defaultProps = {
    style: {}
}

export default ListWrapper;