import React from 'react';
import PropTypes from 'prop-types';
import './styles/Message.css';

const Message = ({ message, error }) => {

    const decideClass = () => {
        if (error) {
            return 'Message-Container Message-ErrorContainer';
        }
        return 'Message-Container';
    }

    return (
        <div className={decideClass()}>
            <p className='ErrorMessage-message'>{message}</p>
        </div>
    )
}

Message.propTypes = {
    message: PropTypes.string,
    error: PropTypes.bool,
};

Message.defaultProps = {
    message: '',
    error: false,
}

export default Message;