import React from 'react';
import PropTypes from 'prop-types';
import './styles/Placeholder.css';

const Placeholder = ({ placeholder }) => {
    return (
        <p className='Placeholder-label'>{placeholder}</p>
    )
}

Placeholder.propTypes = {
    placeholder: PropTypes.string
}

export default Placeholder;