import React from 'react';
import PropTypes from 'prop-types';
import { 
    BigTitle, 
    Body, 
    BrandButton, 
    ModalRowBack,
    Bullet,
    Scroll
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const PaymentDetailsForm = ({ onBack, onNext }) => {
    return ( 
        <Body>
            <ModalRowBack 
                title='Back'
                onBack={onBack}
            />
            <Body>
                <Scroll>
                    <BigTitle 
                        title='Scheduling + Payments Details'
                    />
                    <Bullet 
                        style={styles.margin20}
                        label='Allow your clients to pay you through Argenda'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='Get paid direct deposits into your account via a Debit Card.'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='Get annual info for tax deductions'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='Get monthly dashboards to see your growth.'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='No Show and Cancellation Protection'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='Your first payout typically becomes available after 7 business days. This delay allows Argenda to mitigate the risks inherent in providing credit services.'
                    />
                    <Bullet 
                        style={styles.margin15}
                        label='When a client pays you, the payment goes into a pending balance.'
                    />
                    <Bullet 
                        label='It takes 2 days from the transaction date for the pending amount to become available.'
                        style={styles.margin15}
                    />
                    <Bullet 
                        label='Once an amount becomes available, it is instantly paid out and direct deposited into your bank account via Debit Card.'
                        style={styles.margin15}
                    />
                </Scroll>
            </Body>
            <BrandButton 
                label='Continue'
                onClick={onNext}
                style={BOTTOM_BUTTON}
            />
        </Body>
    )
}

const styles = {
    margin20: {
        marginTop: 20
    },
    margin15: {
        marginTop: 15
    },
}

PaymentDetailsForm.propTypes = {
    onBack: PropTypes.func,
    onNext: PropTypes.func
};

PaymentDetailsForm.defaultProps = {
    onBack: () => {},
    onNext: () => {}
};

export default PaymentDetailsForm;