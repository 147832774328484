import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import './styles/AddressSearch.css';
import { IoChevronBackOutline } from 'react-icons/io5';

let timer = '';

const AddressSearch = ({ onSearch, onBack, startLoading, delay }) => {

    const [search, searchChange] = useState('');

    const onChange = (e) => {
        searchChange(e.target.value);
    }

    const sendSearch = () => {
        onSearch(search);
    }

    const onKeyDown = () => {
        startLoading(true);
        if (timer) {
            clearTimeout(timer);
        }
    }

    const onKeyUp = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(sendSearch, delay)
    }

    return (
        <div className='AS-SearchRow'>
            <IoChevronBackOutline 
                className='AS-Back'
                onClick={onBack}
            />
            <input 
                className='AS-Search'
                placeholder='Search Address...'
                value={search}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
            />
        </div>
    )
}

AddressSearch.propTypes = {
    onSearch: PropTypes.func,
    onBack: PropTypes.func,
    startLoading: PropTypes.func,
    delay: PropTypes.number,
};

AddressSearch.defaultProps = {
    onSearch: () => {},
    onBack: () => {},
    startLoading: () => {},
    delay: 500
};

export default AddressSearch;