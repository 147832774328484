export const ARTIST_APPOINTMENT = 'NEW_APPOINTMENT';
export const ARTIST_REVIEW = 'ARTIST_REVIEW';
export const ARTIST_CANCEL = 'ARTIST_CANCEL';
export const ARTIST_RESCHEDULE = 'ARTIST_RESCHEDULE';
export const ARTIST_NEW_CLIENT = 'ARTIST_NEW_CLIENT';
export const ARTIST_PAYMENT = 'ARTIST_PAYMENT';
export const ARTIST_PAYOUT = 'ARTIST_PAYOUT';
export const ARTIST_NOSHOW = 'ARTIST_NOSHOW';

export const CLIENT_APPOINTMENT = 'CLIENT_APPOINTMENT';
export const CLIENT_CANCEL = 'CLIENT_CANCEL';
export const CLIENT_RESCHEDULE = 'CLIENT_RESCHEDULE';
export const CLIENT_PAYMENT = 'CLIENT_PAYMENT';
export const CLIENT_NOSHOW = 'CLIENT_NOSHOW';

export const translateToTitle = (type) => {
    switch (type) {
        case ARTIST_NOSHOW:
        case CLIENT_NOSHOW:
            return 'No Show';
        case ARTIST_APPOINTMENT:
        case CLIENT_APPOINTMENT:
            return 'New Appointment';
        case ARTIST_REVIEW:
            return 'New Rating';
        case ARTIST_CANCEL:
        case CLIENT_CANCEL:
            return 'Cancellation';
        case ARTIST_RESCHEDULE:
        case CLIENT_RESCHEDULE:
            return 'Reschedule';
        case ARTIST_NEW_CLIENT:
            return 'New Client';
        case ARTIST_PAYMENT:
        case CLIENT_PAYMENT:
            return 'New Payment';
        case ARTIST_PAYOUT:
            return 'New Deposit';
        case CLIENT_APPOINTMENT:
            return 'New Appointment';
    }
}