import React from 'react';
import PropTypes from 'prop-types';
import './styles/Client.css';

const Client = ({ client, onClick }) => {

    const click = () => {
        onClick(client);
    }

    return (
        <div onClick={click} className='Client-Container'>
            <h3 className='Client-Name'>{client.name}</h3>
        </div>
    )
}

Client.propTypes = {
    client: PropTypes.object,
    onClick: PropTypes.func,
};

Client.defaultProps = {
    client: {},
    onClick: () => {}
}

export default Client;