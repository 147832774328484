import { addDoc, collection, doc, getDoc, getFirestore } from "@firebase/firestore";
import axios from "axios";
import { translateToTitle } from "../constants/NotiTypes"
import { BOTH, IN_APP, TEXT } from "../constants/Policies";
import { SEND_TEXT } from '../constants/ArgendaServerURLS';

export const buildNoti = (type, preview, desc, link, destId) => {
    const title = translateToTitle(type);
    return {
        type,
        preview,
        title,
        desc,
        link,
        destId,
        shown: false,
        time: new Date().getTime()
    }
}

export const buildText = (phone, desc) => {
    return {
        body: `${desc}`,
        to: phone
    }
}

export const fetchNotiHash = async (artistId) => {
    const db = getFirestore();
    const ref = doc(db, `users/${artistId}/notificationHash/notificationHash`);
    const snap = await getDoc(ref);
    return snap.data();
}

export const fetchClientNotiHash = async (clientId) => {
    const db = getFirestore();
    const ref = doc(db, `clients/${clientId}/notificationHash/notificationHash`);
    const snap = await getDoc(ref);
    return snap.data();
}

export const handleNoti = async (phone, desc, preview, type, destId, method, link, isClient = false) => {
    if (method === IN_APP) {
        if (isClient) {
            await handleInAppClient(type, preview, desc, link, destId);
        } else {
            await handleInApp(type, preview, desc, link, destId);
        }
    }
    if (method === TEXT) {
        await handleText(phone, desc, link);
    }
    if (method === BOTH) {
        if (isClient) {
            await handleInAppClient(type, preview, desc, link, destId);
        } else {
            await handleInApp(type, preview, desc, link, destId);
        }
        await handleText(phone, desc, link);
    }
}

export const handleInApp = async (type, preview, desc, link, destId) => {
    const noti = buildNoti(type, preview, desc, link, destId);
    const ref = collection(getFirestore(), `users/${destId}/notifications`);
    await addDoc(ref, noti);
}

export const handleInAppClient = async (type, preview, desc, link, destId) => {
    const noti = buildNoti(type, preview, desc, link, destId);
    const ref = collection(getFirestore(), `clients/${destId}/notifications`);
    await addDoc(ref, noti);
}

export const handleText = async (phone, desc) => {
    const text = buildText(phone, desc);
    await axios.post(SEND_TEXT, text);
}