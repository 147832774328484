import React from 'react';
import PropTypes from 'prop-types';
import './styles/ArtistCharge.css';
import { convertUnixTimeToReadable } from '../util/TimeUtil';

const ArtistCharge = ({ charge, onClick }) => {

    const click = () => {
        onClick(charge);
    }

    return (
        <div id={charge.id} onClick={click} className='ArtistCharge-Container'>
            <h2 className='ArtistCharge-Amount'>{`$${((charge.amount - charge.application_fee_amount) / 100).toFixed(2)}`}</h2>
            <h5 className='ArtistCharge-Desc'>{charge.description}</h5>
            <h5 className='ArtistCharge-Created'>{convertUnixTimeToReadable(charge.created * 1000)}</h5>
        </div>
    )
}

ArtistCharge.propTypes = {
    charge: PropTypes.object,
    onClick: PropTypes.func,
};

ArtistCharge.defaultProps = {
    charge: {},
    onClick: () => {}
}

export default ArtistCharge;