
import { combineReducers } from 'redux';
import artistReducer from './artistReducer';
import scheduleReducer from './scheduleReducer';
import serviceReducer from './serviceReducer';
import clientReducer from './clientReducer';
import facingReducer from './facingReducer';


const reducers = combineReducers({
    artist: artistReducer,
    schedule: scheduleReducer,
    service: serviceReducer,
    client: clientReducer,
    facing: facingReducer
});

export default reducers;