import React from 'react';
import PropTypes from 'prop-types'
import './styles/ProfileImage.css';

const ProfileImage = ({ id, size, image, onClick, style }) => {
    const url = image ? `https://argenda.imgix.net/${id}/profileImage/${image}?maskbg=0fff&dpr=${window.devicePixelRatio}&fm=webp&w=${size}&h=${size}` : `https://argenda.imgix.net/defaults/Placeholder.svg?maskbg=0fff&dpr=${window.devicePixelRatio}&fm=webp&w=${size}&h=${size}`;
    const outerSize = size + 10;
    return (
        <div 
            className='ProfileImage-Container'
            style={{ ...style, width: outerSize, height: outerSize }}
            onClick={onClick}
        >
            <img
                className='ProfileImage-Inner'
                src={url}
                style={{ width: size, height: size }}
            />
        </div>
    )
}

ProfileImage.propTypes = {
    id: PropTypes.string,
    size: PropTypes.number,
    image: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
}

ProfileImage.defaultProps = {
    id: '',
    size: 150,
    image: '',
    onClick: () => {},
    style: {}
}

export default ProfileImage;