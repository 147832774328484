import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    FlexRow,
    FlexRowButton,
    Select,
    BrandButton,
    SettingsTitle,
    BigTitle,
    GenOption,
    Value,
    ButtonLabel
} from '.';
import { 
    APPOINTMENT, 
    CANCEL, 
    RESCHEDULE, 
    REVIEW, 
    CLIENT as CLIENT_TYPE,  
    PAYMENT,
    PAYOUT,
    METHODS,
    translateMethod,
} from '../constants/Policies';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { 
    doc, 
    getDoc, 
    getFirestore, 
    setDoc, 
    updateDoc 
} from '@firebase/firestore';

class UpdateCancel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: props.notiHash && props.notiHash[props.type] ? props.notiHash[props.type] : '',
            left: 0,
            optionLeft: window.screen.width,
        }
    }

    change = ({ value }) => {
        this.setState({ type: value });
        this.navToContent();
    }

    focusCancel = () => {
        if (this.cancelRef) {
            this.cancelRef.focus();
        }
    }

    doesExists = async (db) => {
        const ref = doc(db, `users/${this.props.profile.id}/notificationHash/notificationHash`);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return true;
        }
        return false;
    }

    navToOption = () => {
        this.setState({ left: -window.screen.width, optionLeft: 0 });
    }

    navToContent = () => {
        this.setState({ left: 0, optionLeft: window.screen.width });
    }

    save = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/notificationHash/notificationHash`);
        const doesExists = await this.doesExists(db);
        if (doesExists) {
            await updateDoc(ref, {
                [this.props.type]: this.state.type
            });
        } else {
            await setDoc(ref, {
                [this.props.type]: this.state.type
            })
        }
        this.props.onBack();
    }

    translateTitle = () => {
        switch (this.props.type) {
            case CANCEL:
                return 'When a client cancels an appointment with you, how would you like to be notified?';
            case APPOINTMENT:
                return 'When a client books an appointment with you, how would you like to be notified?';
            case RESCHEDULE:
                return 'When a client reschedules an appointment with you, how would you like to be notified?';
            case REVIEW:
                return 'When a client leaves a review for you, how would you like to be notified?';
            case CLIENT_TYPE:
                return 'When you get a new client, how would you like to be notified?'
            case PAYOUT:
                return 'When you recieve a direct deposit from Argenda, how would you like to be notified?';
            case PAYMENT:
                return 'When a client pays you for a service, how would you like to be notified?';
        }
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title={'Notification Settings'}
                onBack={this.props.onBack}
            />
        )
    }

    renderSelect2 = () => {
        return (
            <FlexRow>
                <FlexRowButton
                    style={styles.button}
                    onClick={this.focusCancel}
                >
                    <Select 
                        label='Notification Type'
                        passRefUp={ref => this.cancelRef = ref}
                        value={this.state.type}
                        onChange={this.change}
                        options={METHODS}
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSelect = () => {
        const value = translateMethod(this.state.type)
        return (
            <FlexRowButton
                style={styles.button}
                onClick={this.navToOption}
            >
                <Value value={value} />
                <ButtonLabel label='Notification Type' />
            </FlexRowButton>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                onClick={this.save}
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title={this.translateTitle()}
            />
        )
    }

    renderContent = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.left }}
            >
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderSelect()}
                {this.renderSave()}
            </Body>
        )
    }

    renderMethod = (method, index) => {
        return (
            <GenOption 
                key={index}
                option={method}
                label={method.label}
                onClick={this.change}
            />
        )
    }

    renderOptionsBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.optionLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={this.navToContent}
                />
                <BigTitle title='Pick an Option' />
                {METHODS.map(this.renderMethod)}
            </Body>
        )
    }

    render = () => {
        return (
            <Body relative>
                {this.renderContent()}
                {this.renderOptionsBody()}
            </Body>
        )
    }
}

const styles = {
    button: {
        width: '95%'
    }
}

UpdateCancel.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    type: PropTypes.string,
    notiHash: PropTypes.object
};

UpdateCancel.defaultProps = {
    onBack: () => {},
    profile: {},
    type: '',
    notiHash: {}
}

export default UpdateCancel;