import React from 'react';
import PropTypes from 'prop-types';
import { 
    ButtonLabel, 
    FlexRow, 
    FlexRowButton, 
    InputLabel
} from '.';
import { convertNumberToReadable } from '../util/TimeUtil';
import { convertDateStringToReadable } from '../util/DateUtil';

const SlotButton = ({ slot, onClick, showEndTime, rescheduleMode, rescheduledSlot }) => {

    const decideTimeString = () => {
        if (rescheduleMode && rescheduledSlot) {
            if (showEndTime) {
                return `${convertNumberToReadable(rescheduledSlot.slot.startTime)} - ${convertNumberToReadable(rescheduledSlot.slot.endTime)}`
            }
            return `${convertNumberToReadable(rescheduledSlot.slot.startTime)}`
        }
        if (showEndTime) {
            return `${convertNumberToReadable(slot.slot.startTime)} - ${convertNumberToReadable(slot.slot.endTime)}`
        }
        return `${convertNumberToReadable(slot.slot.startTime)}`
    }

    const decideDateString = () => {
        if (rescheduleMode && rescheduledSlot) {
            return `${convertDateStringToReadable(rescheduledSlot.selectedDay.dateString, true)}`;
        }
        return `${convertDateStringToReadable(slot.selectedDay.dateString, true)}`;
    }

    const renderDoubleSlot = () => {
        return (
            <FlexRowButton style={styles.marginBottom} onClick={onClick}>

            </FlexRowButton>
        )
    }

    const renderInfo = () => {
        if (rescheduleMode) {
            if (rescheduledSlot) {
                return <InputLabel label='Rescheduled Date and Time' />
            }
            return <InputLabel label='Click to Reschedule' />
        }
        if (slot) {
            return <InputLabel label='Tap to change Date and Time' />
        }
    }

    const renderSingleSlot = () => {
        const timeString = decideTimeString();
        const dateString = decideDateString();
        return (
            <FlexRowButton style={styles.marginBottom} onClick={onClick}>
                <ButtonLabel style={styles.margin} label={dateString} />
                <ButtonLabel label={timeString} />
                {renderInfo()}
            </FlexRowButton>
        )
    }

    const renderNoSlot = () => {
        return (
            <FlexRowButton style={styles.marginBottom} onClick={onClick}>
                <ButtonLabel label='Pick a date and time' />
            </FlexRowButton>
        )
    }

    const renderDecide = () => {
        if (!slot) {
            return renderNoSlot();
        }
        if (slot.doubleBreak) {
            return renderDoubleSlot();
        }
        return renderSingleSlot();
    }

    return (
        <FlexRow>
            {renderDecide()}
        </FlexRow>
    )
}

const styles = {
    margin: {
        marginBottom: 10
    },
    marginBottom: {
        marginBottom: 10,
    }
}

SlotButton.propTypes = {
    slot: PropTypes.object,
    onClick: PropTypes.func,
    showEndTime: PropTypes.bool,
    rescheduleMode: PropTypes.bool,
    rescheduledSlot: PropTypes.object
};

SlotButton.defaultProps = {
    slot: null,
    onClick: () => {},
    showEndTime: false,
    rescheduleMode: false,
    rescheduledSlot: null
}

export default SlotButton;