import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchFirstImages,
    fetchMoreImages,
} from '../actions/artistActions';
import PropTypes from 'prop-types';
import { BottomUpModal, PortfolioImage } from '../components';
import './styles/PortfolioPicker.css';
import { getThumbnailSize } from '../util/ImageUtil';

class PortfolioPicker extends Component {

    componentDidMount = () => {
        window.addEventListener('touchend', this.onScroll);
        if (!this.props.noMoreImages) {
            this.props.fetchFirstImages();
        }
    }

    onScroll = (e) => {
        if (this.containerRef && this.containerRef.contains(e.target)) {
            const scrollData = document.getElementById('inner2').getBoundingClientRect();
            const threshold = window.screen.height - (getThumbnailSize() * 3);
            if (scrollData.bottom >= threshold && scrollData.bottom <= window.screen.height && !this.props.noMoreImages) {
                this.props.fetchMoreImages(this.props.imagesCursor)
            }
        }
    }

    onSave = (image) => {
        this.props.onSave(image);
        this.props.onClose();
    }

    renderImage = (image, index) => {
        return (
            <PortfolioImage 
                key={index}
                image={image}
                id={this.props.profile.id}
                index={index}
                onClick={this.onSave}
            />
        )
    }

    renderBody = () => {
        return (
            <div 
                className='PortfolioPicker-OuterContainer'
                ref={ref => this.containerRef = ref}
            >
                <div 
                    className='PortfolioPicker-Container'
                    id='inner'
                >
                    <div 
                        className='PortfolioPicker-Inner'
                        id='inner2'
                    >
                        {this.props.images.map(this.renderImage)}
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={.90}
                title='Pick an Image'
            >
                {this.renderBody()}
            </BottomUpModal>
        )
    }
}

PortfolioPicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func
};

PortfolioPicker.defaultProps = {
    open: false,
    onClose: () => {},
    onSave: () => {}
};

const mapStateToProps = (state) => {
    const { 
        profile,
        images,
        imagesCursor,
        noMoreImages
    } = state.artist;
    return {
        profile,
        images,
        imagesCursor,
        noMoreImages
    }
}

export default connect(mapStateToProps, {
    fetchFirstImages,
    fetchMoreImages
})(PortfolioPicker);