import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { artistPropChange, clearArtist } from '../actions/artistActions';
import { clearService } from '../actions/serviceActions';
import { clearSchedule } from '../actions/scheduleActions';
import { 
    BottomUpModal,
    Option,
    UpdateFees,
    ArtistAccount,
    UpdateNoti,
    ArtistNotis,
    Body,
    BigTitle
} from '.';
import { 
    BsBoxArrowLeft, 
    BsCalendar, 
    BsGearFill, 
    BsFillBellFill
} from 'react-icons/bs';
import { 
    IoBarChartOutline, 
    IoNotificationsCircle, 
} from 'react-icons/io5';
import { 
    OPTION_CANCEL, 
    OPTION_NO_SHOW, 
    OPTION_RESCHEDULE 
} from '../universalStyles/Styles';
import './styles/ArtistSettingsModal.css';
import { 
    getAuth, 
    signOut, 
} from 'firebase/auth';
import { ARTIST, SIGNUP } from '../constants/ClientRoutes';
import { FaDollarSign } from 'react-icons/fa';

class ArtistSettingsModal extends Component {

    close = () => {
        this.props.artistPropChange('settingsOpen', false);
        this.props.artistPropChange('appointmentPoliciesOpen', false);
        this.props.artistPropChange('notificationPoliciesOpen', false);
        this.props.artistPropChange('accountOpen', false);
        this.props.artistPropChange('notisOpen', false);
    }

    navToBalance = () => {
        window.location.hash = ARTIST.ARTIST_VIEW_BALANCE;
    }

    navToStats = () => {
        window.location.hash = ARTIST.ARTIST_STATS;
    }

    logout = () => {
        const auth = getAuth();
        signOut(auth);
        this.props.clearArtist();
        this.props.clearService();
        this.props.clearSchedule();
        window.location.hash = SIGNUP.SPASH;
    }

    toggleAppointmentPolicies = () => {
        this.props.artistPropChange('appointmentPoliciesOpen', !this.props.appointmentPoliciesOpen);
    }

    toggleNotificationsPolicies = () => {
        this.props.artistPropChange('notificationPoliciesOpen', !this.props.notificationPoliciesOpen);
    }

    toggleAccount = () => {
        this.props.artistPropChange('accountOpen', !this.props.accountOpen);
    }

    toggleNotis = () => {
        this.props.artistPropChange('notisOpen', !this.props.notisOpen);
    }

    renderAppointmentPolicies = () => {
        return (
            <UpdateFees 
                profile={this.props.profile}
                policyHash={this.props.policyHash}
                onBack={this.toggleAppointmentPolicies}
            />
        )
    }

    renderNotificationPolicies = () => {
        return (
            <UpdateNoti 
                profile={this.props.profile}
                onBack={this.toggleNotificationsPolicies}
                notiHash={this.props.notificationHash}
            />
        )
    }

    renderAccount = () => {
        return (
            <ArtistAccount 
                profile={this.props.profile}
                onBack={this.toggleAccount}
            />
        )
    }

    renderAppointmentFees = () => {
        if (this.props.profile.stripeAccountId) {
            return (
                <Option 
                    label='Appointment Fees'
                    icon={<BsCalendar />}
                    backgroundColor={OPTION_NO_SHOW}
                    showArrow
                    onClick={this.toggleAppointmentPolicies}
                />
            )
        }
    }

    renderCardAndAccount = () => {
        if (this.props.profile.stripeAccountId) {
            return (
                <Option 
                    label='Card & Account Balance'
                    icon={<FaDollarSign />}
                    backgroundColor='#51DB2C'
                    onClick={this.navToBalance}
                />
            )
        }
    }

    renderSettings = () => {
        return (
            <Body>
                <BigTitle title='Settings' />
                <Option 
                    label='Account'
                    icon={<BsGearFill />}
                    backgroundColor={OPTION_RESCHEDULE}
                    showArrow
                    onClick={this.toggleAccount}
                />
                <Option 
                    label='Notifications'
                    icon={<BsFillBellFill />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={this.toggleNotis}
                    badgeCount={this.props.notis.length}
                />
                {this.renderAppointmentFees()}
                <Option 
                    label='Notification Options'
                    icon={<IoNotificationsCircle />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={this.toggleNotificationsPolicies}
                />
                {this.renderCardAndAccount()}
                <Option 
                    label='Analytics'
                    icon={<IoBarChartOutline />}
                    backgroundColor='#D735AF'
                    onClick={this.navToStats}
                />
                <Option 
                    label='Sign Out'
                    icon={<BsBoxArrowLeft />}
                    backgroundColor={OPTION_CANCEL}
                    onClick={this.logout}
                />
            </Body>

        )
    }

    renderNotis = () => {
        return (
            <ArtistNotis 
                onBack={this.toggleNotis}
                profile={this.props.profile}
                notis={this.props.notis}
            />
        )
    }

    renderDecide = () => {
        if (this.props.appointmentPoliciesOpen) {
            return this.renderAppointmentPolicies();
        }
        if (this.props.notificationPoliciesOpen) {
            return this.renderNotificationPolicies();
        }
        if (this.props.accountOpen) {
            return this.renderAccount();
        }
        if (this.props.notisOpen) {
            return this.renderNotis();
        }
        return this.renderSettings();
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.settingsOpen}
                onClose={this.close}
                height={.85}
                image={this.props.image}
                darken
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        profile,
        settingsOpen,
        appointmentPoliciesOpen,
        notificationPoliciesOpen,
        accountOpen,
        policyHash,
        notificationHash,
        notis,
        notisOpen,
    } = state.artist;
    return {
        profile,
        settingsOpen,
        appointmentPoliciesOpen,
        notificationPoliciesOpen,
        accountOpen,
        policyHash,
        notificationHash,
        notis,
        notisOpen,
    }
}

ArtistSettingsModal.propTypes = {
    image: PropTypes.string,
};

ArtistSettingsModal.defaultProps = {
    image: ''
};

export default connect(mapStateToProps, {
    artistPropChange,
    clearArtist,
    clearService,
    clearSchedule
})(ArtistSettingsModal);