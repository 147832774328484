import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    facingPropChange, 
    facingCloseSearch, 
    facingPillSearch,
    facingPillPage
} from '../../actions/facingActions';
import { clientPropChange, fetchAppointments } from '../../actions/clientActions';
import { userLoggedIn } from '../../actions/authActions';
import {
    AppointmentModal,
    ArgendaLogo,
    Artist2,
    Body,
    BrandButton,
    ClientAvatar,
    ClientSettingsModal,
    ImageBackground,
    ImagesContainer,
    Pill,
    PortfolioImage,
    Scroll,
    SignInModal,
    SelectedImageModal,
    Empty
} from '../../components';
import { IoSearch } from 'react-icons/io5';
import './styles/Intro.css';
import { CLIENT, SIGNUP } from '../../constants/ClientRoutes';
import { searchArtists } from '../../util/AlgoliaUtil';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { THRESHOLD } from '../../constants/ScrollThreshold';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import axios from 'axios';
import { DELETE_CONNECT_ACCOUNT } from '../../constants/ArgendaServerURLS';
import { PROFESSIONS } from '../../constants/ArtistProfessions';

const SEARCHES = [
    "Men's Hair",
    "Women's Hair",
    "Tattoos",
    "Eyelashes",
    "Nails",
    "Massage"
];

const IMAGE_SCROLL = 'IMAGE_SCROLL';

class Intro extends Component {

    constructor(props) {
        super(props);
        this.state = {
            signInOpen: false,  
            selectedImageIndex: -1,
            coords: null,
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchend', this.onScroll)
    }

    componentDidMount = () => {
        this.getCurrentPos();
        window.addEventListener('touchend', this.onScroll)
        this.startAuthListener();
    }

    deleteAccounts = async () => {
        /*
        const accountsToDelete = ['acct_1LgcgIQgUUlaaG3o', 'acct_1LgJGQQgd9e3OmV3'];
        for (let i = 0; i < accountsToDelete.length; i++) {
            const stripeAccount = accountsToDelete[i];
            await axios.post(DELETE_CONNECT_ACCOUNT, { stripeAccount });
        }
        */
    }

    componentDidUpdate = (prevProps) => {
        const { searchOpen, profile } = this.props;
        const { searchOpen: prevSearchOpen, profile: prevProfile } = prevProps;
        if (searchOpen && !prevSearchOpen) {
            window.setTimeout(this.toggleSearchBar, 50)
        }
        if (!searchOpen && prevSearchOpen) {
            this.toggleSearchBar()
        }
        if (!prevProfile && profile) {
            this.props.fetchAppointments();
        }
    }

    getCurrentPos = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { enableHighAccuracy: true, timeout: 1000 });
        }
    }
    
    getCoords = (event) => {
        this.setState({
            coords: {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude
            }
        });
        window.setTimeout(this.fetchInitialImages, 100);
    }

    getCoordsError = (error) => {
        this.fetchInitialImages();
    }

    startAuthListener = () => {
        const auth = getAuth();
        const unsub = onAuthStateChanged(auth, user => {
            if (user) {
                this.props.userLoggedIn(user.uid);
            }
        });
    }

    fetchInitialImages = () => {
        if (this.props.images.length === 0) {
            this.searchImages('');
        }
    }

    onScroll = async (e) => {
        const scrollRef = document.getElementById(IMAGE_SCROLL);
        if (scrollRef && scrollRef.contains(e.target) && this.props.images.length > 0) {
            const lastImageId = `image-${this.props.images.length - 1}`;
            const query = document.getElementById(lastImageId);
            const lastImageTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + THRESHOLD
            if (lastImageTop <= threshold) {
                this.fetchMoreImages();
            }
        }

    }

    fetchMoreImages = () => {
        this.props.facingPillPage(this.props.pillSearch, this.state.coords, this.props.imagePage + 1, this.props.images);
    }

    selectedImageChange = (selectedImageIndex) => {
        this.setState({ selectedImageIndex });
        if (selectedImageIndex === this.props.images.length - 1) {
            this.fetchMoreImages();
        }
    }

    closeSelectedImageModal = () => {
        this.setState({ selectedImageIndex: -1 });
    }

    toggleSignIn = () => {
        this.setState({ signInOpen: !this.state.signInOpen });
    }

    toggleAppointmentModal = () => {
        this.props.clientPropChange('appointmentModalOpen', !this.props.appointmentModalOpen);
    }

    searchChange = async (e) => {
        const search = e.target.value;
        this.props.facingPropChange('search', search);
        if (search) {
            const results = await searchArtists(e.target.value, this.state.coords);
            this.props.facingPropChange('searchResults', results);
        } else {
            this.props.facingPropChange('searchResults', []);
        }
    }

    toggleClientSettings = () => {
        if (this.props.notis.length > 0) {
            this.props.clientPropChange('settingsOpen', !this.props.settingsOpen)
            this.props.clientPropChange('showNotis', true);
        } else {
            this.props.clientPropChange('settingsOpen', !this.props.settingsOpen)
        }

    }

    searchImages = async (pillSearch) => {
        this.props.facingPillSearch(pillSearch, this.state.coords);
    }

    toggleSearchBar = () => {
        this.props.facingPropChange('searchBarOpen', !this.props.searchBarOpen);
    }

    toggleShowSearch = () => {
        this.props.facingPropChange('searchOpen', !this.props.searchOpen);
    }

    navToSignUp = () => {
        window.location.hash = SIGNUP.SIGNUP_AUTH;
    }

    navToArtist = (artist) => {
        window.location.hash = `${CLIENT.CLIENT_VIEW_ARTIST}/${artist.objectID}`;
    }

    renderSignInButton = () => {
        return (
            <BrandButton 
                label='Sign In'
                style={styles.signUp}
                onClick={this.toggleSignIn}
            />
        )
    }

    renderAvatar = () => {
        return (
            <ClientAvatar 
                size={40}
                style={{ marginLeft: 10 }}
                profile={this.props.profile}
                onClick={this.toggleClientSettings}
                settingsBadgeCount={this.props.notis.length}
            />
        )
    }

    renderDecideSignIn = () => {
        if (!this.props.profile) {
            return this.renderSignInButton();
        }
        return this.renderAvatar();
    }

    renderRightHeader = () => {
        return (
            <div className='I-RightHeader'>
                <IoSearch 
                    className='I-SearchIcon' 
                    onClick={this.toggleShowSearch}
                />
                {this.renderDecideSignIn()}
            </div>
        )
    }

    renderHeader = () => {
        return (
            <div className='I-Header'>
                <ArgendaLogo style={styles.logo} />
                {this.renderRightHeader()}
            </div>
        );
    }

    renderSearchHeader = () => {
        return (
            <div className='I-Header I-SearchHeader'>
                {this.renderSearch()}
                {this.renderCancel()}
            </div>
        )
    }

    renderDecideHeader = () => {
        if (this.props.searchOpen) {
            return this.renderSearchHeader()
        }
        return this.renderHeader();
    }

    renderSearch = () => {
        return (
            <input 
                className={`I-Search ${this.props.searchBarOpen && 'I-SearchOpen'}`}
                placeholder='Search...'
                ref={ref => this.searchRef = ref}
                value={this.props.search}
                onChange={this.searchChange}
            />
        )
    }

    renderCancel = () => {
        return (
            <h5 
                className='I-Cancel'
                onClick={this.toggleShowSearch}
            >
                Cancel
            </h5>
        )
    }

    renderImage = (image, index) => {
        return (
            <PortfolioImage 
                key={index}
                image={image}
                id={image.artistId}
                elementId={`image-${index}`}
                onClick={() => this.selectedImageChange(index)}
            />
        )
    }

    renderMyAppointmentsButton = () => {
        if (this.props.profile) {
            return (
                <BrandButton 
                    style={styles.bottom}
                    label='My Appointments'
                    onClick={this.toggleAppointmentModal}
                />
            )
        }
    }

    renderImages = () => {
        return (
            <Scroll style={styles.scroll}>
                <ImagesContainer>
                    {this.props.images.map(this.renderImage)}
                </ImagesContainer>
            </Scroll>

        )
    }

    renderNoImages = () => {
        return (
            <Body center>
                <Empty 
                    message='There are no results...'
                />
            </Body>
        )
    }

    renderDecideImages = () => {
        if (this.props.images.length > 0) {
            return this.renderImages();
        }
        return this.renderNoImages();
    }

    renderContent = () => {
        return (
            <Body id={IMAGE_SCROLL}>
                {this.renderDecideImages()}
                {this.renderMyAppointmentsButton()}
            </Body>
        )
    }

    renderDecideSearchBody = () => {
        if (this.props.searchResults.length > 0) {
            return this.renderSearchBody();
        }
        return this.renderSearchEmptyBody();
    }

    renderSearchEmptyBody = () => {
        return (
            <Body relative center>
                <h2 className={`I-SearchMessage ${this.props.searchBarOpen && 'I-SearchMessageVisible'}`}>Search by Artist Name, Artist Type, Salon/Studio Name, Address, or Phone #</h2>
            </Body>
        )
    }

    renderArtist = (artist, index) => {
        return (
            <Artist2 
                artist={artist}
                key={index}
                onClick={this.navToArtist}
            />
        )
    }

    renderSearchBody = () => {
        return (
            <Scroll style={styles.scroll}>
                {this.props.searchResults.map(this.renderArtist)}
            </Scroll>
        )
    }

    renderDecideContent = () => {
        if (this.props.searchOpen) {
            return this.renderDecideSearchBody();
        }
        return this.renderContent();
    }

    renderSignInModal = () => {
        return (
            <SignInModal 
                open={this.state.signInOpen}
                onClose={this.toggleSignIn}
            />
        )
    }

    renderClientSettingsModal = () => {
        return (
            <ClientSettingsModal />
        )
    }

    renderAppointmentModal = () => {
        return (
            <AppointmentModal 
                open={this.props.appointmentModalOpen}
                onClose={this.toggleAppointmentModal}
                appointments={this.props.appointments}
            />
        )
    }

    renderPill = (pill, index) => {
        const selected = pill === this.props.pillSearch ? true : false;
        return (
            <Pill 
                pill={pill}
                key={index}
                onClick={this.searchImages}
                selected={selected}
            />
        )
    }

    renderPills = () => {
        if (!this.props.searchOpen) {
            return (
                <div className='I-PillRow'>
                    {PROFESSIONS.map(this.renderPill)}  
                </div>
            )
        }
    }

    renderSelectedImagedModal = () => {
        return (
            <SelectedImageModal 
                open={this.state.selectedImageIndex >= 0 ? true : false}
                images={this.props.images}
                currentIndex={this.state.selectedImageIndex}
                indexChange={this.selectedImageChange}
                onClose={this.closeSelectedImageModal}
            />
        )
    }

    render = () => {
        return (
            <ImageBackground 
                darken
                image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '')}
            >
                {this.renderDecideHeader()}
                {this.renderPills()}
                {this.renderDecideContent()}
                {this.renderSignInModal()}
                {this.renderClientSettingsModal()}
                {this.renderAppointmentModal()}
                {this.renderSelectedImagedModal()}
            </ImageBackground>
        )
    }
}

const styles = {
    signUp: {
        height: 30, 
        borderRadius: 15, 
        width: 'auto', 
        marginRight: 15
    },
    scroll: {
        marginTop: 15,
        marginBottom: 30
    },
    logo: {
        marginLeft: 10
    },
    bottom: {
        ...BOTTOM_BUTTON, 
        marginTop: 0, 
        marginBottom: 25
    }
}

const mapStateToProps = (state) => {
    const {
        searchBarOpen,
        searchOpen,
        searchResults,
        search,
        images,
        pillSearch,
        imagePage
    } = state.facing;
    const { 
        profile, 
        settingsOpen,
        appointments,
        appointmentModalOpen,
        notis
    } = state.client;
    return {
        searchBarOpen,
        searchOpen,
        searchResults,
        search,
        images,
        profile,
        settingsOpen,
        appointments,
        appointmentModalOpen,
        pillSearch,
        imagePage,
        notis
    }
}

export default connect(mapStateToProps, { 
    facingPropChange,
    facingCloseSearch,
    clientPropChange,
    fetchAppointments,
    facingPillSearch,
    facingPillPage,
    userLoggedIn
})(Intro);