import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/UpdateEmail.css';
import { 
    Body,
    ModalRowBack,
    BrandButton,
    SettingsTitle,
    SettingsLoader,
    EasyInput
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    updateEmail 
} from 'firebase/auth';
import { 
    UPDATE_EMAIL_FOR_CONNECT_ACCOUNT, 
    UPDATE_EMAIL_FOR_CUSTOMER_ACCOUNT 
} from '../constants/ArgendaServerURLS';
import axios from 'axios';

const INVALID_EMAIL = 'Invalid Email';
const INVALID_PASSWORD = 'Invalid Password';

const INVALID_EMAIL_ERROR = 'Firebase: Error (auth/invalid-email).';
const INVALID_PASSWORD_ERROR = 'Firebase: Error (auth/wrong-password).';

class UpdateEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: false,
            errorMessage: '',
            loading: false,
            success: false
        }
    }

    emailChange = (email) => {
        this.setState({ email });
    }

    passwordChange = (password) => {
        this.setState({ password });
    }

    updateConnect = async () => {
        const pack = {
            stripeAccountId: this.props.profile.stripeAccountId,
            stripeCustomerId: this.props.profile.stripeCustomerId,
            id: this.props.profile.id,
            email: this.state.email
        }
        await axios.post(UPDATE_EMAIL_FOR_CONNECT_ACCOUNT, pack);
    }

    updateNonConnect = async () => {
        const pack = {
            stripeCustomerId: this.props.profile.stripeCustomerId,
            id: this.props.profile.id,
            email: this.state.email
        }
        await axios.post(UPDATE_EMAIL_FOR_CUSTOMER_ACCOUNT, pack);
    }

    reauthAndUpdateEmail = async () => {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, this.props.profile.email, this.state.password);
        await updateEmail(auth.currentUser, this.state.email);
    }

    saveEmail = async () => {
        this.setState({ loading: true });
        try {
            //reauth and update email with firebase auth
            await this.reauthAndUpdateEmail();
            if (this.props.profile.stripeAccountId) {
                //connect account, update accordingly
                this.updateConnect();
            } else {
                //non connect account, update accordingly
                this.updateNonConnect();
            }
            //everything worked
            this.setState({ success: true });
        } catch (ex) {
            let errorMessage = '';
            if (ex.message === INVALID_EMAIL_ERROR) {
                errorMessage = INVALID_EMAIL;
            }
            if (ex.message === INVALID_PASSWORD_ERROR) {
                errorMessage = INVALID_PASSWORD;
            }
            this.setState({ 
                email: '', 
                emailPassword: '', 
                error: true, 
                loading: false,
                errorMessage
            });
        }
    }

    canSave = () => {
        if (this.state.email && this.state.password) {
            return true;
        }
        return false;
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Update Email'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle title='Update Your Email' />
        )
    }

    renderEmail = () => {
        return (
            <EasyInput 
                value={this.state.email}
                onChange={this.emailChange}
                placeholder='New Email'
                label='New Email'
            />
        )
    }

    renderPassword = () => {
        return (
            <EasyInput 
                value={this.state.password}
                onChange={this.passwordChange}
                placeholder='Password'
                label='Password'
                type='password'
            />
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.saveEmail}
                disabled={!this.canSave()}
            />
        )
    }

    renderErrorMessage = () => {
        if (this.state.error) {
            return (
                <SettingsTitle 
                    title={this.state.errorMessage} 
                    error
                />
            )
        }
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderErrorMessage()}
                {this.renderEmail()}
                {this.renderPassword()}
                {this.renderSave()}
            </Body>
        )
    }

    renderLoader = () => {
        return (
            <SettingsLoader 
                message={this.state.success ? 'Email Updated' : 'Updating Email...'}
                success={this.state.success}
                onClick={this.props.onBack}
            />
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

UpdateEmail.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    isCustomer: PropTypes.bool
};

UpdateEmail.defaultProps = {
    onBack: () => {},
    profile: {},
    isCustomer: false
};

export default UpdateEmail;