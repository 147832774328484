import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal, 
    SearchBar,
    Scroll, 
    Client
} from '.';
import algoliasearch from 'algoliasearch';
import { 
    ALGOLIA_ADMIN_KEY, 
    ALGOLIA_APP_ID 
} from '../secret/secret';

class ClientPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            clients: [],
            searchClient: null
        }
    }

    componentDidMount = async () => {
        const algoliaclient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_KEY);
        const searchClient = algoliaclient.initIndex(this.props.artistId);
        this.setState({ searchClient });
        const results = await searchClient.search('', { hitsPerPage: 50 }).then();
        const filteredResults = this.filterResults(results.hits);
        this.setState({ clients: filteredResults });
    }

    filterResults = (results) => {
        const filteredResults = [];
        for (let i = 0; i < results.length; i++) {
            const item = results[i];
            if (!this.props.customHash[item.objectID]) {
                filteredResults.push(item);
            }
        }
        return filteredResults;
    }

    searchChange = (search) => {
        this.setState({ search });
    }

    onChange = (client) => {
        this.props.onChange(client);
        this.props.onClose();
    }

    renderClient = (client, index) => {
        return (
            <Client 
                client={client}
                key={index}
                onClick={this.onChange}
            />
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='My Clients'
                height={.9}
            >
                <SearchBar 
                    value={this.state.search}
                    onChange={this.searchChange}
                    placeholder='Search Clients...'
                    style={styles.margin}
                />
                <Scroll>
                    {this.state.clients.map(this.renderClient)}
                </Scroll>
            </BottomUpModal>
        )
    }
}

const styles = {
    margin: {
        marginBottom: 10
    }
}

ClientPicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    artistId: PropTypes.string,
    onChange: PropTypes.func,
    customHash: PropTypes.object
};

ClientPicker.defaultProps = {
    open: false,
    onClose: () => {},
    artistId: '',
    onChange: () => {},
    customHash: {}
}

export default ClientPicker;