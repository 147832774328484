import { getAuth } from 'firebase/auth';
import { 
    getFirestore, 
    doc, 
    onSnapshot,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { 
    ARTIST_SCHEDULE_PROP_CHANGE,
    ARTIST_SCHEDULE_FETCH, 
    ARTIST_INIT_DAY_SELECTED,
    ARTIST_RESET_SCHEDULE,
    ARTIST_CLEAR_SCHEDULE,
    ARTIST_CLEAR_BREAK
} from '../actionTypes/actionTypes';

export const clearSchedule = () => {
    return { type: ARTIST_CLEAR_SCHEDULE };
}

export const fetchSchedule = () => {
    //attaches a realtime listener to "schedule/{id}"
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = doc(db, `users/${id}/schedule`, 'schedule');
        let scheduleExists = false;
        let scheduleHash = {};
        let vacationHash = {};
        const listener = onSnapshot(ref, snap => {
            if (snap.data() && snap.data().scheduleHash) {
                scheduleExists = true;
                scheduleHash = snap.data().scheduleHash;
                vacationHash = snap.data().vacationHash ? snap.data().vacationHash : {}
            }
            dispatch({ 
                type: ARTIST_SCHEDULE_FETCH,
                payload: {
                    scheduleHash,
                    scheduleExists,
                    vacationHash,
                    listener
                }
            })
        })
    }
}

export const saveVacation = (vacationHash) => {
    return async () => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const ref = doc(db, `users/${id}/schedule`, 'schedule');
        await updateDoc(ref, {
            vacationHash: vacationHash
        })
    }
}

export const saveSchedule = (saveSchedule) => {
    return async (dispatch) => {
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const ref = doc(db, `users/${id}/schedule`, 'schedule');
        const repeatHash = { ...saveSchedule.repeatHash };
        repeatHash[saveSchedule.daySelected] = {
            start: saveSchedule.start,
            end: saveSchedule.end,
            breakStart: saveSchedule.breakStart,
            breakEnd: saveSchedule.breakEnd
        }
        const dayKeys = Object.keys(repeatHash);
        //this calls for setting the schedule on a repeat
        let scheduleHash = saveSchedule.scheduleHash;
        for (let i = 0; i < dayKeys.length; i++) {
            const day = dayKeys[i];
            scheduleHash = {
                ...scheduleHash,
                [day]: {
                    start: saveSchedule.start,
                    end: saveSchedule.end,
                    breakStart: saveSchedule.breakStart,
                    breakEnd: saveSchedule.breakEnd
                }
            }
        }
        if (saveSchedule.scheduleExists) {
            await updateDoc(ref, { scheduleHash });
        } else {
            await setDoc(ref, { scheduleHash });
        }
        dispatch({ type: ARTIST_SCHEDULE_PROP_CHANGE, payload: { prop: 'repeatHash', value: {} }});
    }
}

export const resetSchedule = () => {
    return { type: ARTIST_RESET_SCHEDULE }
}

export const initDaySelected = (daySelected, schedule) => {
    const dayExists = schedule.start ? true : false;
    return {
        type: ARTIST_INIT_DAY_SELECTED,
        payload: {
            start: schedule.start ? schedule.start : '',
            end: schedule.end ? schedule.end : '',
            breakStart: schedule.breakStart ? schedule.breakStart : '',
            breakEnd: schedule.breakEnd ? schedule.breakEnd : '',
            daySelected: daySelected,
            dayExists,
        }
    }
}

export const schedulePropChange = (prop, value) => {
    return { type: ARTIST_SCHEDULE_PROP_CHANGE, payload: { prop, value }};
}

export const closeSchedule = (daySelected, scheduleHash) => {
    return async () => {
        const scheduleHashCopy = { ...scheduleHash };
        delete scheduleHashCopy[daySelected];
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const ref = doc(db, `users/${id}/schedule`, 'schedule');
        await updateDoc(ref, {
            scheduleHash: scheduleHashCopy
        });
    }
}

export const clearBreak = (daySelected, scheduleHash) => {
    return async (dispatch) => {
        const scheduleHashCopy = { ...scheduleHash };
        scheduleHashCopy[daySelected].breakStart = '';
        scheduleHashCopy[daySelected].breakEnd = '';
        const db = getFirestore();
        const id = getAuth().currentUser.uid;
        const ref = doc(db, `users/${id}/schedule`, 'schedule');
        await updateDoc(ref, {
            scheduleHash: scheduleHashCopy
        });
        dispatch({ 
            type: ARTIST_CLEAR_BREAK,
            payload: scheduleHashCopy
        });
    }
}