import React from 'react';
import PropTypes from 'prop-types';
import { 
    FlexRowButton,
    FlexRow,
    TransparentInput,
    InputLabel
} from '../components';

const EasyInput = ({ value, onChange, placeholder, label, type, showLabel }) => {

    let myRef = null;

    const focus = () => {
        if (myRef) {
            myRef.focus();
        }
    }

    return (
        <FlexRow>
            <FlexRowButton onClick={focus}>
                <TransparentInput 
                    value={value}
                    onChange={onChange}
                    passRefUp={ref => myRef = ref}
                    placeholder={placeholder}
                    type={type}
                />
                {(value || showLabel) && <InputLabel label={label} /> }
            </FlexRowButton>
        </FlexRow>
    )
}

EasyInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    showLabel: PropTypes.bool,
};

EasyInput.defaultProps = {
    value: '',
    onChange: () => {},
    type: '',
    placeholder: '',
    label: '',
    type: '',
    showLabel: false
};

export default EasyInput;
