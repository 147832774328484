import React from 'react';
import PropTypes from 'prop-types';
import './styles/SearchBar.css';

const SearchBar = ({ value, placeholder, onChange, style }) => {

    const change = (e) => {
        onChange(e.target.value);
    }
    
    return (
        <div 
            className='SearchBar-Container'
            style={style}
        >
            <input 
                className='SearchBar-Input'
                placeholder={placeholder}
                value={value}
                onChange={change}
            />
        </div>
    )
}

SearchBar.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object
}

SearchBar.defaultProps = {
    value: '',
    placeholder: 'Search...',
    onChange: () => {},
    style: {}
}

export default SearchBar;