import React from 'react';
import PropTypes from 'prop-types';
import './styles/PaidOverlay.css';
import { IoCheckmarkCircleOutline, IoChevronBackOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { BigTitle, ModalTitle } from '.';
import { getTimeSince } from '../util/TimeUtil';

const PaidOverlay = ({ time, showBack = false, onBack, curve = false, amount, noShow = false }) => {

    const decideClass = () => {
        if (curve) {
            return 'PO-Overlay PO-Curve';
        }
        return 'PO-Overlay';
    }

    const renderBack = () => {
        if (showBack) {
            return (
                <IoChevronBackOutline 
                    className='PO-Back'
                    onClick={onBack}
                />
            )
        }
    }

    const renderIcon = () => {
        if (noShow) {
            return (
                <IoCloseCircleOutline className='PO-Icon' />
            )
        }
        return (
            <IoCheckmarkCircleOutline className='PO-Icon' />
        )
    }

    const decideMessage = () => {
        if (noShow) {
            return `No Show Fee $${amount}`;
        }
        return `Paid $${amount}`;
    }

    return (
        <div className={decideClass()}>
            {renderBack()}
            {renderIcon()}
            <BigTitle style={{ marginTop: 15 }} title={decideMessage()}/>
            <p className='PO-Time'>{getTimeSince(time)}</p>
        </div>
    )
}

PaidOverlay.propTypes = {
    time: PropTypes.number,
    showBack: PropTypes.bool,
    amount: PropTypes.number,
    curve: PropTypes.bool,
    onBack: PropTypes.func,
    noShow: PropTypes.bool,
};

PaidOverlay.defaultProps = {
    time: 0,
    showBack: true,
    amount: 0,
    curve: false,
    onBack: () => {},
    noShow: false
}

export default PaidOverlay;