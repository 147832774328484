import { 
    ARTIST_CLEAR_BREAK,
    ARTIST_CLEAR_SCHEDULE,
    ARTIST_INIT_DAY_SELECTED, 
    ARTIST_RESET_SCHEDULE, 
    ARTIST_SCHEDULE_FETCH, 
    ARTIST_SCHEDULE_PROP_CHANGE 
} from "../actionTypes/actionTypes";

const INITIAL_STATE = {
    listener: null,
    scheduleHash: {},
    daySelected: '',
    repeatHash: {},
    start: '',
    end: '',
    breakStart: '',
    breakEnd: '',
    scheduleExists: false,
    vacationHash: {},
    scheduleOpen: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTIST_CLEAR_BREAK:
            return {
                ...state,
                scheduleHash: action.payload,
                breakStart: '',
                breakEnd: ''
            }
        case ARTIST_CLEAR_SCHEDULE:
            return INITIAL_STATE;
        case ARTIST_SCHEDULE_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value };
        case ARTIST_SCHEDULE_FETCH:
            return {
                ...state,
                scheduleHash: action.payload.scheduleHash,
                listener: action.payload.listener,
                scheduleExists: action.payload.scheduleExists,
                vacationHash: action.payload.vacationHash
            }
        case ARTIST_RESET_SCHEDULE:
            return {
                ...state,
                start: '',
                end: '',
                breakStart: '',
                breakEnd: '',
                scheduleExists: false,
                repeatHash: {},
                dayExists: false,
            }
        case ARTIST_INIT_DAY_SELECTED:
            return {
                ...state,
                daySelected: action.payload.daySelected,
                start: action.payload.start,
                end: action.payload.end,
                breakStart: action.payload.breakStart,
                breakEnd: action.payload.breakEnd,
                dayExists: action.payload.dayExists
            }
        default:
            return state;
    }
}