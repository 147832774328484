import React from 'react';
import PropTypes from 'prop-types';
import { Body, BrandButton, ModalTitle, SettingsTitle } from '.';
import { IoCloseCircle } from 'react-icons/io5';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/ModalError.css';
import BigTitle from './BigTitle';

const ModalError = ({ message, onClick }) => {
    return (
        <div className='ModalError-Container'>
            <Body center>
                <IoCloseCircle className='ModalError-Icon' />
                <BigTitle title={message} />
            </Body>
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Try Again'
                onClick={onClick}
            />
        </div>
    )
}

ModalError.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func,
};

ModalError.defaultProps = {
    message: '',
    onClick: () => {}
}

export default ModalError;