import React from 'react';
import PropTypes from 'prop-types';
import './styles/FlexButton.css';

const FlexButton = ({ children, onClick, style }) => {

    return (
        <div 
            onClick={onClick}
            className='FlexButton-Container'
            style={style}
        >
            {children}
        </div>
    )
}

FlexButton.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object
};

FlexButton.defaultProps = {
    onClick: () => {},
    style: {}
}

export default FlexButton;