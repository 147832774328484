import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/FlexRowButton.css';

class FlexRowButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        }
    }

    toggleFocus = () => {
        if (this.props.enableOpacityChange) {
            this.setState({ focused: true });
        }
    }

    onMouseUp = () => {
        if (this.props.enableOpacityChange) {
            window.setTimeout(this.loseFocus, 100);
        }
    }

    loseFocus = () => {
        this.setState({ focused: false });
    }

    decideClass = () => {
        if (this.state.focused) {
            if (this.props.relative) {
                return 'FlexRowButton-Container FlexRowButton-Focus FRB-Relative';
            }
            return 'FlexRowButton-Container FlexRowButton-Focus';
        }
        if (this.props.relative) {
            return 'FlexRowButton-Container FRB-Relative';
        }
        return 'FlexRowButton-Container';
    }

    render = () => {
        const { style, onClick, children } = this.props;;
        return (
            <div 
                className={this.decideClass()}
                style={style}
                onTouchStart={this.toggleFocus}
                onTouchEnd={this.loseFocus}
                onClick={onClick}
                ref={ref => this.buttonRef = ref}
            >
                {children}
            </div>
        )
    }

}

FlexRowButton.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
    enableOpacityChange: PropTypes.bool,
    relative: PropTypes.bool,
};

FlexRowButton.defaultProps = {
    children: null,
    style: {},
    onClick: () => {},
    className: '',
    enableOpacityChange: false,
    relative: false
}

export default FlexRowButton;