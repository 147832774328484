import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/Carousel.css';

const ANIMATE = 'all 0.3s';
const NO_ANIMATE = 'all 0s';

const SLIDE = 100;
const CHANGE_INDEX = 100;

class Carousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            touch: 0,
            left: 0,
            rightLeft: window.screen.width,
            leftLeft: -window.screen.width,
            moveLeft: false,
            moveRight: false
        }
    }

    touchStart = (e) => {
        this.setState({ touch: e.touches[0].clientX })
    }

    resetState = () => {
        this.setState({ 
            touch: 0,
            left: 0,
            rightLeft: window.screen.width,
            leftLeft: -window.screen.width,
            moveLeft: false,
            moveRight: false
        })
    }

    resetRefs = () => {
        const ref = this.getRef();
        const leftRef = this.getLeftRef();
        const rightRef = this.getRightRef();
        ref.remove();
        leftRef.remove();
        rightRef.remove();
    }

    touchMove = (e) => {
        const ref = this.getRef();
        if (ref && ref.contains(e.target)) {
            const left = (this.state.touch - e.touches[0].clientX) * -1; 
            this.setState({ left });
            if (left < 0) {
                this.setState({ rightLeft: left + window.screen.width, moveLeft: true, moveRight: false });
            }
            if (left > 0) {
                this.setState({ leftLeft: left - window.screen.width, moveRight: true, moveLeft: false });
            }
        }
    }

    moveLeftIndex = () => {
        if (this.props.currentIndex + 1 === this.props.length) {
            this.props.indexChange(0);
        } else {
            this.props.indexChange(this.props.currentIndex + 1);
        }
        this.resetState();
    }

    moveRightIndex = () => {
        if (this.props.currentIndex - 1 < 0) {
            this.props.indexChange(this.props.length - 1);
        } else {
            this.props.indexChange(this.props.currentIndex - 1)
        }
        this.resetState();
    }

    getRef = () => {
        const ref = document.getElementById(this.props.currentIndex);
        return ref;
    }

    getRightRef = () => {
        const rightIndex = (this.props.currentIndex === this.props.length - 1) ? 0 : this.props.currentIndex + 1;
        const rightRef = document.getElementById(rightIndex);
        return rightRef;
    }

    getLeftRef = () => {
        const leftIndex = (this.props.currentIndex === 0) ? this.props.length - 1 : this.props.currentIndex - 1;
        const leftRef = document.getElementById(leftIndex);
        return leftRef;
    }

    leftAnimate = () => {
        const ref = this.getRef();
        const rightRef = this.getRightRef();
        ref.style.transition = ANIMATE;
        rightRef.style.transition = ANIMATE;
        this.setState({ left: -window.screen.width, rightLeft: 0 });
    }

    rightAnimate = () => {
        const ref = this.getRef();
        const leftRef = this.getLeftRef();
        ref.style.transition = ANIMATE;
        leftRef.style.transition = ANIMATE;
        this.setState({ left: window.screen.width, leftLeft: 0 });
    }

    stopLeftAnimate = () => {
        const ref = this.getRef();
        const rightRef = this.getRightRef();
        ref.style.transition = NO_ANIMATE;
        rightRef.style.transition = NO_ANIMATE;
        window.setTimeout(this.moveLeftIndex, CHANGE_INDEX)
    }

    stopRightAnimate = () => {
        const ref = this.getRef();
        const leftRef = this.getLeftRef();
        ref.style.transition = NO_ANIMATE;
        leftRef.style.transition = NO_ANIMATE;
        window.setTimeout(this.moveRightIndex, CHANGE_INDEX);
    }

    moveLeft = () => {
        this.leftAnimate();
        window.setTimeout(this.stopLeftAnimate, SLIDE)
    }

    moveRight = () => {
        this.rightAnimate();
        window.setTimeout(this.stopRightAnimate, SLIDE);
    }

    touchEnd = (e) => {
        const ref = this.getRef();
        if (ref && ref.contains(e.target)) {
            if (this.state.moveLeft) {
                this.moveLeft();
            }
            if (this.state.moveRight) {
                this.moveRight();
            }
        }

    }

    renderItem = (index, left) => {
        return this.props.renderItem(index, left);
    }

    calcLeftIndex = () => {
        if (this.props.currentIndex - 1 < 0) {
            return this.props.length - 1;
        }
        return this.props.currentIndex - 1;
    }

    calcRightIndex = () => {
        if (this.props.currentIndex + 1 === this.props.length) {
            return 0;
        }
        return this.props.currentIndex + 1;
    }

    render = () => {
        return (
            <div 
                className='Ca-Container'
                onTouchStart={this.touchStart}
                onTouchMove={this.touchMove}
                onTouchEnd={this.touchEnd}
            >
                {this.renderItem(this.calcLeftIndex(), this.state.leftLeft)}
                {this.renderItem(this.props.currentIndex, this.state.left)}
                {this.renderItem(this.calcRightIndex(), this.state.rightLeft)}
            </div>

        )
    }
}

Carousel.propTypes = {
    currentIndex: PropTypes.number,
    indexChange: PropTypes.func,
    length: PropTypes.number,
    renderItem: PropTypes.func,
    ref: PropTypes.object,
    rightRef: PropTypes.object, 
    leftRef: PropTypes.object
};

Carousel.defaultProps = {
    currentIndex: 0,
    length: 0,
    indexChange: () => {},
    renderItem: () => {},
    ref: null,
    rightRef: null,
    leftRef: null
};

export default Carousel;