import React from 'react';
import PropTypes from 'prop-types';
import './styles/Bullet.css';

const Bullet = ({ label, style }) => {
    return (
        <div style={style} className='Bullet-Container'>
            <div className='Bullet-Circle' />
            <h4 className='Bullet-Label'>{label}</h4>
        </div>
    )
}

Bullet.propTypes = { 
    label: PropTypes.string,
    style: PropTypes.object,
};

Bullet.defaultProps = {
    label: '',
    style: {}
}

export default Bullet;