import { 
    ARTIST_SERVICE_PROP_CHANGE,
    ARTIST_FETCH_SERVICES,
    ARTIST_SERVICE_CHANGE,
    ARTIST_FETCH_CUSTOM_SERVICES,
    ARTIST_CLEAR_SERVICE
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
    services: [],
    listener: null,
    cantDeleteImageHash: {},
    customServiceOpen: false,
    customServices: [],
    customUnsub: null,
    customHash: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTIST_CLEAR_SERVICE:
            return INITIAL_STATE;
        case ARTIST_FETCH_SERVICES:
            return { 
                ...state,
                services: action.payload.services,
                listener: action.payload.listener,
                cantDeleteImageHash: action.payload.cantDeleteImageHash
            };
        case ARTIST_SERVICE_PROP_CHANGE:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };
        case ARTIST_SERVICE_CHANGE: {
            return {
                ...state,
                service: action.payload
            };
        }
        case ARTIST_FETCH_CUSTOM_SERVICES:
            return {
                ...state,
                customServices: action.payload.customServices,
                customUnsub: action.payload.customUnsub,
                customHash: action.payload.customHash
            };
        default:
            return state;
    }
}