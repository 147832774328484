import { convertNumberToDay, readifyDay } from "./DayUtil";

export const JANUARY = 'January';
export const FEBRUARY = 'February';
export const MARCH = 'March';
export const APRIL = 'April';
export const MAY = 'May';
export const JUNE = 'June';
export const JULY = 'July';
export const AUGUST = 'August';
export const SEPTEMBER = 'September';
export const OCTOBER = 'October';
export const NOVEMBER = 'November';
export const DECEMBER = 'December';

export const convertMonthNumberToString = (monthNumber) => {
    switch (monthNumber) {
        case 0:
            return JANUARY;
        case 1:
            return FEBRUARY;
        case 2:
            return MARCH;
        case 3:
            return APRIL;
        case 4:
            return MAY;
        case 5:
            return JUNE;
        case 6:
            return JULY;
        case 7:
            return AUGUST;
        case 8:
            return SEPTEMBER;
        case 9:
            return OCTOBER;
        case 10:
            return NOVEMBER;
        case 11:
            return DECEMBER;
        default:
            return "";
    }
}

export const getDaysInMonth = (month, year) => {
    //given a month and year, will tell you how many days are in that month
    //month is 0 based, Jan = 0, Feb = 1, etc...
    return new Date(year, month + 1, 0).getDate();
}

export const calcMinDate = () => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    return new Date(year, month, day).getTime();
}

export const convertDateStringToReadable = (dateString, includeDay) => {
    const parts = dateString.split('/');
    const month = parts[0];
    const day = parts[1];
    const year = parts[2];
    const readableMonth = convertMonthNumberToString(month - 1);
    if (includeDay) {
        const date = new Date(dateString);
        const dayOfWeek = readifyDay(convertNumberToDay(date.getDay()));
        return `${dayOfWeek} ${readableMonth} ${day}, ${year}`
    }
    return `${readableMonth} ${day}, ${year}`;
}

export const buildCalendarData = (yearsToBuildFor) => {
    const calendarData = [];

    const todaysDate = new Date();
    const todaysMonth = todaysDate.getMonth();
    let todaysYear = todaysDate.getFullYear();

    for (let i = todaysMonth; i % 12 < 12; i++) {
        if (i % 12 === 0 && i > 11) {
            todaysYear++;
        }
        if (i / 12 === yearsToBuildFor) {
            break;
        }
        const daysInMonth = getDaysInMonth(i, todaysYear);
        const monthBase1 = (i % 12) + 1;
        const days = [];
        for (let e = 1; e <= daysInMonth; e++) {
            const dateString = `${monthBase1}/${e}/${todaysYear}`;
            const d = new Date(dateString);
            const day = d.getDay();
            const unixTime = d.getTime();
            days.push({
                dateString,
                day,
                unixTime
            });
        }
        calendarData.push({
            month: i % 12,
            year: todaysYear,
            days
        });
    }
    return calendarData;
}