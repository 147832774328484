import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/AreYouSure.css';

const AreYouSure = ({ onClose, accept, title, valueToType, open, confirmMessage, buttonLabel = 'Close'  }) => {

    const [value, setValue] = useState('');

    const click = (e) => {
        if (e.target.id === 'container') {
            onClose();
            setValue('');
        }
    }

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const canDelete = () => {
        return (value === valueToType);
    }

    const onDelete = () => {
        if (canDelete()) {
            setValue('');
            accept();
        }
    }

    return (
        <div 
            className={`AreYouSure-Overlay ${open && 'AreYouSure-Open'}`}
            onClick={click}
            id='container'
        >
            <div 
                className={`AreYouSure-Modal ${open && 'AreYouSure-ModalOpen'}`}
                id='modal'
            >
                <div className='AreYouSure-TitleContainer'>
                    <p className='AreYouSure-Title'>{title}</p>
                </div>
                <p className='AreYouSure-TypeMessage'>{confirmMessage}<span className='AreYouSure-ValueToType'>{valueToType}</span></p>
                <input 
                    className='AreYouSure-Input'
                    placeholder={valueToType}
                    value={value}
                    onChange={onChange}
                />
                <div className='AreYouSure-BottomRow'>
                    <p 
                        className={`AreYouSure-Delete ${canDelete() && 'AreYouSure-DeleteEnable'}`}
                        onClick={onDelete}
                    >
                        {buttonLabel}
                    </p>
                </div>
            </div>
        </div>
    )
}

AreYouSure.propTypes = {
    onClose: PropTypes.func,
    accept: PropTypes.func,
    title: PropTypes.string,
    confirmMessage: PropTypes.string,
    open: PropTypes.bool,
    valueToType: PropTypes.string,
    buttonLabel: PropTypes.string
}

AreYouSure.defaultProps = {
    onClose: () => {},
    accept: () => {},
    title: '',
    confirmMessage: '',
    open: false,
    valueToType: '',
    buttonLabel: 'Close'
}

export default AreYouSure;