import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal,
    ModalInput,
    ModalLoader,
    ModalError,
    ModalSuccess,
    BrandButton,
    Body,
    TabButtonRow,
    TabButton,
    Card,
    BrandTabButton,
    EasyInput
} from '.';
import './styles/CancelModal.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { deleteDoc, doc, getFirestore } from '@firebase/firestore';
import { decrementAppointmentHashForArtist, incrementMonthStatAndClientStatForPayment, updateCancelStats } from '../util/AppointmentUtil';
import { calcStripeFee, calcStripeFeeInDollars } from '../util/StripeUtil';
import axios from 'axios';
import { MAKE_PAYMENT } from '../constants/ArgendaServerURLS';
import { fetchClientNotiHash, fetchNotiHash, handleNoti } from '../util/NotiUtil';
import { ARTIST_CANCEL, CLIENT_CANCEL } from '../constants/NotiTypes';
import { convertUnixTimeToReadable } from '../util/TimeUtil';
import { CANCEL, TEXT } from '../constants/Policies';

class CancelModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            success: false,
            value: '',
            chargeClient: false,
            cancelWithFee: false
        }
    }

    onChange = (value) => {
        this.setState({ value });
    }

    cancel = async () => {
        const db = getFirestore();
        const ref = doc(db, `appointments/${this.props.appointment.id}`);
        await decrementAppointmentHashForArtist(this.props.appointment.artistId, this.props.appointment.day);
        await updateCancelStats(this.props.appointment, this.props.appointment.artistId, this.props.appointment.clientId);
        await this.handleNoti();
        await deleteDoc(ref);
    }

    handleNoti = async () => {
        if (this.props.isArtist) {
            let method = TEXT;
            if (this.props.appointment.clientId) {
                const notiHash = await fetchClientNotiHash(this.props.appointment.clientId);
                method = notiHash[CANCEL];
            }
            const desc = `${this.props.appointment.artistName} cancelled an appointment with you on ${convertUnixTimeToReadable(this.props.appointment.unixTime)}.`;
            const preview = `${this.props.appointment.artistName} cancelled an appointment with you.`;
            const link = '';
            await handleNoti(this.props.appointment.clientPhone, desc, preview, CLIENT_CANCEL, this.props.appointment.clientId, method, link, true);
        } else {
            const notiHash = await fetchNotiHash(this.props.appointment.artistId);
            const method = notiHash[CANCEL];
            const desc = `${this.props.appointment.clientName} cancelled an appointment with you on ${convertUnixTimeToReadable(this.props.appointment.unixTime)}.`;
            const preview = `${this.props.appointment.clientName} cancelled an appointment with you.`;
            const link = '';
            await handleNoti(this.props.appointment.artistPhone, desc, preview, ARTIST_CANCEL, this.props.appointment.artistId, method, link);
        }
    }

    shouldCharge = () => {
        const now = new Date().getTime();
        if (this.props.appointment.tos && this.props.artist.stripeAccountId) {
            const cancelDate = this.props.appointment.tos.cancelDate;
            if (now > cancelDate) {
                return true;
            }
        }
        return false;
    }

    charge = async () => {
        const costInDollars = this.props.appointment.tos.cancelFee;
        const fee = calcStripeFee(costInDollars);
        const amount = Math.ceil(costInDollars * 100);
        const feeInDollars = calcStripeFeeInDollars(costInDollars);
        const paymentPack = {
            customerId: this.props.client.customerId,
            fee,
            clientDescription: `Cancellation Fee: $${costInDollars.toFixed(2)}`,
            artistDescription: `Cancellation Fee: $${costInDollars.toFixed(2)}`,
            stripeAccountId: this.props.artist.stripeAccountId,
            artistId: this.props.artist.id,
            clientId: this.props.client.id,
            artistName: this.props.artist.name,
            clientName: this.props.client.name,
            type: 'Cancellation',
            tip: 0,
            serviceString: JSON.stringify(this.props.appointment.service),
            amount,
            artistPhone: this.props.artist.phone,
            clientPhone: this.props.client.phone,
            artistMessage: `${this.props.client.name} was just charged a $${costInDollars.toFixed(2)} Cancellation Fee for cancelling an appointment with you.`,
            clientMessage: `You were just charged a $${costInDollars.toFixed(2)} Cancellation Fee.`,
            costInDollars
        }
        await axios.post(MAKE_PAYMENT, paymentPack);
        await incrementMonthStatAndClientStatForPayment(this.props.appointment, costInDollars, feeInDollars);
    }

    startCancel = async () => {
        this.setState({ loading: true });
        try {
            await this.cancel();
            if (this.shouldCharge()) {
                await this.charge();
            }
            this.setState({ loading: false, success: true });
        } catch (ex) {
            console.log(ex);
            this.setState({ loading: false, error: true });
        }
    }

    cancelWithFeeTrue = () => {
        this.setState({ cancelWithFee: true });
    }

    cancelWithFeeFalse = () => {
        this.setState({ cancelWithFee: false });
    }

    resetError = () => {
        this.setState({ error: false });
    }

    canCharge = () => {
        if (this.props.isArtist) {
            if (this.state.value === this.props.appointment.clientName) {
                return true;
            }
            return false;
        }
        if (this.state.value === this.props.appointment.artistName) {
            return true;
        }
        return false;
    }

    renderError = () => {
        return (
            <ModalError 
                message='Oops! Something went wrong, please try again.'
                onClick={this.resetError}
            />
        )
    }

    renderLoader = () => {
        return (
            <ModalLoader 
                message='Cancelling Appointment...'
            />
        )
    }

    renderSuccess = () => {
        return (
            <ModalSuccess 
                message='Appointment Cancelled.'
                onClick={this.props.onCancel}
            />
        )
    }

    renderTabRow = () => {
        return (
            <TabButtonRow>
                <BrandTabButton 
                    label='Cancel With Fee'
                    onClick={this.cancelWithFeeTrue}
                    selected={this.state.cancelWithFee}
                />
                <BrandTabButton 
                    style={styles.marginLeft}
                    label='Just Cancel'
                    onClick={this.cancelWithFeeFalse}
                    selected={!this.state.cancelWithFee}
                />
            </TabButtonRow>
        )
    }

    renderArtistCancelFeeBody = () => {
        return (
            <Body>
                <h2 className='CancelModal-Label'>Cancellation Fee</h2>
                <h1 className='CancelModal-CancelFee'>{`$${this.props.appointment.tos.cancelFee.toFixed(2)}`}</h1>
                <h4 className='CancelModal-Info'>To charge the "Cancel Fee" you must type the name of the client: <strong>{this.props.appointment.clientName}</strong></h4>
                <ModalInput 
                    placeholder={this.props.appointment.clientName}
                    onChange={this.onChange}
                    value={this.state.value}
                />
            </Body>
        )
    }

    renderArtistCancelBody = () => {
        return (
            <Body>
                <h2 className='CancelModal-Label'>Cancellation</h2>
                <h4 className='CancelModal-Info'>To cancel the appointment you must type the name of the client: <strong>{this.props.appointment.clientName}</strong></h4>
                <ModalInput 
                    placeholder={this.props.appointment.clientName}
                    onChange={this.onChange}
                    value={this.state.value}
                />
            </Body>
        )
    }

    renderDecideArtistBody = () => {
        if (this.state.cancelWithFee) {
            return this.renderArtistCancelFeeBody()
        }
        return this.renderArtistCancelBody();
    }

    renderArtistCancelFeeContent = () => {
        return (
            <div className='CancelModal-Container'>
                {this.renderTabRow()}
                {this.renderDecideArtistBody()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Charge'
                    disabled={!this.canCharge()}
                    onClick={this.startCancel}
                />
            </div> 
        )
    }

    renderArtistContent = () => {
        return (
            <div className='CancelModal-Container'>
                {this.renderArtistCancelBody()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Cancel'
                    disabled={!this.canCharge()}
                    onClick={this.startCancel}
                />
            </div>
        )
    }

    renderClientCancelFeeContent = () => {
        if (this.props.appointment.tos.cancelFee) {
            return (
                <div className='CancelModal-Container'>
                    <Body>
                        <Card card={this.props.card} width={window.screen.width * .85} />
                        <h2 className='CancelModal-Label'>Cancellation Fee</h2>
                        <h1 className='CancelModal-CancelFee'>{`$${this.props.appointment.tos.cancelFee.toFixed(2)}`}</h1>
                        <h4 className='CancelModal-Info'>To cancel this appointment you must type the name of the artist: <strong>{this.props.appointment.artistName}</strong></h4>
                        <ModalInput 
                            placeholder={this.props.appointment.artistName}
                            onChange={this.onChange}
                            value={this.state.value}
                        />
                    </Body>
                    <BrandButton 
                        style={BOTTOM_BUTTON}
                        label='Charge'
                        disabled={!this.canCharge()}
                        onClick={this.startCancel}
                    />
                </div>
            )
        }
    }

    renderClientContent = () => {
        return (
            <div className='CancelModal-Container'>
                <Body>
                    <h2 className='CancelModal-Label'>Cancellation</h2>
                    <h4 className='CancelModal-Info'>To cancel the appointment you must type the name of the artist: <strong>{this.props.appointment.artistName}</strong></h4>
                    <EasyInput 
                        placeholder={this.props.appointment.artistName}
                        onChange={this.onChange}
                        value={this.state.value}
                        label={`Type ${this.props.appointment.artistName}`}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Cancel'
                    disabled={!this.canCharge()}
                    onClick={this.startCancel}
                />
            </div>
        )
    }

    renderDecideArtistContent = () => {
        if (this.shouldCharge()) {
            return this.renderArtistCancelFeeContent();
        }
        return this.renderArtistContent();
    }

    renderDecideClientContent = () => {
        if (this.shouldCharge()) {
            return this.renderClientCancelFeeContent();
        }
        return this.renderClientContent();
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.success) {
            return this.renderSuccess();
        }
        if (this.state.error) {
            return this.renderError();
        }
        return this.renderDecideContent();
    }

    renderDecideContent = () => {
        if (this.props.isArtist) {
            return this.renderDecideArtistContent();
        }
        return this.renderDecideClientContent();
    }

    decideHeight = () => {
        if (!this.props.isArtist && this.shouldCharge()) {
            return 0.85;
        }
        return 0.7;
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={this.decideHeight()}
                disableClose={this.state.loading}
                image={this.props.image}
                darken
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

const styles = {
    marginLeft: {
        marginLet: 5
    }
}

CancelModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    appointment: PropTypes.object,
    artist: PropTypes.object,
    client: PropTypes.object,
    card: PropTypes.object,
    isArtist: PropTypes.bool,
    onCancel: PropTypes.func,
    image: PropTypes.string,
};

CancelModal.defaultProps = {
    onClose: () => {},
    open: false,
    appointment: {},
    artist: {},
    client: {},
    card: {},
    isArtist: false,
    onCancel: () => {},
    image: PropTypes.string
}

export default CancelModal;