import React from 'react';
import PropTypes from 'prop-types';
import './styles/CustomService.css';
import { buildImageUrl } from '../util/ImageUtil';
import { convertMinutesToHoursAndMinutesReadable } from '../util/TimeUtil';


const CustomService = ({ service, onClick }) => {

    const click = () => {
        onClick(service);
    }

    const width = window.screen.width - Math.ceil(window.screen.width * 0.05);
    const url = buildImageUrl(service.artistId, service.image, width, 280);
    const readableTime = convertMinutesToHoursAndMinutesReadable(service.duration.singleStart)
    return (
        <div 
            className='CustomService-Container'
            onClick={click}
        >
            <img src={url} className='CustomService-Image' />
            <div className='CustomService-RowContainer'>
                <div className='CustomService-InfoContainer'>
                    <h3 className='CustomService-Name'>{service.name}</h3>
                    <h4 className='CustomService-Duration'>{readableTime}</h4>
                </div>
                <div className='CustomService-PriceContainer'>
                    <h3 className='CustomService-Price'>{`$${service.price}`}</h3>
                </div>
            </div>
            <h2 className='CustomService-ClientName'>{service.client.name}</h2>
        </div>
    )
}

CustomService.propTypes = {
    service: PropTypes.object,
    onClick: PropTypes.func
};

CustomService.defaultProps = {
    service: {},
    onClick: () => {}
}

export default CustomService;