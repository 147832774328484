import axios from 'axios';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground,
    TransparentHeader,
    Body,
    BrandButton,
    ButtonLabel,
    Loader,
    BottomUpModal,
    PriceLabel
} from '../../components';
import { 
    DOWNGRADE_TO_SCHEDULING, 
    DOWNGRADE_TO_SCHEDULING_AND_DELETE_CONNECT, 
    FETCH_BALANCE 
} from '../../constants/ArgendaServerURLS';
import { ARTIST } from '../../constants/ClientRoutes';
import { SCHEDULING_SUBSCRIPTION_PRICE } from '../../constants/Prices';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import './styles/ToScheduling.css';

class ToScheduling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            balance: 0,
            disable: true,
            loading: false,
            areYouSure: false,
            success: false,
            error: false
        }
    }

    componentDidMount = async () => {
        const res = await axios.post(FETCH_BALANCE, { connectAccountId: this.props.profile.stripeAccountId });
        const balance = (res.data.balance.pending[0].amount / 100).toFixed(2);
        this.setState({ balance, disable: false });
    }

    back = () => {
        window.history.back();
    }

    toggleAreYouSure = () => {
        this.setState({ areYouSure: !this.state.areYouSure });
    }

    toggleLoading = () => {
        this.setState({ loading: !this.state.loading });
    }

    downgrade = async () => {
        this.toggleAreYouSure();
        this.toggleLoading();
        try {
            if (this.state.balance > 0) {
                //we have a current balance, lets switch subscriptions and mark the connect account for delete upon last payout
                const pack = {
                    stripeSubId: this.props.profile.stripeSubId,
                    stripeCustomerId: this.props.profile.stripeCustomerId,
                    id: this.props.profile.id,
                }
                await axios.post(DOWNGRADE_TO_SCHEDULING, pack);
            } else {
                //we don't have a balance, lets switch subscriptions and delete the connect account now
                const pack = {
                    stripeSubId: this.props.profile.stripeSubId,
                    stripeCustomerId: this.props.profile.stripeCustomerId,
                    id: this.props.profile.id,
                    stripeAccountId: this.props.profile.stripeAccountId
                }
                await axios.post(DOWNGRADE_TO_SCHEDULING_AND_DELETE_CONNECT, pack);
                const db = getFirestore();
                const ref = doc(db, `users/${this.props.profile.id}`);
                await updateDoc(ref, {
                    stripeAccountId: ''
                });
            }
            this.setState({ success: true });
        } catch (ex) {
            console.log(ex);
            this.setState({ error: true });
        }

    }

    decideMessage = () => {
        if (this.state.success) {
            return 'Downgraded to Scheduling.';
        }
        if (this.state.error) {
            return 'Something went wrong.';
        }
        if (this.state.loading) {
            return 'Downgrading to Just Scheduling...';
        }
    }

    navToHome = () => {
        window.location.hash = ARTIST.ARTIST_HOME;
    }

    renderLoader = () => {
        return (
            <Loader 
                open={this.state.loading}
                message={this.decideMessage()}
                success={this.state.success}
                error={this.state.error}
                onClick={this.navToHome}
            />
        )
    }

    renderAreYouSure = () => {
        return (
            <BottomUpModal 
                open={this.state.areYouSure}
                height={0.25}
                title='Are you sure you want to downgrade?'
                onClose={this.toggleAreYouSure}
            >
                <Body />
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='YES'
                    onClick={this.downgrade}
                />
            </BottomUpModal>
        )
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                onBack={this.back}
                title='Downgrade To Scheduling'
            />
        )
    }

    renderBody = () => {
        return (
            <Body>
                <ButtonLabel 
                    label='Are you sure you want to downgrade? By doing so, you will not be able to be paid through the Argenda anymore.'
                    style={styles.margin15} 
                />
                <PriceLabel 
                    label={`$${SCHEDULING_SUBSCRIPTION_PRICE}/month`}
                />
            </Body>
        )
    }

    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Yes I want to just use scheduling'
                disabled={this.state.disable}
                onClick={this.toggleAreYouSure}
            />
        )
    }

    render = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage)
        return (
            <ImageBackground
                image={url}
                darken
            >
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderButton()}
                {this.renderLoader()}
                {this.renderAreYouSure()}
            </ImageBackground>
        )
    }
}

const styles = {
    margin15: {
        marginTop: 15
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile };
}

export default connect(mapStateToProps, {})(ToScheduling);