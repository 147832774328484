import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AddressButton, AddressPicker2, BigTitle, Body, BrandButton, EasyInput, ModalRowBack } from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const SalonInfoForm = ({ onBack, onChange, salonName, salonAddress, salonPhone }) => {

    const [name, salonNameChange] = useState(salonName);
    const [address, salonAddressChange] = useState(salonAddress);
    const [phone, phoneChange] = useState(salonPhone);
    const [cardTokens, cardTokensChange] = useState([]);
    const [showCard, toggleCard] = useState(false);
    const [left, setLeft] = useState(0);
    const [addressLeft, setAddressLeft] = useState(window.screen.width);

    const navToAddress = () => {
        setLeft(-window.screen.width);
        setAddressLeft(0);
    }

    const navToInfo = () => {
        setLeft(0);
        setAddressLeft(window.screen.width);
    }

    const canSave = () => {
        if (name && address && phone && cardTokens.length > 0) {
            return true;
        }
        return false
    }

    const renderBack = () => {
        return (
            <ModalRowBack 
                title="Back" 
                onBack={onBack} 
            />
        )
    }

    const renderNameInput = () => {
        return (
            <EasyInput 
                value={name}
                onChange={salonNameChange}
                placeholder='Salon/Studio Name'
                label='Salon/Studio Name'
            />
        )
    }

    const renderAddressButton = () => {
        return (
            <AddressButton 
                address={address}
                placeholder='Salon/Studio Address'
                label='Salon/Studio Address'
                showLabel
                onClick={navToAddress}
            />
        )
    }

    const renderPhoneInput = () => {
        return (
            <EasyInput 
                value={phone}
                onChange={phoneChange}
                label='Salon/Studio Phone #'
                placeholder='Salon/Studio Phone #'
            />
        )
    }

    const renderDecideBody = () => {
        if (showCard) {

        }
        return renderInfoContainer();
    }

    const renderInfoContainer = () => {
        return (
            
            <Body relative>
                {renderInfoForm()}
                {renderAddressForm()}
            </Body>
        )
    }

    const renderAddressForm = () => {
        return (
            <Body
                absolute
                style={{ left: addressLeft }}
            >
                <AddressPicker2 
                    onChange={salonAddressChange}
                    onBack={navToInfo}
                />
            </Body>
        )
    }

    const renderInfoForm = () => {
        return (
            <Body
                absolute
                style={{ left: left }}
            >
                {renderBack()}
                <BigTitle title='Basic Salon Info' />
                <Body>
                    {renderNameInput()}
                    {renderAddressButton()}
                    {renderPhoneInput()}
                    <BrandButton 
                        style={BOTTOM_BUTTON}
                        label='Next'
                        disabled={!canSave()}
                    />
                </Body>
            </Body>
        )
    }

    return renderDecideBody();
}

SalonInfoForm.propTypes = {
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    salonName: PropTypes.string,
    salonAddress: PropTypes.object,
    salonPhone: PropTypes.string
};

SalonInfoForm.defaultProps = {
    onBack: () => {},
    onChange: () => {},
    salonName: '',
    salonAddress: {},
    salonPhone: ''
};

export default SalonInfoForm;