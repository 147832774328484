import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { BigTitle, Body, SettingsTitle } from '.';

export const ModalLoader = ({ message, style }) => {
    return (
        <Body style={style} center>
            <ReactLoading 
                type='spokes'
                color='#fff'
            />
            <BigTitle title={message} />
        </Body>
    )
}

ModalLoader.propTypes = {
    message: PropTypes.string,
    style: PropTypes.object
};

ModalLoader.defaultProps = {
    message: '',
    style: {}
}

export default ModalLoader;
