import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, ListWrapper, RowCalendar } from '.';
import './styles/TimeSlotPicker.css';
import _ from 'lodash';
import { 
    doc, 
    getFirestore, 
    onSnapshot, 
} from 'firebase/firestore';
import { convertNumberToDay } from '../util/DayUtil';
import { 
    addTime, 
    convertNumberToReadable, 
    convertScheduleToNumberTimes,
    getActualAppointmentTimeInUNIX, 
} from '../util/TimeUtil';
import { fetchAppointments } from '../util/AppointmentUtil';

class TimeSlotPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scheduleHash: {},
            vacationHash: {},
            unsubscribe: null,
            selectedDay: null,
            slots: []
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open: oldOpen } = prevProps;
        const { open } = this.props;
        if (!oldOpen && open) {
            this.getArtistSchedule();
        }
        if (oldOpen && !open) {
            this.state.unsubscribe();
            this.setState({ slots: [], selectedDay: null });
        }
    }

    getArtistSchedule = async () => {
        const db = getFirestore();
        const scheduleRef = doc(db, `users/${this.props.artistId}/schedule/schedule`);
        const unsubscribe = onSnapshot(scheduleRef, snap => {
            this.setState({
                scheduleHash: snap.data().scheduleHash,
                vacationHash: snap.data().vacationHash,
                unsubscribe
            });
        })

    }

    onDaySelect = (selectedDay) => {
        this.setState({ selectedDay });
        this.createTimeSlots(selectedDay);
    }

    createTimeSlots = async (selectedDay) => {
        if (selectedDay) {
            const stringDay = convertNumberToDay(selectedDay.day);
            const appointments = await fetchAppointments(this.props.artistId, selectedDay.dateString);
            const schedule = this.state.scheduleHash[stringDay];
            const slots = this.findOpenings(schedule, appointments);
            this.setState({ slots });
        }
    }

    selectTimeSlot = (slot) => {
        const unixTime = getActualAppointmentTimeInUNIX(this.state.selectedDay.unixTime, slot.startTime);
        this.props.onChange({ selectedDay: this.state.selectedDay, slot, unixTime });
        this.props.onClose();
    }

    doesSingleInterfereWithSingleAppointment = (_start, _end, appointment) => {
        const appointmentStart = appointment.startTime;
        const appointmentEnd = appointment.endTime;
        if (_start >= appointmentStart && _start < appointmentEnd) {
            _start = appointmentEnd;
            _end = addTime(_start, this.props.service.duration.singleStart); 
        }
        if (_end > appointmentStart && _end <= appointmentEnd) {
            _start = appointmentEnd;
            _end = addTime(_start, this.props.service.duration.singleStart); 
        }
        if (appointmentStart >= _start && appointmentStart < _end) {
            _start = appointmentEnd;
            _end = addTime(_start, this.props.service.duration.singleStart);
        }
        if (appointmentEnd > _start && appointmentEnd <= _end) {
            _start = appointmentEnd;
            _end = addTime(_start, this.props.service.duration.singleStart);
        }
        return { _start, _end };
    }

    doesDoubleInterfereWithSingleAppointment = (_start, _end1, _start1, _end, appointment) => {
        const appointmentStart = appointment.startTime;
        const appointmentEnd = appointment.endTime;
        if (_start >= appointmentStart && _start < appointmentEnd) {
            _start = appointmentEnd;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (_end1 > appointmentStart && _end1 <= appointmentEnd) {
            _start = appointmentEnd;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (_start1 >= appointmentStart && _start1 < appointmentEnd) {
            _start = appointmentEnd;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (_end > appointmentStart && _end <= appointmentEnd) {
            _start = appointmentEnd;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (appointmentStart >= _start && appointmentStart < _end1) {
            _start = appointmentEnd;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (appointmentEnd > _start && appointmentEnd <= _end1) {
            _start = appointment;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (appointmentStart >= _start1 && appointmentStart < _end) {
            _start = appointment;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        if (appointmentEnd > _start1 && appointmentEnd <= _end) {
            _start = appointment;
            _end1 = addTime(_start, this.props.service.duration.doubleStart);
            _start1 = addTime(_end1, this.props.service.duration.doubleBreak);
            _end = addTime(_start1, this.props.service.duration.doubleEnd);
        }
        return { _start, _end1, _start1, _end };
    }

    doesSingleServiceInterfereWithAppointments = (startTime, endTime, appointments) => {
        let _start = startTime;
        let _end = endTime;
        for (let i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            const newTime = this.doesSingleInterfereWithSingleAppointment(_start, _end, appointment);
            _start = newTime._start;
            _end = newTime._end;
        }
        return { startTime: _start, endTime: _end };
    }

    doesDoubleServiceInterfereWithAppointments = (startTime, endTime1, startTime1, endTime, appointments) => {
        let _start = startTime;
        let _end1 = endTime1;
        let _start1 = startTime1;
        let _end = endTime;
        for (let i = 0; i < appointments.length; i++) {
            const appointment = appointments[i];
            const newTime = this.doesDoubleInterfereWithSingleAppointment(_start, _end1, _start1, _end, appointment);
            _start = newTime._start;
            _end1 = newTime._end1;
            _start1 = newTime._start1;
            _end = newTime._end;
        }
        return { startTime: _start, endTime1: _end1, startTime1: _start1, endTime: _end };
    }

    calculateOpeningsForSingleService = (appointments, dayStart, dayEnd) => {
        let startTime = dayStart;
        let endTime = addTime(startTime, this.props.service.duration.singleStart);
        const slots = [];
        while (startTime < dayEnd && endTime <= dayEnd) {
            const newTime = this.doesSingleServiceInterfereWithAppointments(startTime, endTime, appointments);
            if (newTime.endTime > dayEnd) {
                break;
            }
            slots.push({ startTime: newTime.startTime, endTime: newTime.endTime });
            startTime = slots[slots.length - 1].endTime;
            endTime = addTime(startTime, this.props.service.duration.singleStart);
        }
        return slots;
    }

    calculateOpeningsForDoubleService = (appointments, dayStart, dayEnd) => {
        let startTime = dayStart;
        let endTime1 = addTime(startTime, this.props.service.duration.doubleStart);
        let startTime1 = addTime(endTime1, this.props.service.duration.doubleBreak);
        let endTime =  addTime(startTime1, this.props.service.duration.doubleEnd);
        const slots = [];
        while (startTime < dayEnd && endTime <= dayEnd) {
            const newTime = this.doesDoubleServiceInterfereWithAppointments(startTime, endTime1, startTime1, endTime, appointments);
            if (newTime.endTime > dayEnd) {
                break;
            }
            slots.push({ startTime: newTime.startTime, endTime1: newTime.endTime1, startTime1: newTime.startTime1, endTime: newTime.endTime });
            startTime = slots[slots.length - 1].endTime1;
            endTime1 = addTime(startTime, this.props.service.duration.doubleStart);
            startTime1 = addTime(endTime1, this.props.service.duration.doubleBreak);
            endTime =  addTime(startTime1, this.props.service.duration.doubleEnd);
        }
        return slots;
    }

    findOpenings = (schedule, appointments) => {
        const { start: dayStart, breakStart, breakEnd, end: dayEnd } = convertScheduleToNumberTimes(schedule);
        const breakAppointment = {
            startTime: breakStart,
            endTime: breakEnd
        }
        appointments.push(breakAppointment);
        const sortedAppointments = _.orderBy(appointments, 'startTime', 'asc');
        if (this.props.service.duration.isDouble) {
            return this.calculateOpeningsForDoubleService(sortedAppointments, dayStart, dayEnd);
        } else {
            return this.calculateOpeningsForSingleService(sortedAppointments, dayStart, dayEnd);
        }
    }

    renderSlot = (slot, index) => {
        const startString = convertNumberToReadable(slot.startTime);
        const endString = convertNumberToReadable(slot.endTime);
        const title = `${startString} - ${endString}`;
        const unixTime = getActualAppointmentTimeInUNIX(this.state.selectedDay.unixTime, slot.startTime);
        const now = new Date().getTime();
        if (unixTime > now) {
            return (
                <div 
                    className='TimeSlotPicker-Slot'
                    key={index}
                    onClick={() => this.selectTimeSlot(slot)}
                >
                    <h3 className='TimeSlotPicker-SlotTitle'>{title}</h3>
                </div>
            )
        }

    }

    //this will render if the user selects a day and there are openings
    renderSlots = () => {
        console.log(this.state.selectedDay);
        console.log(this.state.slots);
        return (
            <ListWrapper style={styles.wrapper}>
                {this.state.slots.map(this.renderSlot)}
            </ListWrapper>
        )
    }

    //this will render if the user has not selected a day yet
    renderNoDayYet = () => {
        return (
            <div className='TimeSlotPicker-NoDayYet'>
                <h2>Please select a day</h2>
            </div>
        )
    }

    //this will render if the user selects a day but there are no openings
    renderNoSlots = () => {

    }

    renderDecideBody = () => {
        if (!this.state.selectedDay) {
            return this.renderNoDayYet();
        }
        if (this.state.selectedDay && this.state.slots.length > 0) {
            return this.renderSlots();
        }
        if (this.state.selectedDay && this.state.slots.length === 0) {
            return this.renderNoSlots();
        }
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='Pick a date and time'
                height={.9}
                image={this.props.image}
                darken
            >
                <RowCalendar 
                    scheduleHash={this.state.scheduleHash}
                    vacationHash={this.state.vacationHash}
                    selectedDay={this.state.selectedDay}
                    onChange={this.onDaySelect}
                />
                {this.renderDecideBody()}
            </BottomUpModal>
        )
    }
}

const styles = {
    wrapper: {
        marginTop: 20,
        marginBottom: 20
    }
}

TimeSlotPicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    artistId: PropTypes.string,
    service: PropTypes.object,
    image: PropTypes.string,
}

TimeSlotPicker.defaultProps = {
    open: false,
    onClose: () => {},
    onChange: () => {},
    artistId: '',
    service: {},
    image: ''
}

export default TimeSlotPicker;