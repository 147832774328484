import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { 
    BigTitle,
    SubTitle,
    FAB,
    TransparentHeader,
    ImageLimitModal,
    Body,
    ImageInput,
    ImagesContainer,
    BrandButton,
    PortfolioImage,
    Scroll
} from '.';
import { IMAGE_LIMIT } from '../constants/ImageTypes';
import { uploadImages } from '../actions/artistActions';
import { BsCameraFill } from 'react-icons/bs';
import {
    parseFiles 
} from '../util/ImageUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/ImagesSetup.css';

const IMAGE_PICKER = 'IMAGE_PICKER';

class ImagesSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imageModalOpen: false
        }
    }

    openImagePicker = () => {
        document.getElementById(IMAGE_PICKER).click();
    }

    toggleImageModalOpen = () => {
        this.setState({ imageModalOpen: !this.state.imageModalOpen });
    }

    imageChange = (files) => {
        const images = parseFiles(files);
        const limit = IMAGE_LIMIT - this.props.profile.imageCount;
        if (images.length < limit) {
            this.props.uploadImages(images, this.props.profile);
        } else {
            this.setState({ images, imageModalOpen: true });
        }
    }

    uploadLimitImages = (images) => {
        this.props.uploadImages(images, this.props.profile);
    }

    renderDecideBody = () => {
        if (this.props.images.length === 0) {
            return this.renderNoImages();
        }
        return this.renderImageContent();
    }

    renderImage = (image, index) => {
        return (
            <PortfolioImage 
                key={index}
                image={image}
                id={image.artistId}
                elementId={`image-${index}`}
            />
        )
    }

    renderImageContent = () => {
        return (
            <Body>
                <Scroll>
                    <ImagesContainer style={{ marginBottom: 80 }}>
                        {this.props.images.map(this.renderImage)}
                    </ImagesContainer>
                </Scroll>
                <BrandButton 
                    style={{ ...BOTTOM_BUTTON, marginTop: 0, marginBottom: 0 }}
                    label='Set up Services'
                    onClick={this.props.onNext}
                />
            </Body>
        )
    }

    renderNoImages = () => {
        return (
            <Body>
                <SubTitle 
                    title={`Upload up to ${IMAGE_LIMIT} images of your best work so clients can see it. Your work will also be displayed in the explore page for people looking for a new artist.`}
                />
            </Body>
        )
    }

    renderImageInput = () => {
        return (
            <ImageInput 
                id={IMAGE_PICKER}
                multiple
                onChange={this.imageChange}
            />
        )
    }

    renderFABIcon = () => {
        return (
            <BsCameraFill />
        )
    }

    renderFAB = () => {
        return (
            <FAB 
                onClick={this.openImagePicker}
                style={{ bottom: this.props.images.length > 0 ? 100 : 40 }}
                type='CAMERA'
            />
        )
    }

    renderImageModal = () => {
        return (
            <ImageLimitModal 
                images={this.state.images}
                open={this.state.imageModalOpen}
                onClose={this.toggleImageModalOpen}
                imageCount={this.props.profile.imageCount}
                onSave={this.uploadLimitImages}
            />
        )
    }

    decideTitle = () => {
        if (this.props.profile.imageCount > 0) {
            if (this.props.profile.imageCount === 1) {
                return `You can upload 1 more image.`
            }
            if (this.props.profile.imageCount === IMAGE_LIMIT) {
                return 'Your portfolio is full.'
            }
            return `You can upload ${IMAGE_LIMIT - this.props.profile.imageCount} more images.`
        }
        return "Let's set up your portfolio."
    }

    render = () => {
        return (
            <Body>
                <TransparentHeader 
                    onBack={this.props.onBack}
                    showBack
                    title={this.props.profile.imageCount > 0 ? 'Portfolio' : ''}
                />
                <BigTitle 
                    title={this.decideTitle()}
                />        
                {this.renderDecideBody()}
                {this.renderImageInput()}
                {this.renderFAB()}
                {this.renderImageModal()}
            </Body>
        )
    }
}

ImagesSetup.propTypes = {
    onBack: PropTypes.func, 
    onNext: PropTypes.func,
};

ImagesSetup.defaultProps = {
    onBack: () => {},
    onNext: () => {},
};

const mapStateToProps = (state) => {
    const { profile, images } = state.artist;
    return { profile, images };
}

export default connect(mapStateToProps, { uploadImages })(ImagesSetup);