import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    BottomUpModal,
    ImagesContainer,
    Scroll,
    BrandButton,
    Body
} from '.';
import { getThumbnailSize } from '../util/ImageUtil';
import './styles/ImageLimitModal.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { IMAGE_LIMIT } from '../constants/ImageTypes';
import { BsCheckCircle } from 'react-icons/bs';

class ImageLimitModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hash: {}
        }
    }

    canUpload = () => {
        if (Object.keys(this.state.hash).length > 0) {
            return true;
        }
        return false;
    }

    upload = () => {
        const images = [];
        const urls = Object.keys(this.state.hash);
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            const image = this.state.hash[url];
            images.push(image);
        }
        this.props.onSave(images);
        this.props.onClose();
    }

    getImagesAllowed = () => {
        return IMAGE_LIMIT - this.props.imageCount;
    }

    getImagesAllowedTitle = () => {
        const count = this.getImagesAllowed();
        if (count === 1) {
            return `You can only upload ${count} more image. Select the image you want to upload.`;
        }
        return `You can only upload ${count} more image(s). Select the ${count} you want to upload.`;
    }

    selectImage = (image) => {
        const hash = { ...this.state.hash };
        if (hash[image.url]) {
            delete hash[image.url];
        } else {
            if (Object.keys(this.state.hash).length < this.getImagesAllowed()) { 
                hash[image.url] = { ...image };
            }
        }
        this.setState({ hash });
    }

    renderOverlay = (image) => {
        if (this.state.hash[image.url]) {
            return (
                <div className='ILM-Overlay'>
                    <div className='ILM-OverlayInner'>
                        <BsCheckCircle className='ILM-Icon' />
                    </div>
                </div>
            )
        }
    }

    renderImage = (image, index) => {
        const size = getThumbnailSize();
        return (
            <div 
                className='ILM-Container'
                onClick={() => this.selectImage(image)}
                style={{ width: size, height: size, margin: 2 }}
            >
                <img 
                    key={index}
                    src={image.url}
                    style={{ width: size, height: size }}
                    className='ILM-Image'
                />
                {this.renderOverlay(image)}
            </div>
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={0.85}
                title={this.getImagesAllowedTitle()}
            >
                <Body>
                    <Scroll>
                        <ImagesContainer>
                            {this.props.images.map(this.renderImage)}
                        </ImagesContainer>
                    </Scroll>
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Upload Images'
                    onClick={this.upload}
                    disabled={!this.canUpload()}
                />
            </BottomUpModal>
        )
    }
}

ImageLimitModal.propTypes = {
    images: PropTypes.array,
    imageCount: PropTypes.number,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    onSave: PropTypes.func
};

ImageLimitModal.defaultProps = {
    images: [],
    imageCount: 0,
    onClose: () => {},
    onChange: () => {},
    open: false,
    onSave: () => {}
};

export default ImageLimitModal;