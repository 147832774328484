import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal,
    ClockInput,
    DigitalInput
} from '.';

class ClockModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open } = this.props;
        const { open: prevOpen } = prevProps;
        if (open && !prevOpen) {
            window.setTimeout(this.openClockInput, 200);
        }
    }

    openClockInput = () => {
        this.setState({ open: true });
    }

    save = (value) => {
        console.clear();
        console.log(value);
        this.props.onClose();
        this.props.onSave(value);
    }

    renderInput = () => {
        if (this.state.open) {
            return (
                <DigitalInput 
                    onSave={this.save}
                    value={this.props.value}
                />
            )
        }
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title={this.props.title}
                height={.85}
                image={this.props.image}
                darken
            >
                {this.renderInput()}
            </BottomUpModal>
        )
    }
}

ClockModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.string,
    image: PropTypes.string,
};

ClockModal.defaultProps = {
    open: false,
    onClose: () => {},
    onSave: () => {},
    title: '',
    value: '',
    image: ''
}

export default ClockModal;