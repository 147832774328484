import React from 'react';
import PropTypes from 'prop-types';
import { IoIosPeople, IoIosThumbsDown, IoIosThumbsUp } from 'react-icons/io';
import './styles/StatsRow.css';

const StatsRow = ({ profile, toggleLikes, toggleDislikes, toggleClients, didLike, didDislike }) => {

    const decideIconClass = (selected) => {
        if (selected) {
            return 'StatsRow-Icon StatsRow-Selected';
        }
        return 'StatsRow-Icon';
    }

    const decideLabelClass = (selected) => {
        if (selected) {
            return 'StatsRow-IconLabel StatsRow-Selected';
        }
        return 'StatsRow-IconLabel';
    }

    return (
        <div className='StatsRow-Row'>
            <div onClick={toggleLikes} className='StatsRow-Stats'>
                <IoIosThumbsUp className={decideIconClass(didLike)} />
                <p className={decideLabelClass(didLike)}>{profile.likes}</p>
            </div>
            <div onClick={toggleDislikes} className='StatsRow-Stats'>
                <IoIosThumbsDown className={decideIconClass(didDislike)} />
                <p className={decideLabelClass(didDislike)}>{profile.dislikes}</p>
            </div>
            <div onClick={toggleClients} className='StatsRow-Stats'>
                <IoIosPeople className='StatsRow-Icon' />
                <p className='StatsRow-IconLabel'>{profile.clients}</p>
            </div>
        </div>
    )
}

StatsRow.propTypes = {
    profile: PropTypes.object,
    toggleLikes: PropTypes.func,
    toggleDislikes: PropTypes.func,
    toggleClients: PropTypes.func,
    didLike: PropTypes.bool,
    didDislike: PropTypes.bool,
};

StatsRow.defaultProps = {
    profile: {},
    toggleLikes: () => {},
    toggleDislikes: () => {},
    toggleClients: () => {},
    didLike: false,
    didDislike: false
}

export default StatsRow;