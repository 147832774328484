import React from 'react';
import PropTypes from 'prop-types';
import './styles/GradientBackground.css';

const GradientBackground = ({ children }) => {
    return (
        <div className='GradientBackground-Container'>
            {children}
        </div>
    )
}

GradientBackground.propTypes = {
    children: PropTypes.node
};

GradientBackground.defaultProps = {
    children: null
}

export default GradientBackground;