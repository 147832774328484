export const parseAddressForIndividual = (address) => {
    const addressList = address.split(', ');
    const line1 = addressList[0];
    const city = addressList[1];
    const state = addressList[2].split(' ')[0];
    const postal_code = addressList[2].split(' ')[1];
    const country = 'US';
    return {
        line1,
        city,
        state,
        postal_code,
        country
    }
}

export const parseAddressForCard = (address) => {
    const addressList = address.split(', ');
    const address_line1 = addressList[0];
    const address_city = addressList[1];
    const address_state = addressList[2].split(' ')[0];
    const address_zip = addressList[2].split(' ')[1];
    const address_country = 'US';
    return {
        addressList,
        address_line1,
        address_city,
        address_state,
        address_zip,
        address_country,
        currency: 'usd'
    }
}

export const parseDOB = (dob) => {
    const dateValues = dob.split('-');
    const year = parseInt(dateValues[0]);
    const month = parseInt(dateValues[1]);
    const day = parseInt(dateValues[2]);
    return {
        year,
        month,
        day
    }
}