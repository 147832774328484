import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    SettingsTitle,
    Option,
    UpdateClientPhone,
    UpdateClientEmail,
    UpdateClientPassword,
    DeleteClientAccount
} from '.';
import { AiFillPhone } from 'react-icons/ai';
import { MdEmail, MdPassword } from 'react-icons/md';
import { OPTION_RESCHEDULE } from '../universalStyles/Styles';
import { IoClose } from 'react-icons/io5';
import { CLIENT } from '../constants/ClientRoutes';

class ClientAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: false,
            emailOpen: false,
            passwordOpen: false,
        }
    }

    togglePhone = () => {
        this.setState({ phoneOpen: !this.state.phoneOpen });
    }

    toggleEmail = () => {
        this.setState({ emailOpen: !this.state.emailOpen });
    }

    togglePassword = () => {
        this.setState({ passwordOpen: !this.state.passwordOpen });
    }

    navToDeleteProfile = () => {
        window.location.hash = `${CLIENT.CLIENT_DELETE_PROFILE}`
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Account'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Account'
            />
        )
    }

    renderUpdatePhone = () => {
        return (
            <UpdateClientPhone 
                onBack={this.togglePhone}
                profile={this.props.profile}
            />
        )
    }

    renderUpdateEmail = () => {
        return (
            <UpdateClientEmail 
                onBack={this.toggleEmail}
                profile={this.props.profile}
            />
        )
    }

    renderDeleteClientAccount = () => {
        return (
            <DeleteClientAccount 
                onBack={this.toggleDelete}
                profile={this.props.profile}
                appointments={this.props.appointments}
                goToAppointments={this.props.goToAppointments}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                <Option 
                    label='Change Phone'
                    icon={<AiFillPhone />}
                    backgroundColor='#D735AF'
                    onClick={this.togglePhone}
                />
                <Option 
                    label='Change Email'
                    icon={<MdEmail />}
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.toggleEmail}
                />
                <Option 
                    label='Change Password'
                    icon={<MdPassword />}
                    backgroundColor='#FF3A3A'
                    onClick={this.togglePassword}
                />
                <Option 
                    label='Delete Account'
                    icon={<IoClose />}
                    backgroundColor='#FF3A3A'
                    onClick={this.navToDeleteProfile}
                />
            </Body>
        )
    }

    renderUpdatePass = () => {
        return (
            <UpdateClientPassword 
                onBack={this.togglePassword}
                profile={this.props.profile}
            />
        )
    }

    renderDecide = () => {
        if (this.state.phoneOpen) {
            return this.renderUpdatePhone();
        }
        if (this.state.emailOpen) {
            return this.renderUpdateEmail();
        }
        if (this.state.passwordOpen) {
            return this.renderUpdatePass();
        }
        if (this.state.deleteOpen) {
            return this.renderDeleteClientAccount();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

ClientAccount.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    appointments: PropTypes.array,
    goToAppointments: PropTypes.func,
};

ClientAccount.defaultProps = {
    onBack: () => {},
    profile: {},
    appointments: [],
    goToAppointments: () => {}
}

export default ClientAccount;