import React from 'react';
import PropTypes from 'prop-types';
import './styles/ModalRowBack.css';
import { IoChevronBackOutline } from 'react-icons/io5';

const ModalRowBack = ({ title, onBack }) => {
    return (
        <div onClick={onBack} className='ModalRowBack-BackContainer'>
            <IoChevronBackOutline className='ModalRowBack-Back' />
            <h4 className='ModalRowBack-BackTitle'>Back</h4>
        </div>
    )
}

ModalRowBack.propTypes = {
    title: PropTypes.string,
    onBack: PropTypes.func,
}

ModalRowBack.defaultProps = {
    title: '',
    onBack: () => {}
}

export default ModalRowBack;