import {
    CLIENT_CLEAR,
    CLIENT_FETCH_APPOINTMENTS,
    CLIENT_FETCH_NOTIS,
    CLIENT_FETCH_PROFILE,
    CLIENT_PROP_CHANGE,
    CLIENT_PURGE
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
    profile: null,
    profileListener: null,
    shouldRender: false,
    appointmentModalOpen: false,
    appointments: [],
    appointmentsUnsub: null,
    settingsOpen: false,
    notis: [],
    notiListener: null,
    showNotis: false,
    showNotiOptions: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLIENT_CLEAR:
            return INITIAL_STATE;
        case CLIENT_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value };
        case CLIENT_FETCH_PROFILE:
            return {
                ...state,
                profile: action.payload.profile,
                profileListener: action.payload.profileListener,
                shouldRender: true
            }
        case CLIENT_FETCH_APPOINTMENTS:
            return {
                ...state,
                appointments: action.payload.appointments,
                appointmentsUnsub: action.payload.appointmentsUnsub
            }
        case CLIENT_FETCH_NOTIS:
            return {
                ...state,
                notis: action.payload.notis,
                notiListener: action.payload.notiListener
            }
        case CLIENT_PURGE: 
            return INITIAL_STATE;
        default:
            return state;
    }
}