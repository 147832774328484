import React, { Component } from 'react';
import { 
    doc, 
    getDoc, 
    getFirestore, 
    query, 
    where, 
    orderBy, 
    getDocs, 
    collection 
} from 'firebase/firestore';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { 
    ImageBackground, 
    Loader, 
    TransparentHeader, 
    Body,
    SettingsTitle, 
    StatCircle,
    BottomUpModal,
    Option,
    ListWrapper,
    Empty,
    Appointment,
    BrandButton,
} from '../../components';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import { convertUnixTimeToReadable } from '../../util/TimeUtil';
import './styles/ViewClient.css';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { IoChatbubbleOutline } from 'react-icons/io5';
import { BOTTOM_BUTTON, OPTION_RESCHEDULE } from '../../universalStyles/Styles';
import { parseDocs } from '../../util/FirestoreUtil';
import { ARTIST } from '../../constants/ClientRoutes';

class ViewClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            open: false,
            appointments: [],
            apptOpen: false
        }
    }

    componentDidMount = async () => {
        await this.fetchClientInfo();
        await this.fetchAppointments();
    }

    fetchClientInfo = async () => {
        const { clientId } = this.props.match.params;
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/clients/${clientId}`);
        const statsSnap = await getDoc(ref).then();
        const clientRef = doc(db, `clients/${clientId}`);
        const clientSnap = await getDoc(clientRef).then();
        const client = { ...statsSnap.data(), ...clientSnap.data(), id: clientId };
        this.setState({ client });
    }

    fetchAppointments = async () => {
        const db = getFirestore();
        const ref = collection(db, `appointments`);
        const w1 = where('artistId', '==', this.props.profile.id);
        const w2 = where('clientId', '==', this.state.client.id);
        const o1 = orderBy('unixTime');
        const q = query(ref, w1, w2, o1);
        const snap = await getDocs(q);
        const { results: appointments } = parseDocs(snap);
        console.log(appointments);
        this.setState({ appointments });
    }

    toggleOpen = () => {
        this.setState({ open: !this.state.open });
    }

    toggleApt = () => {
        this.setState({ apptOpen: !this.state.apptOpen });
    }

    onBack = () => {
        window.history.back();
    }

    navToAppointment = (appointment) => {
        window.location.hash = `${ARTIST.ARTIST_VIEW_APPOINTMENT}/${appointment.id}`;
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading...'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title={`Client since ${convertUnixTimeToReadable(this.state.client.clientSince)}`}
            />
        )
    }

    renderRow1 = () => {
        return (
            <div className='VC-Row'>
                <StatCircle 
                    count={this.state.client.appointments}
                    label='Appointments'
                    color='#D735AF'
                />
                <StatCircle 
                    count={this.state.client.cancellations}
                    label='Cancellations'
                    color='#FF3466'
                />
            </div>
        )
    }

    renderRow2 = () => {
        return (
            <div className='VC-Row'>
                <StatCircle 
                    count={this.state.client.noShows}
                    label='No Shows'
                    color='#FF3A3A'
                />
                <StatCircle 
                    count={this.state.client.reschedules}
                    label='Reschedules'
                    color='#D735AF'
                />
            </div>
        )
    }

    renderRow3 = () => {
        return (
            <div className='VC-Row'>
                <StatCircle 
                    count={`$${this.state.client.earned}`}
                    label='Earned'
                    color='#D735AF'
                />
                <StatCircle 
                    count={`$${this.state.client.fees}`}
                    label='Fees'
                    color='#FF3466'
                />
            </div>
        )
    }

    renderModal = () => {
        return (
            <BottomUpModal 
                open={this.state.open}
                height={0.25}
                title='Client Options'
                onClose={this.toggleOpen}
            >
                <a 
                    href={`tel:+1${this.state.client.phone}`}
                    className='VC-Atag'
                >
                    <Option 
                        label={`Call ${this.state.client.name.split(' ')[0]}`}
                        icon={<BsFillTelephoneFill />}
                        backgroundColor={OPTION_RESCHEDULE}
                    />
                </a>
                <a 
                    href={`sms:+1${this.state.client.phone}`}
                    className='VC-Atag'
                >
                    <Option 
                        label={`Text ${this.state.client.name.split(' ')[0]}`}
                        icon={<IoChatbubbleOutline />}
                        backgroundColor={OPTION_RESCHEDULE}
                    />
                </a>
            </BottomUpModal>
        )
    }

    renderEmptyAppt = () => {
        return (
                <Empty message={`You have no upcoming appointments with ${this.state.client.name}`} />
        )
    }

    renderAppt = (appointment, index) => {
        return (
            <Appointment 
                appointment={appointment}
                key={index}
                showDay
                onClick={this.navToAppointment}
            />
        )
    }

    renderAppts = () => {
        return this.state.appointments.map(this.renderAppt);
    }

    renderDecideAppointments = () => {
        if (this.state.appointments.length === 0) {
            return this.renderEmptyAppt();
        }
        return this.renderAppts();
    }
    
    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label={`${this.state.appointments.length} Upcoming Appointments`}
                onClick={this.toggleApt}
            />
        )
    }

    renderAppointments = () => {
        return (
            <BottomUpModal
                open={this.state.apptOpen}
                height={0.8}
                onClose={this.toggleApt}
                title={`${this.state.appointments.length} Upcoming Appointments`}
            >
                <ListWrapper>
                    {this.renderDecideAppointments()}
                </ListWrapper>
            </BottomUpModal>
        )
    }

    renderContent = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    title={this.state.client.name}
                    onBack={this.onBack}
                    showSettings
                    onSettings={this.toggleOpen}
                />
                <Body>
                    {this.renderTitle()}
                    {this.renderRow1()}
                    {this.renderRow2()}
                    {this.renderRow3()}
                </Body>
                {this.renderButton()}
                {this.renderModal()}
                {this.renderAppointments()}
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.state.client) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    bottom: {
        marginBottom: 100
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile }
}

export default withRouter(connect(mapStateToProps, { })(ViewClient));