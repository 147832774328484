import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/ArtistRow.css';
import { searchArtists } from '../util/AlgoliaUtil';
import { ArtistForRow } from '.';
import { CLIENT } from '../constants/ClientRoutes';

class ArtistRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coords: null,
            artists: []
        }
    }

    componentDidMount = () => {
        this.getCurrentPos();
    }

    getCurrentPos = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { timeout: 1000 });
        }
    }

    getCoordsError = async (error) => {
        const artists = await searchArtists(this.props.search, null);
        console.log(artists);
        this.setState({ artists });

    }

    getCoords = async (event) => {
        this.setState({
            coords: {
                lat: event.coords.latitude,
                lng: event.coords.longitude
            }
        });
        const artists = await searchArtists(this.props.search, event.coords);
        this.setState({ artists });
    }

    goToArtist = (artist) => {
        window.location.hash = `${CLIENT.CLIENT_VIEW_ARTIST}/${artist.objectID}`;
    }

    renderTitle = () => {
        return (
            <h3 className='AR-Title'>{this.props.search}</h3>
        )
    }

    renderArtist = (artist, index) => {
        return (
            <ArtistForRow 
                artist={artist}
                key={index}
                onClick={this.goToArtist}
            />
        )
    }

    renderRow = () => {
        return (
            <div className='AR-Row'>
                {this.state.artists.map(this.renderArtist)}
            </div>
        )
    }
    
    render = () => {
        return (
            <div className='AR-Container'>
                {this.renderTitle()}
                {this.renderRow()}
            </div>
        )
    }
}

ArtistRow.propTypes = {
    search: PropTypes.string
}

ArtistRow.defaultProps = {
    search: ''
}

export default ArtistRow;