import React, { Component } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { connect } from 'react-redux';
import { 
    TransparentHeader, 
    ImageBackground, 
    ProfileImage,
    Scroll,
    ArtistTop,
    InfoRow,
    StatsRow,
    Bio,
    Service,
    ImagesContainer,
    PortfolioImage,
    Loader,
    BrandButton,
    ArtistScheduleModal,
    ArtistAddressModal,
    Header,
    FullPhotoModal,
    ArtistSettingsModal,
    ClientPicker,
    RatingModal,
    Empty,
    ImageInput,
    ImageLimitModal,
    TypeIndicator,
} from '../../components';
import { 
    getProfile,
    setVisible,
    fetchFirstImages,
    uploadImages,
    artistPropChange,
    saveAddress,
    fetchMoreImages,
    getPolicyHash,
    getNotificationHash,
    uploadProfileImage,
    deleteImages,
    fetchAppointmentHash
} from '../../actions/artistActions';
import { 
    fetchSchedule, 
    schedulePropChange, 
    initDaySelected,
    saveVacation 
} from '../../actions/scheduleActions';
import { fetchServices, servicePropChange } from '../../actions/serviceActions';
import './styles/ArtistHome.css';
import { buildProfileImageUrl, parseBlob, parseFiles } from '../../util/ImageUtil';
import { ARTIST, SIGNUP } from '../../constants/ClientRoutes';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { IoIosAddCircle, IoIosClose, IoIosCreate, IoIosTrash } from 'react-icons/io';
import Setup from './Setup';
import { IMAGE_LIMIT } from '../../constants/ImageTypes';

const IMAGE_PICKER = 'imagePicker';
const PROFILE_IMAGE_PICKER = 'profileImagePicker';

class ArtistHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageIndex: 0,
            imageModalOpen: false,
            imageEdit: false,
            imageDeleteHash: {},
            serviceEdit: false,
            serviceDeleteHash: {},
            ratingOpen: false,
            showLikes: false,
            limitModalOpen: false,
            limitImages: []
        }
    }

    componentDidMount = () => {
        if (!this.props.profile) {
            this.getProfile();
            this.fetchSchedule();
            this.fetchServices();
            this.fetchFirstImages();
            this.getPolicyHash();
            this.getNotificationHash();
            this.fetchAppointmentHash();
        }
        this.loadScrollTop();
        window.addEventListener('touchend', this.onScroll);
    }

    componentDidUpdate = () => {
        this.shouldBeVisible();
        this.shouldntBeVisible();
    }

    componentWillUnmount = () => {
        this.getScrollTop();
        window.removeEventListener('touchend', this.onScroll);
    }

    getBackgroundImage = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return url;
    }

    getProfile = () => {
        if (!this.props.profileListener) {
            this.props.getProfile();
        }
    }

    fetchSchedule = () => {
        if (!this.props.scheduleListener) {
            this.props.fetchSchedule();
        }
    }

    fetchServices = () => {
        if (!this.props.serviceListener) {
            this.props.fetchServices();
        }
    }

    fetchFirstImages = () => {
        if (!this.props.imageListener) {
            this.props.fetchFirstImages();
        }
    }

    getPolicyHash = () => {
        if (!this.props.policyUnsub) {
            this.props.getPolicyHash();
        }
    }

    getNotificationHash = () => {
        if (!this.props.notificationHashUnsub) {
            this.props.getNotificationHash();
        }
    }

    fetchAppointmentHash = () => {
        if (!this.props.appointmentHashListener) {
            this.props.fetchAppointmentHash();
        }
    }

    toggleSettings = () => {
        if (this.props.notis.length > 0) {
            this.props.artistPropChange('settingsOpen', !this.props.settingsOpen);
            this.props.artistPropChange('notisOpen', true);
        } else {
            this.props.artistPropChange('settingsOpen', !this.props.settingsOpen);
        }
    }

    deleteImages = () => {
        const keys = Object.keys(this.state.imageDeleteHash);
        const imagesToDelete = [];
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const image = this.state.imageDeleteHash[key];
            imagesToDelete.push(image);
        }
        this.props.deleteImages(imagesToDelete, this.props.profile);
        this.closeImageEdit();
    }

    openLikes = () => {
        this.setState({ ratingOpen: true, showLikes: true });
    }

    openDislikes = () => {
        this.setState({ ratingOpen: true, showLikes: false });
    }

    closeRating = () => {
        this.setState({ ratingOpen: false });
    }

    toggleClientPicker = () => {
        this.props.artistPropChange('clientPickerOpen', !this.props.clientPickerOpen);
    }

    onScroll = (e) => {
        if (this.props.images.length > 0 && !this.props.noMoreImages) {
            const lastImageId = `image-${this.props.images.length - 1}`;
            const query = document.getElementById(lastImageId);
            const lastImageTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + 250;
            if (lastImageTop <= threshold && !this.props.noMoreImages) {
                this.props.fetchMoreImages(this.props.imagesCursor);
            }
        }

    }

    fetchMoreImages = () => {
        if (!this.props.noMoreImages) {
            this.props.fetchMoreImages(this.props.imagesCursor);
        }
    }

    openImageEdit = () => {
        this.setState({ imageEdit: true });
    }

    openServiceEdit = () => {
        this.setState({ serviceEdit: true });
    }

    closeImageEdit = () => {
        this.setState({ imageEdit: false, imageDeleteHash: {} });
    }

    closeServiceEdit = () => {
        this.setState({ serviceEdit: false, serviceDeleteHash: {} });
    }

    closeLimitImageModal = () => {
        this.setState({ limitModalOpen: !this.state.limitModalOpen });
    }

    getScrollTop = () => {
        let scrollTop = 0;
        const query = document.getElementById('scrollContainer');
        if (query) {
            scrollTop = query.scrollTop;
        }
        this.props.artistPropChange('scrollTop', scrollTop);
    }

    loadScrollTop = () => {
        const query = document.getElementById('scrollContainer');
        if (query) {
            query.scrollTop = this.props.scrollTop;
        }
    }

    selectOrDeselectImage = (image) => {
        const imageDeleteHash = { ...this.state.imageDeleteHash };
        if (!this.props.cantDeleteImageHash[image.imageName]) {
            if (imageDeleteHash[image.imageName]) {
                delete imageDeleteHash[image.imageName];
            } else {
                imageDeleteHash[image.imageName] = { ...image };
            }
            this.setState({ imageDeleteHash });
        }

    }

    selectOrDeselectService = (service) => {
        const serviceDeleteHash = { ...this.state.serviceDeleteHash };
        if (serviceDeleteHash[service.id]) {
            delete serviceDeleteHash[service.id];
        } else {
            serviceDeleteHash[service.id] = true;
        }
        this.setState({ serviceDeleteHash });
    }

    onImageTap = ({ image, index }) => {
        if (this.state.imageEdit) {
            this.selectOrDeselectImage(image);
        } else {
            this.openImageModal({ index });
        }
    }

    openImageModal = ({ index: imageIndex }) => {
        this.setState({ imageIndex });
        window.setTimeout(() => {
            this.setState({ imageModalOpen: true })
        }, 10)
    }

    closeImageModal = () => {
        this.setState({ imageModalOpen: false });
    }
    
    imageIndexChange = (imageIndex) => {
        if (imageIndex < this.props.images.length) {
            this.setState({ imageIndex });
        }
    }

    toggleSchedule = () => {
        this.props.schedulePropChange('scheduleOpen', !this.props.scheduleOpen)
    }

    toggleMap = () => {
        this.props.artistPropChange('mapOpen', !this.props.mapOpen)
    }

    imageChange = (files) => {
        const images = parseFiles(files);
        const limit = IMAGE_LIMIT - this.props.profile.imageCount;
        if (images.length < limit) {
            this.props.uploadImages(images, this.props.profile);
        } else {
            this.setState({ limitModalOpen: true, limitImages: images });
        }
    }

    profileChange = (e) => {
        const image = parseBlob(e);
        this.props.uploadProfileImage(image, this.props.profile.profileImage);
    }

    /*
        if there is a schedule and services
        make the artist visible in search results
    */
    shouldBeVisible = () => {
        if (Object.keys(this.props.scheduleHash).length > 0 && this.props.services.length > 0 && this.props.profile &&  !this.props.profile.visible) {
            this.props.setVisible(true);
        }
    }

    /*
        if there isnt a schedule or services
        make the artist not visible in search results
    */
    shouldntBeVisible = () => {
        if ((Object.keys(this.props.scheduleHash).length === 0 || this.props.services.length === 0) && this.props.profile && this.props.profile.visible) {
            this.props.setVisible(false);
        }
    }

    logout = () => {
        const auth = getAuth();
        signOut(auth);
        window.location.hash = SIGNUP.SPASH;
    }

    navToService = (service) => {
        window.location.hash = `${ARTIST.ARTIST_EDIT_SERVICE}/${service.id}`
    }

    navToAppointments = () => {
        window.location.hash = ARTIST.ARTIST_VIEW_APPOINTMENTS;
    }

    onServiceTap = (service) => {
        if (this.state.serviceEdit) {
            this.selectOrDeselectService(service);
        } else {
            this.navToService(service);
        }
    }

    uploadLimitImages = (images) => {
        this.props.uploadImages(images, this.props.profile);
    }

    navToAddService = () => {
        window.location.hash = ARTIST.ARTIST_ADD_SERVICE
    }

    navToViewClient = (client) => {
        window.location.hash = `${ARTIST.ARTIST_VIEW_CLIENT}/${client.objectID}`;
    }

    navToDay = (day) => {
        window.location.hash = `${ARTIST.ARTIST_SET_SCHEDULE}/${day}`;
    }

    pickImage = () => {
        document.getElementById(IMAGE_PICKER).click();
    }

    pickProfileImage = () => {
        document.getElementById(PROFILE_IMAGE_PICKER).click();
    }

    shouldRenderSetUp = () => {
        if (!this.props.profile.finishedSetup) {
            return true;
        }
        return false;
    }

    renderScheduleModal = () => {
        return (
            <ArtistScheduleModal 
                open={this.props.scheduleOpen}
                scheduleHash={this.props.scheduleHash}
                vacationHash={this.props.vacationHash}
                onClose={this.toggleSchedule}
                selectDay={this.navToDay}
                saveVacation={this.props.saveVacation}
                image={this.getBackgroundImage()}
                darken
            />
        )
    }

    renderProfileImage = () => {
        return (
            <ProfileImage 
                id={this.props.profile.id}
                image={this.props.profile.profileImage}
                size={Math.ceil(window.screen.width / 3)}
                onClick={this.pickProfileImage}
            />
        )
    }

    renderService = (service, index) => {
        return (
            <Service 
                service={service}
                key={index}
                onClick={this.onServiceTap}
                selected={this.state.serviceEdit && this.state.serviceDeleteHash[service.id]}
            />
        )
    }

    renderImage = (image, index) => {
        let canShow = true;
        if (this.state.imageEdit && this.props.cantDeleteImageHash[image.imageName]) {
            //this image is attached to a service and you cannot delete it
            canShow = false;
        }
        if (canShow) {
            return (
                <PortfolioImage 
                    key={index}
                    image={image}
                    id={getAuth().currentUser.uid}
                    elementId={`image-${index}`}
                    index={index}
                    onClick={this.onImageTap}
                    selected={this.state.imageEdit && this.state.imageDeleteHash[image.imageName]}
                />
            )
        }

    }

    renderInvisibleInput = () => {
        return (
            <ImageInput 
                id={IMAGE_PICKER}
                multiple
                onChange={this.imageChange}
            />
        )
    }

    renderInvisibleProfilePhotoInput = () => {
        return (
            <input 
                type='file'
                id={PROFILE_IMAGE_PICKER}
                className='ArtistHome-ImagePicker'
                onChange={this.profileChange}
            />
        )
    }

    renderMapModal = () => {
        return (
            <ArtistAddressModal 
                open={this.props.mapOpen}
                onClose={this.toggleMap}
                profile={this.props.profile}
                saveAddress={this.props.saveAddress}
            />
        )
    }

    decideServicesTitle = () => {
        if (this.state.serviceEdit) {
            const selectedServicesLength = Object.keys(this.state.serviceDeleteHash).length;
            if (selectedServicesLength > 0) {
                return `${selectedServicesLength} Services Selected`;
            }
            return 'Select a Service';
        }
        return 'Services';
    }

    renderServiceRow = () => {
        return (
            <div className='ArtistHome-Row'>
                {!this.state.serviceEdit ? <IoIosCreate className='ArtistHome-Edit' onClick={this.openServiceEdit} /> : <IoIosClose className='ArtistHome-Edit' onClick={this.closeServiceEdit} />}
                <Header title={this.decideServicesTitle()} />
                {this.state.serviceEdit ? <IoIosTrash className='ArtistHome-Add' /> : <IoIosAddCircle className='ArtistHome-Add'  onClick={this.navToAddService} />}
            </div>
        )
    }

    decideImageTitle = () => {
        if (this.state.imageEdit) {
            const selectedImagesLength = Object.keys(this.state.imageDeleteHash).length;
            if (selectedImagesLength > 0) {
                return `${selectedImagesLength} Images Selected`;
            }
            return 'Select an Image';
        }
        return 'Portfolio';
    }

    renderImageEditOrCancel = () => {
        if (!this.state.imageEdit) {
            return (
                <IoIosCreate className='ArtistHome-Edit' onClick={this.openImageEdit} />
            )
        }
        return (
            <IoIosClose className='ArtistHome-Edit' onClick={this.closeImageEdit} />
        )
    }

    renderImageAddOrDelete = () => {
        if (this.state.imageEdit) {
            return (
                <IoIosTrash 
                    className='ArtistHome-Add' 
                    onClick={this.deleteImages}
                />
            )
        }
        return (
            <IoIosAddCircle 
                className='ArtistHome-Add'
                onClick={this.pickImage}
            />
        )
    }

    renderImageRow = () => {
        return (
            <div className='ArtistHome-Row'>
                {this.renderImageEditOrCancel()}
                <Header title={this.decideImageTitle()} />
                {this.renderImageAddOrDelete()}
            </div>
        )
    }

    renderBio = () => {
        if (this.props.profile.bio) {
            return (
                <Bio profile={this.props.profile} />
            )
        }
    }

    renderArtistType = () => {
        return (
            <Header 
                style={styles.artistType} 
                title={this.props.profile.artistType} 
                onClick={this.startArtistTypeEdit}
            />
        )
    }

    renderArtistTop = () => {
        return (
            <ArtistTop>
                {this.renderProfileImage()}
                {this.renderArtistType()}
                <TypeIndicator 
                    stripeAccountId={this.props.profile.stripeAccountId}
                />
                {this.renderBio()}
                <InfoRow 
                    profile={this.props.profile}
                    toggleHours={this.toggleSchedule}
                    toggleMap={this.toggleMap}
                />
                <StatsRow 
                    profile={this.props.profile}
                    toggleClients={this.toggleClientPicker}
                    toggleLikes={this.openLikes}
                    toggleDislikes={this.openDislikes}
                />
            </ArtistTop>
        )
    }

    renderDecideServices = () => {
        if (this.props.services.length > 0) {
            return this.renderServices();
        }
        return this.renderNoServices();
    }

    renderNoServices = () => {
        return (
            <Empty 
                message='You have no services yet.'
            />
        )
    }

    renderServices = () => {
        return this.props.services.map(this.renderService);
    }

    renderNoImages = () => {
        return (
            <Empty 
                message='You have no images yet.'
            />
        )
    }

    renderDecideImages = () => {
        if (this.props.images.length > 0) {
            return this.renderImages();
        }
        return this.renderNoImages();
    }

    renderImages = () => {
        return (
            <ImagesContainer>
                {this.props.images.map(this.renderImage)}
            </ImagesContainer>
        )
    }


    renderFullImageModal = () => {
        const id = getAuth().currentUser.uid;
        return (
            <FullPhotoModal 
                open={this.state.imageModalOpen}
                imageIndex={this.state.imageIndex}
                images={this.props.images}
                imageIndexChange={this.imageIndexChange}
                id={id}
                onClose={this.closeImageModal}
                fetchMoreImages={this.fetchMoreImages}
            />
        )
    }

    renderSettings = () => {
        return (
            <ArtistSettingsModal 
                image={this.getBackgroundImage()}
            />
        )
    }

    renderClientPicker = () => {
        return (
            <ClientPicker 
                open={this.props.clientPickerOpen}
                artistId={this.props.profile.id}
                onClose={this.toggleClientPicker}
                onChange={this.navToViewClient}
            />
        )
    }

    renderRatingModal = () => {
        return (
            <RatingModal 
                onClose={this.closeRating}
                showLikes={this.state.showLikes}
                open={this.state.ratingOpen}
                artist={this.props.profile}
            />
        )
    }

    renderSetUp = () => {
        return (
            <Setup 
            
            />
        )
    }

    renderLimitImageModal = () => {
        return (
            <ImageLimitModal 
                images={this.state.limitImages}
                open={this.state.limitModalOpen}
                imageCount={this.props.profile.imageCount}
                onClose={this.closeLimitImageModal}
                onSave={this.uploadLimitImages}
            />
        )
    }

    renderContent = () => {
        return (
            <ImageBackground 
                image={this.getBackgroundImage()}
                darken
            >
                <TransparentHeader 
                    showSettings
                    title={this.props.profile.name}
                    showBack={false}
                    onSettings={this.toggleSettings}
                    settingsBadgeCount={this.props.notis.length}
                />
                <Scroll id={'scrollContainer'}>
                    {this.renderArtistTop()}
                    {this.renderServiceRow()}
                    {this.renderDecideServices()}
                    {this.renderImageRow()}
                    {this.renderDecideImages()}
                    {this.renderInvisibleInput()}
                    {this.renderInvisibleProfilePhotoInput()}
                </Scroll>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='My Appointments'
                    onClick={this.navToAppointments}
                />
                {this.renderScheduleModal()}
                {this.renderMapModal()}
                {this.renderFullImageModal()}
                {this.renderSettings()}
                {this.renderClientPicker()}
                {this.renderRatingModal()}
                {this.renderLimitImageModal()}
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading Profile'
            />
        )
    }

    renderDecide = () => {
        if (this.props.shouldRender) {
            if (this.shouldRenderSetUp()) {
                return this.renderSetUp();
            }
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    artistType: {
        marginTop: 15,
        marginBottom: 15
    }
}

const mapStateToProps = (state) => {
    const { 
        profile, 
        shouldRender,
        images,
        imagesCursor,
        noMoreImages,
        scrollTop,
        mapOpen,
        settingsOpen,
        clientPickerOpen,
        notis,
        profileListener,
        imageListener,
        policyUnsub,
        notificationHashUnsub,
        appointmentHashListener
    } = state.artist;
    const { 
        scheduleHash, 
        scheduleOpen, 
        vacationHash,
        listener: scheduleListener
    } = state.schedule;
    const { 
        services, 
        cantDeleteImageHash,
        listener: serviceListener 
    } = state.service;
    return { 
        profile, 
        shouldRender,
        scheduleHash,
        services,
        images,
        scheduleOpen,
        vacationHash,
        scrollTop,
        mapOpen,
        imagesCursor,
        noMoreImages,
        cantDeleteImageHash,
        settingsOpen,
        clientPickerOpen,
        notis,
        profileListener,
        scheduleListener,
        serviceListener,
        imageListener,
        policyUnsub,
        notificationHashUnsub,
        appointmentHashListener
    }
} 

export default connect(mapStateToProps, { 
    getProfile, 
    fetchSchedule, 
    setVisible, 
    fetchServices, 
    fetchFirstImages, 
    servicePropChange ,
    schedulePropChange,
    initDaySelected,
    saveVacation,
    uploadImages,
    artistPropChange,
    saveAddress,
    fetchMoreImages,
    getPolicyHash,
    getNotificationHash,
    uploadProfileImage,
    deleteImages,
    fetchAppointmentHash
})(ArtistHome);