import { getAuth } from '@firebase/auth';
import { 
    doc, 
    getFirestore, 
    onSnapshot, 
    collection, 
    query,
    where,
    orderBy,
    updateDoc
} from '@firebase/firestore';
import axios from 'axios';
import {
    CLIENT_FETCH_APPOINTMENTS,
    CLIENT_FETCH_PROFILE,
    CLIENT_PROP_CHANGE,
    CLIENT_FETCH_NOTIS,
    CLIENT_CLEAR,
    CLIENT_PURGE
} from '../actionTypes/actionTypes';
import { CREATE_CUSTOMER_ACCOUNT_FOR_CLIENT, UPDATE_CUSTOMER_CARD } from '../constants/ArgendaServerURLS';
import { parseDocs } from '../util/FirestoreUtil';

export const clientPropChange = (prop, value) => {
    return { type: CLIENT_PROP_CHANGE, payload: { prop, value }};
}

export const purgeClient = () => {
    return { type: CLIENT_PURGE };
}

export const saveCard = (profile, tokens) => {
    return async () => {
        if (!profile.cardId) {
            await noExistingCardSave(profile, tokens);
        } else {
            await updateExistingCard(profile, tokens);
        }
    }
}

const noExistingCardSave = async (profile, tokens) => {
    const pack = {
        token: tokens[0],
        account: profile
    }
    const res = await axios.post(CREATE_CUSTOMER_ACCOUNT_FOR_CLIENT, pack);
    const db = getFirestore();
    const profileRef = doc(db, `clients/${profile.id}`);
    await updateDoc(profileRef, {
        cardId: res.data.card.id,
        customerId: res.data.customerId
    });
}

const updateExistingCard = async (profile, tokens) => {
    const pack = {
        token: tokens[0],
        customerId: profile.customerId
    }
    const res = await axios.post(UPDATE_CUSTOMER_CARD, pack);
    const db = getFirestore();
    const profileRef = doc(db, `clients/${profile.id}`);
    await updateDoc(profileRef, {
        cardId: res.data.card.id,
        customerId: res.data.customerId
    });
}

export const fetchClientProfile = () => {
    return async (dispatch) => {
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const ref = doc(db, `clients`, id);
        const profileListener = onSnapshot(ref, (snap) => {
            const profile = { ...snap.data(), id }
            dispatch({
                type: CLIENT_FETCH_PROFILE,
                payload: {
                    profile,
                    profileListener,
                }
            })
        })
    }
}

export const fetchAppointments = () => {
    return async (dispatch) => {
        const db = getFirestore();
        const userId = getAuth().currentUser.uid;
        const ref = collection(db, 'appointments');
        const w = where('clientId', '==', userId);
        const q = query(ref, w);
        try {
            const appointmentsUnsub = onSnapshot(q, (snap) => {
                const { results: appointments } = parseDocs(snap);
                dispatch({
                    type: CLIENT_FETCH_APPOINTMENTS,
                    payload: {
                        appointments,
                        appointmentsUnsub
                    }
                });
            })
        } catch (ex) {
            console.clear();
            console.log(ex);
        }
    }
}

export const fetchNotifications = () => {
    return async (dispatch) => {
        const db = getFirestore();
        const auth = getAuth();
        const ref = collection(db, `clients/${auth.currentUser.uid}/notifications`);
        const q = query(ref, orderBy('time', 'desc'));
        const notiListener = onSnapshot(q, (snap) => {
            const { results: notis } = parseDocs(snap);
            dispatch({ type: CLIENT_FETCH_NOTIS, payload: { notiListener, notis }});
        }) 
    }
}

export const clearClient = () => {
    return { type: CLIENT_CLEAR };
}