import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, Appointment, Scroll, Empty } from '.';
import { CLIENT } from '../constants/ClientRoutes';
import _ from 'lodash';
import { collection, doc, getDocs, getFirestore, where, query, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { parseDocs } from '../util/FirestoreUtil';

class AppointmentsModal extends Component {

    componentDidUpdate = (prevProps) => {
        const { open } = this.props;
        const { open: prevOpen } = prevProps;
        if (open && !prevOpen) {
            this.deleteOldAppointments();
        } 
    }

    getTodaysTimeAtMidnight = () => {
        const currentDate = new Date();
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const day = currentDate.getDate();
        const todaysTimeAtMidnight = new Date(year, month, day).getTime();
        return todaysTimeAtMidnight;
    }
    
    buildQueryForOldAppointments = (db) => {
        const todaysTimeAtMidnight = this.getTodaysTimeAtMidnight();
        const userId = getAuth().currentUser.uid;
        const ref = collection(db, `appointments`);
        const w1 = where('clientId', '==', userId);
        const w2 = where('unixTime', '<', todaysTimeAtMidnight);
        const q = query(ref, w1, w2);
        return q;
    }

    deleteOldAppointment = async (db, appointment) => {
        const ref = doc(db, `appointments/${appointment.id}`);
        await deleteDoc(ref);
    }

    deleteOldAppointments = async () => {
        var db = getFirestore();
        const q = this.buildQueryForOldAppointments(db);
        const snap = await getDocs(q).then();
        const { results } = parseDocs(snap);
        for (let i = 0; i < results.length; i++) {
            const appointment = results[i];
            await this.deleteOldAppointment(db, appointment);
        }
    }

    navToViewAppointment = (appointment) => {
        window.location.hash = `${CLIENT.CLIENT_VIEW_APPOINTMENT}/${appointment.id}`
    }

    renderAppointment = (appointment, index) => {
        return (
            <Appointment 
                appointment={appointment}
                key={index}
                clientPerspective
                onClick={this.navToViewAppointment}
            />
        )
    }

    renderAppointments = () => {
        const appointments = _.orderBy(this.props.appointments, 'unixTime', 'asc');
        return (
            <Scroll>
                {appointments.map(this.renderAppointment)}
            </Scroll>
        )
    }

    renderEmpty = () => {
        return (
            <Empty 
                message="You don't have any appointments"
            />
        )
    }

    renderDecide = () => {
        if (this.props.appointments.length > 0) {
            return this.renderAppointments();
        }
        return this.renderEmpty();
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title='My Upcoming Appointments'
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

AppointmentsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    appointments: PropTypes.array
};

AppointmentsModal.defaultProps = {
    open: false,
    onClose: () => {},
    appointments: []
}

export default AppointmentsModal;