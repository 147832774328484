import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    SettingsTitle,
    UpdateBio,
    UpdatePhone,
    UpdateEmail,
    UpdatePassword,
    Option,
    UpdateArtistType,
    BigTitle
} from '.';
import { AiFillPhone } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import { 
    OPTION_NO_SHOW, 
    OPTION_RESCHEDULE 
} from '../universalStyles/Styles';
import { FiMessageSquare } from 'react-icons/fi';
import { BsCalendar, BsFillPersonFill } from 'react-icons/bs';
import { FaDollarSign } from 'react-icons/fa';
import { MdPassword } from 'react-icons/md';
import { ARTIST } from '../constants/ClientRoutes';
import { IoClose } from 'react-icons/io5';

class ArtistAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bioOpen: false,
            phoneOpen: false,
            emailOpen: false,
            passwordOpen: false,
            artistTypeOpen: false,
        
        }
    }

    navToScheduling = () => {
        window.location.hash = ARTIST.ARTIST_TO_SCHEDULING;
    }

    navToUpgrade = () => {
        window.location.hash = ARTIST.ARTIST_TO_UPGRADE;
    }

    toggleBio = () => {
        this.setState({ bioOpen: !this.state.bioOpen });
    }

    togglePhone = () => {
        this.setState({ phoneOpen: !this.state.phoneOpen });
    }

    toggleEmail = () => {
        this.setState({ emailOpen: !this.state.emailOpen });
    }

    togglePassword = () => {
        this.setState({ passwordOpen: !this.state.passwordOpen });
    }

    toggleArtistType = () => {
        this.setState({ artistTypeOpen: !this.state.artistTypeOpen })
    }

    navToDeleteProfile = () => {
        window.location.hash = ARTIST.ARTIST_DELETE_PROFILE;
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Account'
            />
        )
    }

    renderTitle = () => {
        return (
            <BigTitle title='Account' />
        )
    }

    renderDowngradeToScheduling = () => {
        if (this.props.profile.stripeAccountId) {
            return (
                <Option 
                    label='Downgrade to Just Scheduling'
                    icon={<BsCalendar />}
                    backgroundColor={OPTION_NO_SHOW}
                    onClick={this.navToScheduling}
                />
            )
        }
    }

    renderUpdgradeToPayments = () => {
        if (!this.props.profile.stripeAccountId) {
            return (
                <Option 
                    label='Upgrade to Scheduling + Payments'
                    icon={<FaDollarSign />}
                    backgroundColor='#51DB2C'
                    onClick={this.navToUpgrade}
                />
            )
        }
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                <Option 
                    label='Change Phone'
                    backgroundColor='#D735AF'
                    onClick={this.togglePhone}
                    icon={<AiFillPhone />}
                />
                <Option 
                    label='Change Email'
                    icon={<MdEmail />}
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.toggleEmail}
                />
                <Option 
                    label='Change Artist Type'
                    icon={<BsFillPersonFill />}                    
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.toggleArtistType}
                />
                <Option 
                    label='Change Bio'
                    icon={<FiMessageSquare />}
                    backgroundColor={OPTION_NO_SHOW}
                    onClick={this.toggleBio}
                />
                <Option 
                    label='Change Password'
                    icon={<MdPassword />}
                    backgroundColor='#FF3A3A'
                    onClick={this.togglePassword}
                />
                <Option 
                    label='Delete Account'
                    icon={<IoClose />}
                    backgroundColor='#FF3A3A'
                    onClick={this.navToDeleteProfile}
                />
                {this.renderDowngradeToScheduling()}
                {this.renderUpdgradeToPayments()}
            </Body>
        )
    }

    renderPhone = () => {
        return (
            <UpdatePhone 
                profile={this.props.profile}
                onBack={this.togglePhone}
            />
        )
    }

    renderArtistType = () => {
        return (
            <UpdateArtistType 
                profile={this.props.profile}
                onBack={this.toggleArtistType}
            />
        )
    }

    renderEmail = () => {
        return (
            <UpdateEmail 
                profile={this.props.profile}
                onBack={this.toggleEmail}
            />
        )
    }

    renderPassword = () => {
        return (
            <UpdatePassword 
                profile={this.props.profile}
                onBack={this.togglePassword}
            />
        )
    }

    renderBio = () => {
        return (
            <UpdateBio 
                profile={this.props.profile}
                onBack={this.toggleBio}
            />
        )
    }

    renderDecide = () => {
        if (this.state.phoneOpen) {
            return this.renderPhone();
        }
        if (this.state.emailOpen) {
            return this.renderEmail();
        }
        if (this.state.passwordOpen) {
            return this.renderPassword();
        }
        if (this.state.bioOpen) {
            return this.renderBio();
        }
        if (this.state.artistTypeOpen) {
            return this.renderArtistType();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

ArtistAccount.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object
};

ArtistAccount.defaultProps = {
    onBack: () => {},
    profile: {}
}

export default ArtistAccount;