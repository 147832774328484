import { 
    MON,
    TUE,
    WED,
    THUR,
    FRI,
    SAT,
    SUN,

    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
} from '../constants/Days';

export const translateDay = (dayAbbrev) => {
    if (dayAbbrev === MON) {
        return MONDAY;
    }
    if (dayAbbrev === TUE) {
        return TUESDAY;
    }
    if (dayAbbrev === WED) {
        return WEDNESDAY;
    }
    if (dayAbbrev === THUR) {
        return THURSDAY;
    }
    if (dayAbbrev === FRI) {
        return FRIDAY;
    }
    if (dayAbbrev === SAT) {
        return SATURDAY;
    }
    if (dayAbbrev === SUN) {
        return SUNDAY;
    }
}

export const listifyRepeatHash = (repeatHash) => {
    const repeatKeys = Object.keys(repeatHash);
    const repeatList = [];
    for (let i = 0; i < repeatKeys.length; i++) {
        const repeatKey = repeatKeys[i];
        const day = translateDay(repeatKey);
        repeatList.push(day);
    }
    return repeatList;
}

export const reverseTranslateDay = (day) => {
    if (day === MONDAY) {
        return MON;
    }
    if (day === TUESDAY) {
        return TUE;
    }
    if (day === WEDNESDAY) {
        return WED;
    }
    if (day === THURSDAY) {
        return THUR;
    }
    if (day === FRIDAY) {
        return FRI;
    }
    if (day === SATURDAY) {
        return SAT;
    }
    if (day === SUNDAY) {
        return SUN;
    }
}

export const convertNumberToDay = (number, abbrev = false) => {
    switch (number) {
        case 0:
            if (abbrev) {
                return SUN;
            }
            return SUNDAY;
        case 1:
            if (abbrev) {
                return MON;
            }
            return MONDAY;
        case 2:
            if (abbrev) {
                return TUE;
            }
            return TUESDAY;
        case 3:
            if (abbrev) {
                return WED;
            }
            return WEDNESDAY;
        case 4:
            if (abbrev) {
                return THUR;
            }
            return THURSDAY;
        case 5:
            if (abbrev) {
                return FRI;
            }
            return FRIDAY;
        case 6:
            if (abbrev) {
                return SAT;
            }
            return SATURDAY;
        default:
            return MONDAY;
    }
}

export const readifyDay = (day) => {
    const firstLetter = day[0].toUpperCase();
    const rest = day.substring(1, day.length).toLowerCase();
    return `${firstLetter}${rest}`;
}