import React from 'react';
import PropTypes from 'prop-types';
import './styles/Artist2.css';
import { buildProfileImageUrl } from '../util/ImageUtil';
import { convertMetersToMiles } from '../util/DistanceUtil';

const Artist2 = ({ artist, onClick }) => {

    const click = () => {
        onClick(artist);
    }

    const renderImage = () => {
        const url = buildProfileImageUrl(90, 90, artist.objectID, artist.profileImage, true);
        return (
            <img src={url} className='A-Image' />
        )
    }

    const renderRight = () => {
        return (
            <div className='A-Right'>
                <h4 className='A-Name'>{artist.name}</h4>
                <div className='A-RowContainer'>
                    <p style={styles.marginLeft} className='A-SubTitle'>{artist.artistType}</p>
                    <div className='A-Sep' />
                    <p className='A-SubTitle'>{artist.salonName}</p>
                </div>
                {renderDistance()}
            </div>
        )
    }

    const renderDistance = () => {
        if (artist._rankingInfo) {
            const miles = convertMetersToMiles(artist._rankingInfo.geoDistance);
            return (
                <p className='A-Dist'>{`${miles} mi away`}</p>
            )
        }
    }

    return (
        <div
            className='A-Container'
            onClick={click}
        >
            {renderImage()}
            {renderRight()}
        </div>
    )
}

const styles = {
    marginLeft: {
        marginLeft: 10
    }
}

Artist2.propTypes = {
    artist: PropTypes.object,   
    onClick: PropTypes.func
};

Artist2.defaultProps = {
    artist: {},
    onClick: () => {}
};

export default Artist2;