import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    BottomUpModal,
    BrandButton,
    FlexRow,
    PriceButton
} from '../components';
import './styles/PriceControl.css';
import { BOTTOM_BUTTON, MARGIN_RIGHT } from '../universalStyles/Styles';

class PriceControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            price: this.convertNumberPriceToString(props.price)
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open: prevOpen } = prevProps;
        const { open } = this.props;
        if (!prevOpen && open) {
            this.setState({ 
                price: this.convertNumberPriceToString(this.props.price)
            });
        }
    }

    priceChange = (number) => {
        if (number === '') {
            this.setState({ price: '' });
        } else {
            const { price } = this.state;
            const newPrice = price + number;
            this.setState({ price: newPrice });
        }
    }

    convertStringPriceToNumberPrice = () => {
        const { price } = this.state;
        if (price > 0) {
            const priceInNumber = parseInt(price);
            const formattedPrice = (priceInNumber / 100).toFixed(2);
            return formattedPrice;
        }
        return 0;
    }

    convertNumberPriceToString = (price) => {
        if (price > 0) {
            return `${(parseInt(price) * 100).toString()}`;
        }
        return '';
    }

    canSave = () => {
        const price = this.convertStringPriceToNumberPrice();
        if (price > 0) {
            return true;
        }
        return false;
    }

    onSave = () => {
        const price = this.convertStringPriceToNumberPrice();
        this.props.onSave(price);
        this.props.onClose();
    }

    formatPrice = () => {
        const price = this.convertStringPriceToNumberPrice();
        if (price) {
            return `$${price.toString()}`;
        }
        return '$0'
    }

    renderBody = () => {
        return (
            <div className='PriceControl-Body'>
                <FlexRow style={styles.row}>
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='1'
                        value='1'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='2'
                        value='2'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        label='3'
                        value='3'
                        onClick={this.priceChange}
                    />
                </FlexRow>
                <FlexRow style={styles.row}>
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='4'
                        value='4'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='5'
                        value='5'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        label='6'
                        value='6'
                        onClick={this.priceChange}
                    />
                </FlexRow>
                <FlexRow style={styles.row}>
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='7'
                        value='7'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        style={MARGIN_RIGHT}
                        label='8'
                        value='8'
                        onClick={this.priceChange}
                    />
                    <PriceButton
                        label='9'
                        value='9'
                        onClick={this.priceChange}
                    />
                </FlexRow>
                <FlexRow style={styles.row}>
                    <PriceButton 
                        style={styles.zero}
                        label='0'
                        value='0'
                        onClick={this.priceChange}
                    />
                    <PriceButton 
                        label='C'
                        value=''
                        onClick={this.priceChange}
                    />
                </FlexRow>
            </div>
        )
    }

    renderPriceRow = () => {
        return (
            <div className='PriceControl-PriceRow'>
                <h2 className='PriceControl-Price'>{this.formatPrice()}</h2>
            </div>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                disabled={!this.canSave()}
                onClick={this.onSave}
            />
        )
    }
    
    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={.85}
                title='Decide the price of your service'
                image={this.props.image}
                darken
            >
                <div className='PriceControl-Container'>
                    {this.renderPriceRow()}
                    {this.renderBody()}
                    {this.renderSave()}
                </div>
            </BottomUpModal>
        )
    }
}

const styles = {
    row: {
        justifyContent: 'space-around',
        width: '100%'
    },
    zero: {
        marginRight: 10,
        flex: 2.2
    }
}

PriceControl.propTypes = {
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    price: PropTypes.number,
    image: PropTypes.string
};

PriceControl.defaultProps = {
    open: false,
    onSave: () => {},
    onClose: () => {},
    price: 0,
    image: ''
}

export default PriceControl;
