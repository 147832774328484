import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/Bio.css';

class Bio extends Component {
    render = () => {
        return (
            <div className='Bio-Bio'>
                {this.props.profile.bio}
            </div>
        )
    }
}

Bio.propTypes = {
    profile: PropTypes.object
}

Bio.defaultProps = {
    profile: {}
}

export default Bio;