import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    SettingsTitle,
    ModalRowBack,
    Option,
    FlexRow,
    FlexRowButton,
    Select,
    BrandButton,
    BigTitle,
    Scroll,
    GenOption,
    Value,
    ButtonLabel
} from '.';
import { IoLogoUsd } from 'react-icons/io5';
import { BOTTOM_BUTTON, OPTION_NO_SHOW } from '../universalStyles/Styles';
import { CANCEL_FEE, NO_SHOW_FEE } from '../constants/Policies';
import { getFirestore, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';

class UpdateFee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            noShowOpen: false,
            cancelOpen: false,
            cancelPercentage: props.policyHash[CANCEL_FEE],
            noShowPercentage: props.policyHash[NO_SHOW_FEE],
            cancelLeft: 0,
            cancelOptionLeft: window.screen.width,
            noShowLeft: 0,
            noShowOptionLeft: window.screen.width,
        }
    }

    navToCancelOption = () => {
        this.setState({
            cancelLeft: -window.screen.width,
            cancelOptionLeft: 0
        });
    }

    navToCancel = () => {
        this.setState({
            cancelLeft: 0,
            cancelOptionLeft: window.screen.width,
        });
    }

    navToNoShowOption = () => {
        this.setState({
            noShowLeft: -window.screen.width,
            noShowOptionLeft: 0
        });
    }

    navToNoShow = () => {
        this.setState({
            noShowLeft: 0,
            noShowOptionLeft: window.screen.width
        })
    }

    doesPolicyExists = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/policies/policies`);
        const snap = await getDoc(ref);
        if (snap.exists) {
            return true;
        }
        return false;
    }

    saveNoShowFee = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/policies/policies`);
        if (this.doesPolicyExists()) {
            await updateDoc(ref, {
                [NO_SHOW_FEE]: this.state.noShowPercentage
            })
        } else {
            await setDoc(ref, {
                [NO_SHOW_FEE]: this.state.noShowPercentage
            });
        }
        this.toggleNoShow();
    }

    saveCancelFee = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}/policies/policies`);
        if (this.doesPolicyExists()) {
            await updateDoc(ref, {
                [CANCEL_FEE]: this.state.cancelPercentage
            })
        } else {
            await setDoc(ref, {
                [CANCEL_FEE]: this.state.cancelPercentage
            });
        }
        this.toggleCancel();
    }

    cancelChange = ({ value }) => {
        this.setState({ cancelPercentage: value });
        this.navToCancel();
    }

    noShowChange = ({ value }) => {
        this.setState({ noShowPercentage: value });
        this.navToNoShow();
    }

    toggleNoShow = () => {
        this.setState({ noShowOpen: !this.state.noShowOpen });
    }

    toggleCancel = () => {
        this.setState({ cancelOpen: !this.state.cancelOpen });
    }

    generatePercentages = () => {
        const percentages = [];
        for (let i = 0; i <= 100; i += 5) {
            const percentage = i / 100;
            const label = `${(percentage * 100).toFixed(0)}%`;
            percentages.push({ label, value: percentage });
        }
        return percentages;
    }

    getFee = (feeType) => {
        if (this.props.policyHash[feeType]) {
            return `${(this.props.policyHash[feeType] * 100).toFixed(0)}%`;
        }
        return 'none';
    }

    renderTitle = () => {
        return (
            <SettingsTitle title='Appointment Fees' />
        )
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Appointment Fees'
            />
        )
    }

    renderNoShowFee = () => {
        const fee = this.getFee(NO_SHOW_FEE);
        return (
            <Option 
                label='No Show Fee'
                icon={<IoLogoUsd />}
                backgroundColor={OPTION_NO_SHOW}
                showArrow
                onClick={this.toggleNoShow}
                rightLabel={fee}
            />
        )
    }

    renderCancelFee = () => {
        const fee = this.getFee(CANCEL_FEE)
        return (
            <Option 
                label='Cancellation Fee'
                icon={<IoLogoUsd />}
                backgroundColor={OPTION_NO_SHOW}
                showArrow
                onClick={this.toggleCancel}
                rightLabel={fee}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderNoShowFee()}
                {this.renderCancelFee()}
            </Body>
        )
    }

    renderCancelBack = () => {
        return (
            <ModalRowBack 
                onBack={this.toggleCancel}
                title='Cancel Fee'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Appointment Fees'
            />
        )
    }

    renderCancelSelect = () => {
        return (
            <FlexRow>
                <FlexRowButton 
                    style={styles.button}
                    onClick={this.navToCancelOption}
                >
                    <Value value={`${(this.state.cancelPercentage * 100)}% Fee`} />
                    <ButtonLabel 
                        label='If a client cancels within 1 day of an appointment,
                        they will automatically be charged a fee based off a
                        percentage of the cost of the appointment. The client must
                        agree to this when they book the appointment.'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderNoShowSelect = () => {
        return (
            <FlexRow>
                <FlexRowButton
                    style={styles.button}
                    onClick={this.navToNoShowOption}
                >
                    <Value value={`${(this.state.noShowPercentage * 100)}% Fee`} />
                    <ButtonLabel 
                        label='If a client doesnt show up for an appointment 
                        without notice, you reserve the right to charge 
                        them a "no show" fee. You can determine what 
                        percentage of the appointment cost to charge them. 
                        The client must accept this policy when they book an 
                        appointment.'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderCancel = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.cancelLeft }}
            >
                {this.renderCancelBack()}
                <BigTitle 
                    title='Cancellation Fee'
                />
                {this.renderCancelSelect()}
                <BrandButton 
                    label='Save'
                    style={BOTTOM_BUTTON}
                    onClick={this.saveCancelFee}
                />
            </Body>
        )
    }

    renderCancelBody = () => {
        return (
            <Body relative>
                {this.renderCancel()}
                {this.renderCancelOptions()}
            </Body>
        )
    }

    renderNoShowBack = () => {
        return (
            <ModalRowBack 
                title='No Show Fee'
                onBack={this.toggleNoShow}
            />
        )
    }

    renderNoShow = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.noShowLeft }}
            >
                {this.renderNoShowBack()}
                <BigTitle title='No Show Fee' />
                {this.renderNoShowSelect()}
                <BrandButton 
                    label='Save'
                    style={BOTTOM_BUTTON}
                    onClick={this.saveNoShowFee}
                />
            </Body>
        )
    }

    renderNoShowPercentage = (percentage, index) => {
        return (
            <GenOption 
                key={index}
                option={percentage}
                label={percentage.label}
                onClick={this.noShowChange}
            />
        )
    }

    renderCancelPercentage = (percentage, index) => {
        return (
            <GenOption 
                key={index}
                option={percentage}
                label={percentage.label}
                onClick={this.cancelChange}
            />
        )
    }

    renderNoShowOptions = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.noShowOptionLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={this.navToNoShow}
                />
                <BigTitle title='Pick a percentage' />
                <Scroll style={styles.scroll}>
                    {this.generatePercentages().map(this.renderNoShowPercentage)}
                </Scroll>
            </Body>
        )
    }

    renderCancelOptions = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.cancelOptionLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={this.navToCancel}
                />
                <BigTitle title='Pick a percentage' />
                <Scroll style={styles.scroll}>
                    {this.generatePercentages().map(this.renderCancelPercentage)}
                </Scroll>
            </Body>
        )
    }

    renderNoShowBody = () => {
        return (
            <Body relative>
                {this.renderNoShow()}
                {this.renderNoShowOptions()}
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.cancelOpen) {
            return this.renderCancelBody();
        }
        if (this.state.noShowOpen) {
            return this.renderNoShowBody();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    button: {
        width: '95%'
    },
    scroll: {
        marginBottom: 40
    }
}

UpdateFee.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    policyHash: PropTypes.object
};

UpdateFee.defaultProps = {
    onBack: () => {},
    profile: {},
    policyHash: {}
}

export default UpdateFee;