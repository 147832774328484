import React from 'react';
import PropTypes from 'prop-types';
import { IoIosThumbsDown, IoIosThumbsUp } from 'react-icons/io';
import { getTimeSince } from '../util/TimeUtil';
import './styles/Rating.css';

const Ratings = ({ rating }) => {

    const decideRating = () => {
        if (rating.type === 'LIKE') {
            return (
                <IoIosThumbsUp />
            )
        }
        return (
            <IoIosThumbsDown />
        )
    }

    const renderTimeStamp = () => {
        const since = getTimeSince(rating.time);
        return (
            <p className='Rating-Time'>{since}</p>
        )
    }

    return (
        <div className='Rating-Container' id={rating.id}>
            <div className='Rating-Circle'>
                {decideRating()}
            </div>
            <h3 className='Rating-Name'>{rating.name}</h3>
            {renderTimeStamp()}
        </div>
    )
}

Ratings.propTypes = {
    rating: PropTypes.object,
};

Ratings.defaultProps = {
    rating: {},
}

export default Ratings;
