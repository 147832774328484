import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import './styles/ImageProgress.css';

class ImageProgress extends Component {

    decideTop = () => {
        if (this.props.imageProgressOpen) {
            return 0;
        }
        return -110;
    }

    render = () => {
        return (
            <div 
                className='IP-Container'
                style={{ top: this.decideTop() }}
            >
                <div 
                    className='IP-Inner'
                    style={{ width: window.screen.width * 0.90, height: 70 }}
                >
                    <div
                        className='IP-Inner2'
                        style={{ width: (window.screen.width * 0.90) - 8, height: 62, top: 4, left: 4 }}
                    >
                        <ReactLoading 
                            type='spokes'
                            color='#fff'
                            width={30}
                            height={30}
                        />
                        <h4 className='IP-Message'>{this.props.imageProgressMessage}</h4>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        imageProgressOpen,
        imageProgressMessage
    } = state.artist;
    return {
        imageProgressOpen,
        imageProgressMessage
    };
}

export default connect(mapStateToProps, {})(ImageProgress);