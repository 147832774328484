import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Body,
    ModalRowBack,
    SettingsTitle,
    Option,
    UpdateNotiType
} from '.';
import { 
    IoAddSharp, 
    IoCloseSharp, 
    IoRepeatSharp, 
    IoPencil, 
    IoPersonAddOutline, 
    IoCardOutline,
    IoLogoUsd
} from 'react-icons/io5';
import { 
    APPOINTMENT, 
    CANCEL, 
    RESCHEDULE, 
    REVIEW, 
    CLIENT as CLIENT_TYPE,  
    translateMethod, 
    PAYMENT,
    PAYOUT
} from '../constants/Policies';

class UpdateNoti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: '',
        }
    }

    setType = (type) => {
        this.setState({ type });
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Notification Settings'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Notification Settings'
            />
        )
    }

    renderNewPaymentSetting = () => {
        if (this.props.profile.stripeAccountId) {
            return (
                <Option  
                    label='New Payment'
                    icon={<IoCardOutline />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(PAYMENT)}
                    rightLabel={translateMethod(this.props.notiHash[PAYMENT])}
                />
            )

        }
    }

    renderNewPayoutSetting = () => {
        if (this.props.profile.stripeAccountId) {
            return (
                <Option 
                    label='New Payout'
                    icon={<IoLogoUsd />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(PAYOUT)}
                    rightLabel={translateMethod(this.props.notiHash[PAYOUT])}
                />
            )
        }
    }

    renderUpdate = () => {
        return (
            <UpdateNotiType 
                profile={this.props.profile}
                onBack={() => this.setType('')}
                type={this.state.type}
                notiHash={this.props.notiHash}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                <Option 
                    label='New Appointment'
                    icon={<IoAddSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(APPOINTMENT)}
                    rightLabel={translateMethod(this.props.notiHash[APPOINTMENT])}
                />
                <Option 
                    label='Cancellation'
                    icon={<IoCloseSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(CANCEL)}
                    rightLabel={translateMethod(this.props.notiHash[CANCEL])}
                />
                <Option 
                    label='Reschedule'
                    icon={<IoRepeatSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(RESCHEDULE)}
                    rightLabel={translateMethod(this.props.notiHash[RESCHEDULE])}
                />
                <Option 
                    label='New Review'
                    icon={<IoPencil />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(REVIEW)}
                    rightLabel={translateMethod(this.props.notiHash[REVIEW])}
                />
                <Option 
                    label='New Client'
                    icon={<IoPersonAddOutline />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(CLIENT_TYPE)}
                    rightLabel={translateMethod(this.props.notiHash[CLIENT_TYPE])}
                />
                {this.renderNewPaymentSetting()}
                {this.renderNewPayoutSetting()}
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.type) {
            return this.renderUpdate();
        }
        return this.renderContent();
    }
    
    render = () => {
        console.log(this.props.notiHash);
        return this.renderDecide();
    }
}

UpdateNoti.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    notiHash: PropTypes.object
};

UpdateNoti.defaultProps = {
    onBack: () => {},
    profile: {},
    notiHash: {}
}

export default UpdateNoti;