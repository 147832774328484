import React from 'react';
import PropTypes from 'prop-types';
import './styles/PriceLabel.css';

const PriceLabel = ({ label, style, onClick }) => {
    return (
        <div 
            className='PriceLabel-Container' 
            style={style}
            onClick={onClick}
        >
            <h4 className='PriceLabel-Label'>{label}</h4>
        </div>

    )
}

PriceLabel.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
};

PriceLabel.defaultProps = {
    label: '',
    style: {},
    onClick: () => {}
}

export default PriceLabel;