import React from 'react';
import { BsPlusSquare, BsPin } from 'react-icons/bs';
import { IoShareOutline, IoReorderThree } from 'react-icons/io5';
import {
    ArgendaLogo,
    ImageBackground,
    Body,
} from '.';
import { isChrome, isDuckDuckGo, isEdge, isFirefox, isOpera } from '../util/DeviceUtil';
import { buildProfileImageUrl } from '../util/ImageUtil';
import './styles/PWA.css';


const PWA = ({ }) => {

    const renderSafariMessage = () => {
        return (
            <span className='PWA-Title'><h1>To install Argenda : tap <IoShareOutline /> and then "Add To Home Screen" <BsPlusSquare /></h1></span>
        )
    }

    const renderFirefoxMessage = () => {
        return (
            <span className='PWA-Title'><h1>To install Argenda : tap <IoReorderThree /> and then "Add To Shortcuts" <BsPin className='PWA-FirePin' /></h1></span>
        )
    }

    const renderChromeMessage = () => {
        return (
            <span className='PWA-Title'><h1>You are currently using Chrome. To install Argenda on iOS you must use Safari or Firefox.</h1></span>
        )
    }

    const renderEdgeMessage = () => {
        return (
            <span className='PWA-Title'><h1>You are currently using Edge. To install Argenda on iOS you must use Safari or Firefox.</h1></span>
        )
    }

    const renderOperaMessage = () => {
        return (
            <span className='PWA-Title'><h1>You are currently using Opera. To install Argenda on iOS you must use Safari or Firefox.</h1></span>
        )
    }

    const renderDuckDuckGoMessage = () => {
        return (
            <span className='PWA-Title'><h1>You are currently using DuckDuckGo. To install Argenda on iOS you must use Safari or Firefox.</h1></span>
        )
    }

    const renderDecideMessage = () => {
        if (isOpera()) {
            return renderOperaMessage();
        }
        if (isEdge()) {
            return renderEdgeMessage();
        }
        if (isChrome()) {
            return renderChromeMessage();
        }
        if (isFirefox()) {
            return renderFirefoxMessage();
        }
        if (isDuckDuckGo()) {
            return renderDuckDuckGoMessage();
        }
        return renderSafariMessage();
    }
    return (
        <ImageBackground image={buildProfileImageUrl(window.screen.width, window.screen.height, '', '', '')}>
            <ArgendaLogo 
                width={window.screen.width * 0.85}
                style={styles.logo}
            />
            <Body>
                <h1 style={styles.welcome} className='PWA-Title'>Welcome</h1>
                {renderDecideMessage()}
            </Body>
        </ImageBackground>
    )
}

const styles = {
    logo: {
        marginTop: 30
    },
    welcome: {
        marginTop: '10%'
    }
}

export default PWA;