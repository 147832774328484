import React from 'react';
import PropTypes from 'prop-types';
import './styles/NotiBadge.css';
import { BsFillBellFill } from 'react-icons/bs';

const NotiBadge = ({ count, onClick, isAvatar }) => {

    const decideClass = () => {
        if (isAvatar) {
            return 'NB-BadgeContainer NB-Avatar';
        }
        return 'NB-BadgeContainer';
    }

    return (
        <div 
            className={decideClass()}
            onClick={onClick}
        >
            <BsFillBellFill className='NB-BadgeIcon' />
            <p className='NB-Badge'>{count}</p>
        </div>
    )
}

NotiBadge.propTypes = {
    count: PropTypes.number,
    onClick: PropTypes.func,
    isAvatar: PropTypes.bool,
};

NotiBadge.defaultProps = {
    count: 0,
    onClick: () => {},
    isAvatar: false
};;

export default NotiBadge;