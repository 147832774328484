import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    SettingsTitle,
    FlexRow,
    FlexRowButton,
    TransparentInput,
    BrandButton,
    SettingsLoader,
    ModalRowBack
} from '../components';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    updatePassword 
} from 'firebase/auth';
class UpdatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            success: false,
            currentPassword: '',
            newPassword: '',
        }
    }

    canSave = () => {
        if (this.state.currentPassword && this.state.newPassword) {
            return true;
        }
        return false;
    }

    savePassword = async () => {
        try {
            this.setState({ loading: true });
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, this.props.profile.email, this.state.currentPassword);
            await updatePassword(auth.currentUser, this.state.newPassword);
            this.setState({ success: true, error: false });
        }
        catch (ex) {
            console.log(ex);
            this.setState({ error: true, loading: false, currentPassword: '', newPassword: '' });
        }
    }

    newPasswordChange = (newPassword) => {
        this.setState({ newPassword });
    }

    currentPasswordChange = (currentPassword) => {
        this.setState({ currentPassword });
    }

    focusNewPassword = () => {
        if (this.newPasswordRef) {
            this.newPasswordRef.focus();
        }
    }

    focusCurrentPassword = () => {
        if (this.currentPasswordRef) {
            this.currentPasswordRef.focus();
        }
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title='Update Password'
                onBack={this.props.onBack}
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Password'
            />
        )
    }

    renderError = () => {
        if (this.state.error) {
            return (
                <SettingsTitle 
                    title='Invalid Password'
                    error
                />
            )
        }
    }
    
    
    renderNewPassword = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusNewPassword}>
                    <TransparentInput 
                        value={this.state.newPassword}
                        onChange={this.newPasswordChange}
                        passRefUp={ref => this.newPasswordRef = ref}
                        placeholder='New Password'
                        type='password'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderCurrentPassword = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusCurrentPassword}>
                    <TransparentInput 
                        value={this.state.currentPassword}
                        onChange={this.currentPasswordChange}
                        passRefUp={ref => this.currentPasswordRef = ref}
                        placeholder='Current Password'
                        type='password'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                disabled={!this.canSave()}
                onClick={this.savePassword}
            />
        )
    }

    renderLoader = () => {
        return (
            <SettingsLoader 
                message={this.state.success ? 'Password Updated.' : 'Updating Password...'}
                success={this.state.success}
                onClick={this.props.onBack}
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderError()}
                {this.renderNewPassword()}
                {this.renderCurrentPassword()}
                {this.renderSave()}
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

UpdatePassword.propTypes = {
    profile: PropTypes.object,
    onBack: PropTypes.func
};

UpdatePassword.defaultProps = {
    profile: {},
    onBack: () => {}
}

export default UpdatePassword;