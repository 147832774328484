import React from 'react';
import { Body, ImageInput  } from '.';
import PropTypes from 'prop-types';
import './styles/ProfileImageInput.css';
import { IoPersonOutline } from 'react-icons/io5';
import { parseFile } from '../util/ImageUtil';

const ProfileImageInput = ({ onChange, value }) => {

    const pickImage = () => {
        document.getElementById('imagePicker').click();
    }

    const change = (files) => {
        const profileImage = parseFile(files[0]);
        onChange(profileImage);
    }

    const renderImage = () => {
        return (
            <div 
                className='PII-ImageContainer'
                onClick={pickImage}
            >
                <img 
                    className='PII-Image'
                    src={value.url}
                />
            </div>
        )
    }

    const renderImageholder = () => {
        return (
            <div
                className='PII-ImageHolder'
                onClick={pickImage}
            >
                <IoPersonOutline />
            </div>
        )
    }

    const renderImagePicker = () => {
        return (
            <ImageInput 
                id='imagePicker'
                onChange={change}
            />
        )
    }

    const renderDecide = () => {
        if (value) {
            return renderImage();
        }
        return renderImageholder();
    }

    return (
        <Body>
            {renderDecide()}
            {renderImagePicker()}
        </Body>
    )
}

const styles = {

}

ProfileImageInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object
};

ProfileImageInput.defaultProps = {
    onChange: () => {},
    value: {}
};

export default ProfileImageInput;