import React from 'react';
import PropTypes from 'prop-types';
import './styles/ModalInput.css';

const ModalInput = ({ value, onChange, placeholder, style }) => {

    const change = (e) => {
        onChange(e.target.value);
    }

    return (
        <input 
            className='ModalInput-Input'
            value={value}
            onChange={change}
            placeholder={placeholder}
            style={style}
        />
    )
}

ModalInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    style: PropTypes.object 
};

ModalInput.defaultProps = {
    value: '',
    onChange: () => {},
    placeholder: '',
    style: {}
}

export default ModalInput;