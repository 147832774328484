import React, { Component } from 'react';
import { Body, ProfileImage } from '.';
import PropTypes from 'prop-types';
import './styles/ArtistPreview.css';
import { buildImageUrl } from '../util/ImageUtil';
import { convertMetersToMiles } from '../util/DistanceUtil';

class ArtistPreview extends Component {

    renderSelectedImage = () => {
        if (this.props.image && this.props.image.imageName) {
            const width = window.screen.width;
            const height = window.screen.height * .50
            return (
                <img 
                    style={{ width, height }}
                    src={buildImageUrl(this.props.image.artistId, this.props.image.imageName, width, height)}
                    className='AP-Image'
                />
            )
        }
    }

    renderRowInfo = () => {
        return (
            <div className='AP-RowContainer'>
                <p className='AP-SubTitle'>{this.props.image.artistType}</p>
                <div className='AP-Sep' />
                <p className='AP-SubTitle'>{this.props.image.salonName}</p>
            </div>
        )
    }

    renderDistance = () => {
        console.log(this.props.image);
        if (this.props.image._rankingInfo) {
            const miles = convertMetersToMiles(this.props.image._rankingInfo.geoDistance);
            return (
                <p className='AP-Distance'>{`${miles} mi away`}</p>
            )
        }
    }

    render = () => {
        return (
            <div 
                className='AP-Container'
                ref={this.props.passRefUp}
                style={this.props.style}
                id={this.props.id}
            >
                <div className='AP-TopContainer'>
                    <ProfileImage 
                        id={this.props.image.artistId}
                        image={this.props.image.artistProfileImage}
                        size={80}
                        style={{ marginLeft: 15 }}
                    />
                    <div className='AP-DetailContainer'>
                        <h3 className='AP-Name'>{this.props.image.artistName}</h3>
                        {this.renderRowInfo()}
                        {this.renderDistance()}
                    </div>
                </div>
                {this.renderSelectedImage()}
            </div>
        )
    }
}

ArtistPreview.propTypes = {
    image: PropTypes.object,
    passRefUp: PropTypes.func,
    style: PropTypes.object,
    id: PropTypes.string,
    renderTop: PropTypes.bool,
};

ArtistPreview.defaultProps = {
    image: {},
    passRefUp: () => {},
    style: {},
    id: '',
    renderTop: false
};

export default ArtistPreview;