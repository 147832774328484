import React, { Component } from 'react';
import { IoImage } from 'react-icons/io5';
import { connect } from'react-redux';
import { 
    ImageBackground, 
    TransparentHeader,
    FlexRowButton,
    FlexRow,
    BrandButton,
    TransparentInput,
    InputLabel,
    DurationPicker,
    PriceControl,
    Value,
    PortfolioPicker
} from '../../components';
import { 
    MARGIN_RIGHT,
    BOTTOM_BUTTON 
} from '../../universalStyles/Styles';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { buildImageUrl, buildProfileImageUrl } from '../../util/ImageUtil';
import { convertMinutesToHoursAndMinutesReadable } from '../../util/TimeUtil';
import './styles/AddService.css';

class AddService extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            name: '',
            duration: null,
            price: 0,
            durationOpen: false,
            priceOpen: false,
            imageOpen: false
        }
    }

    onBack = () => {
        window.history.back();
    }

    toggleDuration = () => {
        this.setState({ durationOpen: !this.state.durationOpen });
    }

    togglePrice = () => {
        this.setState({ priceOpen: !this.state.priceOpen });
    }

    toggleImage = () => {
        this.setState({ imageOpen: !this.state.imageOpen });
    }

    focusName = () => {
        this.nameRef.focus();
    }

    focusDuration = () => {
        this.durationRef.focus();
    }

    canSave = () => {
        const { duration, price, name } = this.state;
        if (duration && price && name) {
            return true;
        }
        return false;
    }

    save = () => {
        const { image, duration, price, name } = this.state;
        const service = {
            image: image.imageName,
            duration,
            price,
            name,
            artistId: this.props.profile.id,
            customizations: 0
        }
        const id = getAuth().currentUser.uid;
        const db = getFirestore();
        const services = collection(db, `users/${id}/services`);
        addDoc(services, service);
        this.onBack();
    }

    pickImage = () => {
        document.getElementById('imagePicker').click();
    }

    nameChange = (name) => {
        this.setState({ name });
    }

    priceChange = (price) => {
        this.setState({ price });
    }

    durationChange = (duration) => {
        this.setState({ duration });
    }

    imageChange = (image) => {
        this.setState({ image })
    }

    renderTopButton = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusName}>
                    <TransparentInput 
                        value={this.state.name}
                        onChange={this.nameChange}
                        placeholder='Service Name...'
                        passRefUp={ref => this.nameRef = ref}
                    />
                    <InputLabel 
                        label='Service Name'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderOption = (option, index) => {
        return (
            <option 
                key={index} 
                value={option.value}
            >
                {option.label}
            </option>
        )
    }

    renderEmtpyDuration = () => {
        return (
            <FlexRowButton 
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <InputLabel label='Duration' />
            </FlexRowButton>
        )
    }

    renderSingleDuration = () => {
        return (
            <FlexRowButton
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.singleStart)} />
                <InputLabel label='Duration' />
            </FlexRowButton>
        )
    }

    renderDoubleDuration = () => {
        return (
            <FlexRowButton
                style={MARGIN_RIGHT}
                onClick={this.toggleDuration}
            >
                <Value value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleStart)} />
                <InputLabel 
                    label='Initial Time' 
                />
                <Value 
                    value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleBreak)} 
                    style={styles.margin}
                />
                <InputLabel 
                    label='Inbetween Time' 
                />
                <Value 
                    value={convertMinutesToHoursAndMinutesReadable(this.state.duration.doubleEnd)} 
                    style={styles.margin}
                />
                <InputLabel 
                    label='Finishing up' 
                />
            </FlexRowButton>
        )
    }

    renderDecideDuration = () => {
        if (!this.state.duration) {
            return this.renderEmtpyDuration();
        }
        if (this.state.duration.isDouble) {
            return this.renderDoubleDuration();
        }
        return this.renderSingleDuration();
    }

    renderPrice = () => {
        return (
            <FlexRowButton onClick={this.togglePrice}>
                {this.state.price > 0 ? <Value value={`$${this.state.price.toString()}`} /> : null }
                <InputLabel label='Price' />
            </FlexRowButton>
        )
    }

    renderBottomButtons = () => {
        return (
            <FlexRow style={{ flex: 1.1 }}>
                {this.renderDecideDuration()}
                {this.renderPrice()}
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                disabled={!this.canSave()}
                onClick={this.save}
            />
        )
    }

    renderDecideImage = () => {
        if (this.state.image) {
            return this.renderImage();
        }
        return this.renderImageButton();
    }

    renderImage = () => {
        const width = Math.ceil(.94 * window.screen.width);
        const height = Math.ceil(.3333 * window.screen.height);
        const url = buildImageUrl(this.props.profile.id, this.state.image.imageName, width, height)
        return (
            <img 
                className='AddService-Image'
                src={url}
                onClick={this.toggleImage}
            />
        )
    }

    renderImageButton = () => {
        return (
            <FlexRow>
                <div 
                    className='AddService-ImageHolder'
                    onClick={this.toggleImage}
                >
                    <IoImage 
                        className='AddService-ImageIcon'
                    />
                    <InputLabel 
                        label='Pick an Image to describe this service'
                        style={{ marginTop: 15 }}
                    />
                </div>
            </FlexRow>
        )
    }

    renderDurationPicker = () => {
        return (
            <DurationPicker 
                open={this.state.durationOpen}
                onClose={this.toggleDuration}
                onSave={this.durationChange}
                duration={this.state.duration}
            />
        )
    }

    renderPriceControl = () => {
        return (
            <PriceControl 
                open={this.state.priceOpen}
                onClose={this.togglePrice}
                onSave={this.priceChange}
            />
        )
    }

    renderPortfolioPicker = () => {
        return (
            <PortfolioPicker 
                open={this.state.imageOpen}
                onClose={this.toggleImage}
                onSave={this.imageChange}
            />
        )
    }

    render = () => {
        const defaultUrl = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        const urlToUse = this.state.image ? buildImageUrl(this.props.profile.id, this.state.image.imageName, window.screen.width, window.screen.height) : defaultUrl;
        return (
            <ImageBackground
                image={urlToUse}
                darken
            >
                <TransparentHeader 
                    title='Add a service'
                    onBack={this.onBack}
                />
                {this.renderTopButton()}
                {this.renderBottomButtons()}
                {this.renderDecideImage()}
                {this.renderDurationPicker()}
                {this.renderPriceControl()}
                {this.renderSave()}
                {this.renderPortfolioPicker()}
            </ImageBackground>
        )
    }
}

const styles = {
    margin: {
        marginTop: 10
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile }
}

export default connect(mapStateToProps, {})(AddService);