import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, ListWrapper, Rating } from '.';
import './styles/RatingModal.css';
import { THRESHOLD } from '../constants/ScrollThreshold';
import { 
    collection, 
    getDocs, 
    getFirestore, 
    limit, 
    query, 
    startAfter, 
    where,
    orderBy
} from '@firebase/firestore';
import { parseDocs } from '../util/FirestoreUtil';
import { DISLIKE, LIKE } from '../constants/likes';

const RATING_MODAL = 'RATING_MODAL';
const RATING_PAGE_SIZE = 20;

const INITIAL_STATE = {
    ratings: [],
    lastRating: null,
    noMoreRatings: false
}

class RatingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE}
    }
    
    componentDidMount = () => {
        window.addEventListener('touchend', this.onScroll);
    }
    

    componentDidUpdate = (prevProps) => {
        const { open: prevOpen, showLikes } = prevProps;
        const { open } = this.props;
        if (!prevOpen && open) {
            window.setTimeout(this.fetchInitialRatings, 300);
        }
        if (!open && prevOpen) {
            this.closeAndReset();
        }
    }

    closeAndReset = () => {
        this.setState({ ...INITIAL_STATE });
        window.removeEventListener('touchend', this.onScroll);
    }

    fetchInitialRatings = async () => {
        const db = getFirestore();
        const ratingsRef = collection(db, `users/${this.props.artist.id}/ratings`);
        const limitBy = limit(RATING_PAGE_SIZE);
        const filter = this.props.showLikes ? LIKE : DISLIKE;
        const whereFilter = where('type', '==', filter);
        const order = orderBy('time', 'desc');
        const ratingsQuery = query(ratingsRef, limitBy, order, whereFilter);
        const snap = await getDocs(ratingsQuery).then();
        const { results: ratings, lastDoc: lastRating } = parseDocs(snap);
        this.setState({
            ratings,
            lastRating,
            noMoreRatings: ratings.length < RATING_PAGE_SIZE ? true : false
        });
    }

    fetchMoreRatings = async () => {
        const db = getFirestore();
        const ratingsRef = collection(db, `users/${this.props.artist.id}/ratings`);
        const filter = this.props.showLikes ? LIKE : DISLIKE;
        const order = orderBy('time', 'desc');
        const whereFilter = where('type', '==', filter);
        const limitBy = limit(RATING_PAGE_SIZE);
        const start = startAfter(this.state.lastRating);
        const q = query(ratingsRef, limitBy, order, whereFilter, start);
        const snap = await getDocs(q).then();
        const { results, lastDoc: lastRating } = parseDocs(snap);
        const ratings = [...this.state.ratings, ...results];
        this.setState({
            ratings,
            lastRating,
            noMoreRatings: results.length < RATING_PAGE_SIZE ? true : false
        });
    }


    onScroll = (e) => {
        if (this.props.open && this.state.ratings.length > 0 && !this.state.noMoreRatings) {
            const ratingQuery = document.getElementById(this.state.lastRating.id);
            const lastRatingTop = ratingQuery.getBoundingClientRect().top;
            const modalQuery = document.getElementById(RATING_MODAL);
            const modalTop = modalQuery.getBoundingClientRect().top;
            const threshold = modalTop + THRESHOLD;
            if (lastRatingTop >= threshold) {
                this.fetchMoreRatings()
            }
        }

    }

    renderRating = (rating, index) => {
        return (
            <Rating 
                rating={rating}
                key={index}
            />
        )
    }

    decideTitle = () => {
        const { likes, dislikes } = this.props.artist;
        if (this.props.showLikes) {
            if (likes === 1) {
                return '1 Like';
            }
            if (likes === 0) {
                return `You don't have any likes yet.`;
            }
            if (likes > 0) {
                return `${likes} Likes`
            }
        }
        if (dislikes === 1) {
            return '1 Dislike';
        }
        if (dislikes === 0) {
            return `You don't have any dislikes yet.`;
        }
        if (dislikes > 0) {
            return `${dislikes} Dislikes`
        }
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={0.7}
                modalId={RATING_MODAL}
                title={this.decideTitle()}
            >
                <ListWrapper>
                    {this.state.ratings.map(this.renderRating)}
                </ListWrapper>
            </BottomUpModal>
        )
    }
}

RatingModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    showLikes: PropTypes.bool,
    artist: PropTypes.object
};

RatingModal.defaultProps = {
    open: false,
    onClose: () => {},
    showLikes: false,
    artist: {}
}

export default RatingModal;