import { 
    doc, 
    getDoc,
    getFirestore 
} from "@firebase/firestore"

export const NO_SHOW_FEE = 'NO_SHOW_FEE';
export const CANCEL_FEE = 'CANCEL_FEE';

export const MAX_CANCEL_DAYS = 1;

export const APPOINTMENT = 'APPOINTMENT';
export const CANCEL = 'CANCEL';
export const RESCHEDULE = 'RESCHEDULE';
export const REVIEW = 'REVIEW';
export const CLIENT = 'CLIENT';
export const PAYMENT = 'PAYMENT';
export const PAYOUT = 'PAYOUT';
export const NOSHOW = 'NOSHOW';

export const TEXT = 'TEXT';
export const IN_APP = 'IN_APP';
export const BOTH = 'BOTH';

export const fetchPolicy = async (artistId) => {
    const db = getFirestore();
    const ref = doc(db, `users/${artistId}/policies/policies`);
    const snap = await getDoc(ref);
    return snap.data();
}

export const translateMethod = (method) => {
    switch (method) {
        case TEXT:
            return 'Text';
        case IN_APP:
            return 'In App';
        case BOTH:
            return 'Both';
        default:
            return 'Text';
    }
}

export const METHODS = [
    { label: 'Text', value: TEXT },
    { label: 'In App Notification', value: IN_APP },
    { label: 'Both', value: BOTH }
]

export const DEFAULT_NOTIFICATION_HASH = {
    [APPOINTMENT]: IN_APP,
    [CANCEL]: IN_APP,
    [RESCHEDULE]: IN_APP,
    [REVIEW]: IN_APP,
    [CLIENT]: IN_APP,
    [PAYMENT]: TEXT,
    [PAYOUT]: TEXT,
    [NOSHOW]: IN_APP
};

export const DEFAULT_CLIENT_NOTI_HASH = {
    [APPOINTMENT]: IN_APP,
    [CANCEL]: IN_APP,
    [RESCHEDULE]: IN_APP,
    [PAYMENT]: TEXT,
    [NOSHOW]: IN_APP
}