import { FACING_CLOSE_SEARCH, FACING_PILL_PAGE, FACING_PILL_SEARCH, FACING_PROP_CHANGE } from "../actionTypes/actionTypes"

const INITIAL_STATE = {
    searchOpen: false,
    searchBarOpen: false,
    searchResults: [],
    search: '',
    images: [],
    pillSearch: '',
    imagePage: 0,
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FACING_CLOSE_SEARCH:
            return { 
                ...state,
                searchOpen: false,
                searchBarOpen: false,
                searchResults: [],
                search: ''
            }
        case FACING_PROP_CHANGE:
            return { ...state, [action.payload.prop]: action.payload.value };
        case FACING_PILL_SEARCH:
            return {
                ...state,
                images: action.payload.images,
                pillSearch: action.payload.pillSearch,
                imagePage: 0
            }
        case FACING_PILL_PAGE:
            return {
                ...state,
                images: action.payload.images,
                imagePage: action.payload.imagePage
            }
        default:
            return state;
    }
}