import React from 'react';
import PropTypes from 'prop-types'
import './styles/Stat.css';
import { convertMonthNumberToString } from '../util/DateUtil';

const APPOINTMENTS = 'Appointments';
const CANCELLATIONS = 'Cancellations';
const EARNED = 'Earned';
const FEES = 'Fees';
const NEWCLIENTS = 'New Clients';
const NOSHOW = 'No Shows';
const RESCHEDULES = 'Reschedules';
const VIEWS = 'Views';

const Stat = ({ stat, selected }) => {

    const translateIdToTitle = () => {
        const parts = stat.id.split('-');
        const month = parseInt(parts[0]);
        const year = parseInt(parts[1]);
        const monthString = convertMonthNumberToString(month);
        return `${monthString} ${year}`;
    }

    const renderRow = (label, value) => {
        return (
            <div className='Stat-Row'>
                <div className='Stat-Left'>
                    <h4 className='Stat-Label'>{label}</h4>
                </div>
                <div className='Stat-Right'>
                    <h5 className='Stat-Value'>{value}</h5>
                </div>
            </div>
        )
    }

    const decideTitleClass = () => {
        if (selected) {
            return 'Stat-Title Stat-Selected';
        }
        return 'Stat-Title';
    }


    return (
        <div id={stat.id} className='Stat-Container'>
            <h3 className={decideTitleClass()}>{translateIdToTitle()}</h3>
            {renderRow(APPOINTMENTS, stat.appointments)}
            {renderRow(CANCELLATIONS, stat.cancellations)}
            {renderRow(RESCHEDULES, stat.reschedules)}
            {renderRow(NEWCLIENTS, stat.newClients ? stat.newClients : 0)}
            {renderRow(VIEWS, stat.views)}
            {renderRow(NOSHOW, stat.noShows)}
            {renderRow(EARNED, `$${stat.earned}`)}
            {renderRow(FEES, `$${stat.fees}`)}
        </div>
    )
}

Stat.propTypes = {
    stat: PropTypes.object,
    selected: PropTypes.bool,
};

Stat.defaultProps = {
    stat: {},
    selected: false
};

export default Stat;