import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Body,
    BrandButton,
    FlexRow,
    FlexRowButton,
    ModalLoader,
    InputLabel,
    GradientBackground,
    ModalError,
    ModalRowBack,
    AddressPicker2,
    EasyInput,
    BigTitle
} from '.';
import './styles/CardModal.css';
import { parseAddressForCard } from '../util/ParsingUtil';
import { testCard } from '../util/StripeUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import AddressButton from './AddressButton';

const DEBIT = 'debit';
const DEBIT_ERROR = 'Because you chose to sign up for payments you must use a debit card.';

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#fff",
        color: "#fff",
        fontWeight: 400,
        fontSize: "18px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "rgba(255, 255, 255, 0.6)"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      },
    }
}

const CardInput = ({ onChange, enforceDebit, title, subTitle, onBack }) => {

    const [name, setName] =                         useState('');
    const [cardInfoComplete, setCardInfoComplete] = useState(false);
    const [address, setAddress] =                   useState(null);
    const [error, setError] =                       useState('');
    const [cardLeft, setCardLeft] =                 useState(0);
    const [addressLeft, setAddressLeft] =           useState(window.screen.width);
    const [checking, setChecking] =                 useState(false);
    const [tokens, setTokens] =                     useState([]);


    const stripe = useStripe();
    const elements = useElements();

    const reset = () => {
        setName('');
        setAddress(null);
        setError('');
        setCardLeft(0);
        setAddressLeft(window.screen.width);
        setChecking(false);
    }

    const canSave = () => {
        if (name && address && cardInfoComplete) {
            return true;
        }
        return false;
    }

    const createCardToken = async () => {
        const data = parseAddressForCard(address.address);
        data.name = name;
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card, data);
        return result;
    }

    const startChecking = () => {
        setChecking(true);
    }

    const endChecking = () => {
        setChecking(false);
    }

    const save = async () => {
        startChecking();
        const result = await createCardToken();
        if (result.error) {
            //error; something wrong with card
            setError(result.error.message)
            endChecking();
            return;
        }
        if (enforceDebit && result.token.card.funding !== DEBIT) {
            setError(DEBIT_ERROR);
            endChecking();
            return;
        }
        //no card format verification errors
        setError('');
        //lets test the card with a test charge
        const res = await testCard(result.token.id);
        if (res) {
            //card is good, generate a new card token because the one we used to test the card is consumed
            const tokens = [];
            const tokenCount = enforceDebit ? 2 : 1;
            for (let i = 0; i < tokenCount; i++) {
                const tokenResult = await createCardToken();
                tokens.push(tokenResult.token);
            }
            setTokens(tokens);
            endChecking();
            onChange(tokens);
        } else {
            //card is bad, test charge failed
            setError('The card you entered is invalid.')
            endChecking();
        }
    }

    const showAddress = () => {
        setCardLeft(-window.screen.width);
        setAddressLeft(0);
    }

    const addressChange = (address) => {
        setAddress(address);
        closeAddress();
    }

    const closeAddress = () => {
        setCardLeft(0);
        setAddressLeft(window.screen.width);   
    }

    const cardChange = async (el) => {
        setCardInfoComplete(el.complete);
    }

    const resetError = () => {
        setError('');
    }

    const renderSaveButton = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                onClick={save}
                disabled={!canSave()}
            />
        )
    }

    const renderName = () => {
        return (
            <EasyInput 
                value={name}
                onChange={setName}
                placeholder='Cardholder Name'
                label='Cardholder Name'
            />
        )
    }

    const renderAddressButton = () => {
        return (
            <AddressButton 
                onClick={showAddress}
                label='Cardholder Address'
                address={address}
                placeholder='Cardholder Address'
            />
        )
    }

    const renderCardInput = () => {
        return (
            <FlexRow>
                <FlexRowButton>
                    <CardElement 
                        options={CARD_OPTIONS}
                        onChange={cardChange}
                    />
                    {cardInfoComplete && <InputLabel label='Card Info' />}
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderCardContent = () => {
        return (
            <Body
                absolute
                style={{ left: cardLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={onBack}
                />
                <BigTitle title={title} />
                <Body>
                    {renderName()}
                    {renderAddressButton()}
                    {renderCardInput()}
                </Body>
                {renderSaveButton()}
            </Body>
        )
    }

    const renderAddressContent = () => {
        return (
            <Body
                absolute
                style={{ left: addressLeft }}
                center
            >
                <AddressPicker2 
                    onBack={closeAddress}
                    onChange={addressChange}
                />
            </Body>
        )
    }

    const renderChecking = () => {
        if (checking) {
            return (
                <Body
                    absolute
                    style={styles.zIndex}
                    center
                >
                    <Body relative>
                        <GradientBackground>
                            <Body center>
                                <ModalLoader 
                                    message='Creating Card...'
                                />
                            </Body>
                        </GradientBackground>
                    </Body>
                </Body>
            )
        }
    }

    const renderError = () => {
        if (error) {
            return (
                <Body
                    absolute
                    style={styles.zIndex}
                    center
                >
                    <Body relative>
                        <GradientBackground>
                            <Body center style={{ marginBottom: 70 }}>
                                <ModalError 
                                    message='The card you used was invalid.'
                                    onClick={resetError}
                                />
                            </Body>
                        </GradientBackground>
                    </Body>
                </Body>
            )
        }
    }

    return (
        <Body>
            <Body relative>
                {renderCardContent()}
                {renderAddressContent()}
                {renderChecking()}
                {renderError()}
            </Body>
        </Body>
    )
}

const styles = {
    marginTop: {
        marginTop: 5
    },
    zIndex: {
        zIndex: 2
    }
}

CardInput.propTypes = {
    onChange: PropTypes.func,
    onBack: PropTypes.func,
    enforceDebit: PropTypes.bool,
    message: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

CardInput.defaultProps = {
    onChange: () => {},
    onBack: () => {},
    enforceDebit: false,
    message: '',
    image: '',
    title: '',
    subTitle: ''
}

export default CardInput;