import React, { Component } from 'react';
import { getAuth } from 'firebase/auth';
import { connect } from 'react-redux';
import { 
    IoIosClose, 
    IoIosTrash, 
    IoMdCreate, 
} from'react-icons/io';
import { 
    uploadImages, 
    fetchFirstImages,
    fetchMoreImages,
    artistPropChange
} from '../../actions/artistActions';
import './styles/Portfolio.css';
import { 
    ImageBackground, 
    TransparentHeader,
    PortfolioImage
} from '../../components';
import { 
    buildProfileImageUrl, 
    getThumbnailSize, 
    parseBlobs 
} from '../../util/ImageUtil';
import { ARTIST } from '../../constants/ClientRoutes';

class Portfolio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            selectedImageHash: {}
        }
    }

    componentDidMount = () => { 
        window.addEventListener('touchend', this.onScroll);
        this.props.fetchFirstImages();
    }

    openEdit = () => {
        this.setState({ editOpen: true });
    }

    closeEdit = () => {
        this.setState({
            editOpen: false,
            selectedImageHash: {}
        })
    }

    onScroll = (e) => {
        if (this.containerRef && this.containerRef.contains(e.target)) {
            const scrollData = document.getElementById('inner2').getBoundingClientRect();
            const threshold = window.screen.height - (getThumbnailSize() * 3);
            if (scrollData.bottom >= threshold && scrollData.bottom <= window.screen.height && !this.props.noMoreImages) {
                this.props.fetchMoreImages(this.props.imagesCursor)
            }
        }
    }

    back = () => {
        window.history.back();
    }

    pickImage = () => {
        document.getElementById('imagePicker').click();
    }

    imageChange = (e) => {
        const images = parseBlobs(e);
        this.props.uploadImages(images);
    }

    selectOrDeselectImage = (imageName) => {
        const selectedImageHash = { ...this.state.selectedImageHash };
        if (!selectedImageHash[imageName]) {
            selectedImageHash[imageName] = true;
        } else {
            delete selectedImageHash[imageName];
        }
        this.setState({ selectedImageHash });
    }

    onImageTap = ({ imageName, index }) => {
        if (this.state.editOpen) {
            this.selectOrDeselectImage(imageName)
        }
    }

    renderInvisibleInput = () => {
        return (
            <input 
                type='file'
                id='imagePicker'
                className='Portfolio-ImagePicker'
                multiple
                onChange={this.imageChange}
            />
        )
    }

    renderImage = (image, index) => {
        return (
            <PortfolioImage 
                image={image}
                key={index}
                index={index}
                onClick={this.onImageTap}
                selected={this.state.editOpen && this.state.selectedImageHash[image.data().imageName]}
            />
        )
    }

    renderCancelEdit = () => {
            const className= this.state.editOpen ? 'Portfolio-CancelEditHide Portfolio-CancelEdit' : 'Portfolio-CancelEditHide';
            return (
                <IoIosClose 
                    className={className}
                    onClick={this.closeEdit}
                />
            )
    }

    renderEditOpen = () => {
        if (this.state.editOpen) {
            return (
                <IoIosTrash 
                    className='Portfolio-EditButton'
                />
            )
        }
        return (
            <IoMdCreate 
                onClick={this.openEdit} 
                className='Portfolio-EditButton' 
            />
        )
    }

    renderFooter = () => {
        return (
            <div className='Portfolio-Footer'>
                {this.renderCancelEdit()}
                {this.renderSelectedImages()}
                {this.renderEditOpen()}
            </div>
        )
    }

    renderSelectedImages = () => {
        const className = this.state.editOpen ? 'Porfolio-HideImageLabel Portfolio-ImageLabel' : 'Portfolio-HideImageLabel';
        const imagesSelected = Object.keys(this.state.selectedImageHash).length;
        let label = '';
        if (imagesSelected === 0) {
            label = 'Select an Image';
        } else {
            label = `${imagesSelected} Images Selected`;
        }
        return (
            <p className={className}>{label}</p>
        )
    }

    renderBody = () => {
        return (
            <div 
                className='Portfolio-OuterContainer'
                ref={ref => this.containerRef = ref}
            >
                <div id='inner' className='Portfolio-Container'>
                    <div id='inner2' className='Portfolio-Inner'>
                        {this.props.images.map(this.renderImage)}
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        const id = getAuth().currentUser.uid;
        return (
            <ImageBackground 
                image={buildProfileImageUrl(window.screen.width, window.screen.height, id, this.props.profile.profileImage)}
                darken
            >
                <TransparentHeader 
                    title='My Portfolio'
                    onBack={this.back}
                    showAdd
                    onAdd={this.pickImage}
                />
                {this.renderBody()}
                {this.renderFooter()}
                {this.renderInvisibleInput()}
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        profile, 
        images, 
        imagesCursor, 
        noMoreImages 
    } = state.artist;
    return { 
        profile, 
        images, 
        imagesCursor, 
        noMoreImages 
    }
}

export default connect(mapStateToProps, { 
    uploadImages, 
    fetchFirstImages,
    fetchMoreImages,
    artistPropChange
})(Portfolio);