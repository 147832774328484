import { 
    doc, 
    getDoc, 
    getFirestore, 
    collection,
    getDocs,
    query,
    where,
    increment, 
    setDoc, 
    updateDoc,
} from "firebase/firestore"
import algoliasearch from 'algoliasearch';
import { ALGOLIA_ADMIN_KEY, ALGOLIA_APP_ID } from "../secret/secret";
import { parseDocs } from "./FirestoreUtil";
import { fetchNotiHash, handleNoti } from "./NotiUtil";
import { ARTIST_NEW_CLIENT } from "../constants/NotiTypes";
import { CLIENT } from "../constants/Policies";
import { EMPTY_CLIENT_STAT, EMPTY_STAT } from "../constants/EmptyStats";

export const doesAppointmentConflict = async (apptInQuestion) => {
    const appointments = await fetchAppointments(apptInQuestion.artistId, apptInQuestion.day);
    if (apptInQuestion.service.duration.isDouble) {
        //do double
        return doesDoubleAppointmentConflict(apptInQuestion, appointments);
    } else {
        //do single
        return doesSingleAppointmentConflict(apptInQuestion, appointments);
    }
}

export const fetchAppointments = async (artistId, day) => {
    const db = getFirestore();
    const appointmentsRef = collection(db, 'appointments');
    const w = where('day', '==', day);
    const w2 = where('artistId', '==', artistId);
    const q = query(appointmentsRef, w, w2);
    const snap = await getDocs(q).then();
    const { results: appointments } = parseDocs(snap);
    return splitDoubleAppointments(appointments);
}

export const splitDoubleAppointments = (appointments) => {
    const results = [];
    for (let i = 0; i < appointments.length; i++) {
        const appointment = appointments[i];
        if (appointment.service.duration.isDouble) {
            results.push({
                startTime: appointment.slot.slot.startTime,
                endTime: appointment.slot.slot.endTime1
            });
            results.push({
                startTime: appointment.slot.slot.startTime1,
                endTime: appointment.slot.slot.endTime
            });
        } else {
            results.push({
                startTime: appointment.slot.slot.startTime,
                endTime: appointment.slot.slot.endTime
            });
        }
    }
    return results;
}

export const updateAppointmentHashForArtist = async (artistId, appointmentDate) => {
    const db = getFirestore();
    const dateString = appointmentDate.replaceAll('/', '-');
    const statsRef = doc(db, `users/${artistId}/appointmentHash`, 'appointmentHash');
    const snap = await getDoc(statsRef).then();
    if (snap.exists()) {
        await updateDoc(statsRef, {
            [dateString]: increment(1)
        });
    } else {
        const stats = {
            [dateString]: 1
        }
        await setDoc(statsRef, stats);
    }
}

export const decrementAppointmentHashForArtist = async (artistId, appointmentDate) => {
    const db = getFirestore();
    const dateString = appointmentDate.replaceAll('/', '-');
    const statsRef = doc(db, `users/${artistId}/appointmentHash`, 'appointmentHash');
    const snap = await getDoc(statsRef).then();
    const stats = snap.data();
    if (stats[dateString] === 1) {
        delete stats[dateString];
        await setDoc(statsRef, stats)
    } else {
        await updateDoc(statsRef, {
            [dateString]: increment(-1)
        });
    }
}

export const updateRescheduleStats = async (appointment, artistId, clientId) => {
    if (clientId) {
        const db = getFirestore();
        const clientRef = doc(db, `users/${artistId}/clients`, clientId);
        await updateDoc(clientRef, {
            reschedules: increment(1)
        });
    }

    await incrementMonthStatsForReschedules(artistId, appointment);
}

export const updateBookStats = async (appointment, artist, client) => {
    let newClient = false;
    const db = getFirestore();
    const doesExist = await doesClientExist(artist, client);
    if (doesExist) {
        const clientRef = doc(db, `users/${artist.id}/clients/${client.id}`);
        //client does exist
        await updateDoc(clientRef, {
            appointments: increment(1),
        });
    } else {
        //client doesn't exist
        newClient = true;
        if (client.id) {
            const artistRef = doc(db, `users/${artist.id}`);
            await updateDoc(artistRef, {
                clients: increment(1)
            });
            const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_KEY);
            const index = searchClient.initIndex(artist.id);
            //index client into artist algolia index so that they can search their clients
            index.saveObject({
                name: client.name,
                objectID: client.id
            });
        }

        if (client.id) {
            //create a client document for the artists so they can view aggregate data for their client
            const clientRef = doc(db, `users/${artist.id}/clients/${client.id}`);
            const stat = EMPTY_CLIENT_STAT;
            stat.appointments = 1;
            stat.clientSince = new Date().getTime();
            await setDoc(clientRef, stat);
            //handle noti
            const notiHash = await fetchNotiHash(appointment.artistId);
            const method = notiHash[CLIENT];
            const preview = `${appointment.clientName} has joined your client list.`;
            const desc = preview;
            const link = `ARTIST.ARTIST_VIEW_CLIENT/${appointment.clientId}`;
            await handleNoti(appointment.artistPhone, desc, preview, ARTIST_NEW_CLIENT, appointment.artistId, method, link);
        }
    }
    await incrementMonthStatsForAppointments(artist, appointment, newClient);
}

export const updateCancelStats = async (appointment, artistId, clientId) => {
    const db = getFirestore();
    if (clientId) {
        const clientRef = doc(db, `users/${artistId}/clients`, clientId);
        await updateDoc(clientRef, {
            cancellations: increment(1)
        });
    }
    await incrementMonthStatsForCancels(artistId, appointment);
}

export const incrementMonthStatAndClientStatForPayment = async (appointment, amount, fee) => {
    const db = getFirestore();
    const statsId = getMonthYearStringFromDateString(appointment.day);
    const statsRef = doc(db, `users/${appointment.artistId}/stats/${statsId}`);
    const statsSnap = await getDoc(statsRef).then();
    if (statsSnap.exists()) {
        await updateDoc(statsRef, {
            earned: increment(amount),
            fees: increment(fee)
        });
    } else {
        const stat = EMPTY_STAT;
        stat.earned = amount;
        stat.fees = fee;
        await setDoc(statsRef, stat);
    }
    const clientRef = doc(db, `users/${appointment.artistId}/clients/${appointment.clientId}`);
    const clientSnap = await getDoc(clientRef).then();
    if (clientSnap.exists()) {
        await updateDoc(clientRef, {
            earned: increment(amount),
            fees: increment(fee)
        });
    } else {
        const stat = EMPTY_CLIENT_STAT;
        stat.clientSince = new Date().getTime();
        stat.earned = amount;
        stat.fees = fee;
        stat.order = createOrder(statsId);
        await setDoc(clientRef, stat);
    }
}

export const incrementViews = async (artistId) => {
    const db = getFirestore();
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const id = `${month}-${year}`;
    const ref = doc(db, `users/${artistId}/stats/${id}`);
    const snap = await getDoc(ref);
    if (snap.exists()) {
        await updateDoc(ref, {
            views: increment(1)
        });
    } else {
        const stat = EMPTY_STAT;
        stat.order = createOrder(id);
        stat.views = 1;
        await setDoc(ref, stat)
    }
}

const doesDoubleAppointmentConflict = (apptInQuestion, appointments) => {
    const _start = apptInQuestion.slot.slot.startTime;
    const _end1 = apptInQuestion.slot.slot.endTime1;
    const _start1 = apptInQuestion.slot.slot.startTime1;
    const _end = apptInQuestion.slot.slot.endTime;
    for (let i = 0; i < appointments.length; i++) {
        const appointment = appointments[i];
        const appointmentStart = appointment.startTime;
        const appointmentEnd = appointment.endTime;
        if (_start >= appointmentStart && _start < appointmentEnd) {
            return true;
        }
        if (_end1 > appointmentStart && _end1 <= appointmentEnd) {
            return true;
        }
        if (_start1 >= appointmentStart && _start1 < appointmentEnd) {
            return true;
        }
        if (_end > appointmentStart && _end <= appointmentEnd) {
            return true;
        }
        if (appointmentStart >= _start && appointmentStart < _end1) {
            return true;
        }
        if (appointmentEnd > _start && appointmentEnd <= _end1) {
            return true;
        }
        if (appointmentStart >= _start1 && appointmentStart < _end) {
            return true;
        }
        if (appointmentEnd > _start1 && appointmentEnd <= _end) {
            return true;
        }
    }
    return false;
}

const doesClientExist = async (artist, client) => {
    if (client.id) {
        const db = getFirestore();
        const clientRef = doc(db, `users/${artist.id}/clients/${client.id}`);
        const clientSnap = await getDoc(clientRef).then();
        if (clientSnap.exists()) {
            return true;
        }
    }
    return false;
}

const doesSingleAppointmentConflict = (apptInQuestion, appointments) => {
    const _start = apptInQuestion.slot.slot.startTime;
    const _end = apptInQuestion.slot.slot.endTime;
    for (let i = 0; i < appointments.length; i++) {
        const appointment = appointments[i];
        const appointmentStart = appointment.startTime;
        const appointmentEnd = appointment.endTime;
        if (_start >= appointmentStart && _start < appointmentEnd) {
            return true;
        }
        if (_end > appointmentStart && _end <= appointmentEnd) {
            return true;
        }
        if (appointmentStart >= _start && appointmentStart < _end) {
            return true;
        }
        if (appointmentEnd > _start && appointmentEnd <= _end) {
            return true;
        }
    }
    return false;
}

const createOrder = (statsId) => {
    const parts = statsId.split('-');
    const month = parseInt(parts[0]);
    const year = parseInt(parts[1]);
    return new Date(year, month).getTime();
}

const incrementMonthStatsForReschedules = async (artistId, appointment) => {
    const db = getFirestore();
    const statsId = getMonthYearStringFromDateString(appointment.day);
    const statsRef = doc(db, `users/${artistId}/stats`, statsId);
    const statsSnap = await getDoc(statsRef).then();
    if (statsSnap.exists()) {
        await updateDoc(statsRef, {
            reschedules: increment(1)
        });
    } else {
        const stat = EMPTY_STAT;
        stat.reschedules = 1;
        stat.order = createOrder(statsId);
        await setDoc(statsRef, stat)
    }
}

const incrementMonthStatsForAppointments = async (artist, appointment, newClient) => {
    //take care of month aggregates
    const db = getFirestore();
    const statsId = getMonthYearStringFromDateString(appointment.day);
    const statsRef = doc(db, `users/${artist.id}/stats`, statsId);
    const statsSnap = await getDoc(statsRef).then();
    if (statsSnap.exists()) {
        //monthly aggregate exists
        //--increment 1 appointment
        //--increment 1 new client
        await updateDoc(statsRef, {
            appointments: increment(1),
            newClients: newClient && increment(1)
        });
    } else {
        //monthly aggregate doesnt exists
        //--initialize with 1 new appointment and 1 new client
        const stat = EMPTY_STAT;
        stat.appointments = 1;
        stat.newClients = newClient === true ? parseInt(1) : parseInt(0);
        stat.order = createOrder(statsId);
        await setDoc(statsRef, stat);
    }
}

const incrementMonthStatsForCancels = async (artistId, appointment) => {
    const db = getFirestore();
    const statsId = getMonthYearStringFromDateString(appointment.day);
    const statsRef = doc(db, `users/${artistId}/stats`, statsId);
    const statsSnap = await getDoc(statsRef).then();
    if (statsSnap.exists()) {
        await updateDoc(statsRef, {
            cancellations: increment(1)
        });
    } else {
        const stat = EMPTY_STAT;
        stat.cancellations = 1;
        stat.order = createOrder(statsId);
        await setDoc(statsRef, stat)
    }
}

const getMonthYearStringFromDateString = (dateString) => {
    const daySplit = dateString.split('/');
    const month = daySplit[0] - 1;
    const year = daySplit[2];
    return `${month}-${year}`;
}