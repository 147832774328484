import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/NotiGroup.css';
import { getFirestore, updateDoc, doc } from 'firebase/firestore';

class NotiGroup extends Component {

    componentWillUnmount = () => {
        this.setShown();
    }

    setShown = async () => {
        const db = getFirestore();
        const prefix = this.props.isClient ? 'clients' : 'users'
        for (let i = 0; i < this.props.notis.length; i++) {
            const noti = this.props.notis[i];
            const ref = doc(db, `${prefix}/${noti.destId}/notifications/${noti.id}`);
            await updateDoc(ref, {
                shown: true
            });
        }
    }

    render = () => {
        return (
            <div 
                onClick={this.props.onClick} 
                className='NG-Container'
            >
                <h5 className='NG-Title'>{`You have ${this.props.notis.length} unread notifications.`}</h5>
            </div>
        )
    }

}

NotiGroup.propTypes = {
    notis: PropTypes.array,
    onClick: PropTypes.func,
    isClient: PropTypes.bool,
};

NotiGroup.defaultProps = {
    notis: [],
    onClick: () => {},
    isClient: false
}

export default NotiGroup;