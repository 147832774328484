import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    FlexRowButton,
    BrandButton,
    ButtonLabel,
    BigTitle,
    GenOption,
    Value
} from '.';
import { 
    APPOINTMENT, 
    CANCEL, 
    RESCHEDULE,  
    PAYMENT,
    METHODS,
    NOSHOW,
    translateMethod
} from '../constants/Policies';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { 
    doc, 
    getDoc, 
    getFirestore, 
    setDoc, 
    updateDoc 
} from '@firebase/firestore';

class ClientUpdateNotiType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: props.notiHash && props.notiHash[props.type] ? props.notiHash[props.type] : '',
            left: 0,
            optionLeft: window.screen.width,
        }
    }

    change = ({ value }) => {
        this.setState({ type: value });
        this.navToContent();
    }

    navToOption = () => {
        this.setState({
            left: -window.screen.width,
            optionLeft: 0
        });
    }

    navToContent = () => {
        this.setState({ 
            left: 0,
            optionLeft: window.screen.width
        });
    }

    doesExists = async (db) => {
        const ref = doc(db, `clients/${this.props.profile.id}/notificationHash/notificationHash`);
        const snap = await getDoc(ref);
        if (snap.exists()) {
            return true;
        }
        return false;
    }

    save = async () => {
        const db = getFirestore();
        const ref = doc(db, `clients/${this.props.profile.id}/notificationHash/notificationHash`);
        const doesExists = await this.doesExists(db);
        if (doesExists) {
            await updateDoc(ref, {
                [this.props.type]: this.state.type
            });
        } else {
            await setDoc(ref, {
                [this.props.type]: this.state.type
            })
        }
        this.props.onBack();
    }

    translateTitle = () => {
        switch (this.props.type) {
            case CANCEL:
                return 'When an artist cancels an appointment with you, how would you like to be notified?';
            case APPOINTMENT:
                return 'When an artist books an appointment with you, how would you like to be notified?';
            case RESCHEDULE:
                return 'When an artist reschedules an appointment with you, how would you like to be notified?';
            case PAYMENT:
                return 'When you pay an artist for a service, how would you like to be notified?';
            case NOSHOW:
                return 'If you no show an artist, and they charge you for it, how would you like to be notified?';
        }
    }

    translateTitle2 = () => {
        switch (this.props.type) {
            case CANCEL:
                return 'Cancellation';
            case APPOINTMENT:
                return 'New Appointment';
            case RESCHEDULE:
                return 'Reschedule';
            case PAYMENT:
                return 'Payment';
            case NOSHOW:
                return 'No Show';
        }
    }

    focusCancel = () => {
        if (this.cancelRef) {
            this.cancelRef.focus();
        }
    }

    renderTitle = () => {
        return (
            <BigTitle 
                title={this.translateTitle2()}
            />
        )
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                title={'Appointment Settings'}
                onBack={this.props.onBack}
            />
        )
    }

    renderSelect = () => {
        return (
            <FlexRowButton
                style={styles.button}
                onClick={this.navToOption}
            >
                <Value value={translateMethod(this.state.type)} />
                <ButtonLabel label={this.translateTitle()} />
            </FlexRowButton>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                onClick={this.save}
            />
        )
    }

    renderContent = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.left }}
            >
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderSelect()}
                {this.renderSave()}
            </Body>
        )
    }

    renderMethod = (method, index) => {
        return (
            <GenOption 
                key={index}
                option={method}
                label={method.label}
                onClick={this.change}
            />
        )
    }

    renderOptions = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.optionLeft }}
            >
                <ModalRowBack title='Back' onBack={this.navToContent} />
                <BigTitle title='Pick an Option' />
                {METHODS.map(this.renderMethod)}
            </Body>
        )
    }

    render = () => {
        return (
            <Body relative>
                {this.renderContent()}
                {this.renderOptions()}
            </Body>
        )
    }
}

const styles = {
    button: {
        width: '95%'
    }
}

ClientUpdateNotiType.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    type: PropTypes.string,
    notiHash: PropTypes.object
};

ClientUpdateNotiType.defaultProps = {
    onBack: () => {},
    profile: {},
    type: '',
    notiHash: {}
}

export default ClientUpdateNotiType;