import React from 'react';
import PropTypes from 'prop-types';
import './styles/Appointment.css';
import { buildImageUrl } from '../util/ImageUtil';
import { convertNumberToReadable } from '../util/TimeUtil';
import { convertDateStringToReadable } from '../util/DateUtil';
import { PaidOverlay } from '.';

const Appointment = ({ appointment, onClick, clientPerspective, showDay }) => {

    const click = () => {
        onClick(appointment);
    }

    const renderName = () => {
        const name = clientPerspective ? appointment.artistName : appointment.clientName;
        return (
            <h2 className='Appointment-ClientName'>{`With ${name}`}</h2>
        )
    }

    const renderDate = () => {
        const date = convertDateStringToReadable(appointment.day, true);
        if (clientPerspective || showDay) {
            return (
                <h4 className='Appointment-Date'>{date}</h4>
            )
        }
    }

    const renderFirstSlot = () => {
        if (appointment.service.duration.isDouble) {
            return <h4 className='Appointment-Duration'>{`${convertNumberToReadable(appointment.slot.slot.startTime)} - ${convertNumberToReadable(appointment.slot.slot.endTime1)}`}</h4>
        }
        return <h4 className='Appointment-Duration'>{`${convertNumberToReadable(appointment.startTime)} - ${convertNumberToReadable(appointment.endTime)}`}</h4>
    }

    const renderSecondSlot = () => {
        if (appointment.service.duration.isDouble) {
            return <h4 className='Appointment-Duration2'>{`${convertNumberToReadable(appointment.slot.slot.startTime1)} - ${convertNumberToReadable(appointment.slot.slot.endTime)}`}</h4>
        }
    }

    const renderPaidOverlay = () => {
        if (appointment.paid || appointment.noShow) {
            return (
                <PaidOverlay 
                    time={appointment.paidTime} 
                    amount={appointment.paidAmount}
                    curve={true}
                    showBack={false}
                    noShow={appointment.noShow}
                />
            )
        }
    }

    const width = window.screen.width - Math.ceil(window.screen.width * 0.05);
    const url = buildImageUrl(appointment.artistId, appointment.service.image, width, 280);
    return (
        <div 
            className='Appointment-Container'
            onClick={click}
        >
            <img 
                src={url}
                className='Appointment-Image'
                alt='hello'
            />
            <div className='Appointment-RowContainer'>
                <div className='Appointment-InfoContainer'>
                    <h3 className='Appointment-Name'>{appointment.service.name}</h3>
                    {renderFirstSlot()}
                    {renderSecondSlot()}
                </div>
                <div className='Appointment-PriceContainer'>
                    <h2 className='Appointment-Price'>{`$${appointment.service.price}`}</h2>
                </div>
            </div>
            {renderName()}
            {renderDate()}
            {renderPaidOverlay()}
        </div>
    )
}

Appointment.propTypes = {
    appointment: PropTypes.object,
    onClick: PropTypes.func,
    clientPerspective: PropTypes.bool,
    showDay: PropTypes.bool,
}

Appointment.defaultProps = {
    appointment: {},
    onClick: () => {},
    clientPerspective: false,
    showDay: false
}

export default Appointment;