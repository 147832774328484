import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Body,
    ModalRowBack,
    SettingsTitle,
    Option,
    ClientUpdateNotiType
} from '.';
import { 
    IoAddSharp, 
    IoCloseSharp, 
    IoRepeatSharp, 
    IoCardOutline,
} from 'react-icons/io5';
import { BsPersonXFill } from 'react-icons/bs';
import { 
    APPOINTMENT, 
    CANCEL, 
    RESCHEDULE,  
    translateMethod, 
    PAYMENT,
    NOSHOW
} from '../constants/Policies';
import { getFirestore, onSnapshot, doc } from '@firebase/firestore';

class ClientUpdateNoti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: '',
            listener: null,
            notiHash: {}
        }
    }

    componentDidMount = () => {
        const db = getFirestore();
        const ref = doc(db, `clients/${this.props.profile.id}/notificationHash/notificationHash`);
        const listener = onSnapshot(ref, (snap => {
            this.setState({ notiHash: snap.data() ? snap.data() : {}, listener });
        }))
    }

    componentWillUnmount = () => {
        if (this.state.listener) {
            this.state.listener();
        }
    }

    setType = (type) => {
        this.setState({ type });
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Notification Settings'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Notification Settings'
            />
        )
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                <Option 
                    label='New Appointment'
                    icon={<IoAddSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(APPOINTMENT)}
                    rightLabel={this.state.notiHash[APPOINTMENT] ? translateMethod(this.state.notiHash[APPOINTMENT]) : ''}
                />
                <Option 
                    label='Cancellation'
                    icon={<IoCloseSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(CANCEL)}
                    rightLabel={this.state.notiHash[CANCEL] ? translateMethod(this.state.notiHash[CANCEL]) : ''}
                />
                <Option 
                    label='Reschedule'
                    icon={<IoRepeatSharp />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(RESCHEDULE)}
                    rightLabel={this.state.notiHash[RESCHEDULE] ? translateMethod(this.state.notiHash[RESCHEDULE]) : ''}
                />
                <Option  
                    label='New Payment'
                    icon={<IoCardOutline />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={() => this.setType(PAYMENT)}
                    rightLabel={this.state.notiHash[PAYMENT] ? translateMethod(this.state.notiHash[PAYMENT]) : ''}
                />
                <Option 
                    label='No Show'
                    icon={<BsPersonXFill />}
                    showArrow
                    backgroundColor='#FF3A3A'
                    onClick={() => this.setType(NOSHOW)}
                    rightLabel={this.state.notiHash[NOSHOW] ? translateMethod(this.state.notiHash[NOSHOW]) : ''}
                />
            </Body>
        )
    }

    renderUpdate = () => {
        return (
            <ClientUpdateNotiType 
                profile={this.props.profile}
                onBack={() => this.setType('')}
                type={this.state.type}
                notiHash={this.state.notiHash}
            />
        )
    }

    renderDecide = () => {
        if (this.state.type) {
            return this.renderUpdate();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

ClientUpdateNoti.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
}

ClientUpdateNoti.defaultProps = {
    onBack: () => {},
    profile: {},
}

export default ClientUpdateNoti;