import React from 'react';
import PropTypes from 'prop-types';
import { 
    IoChevronBackOutline, 
    IoCloseOutline, 
    IoEllipsisHorizontal 
} from 'react-icons/io5';
import { IoIosAdd } from 'react-icons/io';
import './styles/TransparentHeader.css';
import { ModalTitle, NotiBadge } from '.';
import { BsFillBellFill } from 'react-icons/bs';

const TransparentHeader = ({ title, onBack, showBack = true, showSettings = false, showAdd = false, showDelete = false, onDelete, onSettings, onAdd, loading, settingsBadgeCount }) => {

    const renderShowBack = () => {
        if (showBack) {
            return (
                <IoChevronBackOutline 
                    className='TransparentHeader-Back'
                    onClick={onBack} 
                />
            )
        }
    }

    const renderShowAdd = () => {
        if (showAdd) {
            return (
                <IoIosAdd 
                    className='TransparentHeader-Settings'
                    onClick={onAdd}
                />
            )
        }
    }

    const renderShowSettings = () => {
        if (showSettings) {
            return (
                <IoEllipsisHorizontal 
                    className='TransparentHeader-Settings'
                    onClick={onSettings}
                />
            )
        }
    }

    const renderShowDelete = () => {
        if (showDelete) {
            return (
                <IoCloseOutline 
                    className='TransparentHeader-Settings'
                    onClick={onDelete}
                />
            )
        }
    }

    const renderLoader = () => {
        return (
            <div className='TransparentHeader-LoadContainer'>
                <div className={`TransparentHeader-LoadPart ${loading ? 'TransparentHeader-MoveLoad' : ''}`}>

                </div>
            </div>
        )
    }

    const renderSettingsBadgeCount = () => {
        if (settingsBadgeCount > 0) {
            return (
                <NotiBadge 
                    onClick={onSettings}
                    count={settingsBadgeCount}
                />
            )
        }
    }

    return (
        <div className='TransparentHeader-Container'>
            {renderShowBack()}
            <ModalTitle title={title} />
            {renderShowSettings()}
            {renderShowAdd()}
            {renderShowDelete()}
            {renderLoader()}
            {renderSettingsBadgeCount()}
        </div>
    )
}

TransparentHeader.propTypes = {
    title: PropTypes.string,
    onBack: PropTypes.func,
    showBack: PropTypes.bool,
    showSettings: PropTypes.bool,
    showAdd: PropTypes.bool,
    showDelete: PropTypes.bool,
    onSettings: PropTypes.func,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    loading: PropTypes.bool,
    settingsBadgeCount: PropTypes.number,
};

TransparentHeader.defaultProps = {
    title: '',
    onSettings: () => {},
    onBack: () => {},
    onAdd: () => {},
    onDelete: () => {},
    showBack: true,
    showAdd: false,
    showDelete: false,
    showSettings: false,
    loading: false,
    settingsBadgeCount: 0
}

export default TransparentHeader;