import React from 'react';
import PropTypes from 'prop-types';
import './styles/Address.css';


const Address = ({ address, onClick }) => {

    const click = () => {
        const pack = {
            address: address.formatted_address,
            location: address.geometry.location
        }
        onClick(pack);
    }

    console.log(address);
    return (
        <div 
            className='Address2-Container'
            onClick={click}
        >
            <h4 className='Address-Title'>{address.formatted_address}</h4>
        </div>
    )
};

Address.propTypes = {
    address: PropTypes.object,
    onClick: PropTypes.func,
};

Address.defaultProps = {
    address: {},
    onClick: () => {}
}

export default Address;