import React from 'react';
import PropTypes from 'prop-types';
import './styles/Artist.css';
import { buildProfileImageUrl } from '../util/ImageUtil';
import { IoThumbsUp } from 'react-icons/io5';
const METERS_IN_MILE = 1600;

const Artist = ({ artist, onClick }) => {

    const click = () => {
        onClick(artist);
    }

    const renderDistance = () => {
        if (artist._rankingInfo && artist._rankingInfo.geoDistance) {
            const distance = Math.ceil(artist._rankingInfo.geoDistance / METERS_IN_MILE).toFixed(2);
            return (
                <h5 className='Artist-Duration'>{`${distance} mi away`}</h5>
            )
        }
    }

    const width = window.screen.width - Math.ceil(window.screen.width * 0.05);
    const url = buildProfileImageUrl(width, 280, artist.objectID ? artist.objectID : artist.id, artist.profileImage);
    return (
        <div 
            className='Artist-Container'
            onClick={click}
        >
            <img 
                src={url}
                className='Artist-Image'
            />
            <div className='Artist-TopContainer'>
                <h3 className='Artist-Name'>{artist.name}</h3>
                <h4 className='Artist-Duration'>{`${artist.artistType}, ${artist.salonName}`}</h4>
                <div className='Artist-LikesContainer'>
                    <IoThumbsUp className='Artist-Thumb'/>
                    <h4 className='Artist-Likes'>{artist.likes}</h4>
                </div>
            </div>
            <div className='Artist-BottomContainer'>
                <h5 className='Artist-Duration' style={{ width: '70%' }}>{artist.address}</h5>
                {renderDistance()}
            </div>
        </div>
    )
}

Artist.propTypes = {
    artist: PropTypes.object,
    onClick: PropTypes.func
};

Artist.defaultProps = {
    artist: {},
    onClick: () => {}
};

export default Artist;