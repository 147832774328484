export const EMPTY_STAT = {
    noShows: 0,
    cancellations: 0,
    reschedules: 0,
    appointments: 0,
    earned: 0,
    fees: 0,
    views: 0,
}

export const EMPTY_CLIENT_STAT = {
    noShows: 0,
    cancellations: 0,
    reschedules: 0,
    appointments: 0,
    earned: 0,
    fees: 0,
}