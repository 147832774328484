import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground, 
    TransparentHeader,
    Card,
    Loader,
    ListWrapper,
    ArtistCharge,
    ArtistChargeDetail,
    Payout,
    BrandButton,
    TabButtonRow,
    BrandTabButton,
    CardModal
} from '../../components';
import { 
    CHANGE_ARTIST_CONNECT_CARD,
    FETCH_BEFORE_ARITST_PAYOUTS,
    FETCH_BEFORE_ARTIST_CHARGES,
    FETCH_CONNECT_CARD_AND_BALANCE, 
    FETCH_MORE_ARTIST_CHARGES, 
    FETCH_MORE_ARTIST_PAYOUTS,
    UPDATE_CUSTOMER_CARD
} from '../../constants/ArgendaServerURLS';
import { ARTIST } from '../../constants/ClientRoutes';
import { THRESHOLD } from '../../constants/ScrollThreshold';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { buildProfileImageUrl } from '../../util/ImageUtil';
import './styles/Balance.css';

class Balance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card: null,
            balance: null,
            charges: [],
            hasMoreCharges: true,
            loading: false,
            selectedCharge: null,
            paymentsSelected: true,
            cardOpen: false
        }
    }

    componentDidMount = async () => {
        window.addEventListener('touchend', this.onScroll);
        const res = await axios.post(FETCH_CONNECT_CARD_AND_BALANCE, { connectAccountId: this.props.profile.stripeAccountId, limit: 10 });
        const { card, charges: chargeData, balance, payouts: payoutData } = res.data;
        const charges = chargeData.data;
        const payouts = payoutData.data;
        const hasMoreCharges = chargeData.has_more;
        const hasMorePayouts = payoutData.has_more;
        this.setState({ 
            card, 
            charges, 
            balance, 
            hasMoreCharges,
            payouts,
            hasMorePayouts,
            loading: false
        });
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchend', this.onScroll);
    }

    toggleCardOpen = () => {
        this.setState({ cardOpen: !this.state.cardOpen });
    }

    selectCharge = (selectedCharge) => {
        this.setState({ selectedCharge });
    }

    closeCharge = () => {
        this.setState({ selectedCharge: null });
    }

    selectPayments = () => {
        this.setState({ paymentsSelected: true });
        this.fetchBeforeCharges();
    }

    selectPayouts = () => {
        this.setState({ paymentsSelected: false });
        this.fetchBeforePayouts();
    }

    fetchBeforeCharges = async () => {
        this.setState({ loading: true });
        const pack = {
            connectAccountId: this.props.profile.stripeAccountId,
            endingBefore: this.state.charges[0].id,
        }
        const res = await axios.post(FETCH_BEFORE_ARTIST_CHARGES, pack);
        const charges = res.data.data;
        const newCharges = [...charges, ...this.state.charges];
        this.setState({ loading: false, charges: newCharges });
    }

    fetchBeforePayouts = async () => {
        this.setState({ loading: true });
        const pack = {
            connectAccountId: this.props.profile.stripeAccountId,
            endingBefore: this.state.payouts[0].id,
        }
        const res = await axios.post(FETCH_BEFORE_ARITST_PAYOUTS, pack);
        const payouts = res.data.data;
        const newPayouts = [...payouts, ...this.state.payouts];
        this.setState({ loading: false, payouts: newPayouts });
    }

    fetchMoreCharges = async () => {
        if (this.state.hasMoreCharges) {
            this.setState({ loading: true });
            const pack = {
                connectAccountId: this.props.profile.stripeAccountId,
                startingAfter: this.state.charges[this.state.charges.length - 1].id,
                limit: 15
            }
            const res = await axios.post(FETCH_MORE_ARTIST_CHARGES, pack);
            const charges = res.data.data;
            const newCharges = [...this.state.charges, ...charges];
            const hasMoreCharges = res.data.has_more;
            this.setState({ loading: false, charges: newCharges, hasMoreCharges });
        }
    }

    fetchMorePayouts = async () => {
        if (this.state.hasMorePayouts) {
            this.setState({ loading: true });
            const pack = {
                connectAccountId: this.props.profile.stripeAccountId,
                startingAfter: this.state.payouts[this.state.payouts.length - 1].id,
                limit: 15
            }
            const res = await axios.post(FETCH_MORE_ARTIST_PAYOUTS, pack);
            const payouts = res.data.data;
            const newPayouts = [...this.state.payouts, ...payouts];
            const hasMorePayouts = res.data.has_more;
            this.setState({ loading: false, payouts: newPayouts, hasMorePayouts });
        }
    }

    createAvailableBalance = () => {
        const balance = (this.state.balance.instant_available[0].amount / 100).toFixed(2);
        return `$${balance}`;
    }

    navToNewCard = () => {
        window.location.hash = ARTIST.ARTIST_NEW_CARD;
    }

    onScroll = async (e) => {
        if (this.state.paymentsSelected && this.state.charges.length > 0) {
            const query = document.getElementById(this.state.charges[this.state.charges.length - 1].id);
            const lastChargeTop = query.getBoundingClientRect().top;
            const threshold = window.screen.height + THRESHOLD;
            if (lastChargeTop < threshold) {
                await this.fetchMoreCharges();
            }
        } else {
            if (this.state.payouts.length > 0) {
                const query = document.getElementById(this.state.payouts[this.state.payouts.length - 1].id);
                const lastChargeTop = query.getBoundingClientRect().top;
                const threshold = window.screen.height + THRESHOLD;
                if (lastChargeTop < threshold) {
                    await this.fetchMorePayouts();
                }
            }
        }
    }

    createPendingBalance = () => {
        const balance = (this.state.balance.pending[0].amount / 100).toFixed(2);
        return `$${balance}`;
    }

    back = () => {
        window.history.back();
    }

    changeConnectCard = async (tokens) => {
        this.setState({ loading: true });
        try {
            const pack = {
                tokens,
                customerId: this.props.profile.stripeCustomerId,
                connectAccountId: this.props.profile.stripeAccountId
            }
            const res = await axios.post(CHANGE_ARTIST_CONNECT_CARD, pack);
            const card = res.data.connectCard;
            this.setState({ loading: false, card });
        } catch (ex) {
            console.log(ex);
        }
    }

    changeCustomerCard = async (tokens) => {
        this.setState({ loading: true });
        try {
            const pack = {
                token: tokens[0],
                customerId: this.props.profile.stripeCustomerId,
            }
            const res = await axios.post(UPDATE_CUSTOMER_CARD, pack);
            this.setState({ loading: false, card: res.data.card });
        } catch (ex) {
            console.log(ex);
        }
    }

    cardChange = (tokens) => {
        if (this.props.profile.stripeAccountId) {
            this.changeConnectCard(tokens);
        } else {
            this.changeCustomerCard(tokens);
        }
    }

    renderCard = () => {
        if (this.state.card) {
            return (
                <Card 
                    card={this.state.card}
                    width={window.screen.width * .85}
                    style={{ marginTop: 15 }}
                />
            )
        }
    }

    renderBalance = () => {
        if (this.state.balance) {
            return (
                <div className='Balance-BalanceContainer'>
                    <div className='Balance-BalanceColumn'>
                        <h1 className='Balance-Amount'>{this.createPendingBalance()}</h1>
                        <p className='Balance-Label'>Pending Balance</p>
                    </div>
                </div>
            )
        }
    }

    renderTabs = () => {
        if (this.state.charges.length > 0) {
            return (
                <TabButtonRow>
                    <BrandTabButton 
                        selected={this.state.paymentsSelected}
                        onClick={this.selectPayments}
                        label='Payments'
                    />
                    <BrandTabButton 
                        selected={!this.state.paymentsSelected}
                        onClick={this.selectPayouts}
                        label='Deposits'
                        style={styles.marginLeft}
                    />
                </TabButtonRow>
            )
        }
    }

    renderCharge = (charge, index) => {
        return (
            <ArtistCharge 
                key={index}
                charge={charge}
                onClick={this.selectCharge}
            />
        )
    }

    renderCharges = () => {
        return this.state.charges.map(this.renderCharge)
    }

    renderEmptyCharges = () => {
        return (
            <div className='Balance-Empty'>
                <h3 className='Balance-EmptyTitle'>You don't have any payments yet.</h3>
            </div>
        )
    }

    renderDecideCharges = () => {
        if (this.state.charges.length > 0) {
            return this.renderCharges();
        }
        return this.renderEmptyCharges();
    }

    renderDecidePayouts = () => {
        if (this.state.payouts.length > 0) {
            return this.renderPayouts();
        }
        return this.renderEmptyPayouts();
    }

    renderEmptyPayouts = () => {
        return (
            <div className='Balance-Empty'>
                <h3 className='Balance-EmptyTitle'>You don't have any payouts yet.</h3>
            </div>
        )
    }

    renderPayout = (payout, index) => {
        return (
            <Payout 
                key={index}
                payout={payout}
            />
        )
    }

    renderPayouts = () => {
        return this.state.payouts.map(this.renderPayout)
    }

    renderDecideList = () => {
        if (this.state.paymentsSelected) {
            return this.renderDecideCharges();
        }
        return this.renderDecidePayouts();
    }

    renderChargesContent = () => {
        return (
            <ListWrapper>
                {this.renderCard()}
                {this.renderBalance()}
                {this.renderTabs()}
                {this.renderDecideList()}
            </ListWrapper>
        )
    }

    renderChargeDetail = () => {
        return (
            <ArtistChargeDetail 
                charge={this.state.selectedCharge}
                open={this.state.selectedCharge ? true : false}
                onClose={this.closeCharge}
            />
        )
    }

    renderNewCard = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='New Debit Card'
                onClick={this.toggleCardOpen}
            />
        )
    }

    renderLoader = (url) => {
        return (
            <ImageBackground
                image={url}
                darken
            >
                <Loader 
                    open
                    message='Loading...'
                />
            </ImageBackground>
        )
    }

    renderCardModal = () => {
        return (
            <CardModal
                open={this.state.cardOpen}
                onClose={this.toggleCardOpen}
                message='Change Your Card'
                enforceDebit={this.props.profile.stripeAccountId ? true : false}
                tokenCount={this.props.profile.stripeAccountId ? 2 : 1}
                showPreview={false}
                onChange={this.cardChange}
            />
        )
    }

    renderContent = (url) => {
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    title='Card & Balance'
                    showBack
                    onBack={this.back}
                    loading={this.state.loading}
                />
                {this.renderChargesContent()}
                {this.renderChargeDetail()}
                {this.renderNewCard()}
                {this.renderCardModal()}
            </ImageBackground>
        )
    }

    renderDecideContent = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        if (!this.state.card) {
            return this.renderLoader(url);
        }
        return this.renderContent(url);
    }

    render = () => {
        return this.renderDecideContent();
    }
}

const styles = {
    marginLeft: {
        marginLeft: 5
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return {
        profile
    }
}

export default connect(mapStateToProps, {})(Balance);