import React, { Component } from 'react';
import { 
    addDoc, 
    collection, 
    doc, 
    getDoc, 
    getFirestore 
} from '@firebase/firestore';
import { connect } from 'react-redux';
import { 
    ImageBackground, 
    TransparentHeader, 
    ClientPicker,
    TimeSlotPicker,
    FlexRow,
    FlexRowButton,
    ButtonLabel,
    BrandButton,
    Value,
    InputLabel,
    ServicePicker,
    SlotButton,
    ServiceButton,
    Loader,
    NoProfileModal
} from '../../components';
import { CLIENT } from '../../constants/ClientRoutes';
import { CLIENT_APPOINTMENT } from '../../constants/NotiTypes';
import { RESCHEDULE, TEXT } from '../../constants/Policies';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { 
    updateAppointmentHashForArtist, 
    updateBookStats 
} from '../../util/AppointmentUtil';
import { 
    buildImageUrl, 
    buildProfileImageUrl 
} from '../../util/ImageUtil';
import { 
    fetchClientNotiHash, 
    handleNoti 
} from '../../util/NotiUtil';
import { convertUnixTimeToReadable } from '../../util/TimeUtil';
import './styles/Book.css';

class Book extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientOpen: false,
            slotOpen: false,
            seviceOpen: false,
            client: null,
            slot: null,
            service: null,
            loading: false,
            success: false,
            noProfileOpen: false
        }
    }

    canSave = () => {
        if (this.state.slot && this.state.service && this.state.client) {
            return true;
        }
        return false;
    }

    toggleNoProfile = () => {
        this.setState({ noProfileOpen: !this.state.noProfileOpen });
    }

    bookAppointment = async () => {
        this.setState({ loading: true });
        const { service, slot, client } = this.state;
        const { profile } = this.props;
        const appointment = {
            service,
            startTime: slot.slot.startTime,
            unixTime: slot.unixTime,
            endTime: slot.slot.endTime,
            day: slot.selectedDay.dateString,
            slot: slot,
            artistId: profile.id,
            artistName: profile.name,
            artistPhone: profile.phone,
            clientId: client.id ? client.id : '',
            clientName: client.name,
            clientPhone: profile.phone
        }
        const db = getFirestore();
        const ref = collection(db, 'appointments');
        const apptRef = await addDoc(ref, appointment);
        await updateAppointmentHashForArtist(profile.id, appointment.day);
        await updateBookStats(appointment, profile, client);
        if (client.id) {
            const notiHash = await fetchClientNotiHash(client.id);
            const method = notiHash[RESCHEDULE];
            const preview = `${profile.name} booked an appointment with you.`;
            const desc = `${profile.name} booked an appointment with you on ${convertUnixTimeToReadable(slot.unixTime, true)}`;
            const link = `${CLIENT.CLIENT_VIEW_APPOINTMENT}/${apptRef.id}`;
            await handleNoti(client.phone, desc, preview, CLIENT_APPOINTMENT, client.id, method, link, true);
        } else {
            const desc = `${profile.name} booked an appointment with you on ${convertUnixTimeToReadable(slot.unixTime, true)}`;
            await handleNoti(client.phone, desc, desc, CLIENT_APPOINTMENT, '', TEXT, '', true);
        }
        this.setState({ success: true });
    }

    toggleClient = () => {
        this.setState({ clientOpen: !this.state.clientOpen });
    }

    toggleSlot = () => {
        this.setState({ slotOpen: !this.state.slotOpen });
    }

    toggleService = () => {
        this.setState({ serviceOpen: !this.state.serviceOpen });
    }

    clientChange = async (client) => {
        const db = getFirestore();
        const clientRef = doc(db, `clients/${client.objectID}`);
        const snap = await getDoc(clientRef).then();
        this.setState({ 
            client: {
                ...snap.data(),
                id: snap.id
            }
        });
    }

    serviceChange = (service) => {
        this.setState({ service, slot: null });
    }

    slotChange = (slot) => {
        this.setState({ slot });
    }

    onBack = () => {
        window.history.back();
    }

    setNoProfileClientSave = (client) => {
        this.setState({ client });
        this.toggleNoProfile();
    }

    renderNoClient = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleClient}>
                    <ButtonLabel label='Pick a Client' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderClientDecided = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleClient}>
                    <Value value={this.state.client.name} />
                    <InputLabel label='Tap to change client' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderClientNoProfile = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleNoProfile}>
                    <Value value={this.state.client.name} />
                    <InputLabel label='Tap to change client' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderDecideClient = () => {
        if (this.state.client) {
            if (this.state.client.id) {
                return this.renderClientDecided();
            }
            return this.renderClientNoProfile();
        }
        return this.renderNoClient();
    }

    renderDecideSlot = () => {
        if (this.state.client && this.state.service) {
            return (
                <SlotButton 
                    slot={this.state.slot}
                    onClick={this.toggleSlot}
                    showEndTime
                />
            )
        }
    }

    renderDecideService = () => {
        if (this.state.client) {
            return (
                <ServiceButton 
                    service={this.state.service}
                    onClick={this.toggleService}
                    serviceHeightPercent={.3}
                />
            )
        }
    }

    renderClientPicker = () => {
        return (
            <ClientPicker 
                open={this.state.clientOpen}
                onClose={this.toggleClient}
                artistId={this.props.profile.id}
                onChange={this.clientChange}
            />
        )
    }

    renderSlotPicker = () => {
        if (this.state.client && this.state.service) {
            return (
                <TimeSlotPicker 
                    open={this.state.slotOpen}
                    onClose={this.toggleSlot}
                    onChange={this.slotChange}
                    artistId={this.props.profile.id}
                    clientId={this.state.client.id}
                    service={this.state.service}
                />
            )
        }
    }

    renderServicePicker = () => {
        if (this.state.client) {
            return (
                <ServicePicker 
                    open={this.state.serviceOpen}
                    onClose={this.toggleService}
                    onChange={this.serviceChange}
                    artistId={this.props.profile.id}
                    clientId={this.state.client.id ? this.state.client.id : ''}
                />
            )
        }
    }

    decideImage = () => {
        if (this.state.service) {
            return buildImageUrl(this.props.profile.id, this.state.service.image, window.screen.width, window.screen.height);
        }
        return buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
    }

    decideLoadingTitle = () => {
        if (this.state.loading) {
            if (this.state.success) {
                return 'Appointment Booked.';
            }
            return 'Booking Appointment...'
        }
    }

    renderLoader = () => {
        return (
            <Loader 
                open={this.state.loading}
                message={this.decideLoadingTitle()}
                success={this.state.success}
                onClick={this.onBack}
            />
        )
    }

    renderOr = () => {
        if (!this.state.client && !this.props.profile.stripeAccountId) {
            return (
                <h3 className='Book-Or'>Or</h3>
            )
        }
    }

    renderNoClientProfileButton = () => {
        if (!this.state.client && !this.props.profile.stripeAccountId) {
            return (
                <FlexRow>
                    <FlexRowButton onClick={this.toggleNoProfile}>
                        <ButtonLabel label="Book a client that doesn't have an argenda profile." />
                    </FlexRowButton>
                </FlexRow>
            )
        }
    }

    renderNoProfileModal = () => {
        return (
            <NoProfileModal 
                open={this.state.noProfileOpen}
                onClose={this.toggleNoProfile}
                onSave={this.setNoProfileClientSave}
            />
        )
    }

    renderBookButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Book Appointment'
                disabled={!this.canSave()}
                onClick={this.bookAppointment}
            />
        )
    }

    render = () => {
        const url = this.decideImage();
        return (
            <ImageBackground
                image={url}
                darken
            >
                <TransparentHeader 
                    showBack
                    onBack={this.onBack}
                    title='Book an Appointment'
                />
                {this.renderDecideClient()}
                {this.renderOr()}
                {this.renderNoClientProfileButton()}
                {this.renderDecideSlot()}
                {this.renderDecideService()}
                {this.renderClientPicker()}
                {this.renderSlotPicker()}
                {this.renderServicePicker()}
                {this.renderNoProfileModal()}
                {this.renderBookButton()}
                {this.renderLoader()}
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    return { profile }
}

export default connect(mapStateToProps, {})(Book);