import React from 'react';
import PropTypes from 'prop-types';
import './styles/ImageBackground.css';

const ImageBackground = ({ image, children, darken, style }) => {
    return (
        <div 
            className='ImageBackground-Container'
            style={{ ...style, backgroundImage: `url(${image})` }}
        >
            <div className={`ImageBackground-InnerContainer ${(darken || image) ? 'ImageBackground-Darken' : ''}`}>
                {children}
            </div>
        </div>
    )
}

ImageBackground.propTypes = {
    image: PropTypes.string,
    children: PropTypes.node,
    darken: PropTypes.bool,
    style: PropTypes.object,
};

ImageBackground.defaultProps = {
    image: '',
    children: null,
    darken: false,
    style: {}
}

export default ImageBackground;