import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { 
    IoCheckmarkCircleOutline, 
    IoCloseCircleOutline 
} from 'react-icons/io5'
import './styles/Loader.css';
import { BrandButton, Body } from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const Loader = ({ open, message, success, error, onClick }) => {

    const renderSuccess = () => {
        return (
            <IoCheckmarkCircleOutline className='Loader-Success' />
        )
    }

    const renderError = () => {
        return (
            <IoCloseCircleOutline className='Loader-Error' />
        )
    }

    const renderLoader = () => {
        return (
            <ReactLoading 
                type='spokes'
                color='#FF3466'
                width={200}
                height={200}
            />
        )
    }

    const renderDecideIcon = () => {
        if (success) {
            return renderSuccess();
        }
        if (error) {
            return renderError();
        }
        return renderLoader();
    }

    const renderButton = () => {
        if (success || error) {
            return (
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Ok'
                    onClick={onClick}
                />
            )
        }
    }

    return (
        <div className={`Loader-Container ${open ? 'Loader-Show' : ''}`}>
            <Body center>
                {renderDecideIcon()}
                <p className='Loader-Message'>{message}</p>
            </Body>
            {renderButton()}
        </div>
    )
}

Loader.propTypes = {
    open: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool,
    message: PropTypes.string,
    onClick: PropTypes.func,
};

Loader.defaultProps = {
    open: false,
    success: false,
    error: false,
    message: '',
    onClick: () => {}
}

export default Loader;