import ArtistHome_ from './ArtistHome';
import Portfolio_ from './Portfolio';
import Appointments_ from './Appointments';
import ViewAppointment_ from './ViewAppointment';
import SetSchedule_ from './SetSchedule';
import AddService_ from './AddService';
import EditService_ from './EditService';
import CreateCustomService_ from './CreateCustomService';
import EditCustomService_ from './EditCustomService';
import Book_ from './Book';
import ViewClient_ from './ViewClient';
import Balance_ from './Balance';
import ToScheduling_ from './ToScheduling';
import Upgrade_ from './Upgrade';
import UpgradeIdInfo_ from './UpgradeIdInfo';
import MonthStats_ from './MonthStats';
import Setup_ from './Setup';
import DeleteProfile_ from './DeleteProfile';

export const DeleteProfile = DeleteProfile_;
export const Setup = Setup_;
export const MonthStats = MonthStats_;
export const UpgradeIdInfo = UpgradeIdInfo_;
export const Upgrade = Upgrade_;
export const ToScheduling = ToScheduling_;
export const Balance = Balance_;
export const ViewClient = ViewClient_;
export const Book = Book_;
export const EditCustomService = EditCustomService_;
export const CreateCustomService = CreateCustomService_;
export const EditService = EditService_;
export const AddService = AddService_;
export const SetSchedule = SetSchedule_;
export const Appointments = Appointments_;
export const Portfolio = Portfolio_;
export const ArtistHome = ArtistHome_;
export const ViewAppointment = ViewAppointment_;