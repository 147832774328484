import ImageBackground_ from './ImageBackground';
import BrandButton_ from './BrandButton';
import TransparentHeader_ from './TransparentHeader';
import Body_ from './Body';
import FlexButton_ from './FlexButton';
import TransparentInput_ from './TransparentInput';
import InputLabel_ from './InputLabel';
import ListWrapper_ from './ListWrapper';
import ButtonLabel_ from './ButtonLabel';
import ButtonSubLabel_ from './ButtonSubLabel';
import Placeholder_ from './Placeholder';
import Value_ from './Value';
import AddressPicker_ from './AddressPicker';
import Message_ from './Message';
import FlexRow_ from './FlexRow';
import FlexRowButton_ from './FlexRowButton';
import SSNINput_ from './SSNInput';
import Loader_ from './Loader';
import ProfileImage_ from './ProfileImage';
import RelativeBody_ from './RelativeBody';
import PortfolioImage_ from './PortfolioImage';
import TimeInput_ from './TimeInput';
import AreYouSure_ from './AreYouSure';
import Calendar_ from './Calendar';
import RepeatControl_ from './RepeatControl';
import BottomUpModal_ from './BottomUpModal';
import BreakControl_ from './BreakControl';
import DurationPicker_ from './DurationPicker';
import DurationControl_ from './DurationControl';
import PriceControl_ from './PriceControl';
import PriceButton_ from './PriceButton';
import PortfolioPicker_ from './PortfolioPicker';
import Service_ from './Service';
import AddressPickerModal_ from './AddressPickerModal';
import SearchBar_ from './SearchBar';
import Artist_ from './Artist';
import Scroll_ from './Scroll';
import SalonModal_ from './SalonModal';
import ScheduleModal_ from './ScheduleModal';
import InfoRow_ from './InfoRow';
import StatsRow_ from './StatsRow';
import Bio_ from './Bio';
import ArtistTop_ from './ArtistTop';
import ImagesContainer_ from './ImagesContainer';
import ArtistScheduleModal_ from './ArtistScheduleModal';
import MapMarker_ from './MapMarker';
import ArtistAddressModal_ from './ArtistAddressModal';
import ModalRowBack_ from './ModalRowBack';
import Header_ from './Header';
import TimeSlotPicker_ from './TimeSlotPicker';
import RowCalendar_ from './RowCalendar';
import ServicePicker_ from './ServicePicker';
import FullPhotoModal_ from './FullPhotoModal';
import Appointment_ from './Appointment';
import Option_ from './Option';
import CustomServiceModal_ from './CustomServiceModal';
import ClientPicker_ from './ClientPicker';
import Client_ from './Client';
import CustomService_ from './CustomService';
import ArtistSettingsModal_ from './ArtistSettingsModal';
import PolicyModal_ from './PolicyModal';
import GradientBackground_ from './GradientBackground';
import AppointmentsModal_ from './AppointmentsModal';
import SlotButton_ from './SlotButton';
import ServiceButton_ from './ServiceButton';
import NoCardModal_ from './NoCardModal';
import PayModal_ from './PayModal';
import ModalTitle_ from './ModalTitle';
import Card_ from './Card';
import ClientSettingsModal_ from './ClientSettingsModal';
import Charge_ from './Charge';
import ArtistCharge_ from './ArtistCharge';
import ArtistChargeDetail_ from './ArtistChargeDetail';
import Payout_ from './Payout';
import ConflictModal_ from './ConflictModal';
import NoShowModal_ from './NoShowModal';
import CancelModal_ from './CancelModal';
import ModalError_ from './ModalError';
import ModalSuccess_ from './ModalSuccess';
import ModalLoader_ from './ModalLoader';
import ModalInput_ from './ModalInput';
import TabButton_ from './TabButton';
import TabButtonRow_ from './TabButtonRow';
import RatingModal_ from './RatingModal';
import Rating_ from './Rating';
import Bullet_ from './Bullet';
import PriceLabel_ from './PriceLabel';
import StripeId_ from './StripeId';
import UpdateEmail_ from './UpdateEmail';
import SettingsTitle_ from './SettingsTitle';
import SettingsLoader_ from './SettingsLoader';
import UpdatePassword_ from './UpdatePassword';
import UpdatePhone_ from './UpdatePhone';
import UpdateBio_ from './UpdateBio';
import UpdateFees_ from './UpdateFees';
import Select_ from './Select';
import UpdateNoti_ from './UpdateNoti';
import ArtistAccount_ from './ArtistAccount';
import UpdateNotiType_ from './UpdateNotiType';
import ClientAccount_ from './ClientAccount';
import UpdateClientPhone_ from './UpdateClientPhone';
import UpdateClientEmail_ from './UpdateClientEmail';
import UpdateClientPassword_ from './UpdateClientPassword';
import ArtistNotiSystem_ from './ArtistNotiSystem';
import Noti_ from './Noti';
import ArtistNotis_ from './ArtistNotis';
import Empty_ from './Empty';
import Noti2_ from './Noti2';
import NotiIcon_ from './NotiIcon';
import ClientNotiSystem_ from './ClientNotiSystem';
import ClientNotis_ from './ClientNotis';
import ClientUpdateNoti_ from './ClientUpdateNoti';
import ClientUpdateNotiType_ from './ClientUpdateNotiType';
import StatCircle_ from './StatCircle';
import NoProfileModal_ from './NoProfileModal';
import Stat_ from './Stat';
import PaidOverlay_ from './PaidOverlay';
import DigitalInput_ from './DigitalInput';
import TimeRow_ from './TimeRow';
import ClockModal_ from './ClockModal';
import MeridControl_ from './MeridControl';
import ClockInput_ from './ClockInput';
import BrandTabButton_ from './BrandTabButton';
import ArtistRow_ from './ArtistRow';
import ArtistForRow_ from './ArtistForRow';
import SignInModal_ from './SignInModal';
import Artist2_ from './Artist2';
import ArgendaLogo_ from './ArgendaLogo';
import BrandInput_ from './BrandInput';
import BrandButton2_ from './BrandButton2';
import Error_ from './Error';
import ClientAvatar_ from './ClientAvatar';
import Pill_ from './Pill';
import SelectedImageModal_ from './SelectedImageModal';
import BasicInfoForm_ from './BasicInfoForm';
import ProfileTypeForm_ from './ProfileTypeForm';
import ResetPasswordForm_ from './ResetPasswordForm';
import ArtistPreview_ from './ArtistPreview';
import Carousel_ from './Carousel';
import CardModal_ from './CardModal';
import Address_ from './Address';
import NotiGroup_ from './NotiGroup';
import NotiBadge_ from './NotiBadge';
import DeleteClientAccount_ from './DeleteClientAccount';
import SignInForm_ from './SignInForm';
import ArtistTypeForm_ from './ArtistTypeForm';
import ArtistInfoForm_ from './ArtistInfoForm';
import AddressSearch_ from './AddressSearch';
import DateInput_ from './DateInput';
import StripeInfoForm_ from './StripeInfoForm';
import AddressButton_ from './AddressButton';
import EasyInput_ from './EasyInput';
import ImageInput_ from './ImageInput';
import ProfileImageInput_ from './ProfileImageInput';
import IdInput_ from './IdInput';
import Schedule_ from './Schedule';
import BigTitle_ from './BigTitle';
import HoursSetup_ from './HoursSetup';
import SubTitle_ from './SubTitle';
import WelcomeSetup_ from './WelcomeSetup';
import FAB_ from './FAB';
import ImagesSetup_ from './ImagesSetup';
import ImageProgress_ from './ImageProgress';
import ImageLimitModal_ from './ImageLimitModal';
import ServiceSetup_ from './ServiceSetup';
import AddressPicker2_ from './AddressPicker2';
import CardInput_ from './CardInput';
import UpdateArtistType_ from './UpdateArtistType';
import CancelAppointmentsModal_ from './CancelAppointmentsModal';
import TypeIndicator_ from './TypeIndicator';
import PaymentDetailsForm_ from './PaymentDetailsForm';
import PWA_ from './PWA';
import GenOption_ from './GenOption';
import SalonInfoForm_ from './SalonInfoForm';
import DesktopDialog_ from './DesktopDialog';
import ArtistCardForm_ from './ArtistCardForm';

export const ArtistCardForm = ArtistCardForm_;
export const DesktopDialog = DesktopDialog_;
export const SalonInfoForm = SalonInfoForm_;
export const GenOption = GenOption_;
export const PWA = PWA_;
export const PaymentDetailsForm = PaymentDetailsForm_;
export const TypeIndicator = TypeIndicator_;
export const CancelAppointmentsModal = CancelAppointmentsModal_;
export const UpdateArtistType = UpdateArtistType_;
export const CardInput = CardInput_;
export const AddressPicker2 = AddressPicker2_;
export const ServiceSetup = ServiceSetup_;
export const ImageLimitModal = ImageLimitModal_;
export const ImageProgress = ImageProgress_;
export const ImagesSetup = ImagesSetup_;
export const FAB = FAB_;
export const WelcomeSetup = WelcomeSetup_;
export const SubTitle = SubTitle_;
export const HoursSetup = HoursSetup_;
export const BigTitle = BigTitle_;
export const Schedule = Schedule_;
export const IdInput = IdInput_;
export const ProfileImageInput = ProfileImageInput_;
export const ImageInput = ImageInput_;
export const EasyInput = EasyInput_;
export const AddressButton = AddressButton_;
export const StripeInfoForm = StripeInfoForm_;
export const DateInput = DateInput_;
export const AddressSearch = AddressSearch_;
export const ArtistInfoForm = ArtistInfoForm_;
export const ArtistTypeForm = ArtistTypeForm_;
export const SignInForm = SignInForm_;
export const DeleteClientAccount = DeleteClientAccount_;
export const NotiBadge = NotiBadge_;
export const NotiGroup = NotiGroup_;
export const Address = Address_;
export const CardModal = CardModal_;
export const Carousel = Carousel_;
export const ArtistPreview = ArtistPreview_;
export const ResetPasswordForm = ResetPasswordForm_;
export const ProfileTypeForm = ProfileTypeForm_;
export const BasicInfoForm = BasicInfoForm_;
export const SelectedImageModal = SelectedImageModal_;
export const Pill = Pill_;
export const ClientAvatar = ClientAvatar_;
export const Error = Error_;
export const BrandButton2 = BrandButton2_;
export const BrandInput = BrandInput_;
export const ArgendaLogo = ArgendaLogo_;
export const Artist2 = Artist2_;
export const SignInModal = SignInModal_;
export const ArtistForRow = ArtistForRow_;
export const ArtistRow = ArtistRow_;
export const BrandTabButton = BrandTabButton_;
export const ClockInput = ClockInput_;
export const MeridControl = MeridControl_;
export const ClockModal = ClockModal_;
export const TimeRow = TimeRow_;
export const DigitalInput = DigitalInput_;
export const PaidOverlay = PaidOverlay_;
export const Stat = Stat_;
export const NoProfileModal = NoProfileModal_;
export const StatCircle = StatCircle_;
export const ClientUpdateNotiType = ClientUpdateNotiType_;
export const ClientUpdateNoti = ClientUpdateNoti_;
export const ClientNotis = ClientNotis_;
export const ClientNotiSystem = ClientNotiSystem_;
export const NotiIcon = NotiIcon_;
export const Noti2 = Noti2_;
export const Empty = Empty_;
export const ArtistNotis = ArtistNotis_;
export const Noti = Noti_;
export const ArtistNotiSystem = ArtistNotiSystem_;
export const UpdateClientEmail = UpdateClientEmail_;
export const UpdateClientPassword = UpdateClientPassword_;
export const UpdateClientPhone = UpdateClientPhone_;
export const ClientAccount = ClientAccount_;
export const UpdateNotiType = UpdateNotiType_;
export const ArtistAccount = ArtistAccount_;
export const UpdateNoti = UpdateNoti_;
export const Select = Select_;
export const UpdateFees = UpdateFees_;
export const UpdateBio = UpdateBio_;
export const UpdatePhone = UpdatePhone_;
export const UpdatePassword = UpdatePassword_;
export const SettingsLoader = SettingsLoader_;
export const SettingsTitle = SettingsTitle_;
export const UpdateEmail = UpdateEmail_;
export const StripeId = StripeId_;
export const PriceLabel = PriceLabel_;
export const Bullet = Bullet_;
export const Rating = Rating_;
export const RatingModal = RatingModal_;
export const TabButtonRow = TabButtonRow_;
export const TabButton = TabButton_;
export const ModalInput = ModalInput_;
export const ModalLoader = ModalLoader_
export const ModalSuccess = ModalSuccess_;
export const ModalError = ModalError_;
export const CancelModal = CancelModal_;
export const NoShowModal = NoShowModal_;
export const ConflictModal = ConflictModal_;
export const Payout = Payout_;
export const ArtistChargeDetail = ArtistChargeDetail_;
export const ArtistCharge = ArtistCharge_;
export const Charge = Charge_;
export const ClientSettingsModal = ClientSettingsModal_;
export const Card = Card_;
export const ModalTitle = ModalTitle_;
export const PayModal = PayModal_;
export const NoCardModal = NoCardModal_;
export const ServiceButton = ServiceButton_;
export const SlotButton = SlotButton_;
export const AppointmentModal = AppointmentsModal_;
export const GradientBackground = GradientBackground_;
export const PolicyModal = PolicyModal_;
export const ArtistSettingsModal = ArtistSettingsModal_;
export const CustomService = CustomService_;
export const Client = Client_;
export const ClientPicker = ClientPicker_;
export const CustomServiceModal = CustomServiceModal_;
export const Option = Option_;
export const Appointment = Appointment_;
export const FullPhotoModal = FullPhotoModal_;
export const ServicePicker = ServicePicker_;
export const RowCalendar = RowCalendar_;
export const TimeSlotPicker = TimeSlotPicker_
export const Header = Header_;
export const ModalRowBack = ModalRowBack_
export const ArtistAddressModal = ArtistAddressModal_;
export const MapMarker = MapMarker_;
export const ArtistScheduleModal = ArtistScheduleModal_;
export const ImagesContainer = ImagesContainer_;
export const ArtistTop = ArtistTop_;
export const Bio = Bio_;
export const StatsRow = StatsRow_;
export const InfoRow = InfoRow_;
export const ScheduleModal = ScheduleModal_;
export const SalonModal = SalonModal_;
export const Scroll = Scroll_;
export const Artist = Artist_;
export const SearchBar = SearchBar_;
export const AddressPickerModal = AddressPickerModal_;
export const Service = Service_;
export const PortfolioPicker = PortfolioPicker_;
export const PriceButton = PriceButton_;
export const PriceControl = PriceControl_;
export const DurationControl = DurationControl_;
export const DurationPicker = DurationPicker_;
export const BreakControl = BreakControl_;
export const BottomUpModal = BottomUpModal_;
export const RepeatControl = RepeatControl_;
export const Calendar = Calendar_;
export const AreYouSure = AreYouSure_;
export const TimeInput = TimeInput_;
export const PortfolioImage = PortfolioImage_;
export const RelativeBody = RelativeBody_;
export const ProfileImage = ProfileImage_;
export const Loader = Loader_;
export const FlexRowButton = FlexRowButton_;
export const FlexRow = FlexRow_;
export const SSNINput = SSNINput_;
export const Message = Message_;
export const AddressPicker = AddressPicker_;
export const Value = Value_;
export const Placeholder = Placeholder_;
export const ButtonSubLabel = ButtonSubLabel_;
export const ButtonLabel = ButtonLabel_;
export const ListWrapper = ListWrapper_;
export const InputLabel = InputLabel_;
export const TransparentInput = TransparentInput_;
export const FlexButton = FlexButton_;
export const Body = Body_;
export const TransparentHeader = TransparentHeader_;
export const BrandButton = BrandButton_;
export const ImageBackground = ImageBackground_;