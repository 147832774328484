import React from 'react';
import './styles/Payout.css';
import PropTypes from 'prop-types';
import { convertUnixTimeToReadable } from '../util/TimeUtil';

const Payout = ({ payout, onClick }) => {

    const click = () => {
        onClick(payout);
    }

    const amount = (payout.amount / 100).toFixed(2);
    const created = convertUnixTimeToReadable(payout.created * 1000);
    return (
        <div
            onClick={click}
            id={payout.id}
            className='Payout-Container'
        >
            <h2 className='Payout-Amount'>{`$${amount}`}</h2>
            <h5 className='Payout-Created'>{created}</h5>
        </div>
    )
}

Payout.propTypes = {
    payout: PropTypes.object,
    onClick: PropTypes.func
};

Payout.defaultProps = {
    payout: {},
    onClick: () => {}
}

export default Payout;