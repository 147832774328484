import React from 'react';
import PropTypes from 'prop-types';
import { Body, BrandButton, SettingsTitle } from '.';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/ModalSuccess.css';
import BigTitle from './BigTitle';

const ModalSuccess = ({ message, onClick }) => {
    return (
        <Body>
            <Body center>
                <IoCheckmarkCircle className='ModalSuccess-Icon' />
                <BigTitle title={message} />
            </Body>
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Okay'
                onClick={onClick}
            />
        </Body>

    )
}

ModalSuccess.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func,
};

ModalSuccess.defaultProps = {
    message: '',
    onClick: () => {}
}

export default ModalSuccess;