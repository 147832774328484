import React from 'react';
import PropTypes from 'prop-types';
import { getLogo } from '../util/ImageUtil';

const ASPECT_RATIO = 50/161;

const ArgendaLogo = ({ width, style }) => {

    const calcHeight = () => {
        return (ASPECT_RATIO) * width;
    }

    return (
        <img src={getLogo(width, calcHeight())} style={{ ...style, width, height: calcHeight() }} />
    )
}

ArgendaLogo.propTypes = {
    width: PropTypes.number,
    style: PropTypes.object
};

ArgendaLogo.defaultProps = {
    width: 161,
    style: {}
}

export default ArgendaLogo;