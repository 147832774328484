import React from 'react';
import PropTypes from 'prop-types';
import { Body, BottomUpModal, BrandButton, ModalTitle } from '.';
import { convertNumberToReadable } from '../util/TimeUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

const ConflictModal = ({ open, onClick, slot }) => {
    const time = slot ? convertNumberToReadable(slot.slot.startTime) : '';
    return (
        <BottomUpModal
            open={open}
            height={0.4}
            title='Oops! There is a conflict.'
        >
            <Body center>
                <ModalTitle 
                    title={`You took to long to book your appointment. Your ${time} slot is no longer available. Please choose another slot.`}
                />
            </Body>
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Okay'
                onClick={onClick}
            />
        </BottomUpModal>
    )
}

ConflictModal.propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func,
    slot: PropTypes.object
};

ConflictModal.defaultProps = {
    open: false,
    onClick: () => {},
    slot: {}
}

export default ConflictModal;