import React, { Component } from 'react';
import {
    ImageBackground,
    SettingsTitle,
    TransparentHeader,
    Body,
    EasyInput,
    BrandButton,
    ModalLoader,
    ModalSuccess,
    CancelAppointmentsModal,
    ModalError,
    BigTitle
} from '../../components';
import { connect } from 'react-redux';
import { buildProfileImageUrl, deleteImage } from '../../util/ImageUtil';
import { SIGNUP } from '../../constants/ClientRoutes';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { GetClientIndexForArtist } from '../../util/AlgoliaUtil';
import { parseDocs } from '../../util/FirestoreUtil';
import { deleteUser, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import axios from 'axios';
import { DELETE_CUSTOMER_ACCOUNT, DELETE_PAYMENT_ARTIST, FETCH_CONNECT_CARD_AND_BALANCE } from '../../constants/ArgendaServerURLS';

class DeleteProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: '',
            password: '',
            cancelOpen: false,
            error: false,
            balance: null
        }
    }

    componentDidMount = async () => {
        if (this.props.profile.stripeAccountId) {
            const res = await axios.post(FETCH_CONNECT_CARD_AND_BALANCE, { connectAccountId: this.props.profile.stripeAccountId, limit: 1 });
            const { balance } = res.data;
            this.setState({ balance });
        }
    }

    emailChange = (email) => {
        this.setState({ email });
    }

    passwordChange = (password) => {
        this.setState({ password });
    }

    toggleCancel = () => {
        this.setState({ cancelOpen: !this.state.cancelOpen });
    }

    resetError = () => {
        this.setState({
            error: false,
        });
    }

    back = () => {
        window.history.back();
    }

    login = async () => {
        try {
            this.setState({ loading: true });   
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, this.state.email, this.state.password);
            this.deleteArtist();
        } catch (ex) {
            this.setState({ error: true, loading: false });
        }
    }

    deleteArtist = async () => {
        const db = getFirestore();
        await this.deleteAppointmentHash(db);
        await this.deleteClients(db);
        await this.deleteNotificationHash(db);
        await this.deleteNotifications(db);
        await this.deleteRatings(db);
        await this.deleteSchedule(db);
        await this.deleteServices(db);
        await this.deleteStats(db);
        await this.deleteImages(db);
        await this.deleteProfileImage();
        await this.deleteType(db);
        await this.deletePolicy(db);
        if (this.props.profile.stripeAccountId) {
            await this.deletePaymentAccount();
        } else {
            await this.deleteStripeCustomerAccount();
        }
        await this.deleteProfile(db);
        await this.deleteFirebaseAccount();
        this.setState({ loading: false, success: true });
    }

    deleteAppointmentHash = async (db) => {
        const ref = doc(db, `users/${this.props.profile.id}/appointmentHash/appointmentHash`);
        await deleteDoc(ref);
    }

    deleteClients = async (db) => {
        const index = GetClientIndexForArtist(this.props.profile.id);
        const clientsRef = collection(db, `users/${this.props.profile.id}/clients`);
        const clientsSnap = await getDocs(clientsRef).then();
        const { results: clients } = parseDocs(clientsSnap);
        for (let i = 0; i < clients.length; i++) {
            const client = clients[i];
            index.deleteObject(client.id);
            const clientRef = doc(db, `users/${this.props.profile.id}/clients/${client.id}`);
            await deleteDoc(clientRef);
        }
    }

    deleteNotificationHash = async (db) => {
        const notiHashRef = doc(db, `users/${this.props.profile.id}/notificationHash/notificationHash`);
        await deleteDoc(notiHashRef);
    }

    deleteNotifications = async (db) => {
        const notisRef = collection(db, `users/${this.props.profile.id}/notifications`);
        const notisSnap = await getDocs(notisRef).then();
        const { results: notis } = parseDocs(notisSnap);
        for (let i = 0; i < notis.length; i++) {
            const noti = notis[i];
            const notiRef = doc(db, `users/${this.props.profile.id}/notifications/${noti.id}`);
            await deleteDoc(notiRef);
        }
    }

    deleteRatings = async (db) => {
        const rantingsRef = collection(db, `users/${this.props.profile.id}/ratings`);
        const snap = await getDocs(rantingsRef).then();
        const { results: ratings } = parseDocs(snap);
        for (let i = 0; i < ratings.length; i++) {
            const rating = ratings[i];
            const ref = doc(db, `users/${this.props.profile.id}/ratings/${rating.id}`);
            await deleteDoc(ref);
        }
    }

    deleteSchedule = async (db) => {
        const scheduleRef = doc(db, `users/${this.props.profile.id}/schedule/schedule`);
        await deleteDoc(scheduleRef);
    }

    deleteServices = async (db) => {
        const servicesRef = collection(db, `users/${this.props.profile.id}/services`);
        const snap = await getDocs(servicesRef).then();
        const { results: services } = parseDocs(snap);
        for (let i = 0; i < services.length; i++) {
            const service = services[i];
            const serviceRef = doc(db, `users/${this.props.profile.id}/services/${service.id}`);
            await deleteDoc(serviceRef);
        }
    }

    deleteStats = async (db) => {
        const statsRef = collection(db, `users/${this.props.profile.id}/stats`);
        const snap = await getDocs(statsRef).then();
        const { results: stats } = parseDocs(snap);
        for (let i = 0; i < stats.length; i++) {
            const stat = stats[i];
            const statRef = doc(db, `users/${this.props.profile.id}/stats/${stat.id}`);
            await deleteDoc(statRef);
        }
    }

    deletePolicy = async (db) => {
        if (this.props.profile.stripeAccountId) {
            const ref = doc(db, `users/${this.props.profile.id}/policies/policies`);
            await deleteDoc(ref);
        }
    }

    deleteImages = async (db) => {
        const w = where('artistId', '==', this.props.profile.id);
        const ref = collection(db, `images`);
        const q = query(ref, w);
        const snap = await getDocs(q);
        const { results: images } = parseDocs(snap);
        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const imageStorageRef = `images/${image.imageName}`;
            await deleteImage(imageStorageRef);
            const imageRef = doc(db, `images/${image.id}`);
            await deleteDoc(imageRef);
        }
    }

    deleteProfileImage = async () => {
        if (this.props.profile.profileImage) {
            const imageRef = `${this.props.profile.id}/profileImage/${this.props.profile.profileImage}`;
            deleteImage(imageRef);
        }

    }

    deleteProfile = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}`);
        await deleteDoc(ref);
    }

    deleteType = async () => {
        const db = getFirestore();
        const ref = doc(db, `types/${this.props.profile.id}`);
        await deleteDoc(ref);
    }

    deleteFirebaseAccount = async () => {
        const auth = getAuth();
        await deleteUser(auth.currentUser);
    }

    deleteStripeCustomerAccount = async () => {
        await axios.post(DELETE_CUSTOMER_ACCOUNT, { stripeCustomerId: this.props.profile.stripeCustomerId });
    }

    deletePaymentAccount = async () => {
        await axios.post(DELETE_PAYMENT_ARTIST, {
            stripeCustomerId: this.props.profile.stripeCustomerId,
            stripeAccountId: this.props.profile.stripeAccountId
        });
    }

    navToSplash = () => {
        window.location.hash = SIGNUP.SPASH;
    }

    buildBackgroundURL = () => {
        const url = buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage);
        return url;
    }

    calcTotalAppointmentsFromHash = () => {
        if (this.props.appointmentHash) {
            const keys = Object.keys(this.props.appointmentHash);
            let total = 0;
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                total += this.props.appointmentHash[key];
            }
            return total;
        }
        return 0;
    }

    createDeleteWarningMessage = () => {
        const totalAppointments = this.calcTotalAppointmentsFromHash();
        if (totalAppointments === 1) {
            return `To delete your profile you need to first cancel your appointment.`
        }
        return `To delete your profile you need to first cancel your ${totalAppointments} appointments.`
    }

    renderHeader = () => {
        return (
            <TransparentHeader 
                title='Delete Profile'
                onBack={this.back}
            />
        )
    }

    renderCancelAppointmentsModal = () => {
        return (
            <CancelAppointmentsModal 
                open={this.state.cancelOpen}
                onClose={this.toggleCancel}
                profile={this.props.profile}
            />
        )
    }

    renderAppointmentsNoDeleteBody = () => {
        return (
            <ImageBackground
                image={this.buildBackgroundURL()}
                darken
            >
                {this.renderHeader()}
                <Body center>
                    <BigTitle 
                        title={this.createDeleteWarningMessage()}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='View Appointments'
                    onClick={this.toggleCancel}
                />
                {this.renderCancelAppointmentsModal()}
            </ImageBackground>
        )
    }

    renderBalanceNoDeleteBody = () => {
        return (
            <ImageBackground
                image={this.buildBackgroundURL()}
                darken
            >
                {this.renderHeader()}
                <Body center>
                    <BigTitle 
                        title={`You currently have a balance of $${(this.state.balance.pending[0].amount / 100).toFixed(2)} waiting to be paid out. You have to wait to be paid out before you delete your profile.`}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Okay'
                    onClick={this.back}
                />
            </ImageBackground>
        )
    }

    renderContent = () => {
        return (
            <ImageBackground darken image={this.buildBackgroundURL()}>
                {this.renderHeader()}
                <Body>
                    <SettingsTitle 
                        title={`To delete your profile please Log In again.`}
                    />
                    <EasyInput 
                        value={this.state.email}
                        onChange={this.emailChange}
                        placeholder='Email'
                        label='Email'
                    />
                    <EasyInput 
                        value={this.state.password}
                        onChange={this.passwordChange}
                        placeholder='Password'
                        label='Password'
                        type='password'
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Delete Profile'
                    disabled={!(this.state.email && this.state.password)}
                    onClick={this.login}
                />
            </ImageBackground>
        )
    }

    renderLoader = () => {
        return (
            <ImageBackground darken image={this.buildBackgroundURL()}>
                <ModalLoader message='Deleting Profile...' />
            </ImageBackground>
        )
    }

    renderSuccess = () => {
        return (
            <ImageBackground darken image={this.buildBackgroundURL()}>
                <ModalSuccess 
                    message='Profile Deleted.'
                    onClick={this.navToSplash}
                />
            </ImageBackground>
        )
    }

    renderError = () => {
        return (
            <ImageBackground darken image={this.buildBackgroundURL()}>
                <ModalError 
                    message="There was a problem with your email or password, please try again."
                    onClick={this.resetError}
                />
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.calcTotalAppointmentsFromHash() > 0) {
            return this.renderAppointmentsNoDeleteBody();
        }
        if (this.props.profile.stripeAccountId && this.state.balance && this.state.balance.pending[0].amount > 0) {
            return this.renderBalanceNoDeleteBody();
        }
        if (this.state.error) {
            return this.renderError();
        }
        if (this.state.loading) {
            return this.renderLoader();
        }
        if (this.state.success) {
            return this.renderSuccess();
        }
        return this.renderContent();
    }

    render = () => {
        return this.renderDecide();
    }
}

const mapStateToProps = (state) => {
    const { profile, appointmentHash } = state.artist;
    return { 
        profile,
        appointmentHash
    }
}

export default connect(mapStateToProps, {})(DeleteProfile);