import React from 'react';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';
import './styles/PortfolioImage.css';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { buildImageUrl, getThumbnailSize } from '../util/ImageUtil';

const PortfolioImage = ({ image, onClick, selected, index, id, elementId }) => {

    const click = () => {
        const result = {
            imageName: image.imageName,
            index,
            image
        }
        onClick(result);
    }

    const renderSelected = () => {
        if (selected) {
            return (
                <div className='PortfolioImage-SelectedContainer'>
                    <IoIosCheckmarkCircle className='PortfolioImage-Check' />
                </div>
            )
        }
    }

    const size = getThumbnailSize();
    const url = buildImageUrl(id, image.imageName, size, size);
    return (
        <div 
            className='PortfolioImage-Container'
            style={{ width: size, height: size, margin: 2 }}
            onClick={click}
            id={elementId}
        >
            <img 
                style={{ width: size, height: size, }}
                src={url}
            />
            {renderSelected()}
        </div>
    )
}

PortfolioImage.propTypes = {
    image: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string,
    elementId: PropTypes.string,
};

PortfolioImage.defaultProps = {
    image: null,
    onClick: () => {},
    selected: false,
    index: 0,
    id: '',
    elementId: ''
}

export default PortfolioImage;