import React from 'react'
import PropTypes from 'prop-types';
import './styles/TabButtonRow.css';

const TabButtonRow = ({ children, style }) => {
    return (
        <div 
            className='TabButtonRow-Container'
            style={style}
        >
            {children}
        </div>
    )
}

TabButtonRow.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object
};

TabButtonRow.defaultProps = {
    children: null,
    style: {}
}

export default TabButtonRow;