import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack, 
    SettingsTitle,
    FlexRow,
    FlexRowButton,
    TransparentInput,
    BrandButton,
    ModalTitle
} from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';

class DeleteAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirm: ''
        }
    }

    onChange = (confirm) => {
        this.setState({ confirm });
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Back'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title={`Are you sure you want to delete your account? If so please type "${this.props.profile.name}."`}
            />
        )
    }

    renderInput = () => {
        return (
            <FlexRow>
                <FlexRowButton>
                    <TransparentInput 
                        value={this.state.confirm}
                        onChange={this.onChange}
                        passRefUp={ref => this.confirmRef = ref}
                        placeholder={`Type ${this.props.profile.name} here`}
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }
    
    renderButton = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                disabled={!this.state.confirm}
                onClick={this.deleteAccount}
            />
        )
    }

    renderAppointmentsTitle = () => {
        const title = this.props.appointments.length > 1 ? `You currently have ${this.props.appointments.length} appointments scheduled. Before you delete your account, you must cancel all of your appointments.` : `You currently have 1 appointment scheduled. You must cancel your appointment before you delete your account.`
        return (
            <ModalTitle 
                title={title}
            />
        )
    }

    renderGoToAppointmentsButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Go to my appointments'
                onClick={this.props.goToAppointments}
            />
        )
    }

    renderDeleteAppointmentsMessage = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderAppointmentsTitle()}
                <Body />
                {this.renderGoToAppointmentsButton()}
            </Body>
        )
    }

    renderDecide = () => {
        if (this.props.appointments.length > 0) {
            return this.renderDeleteAppointmentsMessage();
        }
        return this.renderContent();
    }

    renderContent = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderInput()}
                {this.renderButton()}
            </Body>
        )
    }

    render = () => {
        return this.renderDecide();
    }
}

DeleteAccount.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
    appointments: PropTypes.array,
    goToAppointments: PropTypes.func,
};

DeleteAccount.defaultProps = {
    onBack: () => {},
    profile: {},
    appointments: [],
    goToAppointments: () => {}
};

export default DeleteAccount;