
import ArtistProfile_ from './ArtistProfile';
import Book_ from './Book';
import ViewAppointment_ from './ViewAppointment';
import PaymentInfo_ from './PaymentInfo';
import DeleteProfile_ from './DeleteProfile';
import Intro_ from './Intro';

export const Intro = Intro_;
export const DeleteProfile = DeleteProfile_;
export const PaymentInfo = PaymentInfo_;
export const ViewAppointment = ViewAppointment_;
export const Book = Book_;
export const ArtistProfile = ArtistProfile_;