import React from 'react';
import PropTypes from 'prop-types';
import './styles/ClientAvatar.css';
import { NotiBadge } from '.';

const ClientAvatar = ({ profile, size, onClick, style, settingsBadgeCount }) => {
    const outerSize = size + 4;

    const createInitials = () => {
        const first = profile.name.split(' ')[0];
        const second = profile.name.split(' ')[1];
        return `${first[0]}${second[0]}`;
    }

    const renderBadgeCount = () => {
        if (settingsBadgeCount > 0) {
            return (
                <NotiBadge 
                    count={settingsBadgeCount}
                    onClick={onClick}
                    isAvatar
                />
            )
        }
    }

    return (
        <div 
            className='CA-Outer'
            style={{ ...style, width: outerSize, height: outerSize }}
            onClick={onClick}
        >
            <div 
                className='CA-Inner'
                style={{ width: size, height: size }}
            >
                <h4>{createInitials()}</h4>
            </div>
            {renderBadgeCount()}
        </div>
    )
}

ClientAvatar.propTypes = {
    profile: PropTypes.object,
    size: PropTypes.number,
    onClick: PropTypes.func,
    style: PropTypes.object,
    settingsBadgeCount: PropTypes.number,
};

ClientAvatar.defaultProps = {
    profile: {},
    size: 150,
    onClick: () => {},
    style: {},
    settingsBadgeCount: 0
};

export default ClientAvatar;