import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal, 
    Body,
    FlexRow,
    FlexRowButton,
    BrandButton,
    TransparentInput,
    InputLabel
} from '.';
import { 
    BOTTOM_BUTTON 
} from '../universalStyles/Styles';

class NoProfileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: ''
        }
    }

    nameChange = (name) => {
        this.setState({ name });
    }

    phoneChange = (phone) => {
        this.setState({ phone });
    }

    focusPhone = () => {
        if (this.phoneRef) {
            this.phoneRef.focus();
        }
    }

    focusName = () => {
        if (this.nameRef) {
            this.nameRef.focus();
        }
    }

    canSave = () => {
        if (this.state.name && this.state.phone) {
            return true;
        }
        return false;
    }

    onSave = () => {
        const { name, phone } = this.state;
        this.props.onSave({ name, phone });
    }

    renderName = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusName}>
                    <TransparentInput 
                        value={this.state.name}
                        onChange={this.nameChange}
                        passRefUp={ref => this.nameRef = ref}
                        placeholder='Name'
                    />
                    <InputLabel label={this.state.name ? 'Name' : ''}/>
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderPhone = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusPhone}>
                    <TransparentInput 
                        value={this.state.phone}
                        onChange={this.phoneChange}
                        passRefUp={ref => this.phoneRef = ref}
                        type='tel'
                        placeholder='Phone #'
                    />
                    <InputLabel label={this.state.phone ? 'Phone #' : ''} />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.onSave}
                disabled={!this.canSave()}
            />
        )
    }

    render = () => {
        return (
            <BottomUpModal
                title='Client Info'
                open={this.props.open}
                onClose={this.props.onClose}
                height={0.6}
            >
                <Body>
                    {this.renderName()}
                    {this.renderPhone()}
                </Body>
                {this.renderSave()}
            </BottomUpModal>
        )
    }
}

NoProfileModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onSave: PropTypes.func,
};

NoProfileModal.defaultProps = {
    onClose: () => {},
    open: false,
    onSave: () => {}
}

export default NoProfileModal;