import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, BrandButton, Card, Body, ModalLoader, ModalSuccess, ModalError } from '.';
import axios from 'axios';
import './styles/PayModal.css';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { MAKE_PAYMENT } from '../constants/ArgendaServerURLS';
import { calcStripeFee, calcStripeFeeInDollars } from '../util/StripeUtil';
import { fetchClientNotiHash, fetchNotiHash, handleNoti } from '../util/NotiUtil';
import { PAYMENT } from '../constants/Policies';
import { ARTIST_PAYMENT, CLIENT_PAYMENT } from '../constants/NotiTypes';
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { incrementMonthStatAndClientStatForPayment } from '../util/AppointmentUtil';

const TIP_OPTIONS = [
    {
        label: '15%',
        value: .15
    },
    {
        label: '20%',
        value: .2
    },
    {
        label: '25%',
        value: .25,
    },
    {
        label: '30%',
        value: .30
    }
]

class PayModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTip: null,
            paymentProcessing: false,
            paymentSuccess: false,
            paymentFailed: false,
        }
    }

    calculateTip = () => {
        if (this.state.selectedTip)  {
            const tip = parseFloat((this.state.selectedTip.value * this.props.price)).toFixed(2);
            return tip;
        }
        return 0;
    }

    selectTip = (selectedTip) => {
        if (this.state.selectedTip && this.state.selectedTip.label === selectedTip.label) {
            this.setState({ selectedTip: null });
        } else {
            this.setState({ selectedTip });
        }
    }

    calcPaymentAmmount = () => {
        if (this.state.selectedTip) {
            const tip = Math.ceil(this.props.price * this.state.selectedTip.value);
            return Math.ceil(this.props.price + tip);
        }
        return Math.ceil(this.props.price);
    }

    buildPaymentPack = () => {
        const costInDollars = this.calcPaymentAmmount();
        const fee = calcStripeFee(costInDollars);
        const amount = Math.ceil(costInDollars * 100);
        return {
            customerId: this.props.client.customerId,
            fee,
            clientDescription: this.props.clientDescription,
            artistDescription: this.props.artistDescription,
            stripeAccountId: this.props.artist.stripeAccountId,
            artistId: this.props.artist.id,
            clientId: this.props.client.id,
            artistName: this.props.artist.name,
            clientName: this.props.client.name,
            type: 'Payment',
            tip: this.calculateTip(),
            serviceString: JSON.stringify(this.props.service),
            amount,
            artistPhone: this.props.artist.phone,
            clientPhone: this.props.client.phone,
            artistMessage:  `${this.props.client.name} just paid you $${this.calcPaymentAmmount()}.`,
            clientMessage: `You just paid ${this.props.artist.name} $${this.calcPaymentAmmount()}.`,
            costInDollars
        }
    }

    handleNoti = async (paymentPack) => {
        const desc = `${this.props.client.name} paid you $${paymentPack.costInDollars.toFixed(2)}.`
        const preview = desc;
        const notiHash = await fetchNotiHash(this.props.artist.id);
        const method = notiHash[PAYMENT];
        await handleNoti(this.props.artist.phone, desc, preview, ARTIST_PAYMENT, this.props.artist.id, method, '');
        const clientNotiHash = await fetchClientNotiHash(this.props.client.id);
        const clientMethod = clientNotiHash[PAYMENT];
        const clientDesc = `You paid ${this.props.artist.name} $${paymentPack.costInDollars.toFixed(2)}.`;
        const clientPreview = clientDesc;
        await handleNoti(this.props.client.phone, clientDesc, clientPreview, CLIENT_PAYMENT, this.props.client.id, clientMethod, '', true);
    }


    startPayment = async () => {
        this.setState({ paymentProcessing: true });
        const paymentPack = this.buildPaymentPack();
        try {
            await axios.post(MAKE_PAYMENT, paymentPack);
            await this.handleNoti(paymentPack);
            const amount = paymentPack.costInDollars;
            const fee = calcStripeFeeInDollars(amount);
            await incrementMonthStatAndClientStatForPayment(this.props.appointment, amount, fee);
            this.setState({ paymentProcessing: false, paymentSuccess: true });
            await this.markAppointmentPaid(amount);
        } catch (ex) {
            console.clear();
            console.log(ex);
            this.setState({ paymentFailed: true, paymentProcessing: false, paymentSuccess: false });
        }
    }

    markAppointmentPaid = async (paidAmount) => {
        const db = getFirestore();
        const ref = doc(db, `appointments/${this.props.appointment.id}`);
        await updateDoc(ref, {
            paid: true,
            paidTime: new Date().getTime(),
            paidAmount
        });
    }

    decideTipButtonClass = (selected, index) => {
        if (index < TIP_OPTIONS.length - 1) {
            if (selected) {
                return 'PayModal-TipButton PayModal-TipButtonSelected PayModal-TipButtonMargin'
            }
            return 'PayModal-TipButton PayModal-TipButtonMargin';
        }
        if (selected) {
            return 'PayModal-TipButton PayModal-TipButtonSelected';
        }
        return 'PayModal-TipButton';
    }

    decideTipButtonPercentClass = (selected) => {
        if (selected) {
            return 'PayModal-TipButtonPercent PayModal-TipButtonLabelSelected';
        }
        return 'PayModal-TipButtonPercent';
    }

    decideTipButtonLabelClass = (selected) => {
        if (selected) {
            return 'PayModal-TipButtonPrice PayModal-TipButtonLabelSelected';
        }
        return 'PayModal-TipButtonPrice';
    }

    renderTip = (tip, index) => {
        const price = `$${(tip.value * this.props.price).toFixed(2).toString()}`;
        const selected = (this.state.selectedTip && tip.label === this.state.selectedTip.label) ? true : false;
        return (
            <div 
                className={this.decideTipButtonClass(selected, index)}
                key={index}
                onClick={() => this.selectTip(tip)}
            >
                <p className={this.decideTipButtonPercentClass(selected)}>{tip.label}</p>
                <p className={this.decideTipButtonLabelClass(selected)}>{price}</p>
            </div>
        )
    }

    renderSubTipRow = () => {
        const tip = this.calculateTip();
        return (
            <div style={{ marginTop: 15 }} className='PayModal-SubRow'>
                <h3 className='PayModal-SubLabel'>Tip</h3>
                <h3 className='PayModal-SubValue'>{`$${tip}`}</h3>
            </div>
        )
    }

    renderTotalRow = () => {
        const tip = this.calculateTip();
        const price = this.props.price;
        const total = parseFloat(tip) + price;
        return (
            <div style={{ marginTop: 40 }} className='PayModal-SubRow'>
                <h3 className='PayModal-SubLabel'>Total</h3>
                <h3 className='PayModal-SubValue'>{`$${total.toFixed(2)}`}</h3>
            </div>
        )
    }

    renderDecideContent = () => {
        if (!this.state.paymentProcessing && !this.state.paymentSuccess && !this.state.paymentFailed) {
            return this.renderPayBody();
        }
        if (this.state.paymentProcessing && !this.state.paymentSuccess && !this.state.paymentFailed) {
            return this.renderPaymentProcessing();
        }
        if (this.state.paymentFailed) {
            return this.renderPaymentFailed();
        }
        if (this.state.paymentSuccess && !this.state.paymentProcessing && !this.state.paymentFailed) {
            return this.renderPaymentSuccess();
        }
    }

    renderPaymentProcessing = () => {
        return (
            <ModalLoader 
                message='Payment Processing...'
            />
        )
    }

    renderPaymentSuccess = () => {
        return (
            <ModalSuccess 
                message='Payment Accepted'
                onClick={this.props.payEnd}
            />
        )
    }

    renderPaymentFailed = () => {
        return (
            <ModalError 
                message='Title Failed'
                onClick={() => this.setState({ paymentFailed: false })}
            />
        )
    }

    renderPayBody = () => {
        return (
            <div className='PayModal-Container'>
                <Body>
                    <Card card={this.props.card} width={window.screen.width * .85} />
                    <h3 className='PayModal-SubTitle'>Tip</h3>
                    <div className='PayModal-TipOptionsRow'> 
                        {TIP_OPTIONS.map(this.renderTip)}
                    </div>
                    <div style={{ marginTop: 20 }} className='PayModal-SubRow'>
                        <h3 className='PayModal-SubLabel'>Subtotal</h3>
                        <h3 className='PayModal-SubValue'>{`$${this.props.price.toFixed(2)}`}</h3>
                    </div>
                    {this.renderSubTipRow()}
                    {this.renderTotalRow()}
                </Body>
                {this.renderPayButton()}
            </div>
        )
    }

    renderPayButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Pay'
                onClick={this.startPayment}
            />
        )
    }

    renderDismissButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Okay'
                onClick={this.props.payEnd}
            />
        )
    }


    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                height={.85}
                title={(!this.state.paymentProcessing && !this.state.paymentSuccess) ? 'Checkout' : ''}
                onClose={this.props.onClose}
                disableClose={this.state.paymentProcessing}
            >
                {this.renderDecideContent()}
            </BottomUpModal>
        )
    }
}

PayModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    payEnd: PropTypes.func,
    price: PropTypes.number,
    card: PropTypes.object,
    client: PropTypes.object,
    artist: PropTypes.object, 
    clientDescription: PropTypes.string,
    artistDescription: PropTypes.string,
    service: PropTypes.object,
    appointment: PropTypes.object
};

PayModal.defaultProps = {
    open: false,
    onClose: () => {},
    payEnd: () => {},
    price: 0,
    card: {},
    client: {},
    artist: {},
    description: '',
    artistDescription: '',
    service: {},
    appointment: {}
}

export default PayModal;