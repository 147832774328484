import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal,
    BrandButton,
    FlexRow,
    FlexRowButton,
    Value,
    ButtonLabel,
    Body,
    DigitalInput
} from '../components';
import { BOTTOM_BUTTON, INPUT_LABEL } from '../universalStyles/Styles';
import { convertWebTimeStringToNumber, convertWebTimeStringToReadable } from '../util/TimeUtil';

class BreakControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breakStart: props.schedule.breakStart,
            breakEnd: props.schedule.breakEnd,
            startOpen: false,
            endOpen: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        const { schedule } = this.props;
        const { schedule: prevSchedule } = prevProps;
        if (schedule.breakStart !== prevSchedule.breakStart || schedule.breakStart !== prevSchedule.breakStart) {
            this.setState({ breakStart: schedule.breakStart, breakEnd: schedule.breakEnd });
        }
    }


    canSave = () => {
        const { breakStart, breakEnd } = this.state;
        const hasData = (breakStart && breakEnd);
        const numberBreakStart = convertWebTimeStringToNumber(breakStart);
        const numberBreakEnd = convertWebTimeStringToNumber(breakEnd);
        const numberStart = convertWebTimeStringToNumber(this.props.schedule.start);
        const numberEnd = convertWebTimeStringToNumber(this.props.schedule.end);
        const validBreak = (numberBreakEnd > numberBreakStart);
        const validSlot = (numberBreakStart > numberStart && numberBreakEnd < numberEnd);
        return hasData && validBreak && validSlot;
    }

    save = () =>  {
        this.props.onSave({ breakStart: this.state.breakStart, breakEnd: this.state.breakEnd });
        this.props.onClose();
    }

    breakStartChange = (breakStart) => {
        this.setState({ breakStart, startOpen: false });
    }

    breakEndChange = (breakEnd) => {
        this.setState({ breakEnd, endOpen: false });
    }

    toggleStart = () => {
        this.setState({ startOpen: !this.state.startOpen });
    }

    toggleEnd = () => {
        this.setState({ endOpen: !this.state.endOpen });
    }

    renderStartValue = () => {
        if (this.state.breakStart) {
            return (
                <Value value={convertWebTimeStringToReadable(this.state.breakStart)} />
            )
        }
    }

    renderEndValue = () => {
        if (this.state.breakEnd) {
            return (
                <Value value={convertWebTimeStringToReadable(this.state.breakEnd)} />
            )
        }
    }

    renderStart = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleStart}>
                    {this.renderStartValue()}
                    <ButtonLabel 
                        label='When does your break start?'
                        style={INPUT_LABEL}
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderEnd = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleEnd}>
                    {this.renderEndValue()}
                    <ButtonLabel 
                        label='When does your break end?'
                        style={INPUT_LABEL}
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    createTitle = () => {
        const uiFriendlyStart = convertWebTimeStringToReadable(this.props.schedule.start);
        const uiFriendlyEnd = convertWebTimeStringToReadable(this.props.schedule.end);
        const message = `Your break must take place between ${uiFriendlyStart} and ${uiFriendlyEnd}.`
        return message;
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                disabled={!this.canSave()}
                onClick={this.save}
            />
        )
    }

    renderDecide = () => {
        if (this.state.startOpen) {
            return this.renderStartBody();
        }
        if (this.state.endOpen) {
            return this.renderEndBody();
        }
        return this.renderBody();
    }

    renderStartBody = () => {
        return (
            <DigitalInput 
                value={this.state.breakStart}
                onSave={this.breakStartChange}
            />
        )
    }

    renderEndBody = () => {
        return (
            <DigitalInput 
                value={this.state.breakEnd}
                onSave={this.breakEndChange}
            />
        )
    }

    renderBody = () => {
        return (
            <Body>
                {this.renderStart()}
                {this.renderEnd()}
                {this.renderSave()}
            </Body>
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                title={this.createTitle()}
                image={this.props.image}
                darken
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

BreakControl.propTypes = {
    break: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    schedule: PropTypes.object,
    image: PropTypes.string,
    onSave: PropTypes.func
}

BreakControl.defaultProps = {
    break: {},
    open: false,
    onClose: () => {},
    schedule: {},
    image: '',
    onSave: () => {}
}

export default BreakControl;