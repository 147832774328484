import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground,  
    Body, 
    HoursSetup,
    WelcomeSetup,
    ImagesSetup,
    ServiceSetup
} from '../../components';
import { setupPageChange } from '../../actions/artistActions';
import './styles/Setup.css';
import { buildProfileImageUrl } from '../../util/ImageUtil';

class Setup extends Component {

    navToHours = () => {
        this.props.setupPageChange(-window.screen.width, 0, window.screen.width, window.screen.width * 2);
    }

    toggleImageOpen = () => {
        this.setState({ imageOpen: !this.state.imageOpen });
    }

    navToWelcome = () => {
        this.props.setupPageChange(0, window.screen.width, window.screen.width * 2, window.screen.width * 3);
    }

    navToImages = () => {
        this.props.setupPageChange(-window.screen.width * 2, -window.screen.width, 0, window.screen.width * 2);
    }

    navToServices = () => {
        this.props.setupPageChange(-window.screen.width * 3, -window.screen.width * 2, -window.screen.width, 0);
    }

    renderWelcome = () => {
        return (
            <Body 
                absolute
                style={{ left: this.props.welcomeLeft }}
            >
                <WelcomeSetup 
                    onNext={this.navToHours}
                />
            </Body>
        )
    }

    renderHours  = () => {
        return (
            <Body
                absolute
                style={{ left: this.props.scheduleLeft }}
            >
                <HoursSetup 
                    scheduleHash={this.props.scheduleHash}
                    onBack={this.navToWelcome}
                    onNext={this.navToImages}
                />
            </Body>
        )
    }

    renderImages = () => {
        return (
            <Body
                absolute
                style={{ left: this.props.imagesLeft }}
            >
                <ImagesSetup 
                    onBack={this.navToHours}
                    onNext={this.navToServices}
                    images={this.props.images}
                />
            </Body>
        )
    }

    renderServices = () => {
        return (
            <Body
                absolute
                style={{ left: this.props.servicesLeft }}
            >
            <ServiceSetup  
                onBack={this.navToImages}
            />
            </Body>
        )
    }

    render = () => {
        return (
            <ImageBackground
                image={buildProfileImageUrl(window.screen.width, window.screen.height, this.props.profile.id, this.props.profile.profileImage)}
                darken
            >
                {this.renderWelcome()}
                {this.renderHours()}
                {this.renderImages()}
                {this.renderServices()}
            </ImageBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        profile, 
        images,
        welcomeLeft,
        scheduleLeft,
        imagesLeft,
        servicesLeft
    } = state.artist;
    const { scheduleHash } = state.schedule;
    const { services } = state.service;
    return { 
        profile,
        images,
        scheduleHash,
        services,
        welcomeLeft,
        scheduleLeft,
        imagesLeft,
        servicesLeft
    };
}

export default connect(mapStateToProps, { setupPageChange })(Setup);