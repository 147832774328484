import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from '.';
import { buildCalendarData, calcMinDate, convertMonthNumberToString } from '../util/DateUtil';
import { convertNumberToDay } from '../util/DayUtil';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import './styles/RowCalendar.css';

class RowCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startingIndex: 0,
            calendarData: buildCalendarData(2),
        }
    }

    nextMonth = () => {
        this.props.onChange(null);
        this.setState({ startingIndex: this.state.startingIndex + 1 });
        const row = document.getElementById('row');
        const rowData = row.getBoundingClientRect();
        row.scrollTo({
            top: rowData.top,
            left: 0,
            behavior: 'smooth'
        })
        this.props.onChange(null);
    }

    prevMonth = () => {
        this.setState({ startingIndex: this.state.startingIndex - 1});
        this.props.onChange(null);
    }

    calcDayWidth = () => {
        const daysArtistIsAvailable = Object.keys(this.props.scheduleHash).length;
        return Math.ceil(window.screen.width / daysArtistIsAvailable);
    }

    decideDayClass = (day) => {
        const today = calcMinDate();
        if (this.props.selectedDay && this.props.selectedDay.dateString === day.dateString) {
            return `RowCalendar-Day RowCalendar-DaySelected`;
        }
        if (day.unixTime === today) {
            return 'RowCalendar-Day RowCalendar-Today'
        }
        return 'RowCalendar-Day';
    }

    renderBadge = (day) => {
        const dateString = day.dateString.replaceAll('/', '-');
        if (this.props.appointmentHash[dateString]) {
            return (
                <div className='RowCalendar-Badge'>
                    <h4 className='RowCalendar-BadgeTitle'>{this.props.appointmentHash[dateString]}</h4>
                </div>
            )
        }
    }

    renderDay = (day, index) => {
        const stringDay = convertNumberToDay(day.day);
        const minDate = calcMinDate();
        const isAWorkDay = this.props.scheduleHash[stringDay] ? true : false;
        const isPastMin = day.unixTime >= minDate ? true : false;
        const isVacay = this.props.vacationHash[day.dateString] ? true : false;
        if (isAWorkDay && isPastMin && !isVacay) {
            return (
                <div 
                    className={this.decideDayClass(day)}
                    key={index}
       
                    id={day.dateString}
                    onClick={() => this.props.onChange(day)}
                >
                    <h3 className='RowCalendar-Number'>{day.dateString.split('/')[1]}</h3>
                    <h4 className={`RowCalendar-DayTitle ${(this.props.selectedDay && this.props.selectedDay.dateString === day.dateString) ? 'RowCalendar-TitleSelected' : ''}`}>{convertNumberToDay(day.day, true)}</h4>
                    {this.renderBadge(day)}
                </div>
            )
        }
    }

    renderPrevMonth = () => {
        if (this.state.startingIndex > 0) {
            return (
                <div 
                    className='RowCalendar-Day'
                    onClick={this.prevMonth}
                    style={{ width: this.calcDayWidth() }}
                >
                    <IoChevronBackOutline className='RowCalendar-Right' />
                </div>
            )
        }
    }

    renderNextMonth = () => {
        if (this.state.startingIndex < this.state.calendarData.length) {
            return (
                <div 
                    className='RowCalendar-Day'
                    onClick={this.nextMonth}
                    style={{ width: this.calcDayWidth() }}
                >
                    <IoChevronForwardOutline className='RowCalendar-Right' />
                </div>
            )
        }
    }

    render = () => {
        const monthData = this.state.calendarData[this.state.startingIndex];
        return (
            <div className={`RowCalendar-Container ${this.props.darken ? 'RowCalendar-Darken' : ''}`}>
                <Header title={convertMonthNumberToString(monthData.month)} style={{ marginBottom: 15 }} />
                <div 
                    className='RowCalendar-Row'
                    id='row'
                >
                    {this.renderPrevMonth()}
                    {monthData.days.map(this.renderDay)}
                    {this.renderNextMonth()}
                </div>
            </div>
        )
    }
}

RowCalendar.propTypes = {
    scheduleHash: PropTypes.object,
    vacationHash: PropTypes.object,
    appointmentHash: PropTypes.object,
    onChange: PropTypes.func,
    selectedDay: PropTypes.object,
    darken: PropTypes.bool,
};

RowCalendar.defaultProps = {
    scheduleHash: {},
    vacationHash: {},
    appointmentHash: {},
    onChange: () => {},
    selectedDay: null,
    darken: false,
}

export default RowCalendar;
