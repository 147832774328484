import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { clientPropChange, clearClient } from '../actions/clientActions';
import { 
    BottomUpModal, 
    ClientAccount, 
    Option, 
    Body,
    ClientNotis,
    ClientUpdateNoti,
} from '.';
import './styles/ClientSettingsModal.css';
import { BsBoxArrowLeft, BsFillBellFill, BsGearFill } from 'react-icons/bs';
import { IoCardOutline, IoNotificationsCircle } from 'react-icons/io5';
import { OPTION_CANCEL, OPTION_RESCHEDULE } from '../universalStyles/Styles';
import { getAuth, signOut } from '@firebase/auth';
import { CLIENT, SIGNUP } from '../constants/ClientRoutes';

const COLOR1 = '#FF3A3A';
const COLOR2 = '#51DB2C';
const COLOR3 = '#D735AF';

class ClientSettingsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountOpen: false
        }
    }

    logout = () => {
        const auth = getAuth();
        signOut(auth);
        this.props.clearClient();
        window.location.hash = SIGNUP.SPASH;
    }

    toggleAccount = () => {
        this.setState({ accountOpen: !this.state.accountOpen });
    }

    toggleNotis = () => {
        this.props.clientPropChange('showNotis', !this.props.showNotis);
    }

    toggleNotiOptions = () => {
        this.props.clientPropChange('showNotiOptions', !this.props.showNotiOptions);
    }

    goToAppointments = () => {
        this.closeSettings();
        this.props.clientPropChange('appointmentModalOpen', true);
    }

    closeSettings = () => {
        this.props.clientPropChange('settingsOpen', false);
        this.props.clientPropChange('showNotis', false);
        this.props.clientPropChange('showNotiOptions', false);
        this.setState({ accountOpen: false });
    }

    navToPaymentInfo = () => {
        window.location.hash = CLIENT.CLIENT_PAYMENT_INFO
    }

    renderClientAccount = () => {
        return (
            <ClientAccount 
                onBack={this.toggleAccount}
                profile={this.props.profile}
                appointments={this.props.appointments}
                goToAppointments={this.goToAppointments}
            />
        )
    }

    renderNotis = () => {
        return (
            <ClientNotis 
                profile={this.props.profile}
                notis={this.props.notis}
                onBack={this.toggleNotis}
            />
        )
    }

    renderNotiOptions = () => {
        return (
            <ClientUpdateNoti 
                profile={this.props.profile}
                onBack={this.toggleNotiOptions}
                notiHash={this.props.notiHash}
            />
        )
    }

    renderDecide = () => {
        if (this.state.accountOpen) {
            return this.renderClientAccount();
        }
        if (this.props.showNotis) {
            return this.renderNotis();
        }
        if (this.props.showNotiOptions) {
            return this.renderNotiOptions();
        }
        return this.renderContent();
    }

    renderContent = () => {
        return (
            <Body>
                <Option 
                    label='Account'
                    icon={<BsGearFill />}
                    backgroundColor={OPTION_RESCHEDULE}
                    onClick={this.toggleAccount}
                    showArrow
                />
                <Option 
                    label='Card Info & Payments'
                    icon={<IoCardOutline />}
                    backgroundColor={COLOR2}
                    onClick={this.navToPaymentInfo}
                />
                <Option 
                    label='Notifications'
                    icon={<BsFillBellFill />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    badgeCount={this.props.notis.length}
                    onClick={this.toggleNotis}
                />
                <Option 
                    label='Notification Options'
                    icon={<IoNotificationsCircle />}
                    backgroundColor='#FF3A3A'
                    showArrow
                    onClick={this.toggleNotiOptions}
                />
                <Option 
                    label='Sign Out'
                    icon={<BsBoxArrowLeft />}
                    backgroundColor={OPTION_CANCEL}
                    onClick={this.logout}
                />
            </Body>
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.settingsOpen}
                onClose={this.closeSettings}
                height={.7}
            >
                {this.renderDecide()}
            </BottomUpModal>
        )
    }
}

const mapStateToProps = (state) => {
    const { 
        profile, 
        settingsOpen,
        notis,
        showNotis,
        showNotiOptions,
        appointments,
    } = state.client;
    return { 
        profile, 
        settingsOpen,
        notis,
        showNotis,
        showNotiOptions,
        appointments
    }
}

export default connect(mapStateToProps, { clientPropChange, clearClient })(ClientSettingsModal);