import React from 'react';
import PropTypes from 'prop-types';
import './styles/SettingsTitle.css';

const SettingsTitle = ({ title, style, error }) => {
    return (
        <h3 className={error ? 'ST-Title ST-Error' : 'ST-Title'} style={style}>{title}</h3>
    )
}

SettingsTitle.propTypes = {
    title: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.bool,
}

SettingsTitle.defaultProps = {
    title: '',
    style: {},
    error: false
}

export default SettingsTitle;