import React from 'react';
import PropTypes from 'prop-types';
import { SettingsTitle } from '.';
import './styles/Select.css';


const Select = ({ value, onChange, passRefUp, label, options }) => {

    const change = (e) => {
        onChange(e.target.value);
    }


    const renderOption = (option, index) => {
        return (
            <option
                key={index}
                value={option.value}
            >
                {option.label}
            </option>
        )
    }

    return (
        <div className='S-Wrapper'>
            <SettingsTitle label={label} />
            <select
                ref={passRefUp}
                value={value}
                onChange={change}
                title={label}
                className='S-Select'
            >
                {options.map(renderOption)}
            </select>
        </div>
    )
}

Select.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    passRefUp: PropTypes.func,
    label: PropTypes.string
};

Select.defaultProps = {
    value: 0,
    onChange: () => {},
    passRefUp: () => {},
    label: ''
}

export default Select;