import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    BrandButton,
    EasyInput,
    AddressButton,
    AddressPicker2,
    BigTitle,
    GenOption,
    FlexRowButton,
    ButtonLabel,
    Placeholder,
    Value,
    FlexRow,
    InputLabel
} from '../components';
import { 
    BOTTOM_BUTTON 
} from '../universalStyles/Styles';
import { PROFESSIONS } from '../constants/ArtistProfessions';

class ArtistInfoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bodyLeft: 0,
            addressLeft: window.screen.width,
            typeLeft: -window.screen.width,
            artistType: props.artistType,
            salonName: props.salonName,
            salonAddress: props.salonAddress,
        }
    }

    onChange = () => {
        this.props.onChange({
            artistType: this.state.artistType,
            salonName: this.state.salonName,
            salonAddress: this.state.salonAddress,
        });
    }

    artistTypeChange = (artistType) => {
        this.setState({ artistType });
        this.closeType();
    }

    canNext = () => {
        const { artistType, salonName, salonAddress } = this.state;
        if (artistType && salonName && salonAddress) {
            return true;
        }
        return false;
    }

    salonNameChange = (salonName) => {
        this.setState({ salonName });
    }

    salonAddressChange = (salonAddress) => {
        this.setState({ salonAddress });
        this.closeAddress();
    }

    openAddress = () => {
        this.setState({ 
            bodyLeft: -window.screen.width, 
            addressLeft: 0,
            typeLeft: window.screen.width
        });
    }

    closeAddress = () => {
        this.setState({ 
            bodyLeft: 0, 
            addressLeft: window.screen.width,
            typeLeft:  2*window.screen.width
        });
    }

    openType = () => {
        this.setState({
            bodyLeft: -2*window.screen.width,
            addressLeft: -window.screen.width,
            typeLeft: 0
        });
    }

    closeType = () => {
        this.setState({
            bodyLeft: 0,
            address: window.screen.width,
            typeLeft: 2*window.screen.width
        });
    }

    renderInfoBack = () => {
        return (
            <ModalRowBack 
                title='Back'
                onBack={this.props.onBack}
            />
        )
    }

    renderArtistType = () => {
        return (
            <FlexRow>
            <FlexRowButton
                onClick={this.openType}
            >
                {this.state.artistType && <Value value={this.state.artistType} />}
                {!this.state.artistType && <Placeholder placeholder='Profession...' />}
                <InputLabel label='Profession' />
            </FlexRowButton>
            </FlexRow>
        )
    }

    renderSalonName = () => {
        return (
            <EasyInput 
                value={this.state.salonName}
                onChange={this.salonNameChange}
                placeholder='Salon/Studio Name...'
                label={'What\'s the name of the Salon/Studio that you work at?'}
                showLabel
            />
        )
    }

    renderAddressButton = () => {
        return (
            <AddressButton 
                onClick={this.openAddress}
                address={this.state.salonAddress}
                placeholder='Salon/Studio Address...'
                label='Salon/Studio Address'
                showLabel
            />
        )
    }

    renderButton = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label={'Next'}
                onClick={this.onChange}
                disabled={!this.canNext()}
            />
        )
    }

    renderInfoBody = () => {
        return (
            <Body 
                absolute
                style={{ left: this.state.bodyLeft }}
            >
                {this.renderInfoBack()}
                <BigTitle title='Artist Info' />
                <Body>
                    {this.renderArtistType()}
                    {this.renderSalonName()}
                    {this.renderAddressButton()}
                </Body>
                {this.renderButton()}
            </Body>
        )
    }

    renderAddressBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.addressLeft }}
            >
                <AddressPicker2 
                    onChange={this.salonAddressChange}
                    onBack={this.closeAddress}
                />
            </Body>
        )
    }

    renderProfession = (profession, index) => {
        return (
            <GenOption 
                key={index}
                option={profession}
                label={profession}
                onClick={this.artistTypeChange}
            />
        )
    }

    renderTypeBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.typeLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={this.closeType}
                />
                <BigTitle title='Pick a Profession' />
                {PROFESSIONS.map(this.renderProfession)}
            </Body>
        )
    }

    renderInfoContainer = () => {
        return (
            <Body relative>
                {this.renderInfoBody()}
                {this.renderAddressBody()}
                {this.renderTypeBody()}
            </Body>
        )
    }

    renderDecideBody = () => {
        return this.renderInfoContainer();
    }


    render = () => {
        return this.renderDecideBody()
    }
}

ArtistInfoForm.propTypes = {
    onChange: PropTypes.func,
    onBack: PropTypes.func,
    artistType: PropTypes.string,
    salonName: PropTypes.string,
    salonAddress: PropTypes.object,
};

ArtistInfoForm.defaultProps = {
    onChange: () => {},
    onBack: () => {},
    salonName: '',
    salonAddress: null,
};

export default ArtistInfoForm;