import React from 'react';
import PropTypes from 'prop-types';
import { BottomUpModal, BrandButton, Body } from '.';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/PolicyModal.css';
import { CANCEL_FEE, NO_SHOW_FEE } from '../constants/Policies';
import { convertUnixTimeToReadable, getActualAppointmentTimeInUNIX, subDaysFromUnixTime } from '../util/TimeUtil';
import { getFee } from '../util/PriceUtil';

const PolicyModal = ({ open, onClose, policyHash, artistName, service, slot, onAccept }) => {

    const cancelFee = getFee(policyHash[CANCEL_FEE], service.price);
    const noShowFee = getFee(policyHash[NO_SHOW_FEE], service.price);
    const appointmentTimeInUnix = getActualAppointmentTimeInUNIX(slot.selectedDay.unixTime, slot.slot.startTime);
    const cancelDate = subDaysFromUnixTime(appointmentTimeInUnix, 1);
    const cancelReadable = convertUnixTimeToReadable(cancelDate);

    const accept = () => {
        const tosPack = {
            cancelFee,
            noShowFee,
            cancelDate,
            acceptedDate: new Date().getTime()
        }
        onAccept(tosPack);
        onClose();
    }

    const renderNoShowFee = () => {
        if (noShowFee > 0) {
            return (
                <div className='PolicyModal-Section'>
                    <h3 className='PolicyModal-Title'>No Show Fee</h3>
                    <h5 className='PolicyModal-Desc'>{`If you do not show up for this appointment ${artistName} reserves the right to charge you a fee of $${noShowFee.toFixed(2)}.`}</h5>
                </div>
            )
        }
    }

    const renderCancelFee = () => {
        if (cancelFee > 0) {
            return (
                <div className='PolicyModal-Section'>
                    <h3 className='PolicyModal-Title'>Cancel Fee</h3>
                    <h5 className='PolicyModal-Desc'>{`If you need to cancel and fail to do so by ${cancelReadable}, you will automatically be charged a fee of $${cancelFee.toFixed(2)}.`}</h5>
                </div>
            )
        }
    }

    const renderTOS = () => {
        if (cancelFee > 0 && noShowFee > 0) {
            return (
                <h5 className='PolicyModal-Desc'>{`By booking this appointment, you agree to the No Show and Cancellation Fees outlined above.`}</h5>
            )
        }
        if (cancelFee > 0) {
            return (
                <h5 className='PolicyModal-Desc'>{`By booking this appointment, you agree to the Cancellation Fee outlined above.`}</h5>
            )
        }
        if (noShowFee > 0) {
            return (
                <h5 className='PolicyModal-Desc'>{`By booking this appointment, you agree to the No Show Fee outlined above.`}</h5>
            )
        }
    }

    return (
        <BottomUpModal
            open={open}
            onClose={onClose}
            title={`${artistName}'s Appointment Policies`}
            height={.6}
        >
            <Body>
                {renderNoShowFee()}
                {renderCancelFee()}
                {renderTOS()}
            </Body>
            <BrandButton 
                label='Book'
                style={BOTTOM_BUTTON}
                onClick={accept}
            />
        </BottomUpModal>
    )
}

PolicyModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    policyHash: PropTypes.object,
    artistName: PropTypes.string,
    service: PropTypes.object,
    slot: PropTypes.object,
    onAccept: PropTypes.func,
};

PolicyModal.defaultProps = {
    open: false,
    onClose: () => {},
    policyHash: {},
    artistName: '',
    service: {},
    slot: {},
    onAccept: () => {},
}

export default PolicyModal;