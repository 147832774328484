import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Body,
    AddressSearch,
    Scroll,
    Address,
    ModalLoader,
} from '.';
import { searchPlaces2 } from '../util/GooglePlacesUtil';

class AddressPicker2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            addresses: [],
            coords: null
        }
    }

    componentDidMount = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { enableHighAccuracy: true, timeout: 1000 });
        }
    }

    getCoords = (event) => {
        this.setState({
            coords: {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude
            }
        });
    }

    getCoordsError = (error) => {
        console.log(error);
    }

    startLoading = () => {
        this.setState({ loading: true });
    }

    searchAddress = async (search) => {
        const addresses = await searchPlaces2(search, this.state.coords)
        this.setState({ addresses, loading: false });
    }

    addressChange = (address) => {
        this.props.onChange(address);
        if (this.props.navBack) {
            this.props.onBack();
        }

    }

    renderSearch = () => {
        return (
            <AddressSearch 
                onBack={this.props.onBack}
                onSearch={this.searchAddress}
                startLoading={this.startLoading}
            />
        )
    }

    renderLoader = () => {
        return (
            <ModalLoader 
                message='Searching...'
            />
        )
    }

    renderAddress = (address, index) => {
        return (
            <Address 
                key={index}
                address={address}
                onClick={this.addressChange}
            />
        )
    }

    renderAddresses = () => {
        return (
            <Body>
                <Scroll>
                    {this.state.addresses.map(this.renderAddress)}
                </Scroll>
            </Body>
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderAddresses();
    }

    render = () => {
        return (
            <Body>
                {this.renderSearch()}
                {this.renderDecide()}
            </Body>
        )
    }
}

AddressPicker2.propTypes = {
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    navBack: PropTypes.bool,
};

AddressPicker2.defaultProps = {
    onBack: () => {},
    onChange: () => {},
    navBack: true
};

export default AddressPicker2;