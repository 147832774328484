import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    ImageBackground,
    TransparentHeader,
    BrandButton,
    Loader,
    FlexRow,
    FlexRowButton,
    Value,
    InputLabel,
    PriceControl,
    DurationPicker,
    AreYouSure
} from '../../components';
import { buildImageUrl } from '../../util/ImageUtil';
import { withRouter } from "react-router-dom";
import { 
    doc, 
    getDoc, 
    getFirestore, 
    updateDoc, 
    increment, 
    deleteDoc 
} from 'firebase/firestore';
import { BOTTOM_BUTTON } from '../../universalStyles/Styles';
import {  getReadableFromDuration } from '../../util/TimeUtil';
import { getAuth } from '@firebase/auth';

class EditCustomService extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            service: null,
            priceOpen: false,
            durationOpen: false,
            price: 0,
            duration: null,
            deleteOpen: false
        }
    }

    componentDidMount = () => {
        this.fetchService();
    }

    componentWillUnmount = () => {
        if (this.state.unsub) {
            this.state.unsub();
        }
    }

    onBack = () => {
        window.history.back();
    }

    toggleDelete = () => {
        this.setState({ deleteOpen: !this.state.deleteOpen });
    }

    togglePrice = () => {
        this.setState({ priceOpen: !this.state.priceOpen });
    }

    toggleDuration = () => {
        this.setState({ durationOpen: !this.state.durationOpen });
    }

    fetchService = async () => {
        const { serviceId, clientId, artistId } = this.props.match.params;
        const db = getFirestore();
        const ref = doc(db, `users/${artistId}/services/${serviceId}/custom/${clientId}`);
        const snap = await getDoc(ref).then();
        const service = {
            ...snap.data(),
            id: snap.id
        };
        this.setState({ 
            service,
            price: service.price,
            duration: service.duration
        });
    }

    priceChange = (price) => {
        this.setState({ price });
    }

    durationChange = (duration) => {
        this.setState({ duration });
    }

    save = async () => {
        const db = getFirestore();
        const { serviceId, clientId, artistId } = this.props.match.params;
        const ref = doc(db, `users/${artistId}/services/${serviceId}/custom/${clientId}`);
        await updateDoc(ref, {
            price: this.state.price,
            duration: this.state.duration
        });
        this.onBack();
    }

    canSave = () => {
        if (this.state.price && this.state.duration) {
            return true;
        }
        return false;
    }

    delete = async () => {
        const db = getFirestore();
        const { serviceId, clientId, artistId } = this.props.match.params;
        const ref = doc(db, `users/${artistId}/services/${serviceId}/custom/${clientId}`);
        const serviceRef = doc(db, `users/${artistId}/services/${serviceId}`);
        await updateDoc(serviceRef, {
            customizations: increment(-1)
        });
        await deleteDoc(ref);
        this.onBack();
    }

    renderLoader = () => {
        return (
            <Loader 
                message='Loading...'
            />
        )
    }

    renderDecideDuraton = () => {
        if (this.state.service.duration.doubleStart) {
            return this.renderDoubleDuration();
        }
        return this.renderDuration();
    }

    renderDuration = () => {
        const { singleStart } = getReadableFromDuration(this.state.duration);
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleDuration}>
                    <Value value={singleStart} />
                    <InputLabel label='Duration' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderDoubleDuration = () => {
        const { doubleStart, doubleBreak, doubleEnd } = getReadableFromDuration(this.state.duration);
        return (
            <FlexRow>
                <FlexRowButton onClick={this.toggleDuration}>
                    <Value value={doubleStart} />
                    <InputLabel label='Initial Time' />
                    <Value value={doubleBreak} />
                    <InputLabel label='Inbetween Time' />
                    <Value value={doubleEnd} />
                    <InputLabel label='Finishing Up' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderPrice = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.togglePrice}>
                    <Value value={`$${this.state.price.toString()}`} />
                    <InputLabel label='Price' />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderPriceControl = () => {
        return (
            <PriceControl 
                open={this.state.priceOpen}
                onClose={this.togglePrice}
                price={this.state.service.price}
                onSave={this.priceChange}
            />
        )
    }

    renderDurationControl = () => {
        return (
            <DurationPicker 
                open={this.state.durationOpen}
                onClose={this.toggleDuration}
                duration={this.state.service.duration}
                onSave={this.durationChange}
            />
        )
    }

    renderDeleteModal = () => {
        return (
            <AreYouSure 
                open={this.state.deleteOpen}
                onClose={this.toggleDelete}
                title={`Are you sure you want to delete this custom service?`}
                confirmMessage={`To delete this custom service type: `}
                valueToType={this.state.service.client.name}
                accept={this.delete}
                buttonLabel='Delete'
            />
        )
    }

    renderContent = () => {
        const defaultUrl = buildImageUrl(this.props.profile.id, this.props.service.image, window.screen.width, window.screen.height);
        return (
            <ImageBackground
                image={defaultUrl}
                darken
            >
                <TransparentHeader 
                    title={`Edit ${this.state.service.client.name}'s ${this.state.service.name}`}
                    onBack={this.onBack}
                    showDelete
                    onDelete={this.toggleDelete}
                />
                {this.renderDecideDuraton()}
                {this.renderPrice()}
                {this.renderPriceControl()}
                {this.renderDurationControl()}
                {this.renderDeleteModal()}
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Save'
                    disabled={!this.canSave()}
                    onClick={this.save}
                />
            </ImageBackground>
        )
    }

    renderDecide = () => {
        if (this.state.service) {
            return this.renderContent();
        }
        return this.renderLoader();
    }

    render = () => {
        return this.renderDecide();
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.artist;
    const { service } = state.service;
    return { profile, service }; 
}

export default withRouter(connect(mapStateToProps, { })(EditCustomService));