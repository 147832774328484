import React, { Component } from 'react';
import { 
    Body, 
    ImageBackground, 
    ListWrapper, 
    ModalRowBack, 
    TransparentHeader 
} from '.';
import PropTypes from 'prop-types';
import './styles/AddressPicker.css';
import { searchPlaces2 } from '../util/GooglePlacesUtil';
import ReactLoading from 'react-loading';

let timer = '';

class AddressPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            coords: null,
            addresses: [],
            loading: false,
        }
    }

    componentDidMount = () => {
        this.input.focus();
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCoords, this.getCoordsError, { enableHighAccuracy: true, timeout: 1000 });
        }
    }

    getCoords = (event) => {
        this.setState({
            coords: {
                latitude: event.coords.latitude,
                longitude: event.coords.longitude
            }
        });
    }

    search = async () => {
        const addresses = await searchPlaces2(this.state.search, this.state.coords);
        this.setState({ addresses, loading: false });
    }

    getCoordsError = (error) => {
        console.log(error);
    }

    searchChange = (e) => {
        this.setState({ search: e.target.value });
    }

    onKeyUp = () => {
        //if a timer exists clear it
        if (timer) {
            clearTimeout(timer);
        }
        //create a new timer for 1500 ms, when timer runs out, run search
        timer = setTimeout(this.search, 700);
    }

    onKeyDown = () => {
        this.setState({ loading: true });
        if (timer) {
            clearTimeout(timer);
        }
    }

    selectAddress = (addressData) => {
        console.log(addressData);
        const address = {
            address: addressData.formatted_address,
            location: addressData.geometry.location
        }
        this.props.onChange(address);
        this.props.back();
    }

    renderLoader = () => {
        return (
            <div className='AddressPicker-Loading'>
                <ReactLoading 
                    type={'spokes'}
                    color={'rgba(255, 255, 255, 0.3)'}
                />
            </div>
        )
    }

    renderAddress = (address, index) => {
        return (
            <div 
                key={index} 
                className='AddressPicker-Address'
                onClick={() => this.selectAddress(address)}
            >
                <p>{address.formatted_address}</p>
            </div>
        )
    }

    renderAddresses = () => {
        return (
            <div className='AddressPicker-List'>
                <ListWrapper>
                    {this.state.addresses.map(this.renderAddress)}
                </ListWrapper>
            </div>
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderAddresses();
    }

    renderInput = () => {
        return (
            <input 
                className='AddressPicker-Search'
                placeholder='Search Address...'
                value={this.state.search}
                onChange={this.searchChange}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                ref={ref => this.input = ref}
            />
        )
    }

    renderFullSize = () => {
        return (
            <ImageBackground image={this.props.image}>
                <TransparentHeader 
                    title='Search Your Address'
                    onBack={this.props.back}
                />
                <Body>
                    {this.renderInput()}
                    {this.renderDecide()}
                </Body>
            </ImageBackground>
        )
    }

    renderBack = () => {
        if (this.props.showBack) {
            return (
                <ModalRowBack 
                    title='Back'
                    onBack={this.props.back}
                />
            )
        }

    }

    renderInsideModal = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderInput()}
                {this.renderDecide()}
            </Body>
        )
    }

    renderDecideContent = () => {
        if (this.props.insideModal) {
            return this.renderInsideModal();
        }
        return this.renderFullSize();
    }

    render = () => {
        return this.renderDecideContent()
    }
}

AddressPicker.propTypes = {
    back: PropTypes.func,
    onChange: PropTypes.func,
    insideModal: PropTypes.bool,
    back: PropTypes.func,
    showBack: PropTypes.bool,
};

AddressPicker.defaultProps = {
    back: () => {},
    onChange: () => {},
    insideModal: false,
    back: () => {},
    showBack: true
}

export default AddressPicker;