import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    BottomUpModal,
    Body,
    BrandButton,
    FlexRow,
    FlexRowButton,
    TransparentInput,
    Placeholder,
    Value,
    ModalLoader,
    Scroll,
    Address,
    InputLabel,
    Card,
    GradientBackground,
    ModalError
} from '.';
import './styles/CardModal.css';
import { searchPlaces2 } from '../util/GooglePlacesUtil';
import { parseAddressForCard } from '../util/ParsingUtil';
import { testCard } from '../util/StripeUtil';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { IoChevronBackOutline } from 'react-icons/io5';

const DEBIT = 'debit';
const DEBIT_ERROR = 'Because you chose to sign up for payments you must use a debit card.';

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#fff",
        color: "#fff",
        fontWeight: 400,
        fontSize: "18px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "rgba(255, 255, 255, 0.6)"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      },
    }
}

let timer = '';

const CardModal = ({ open, onClose, onChange, enforceDebit, message, tokenCount, coords, showPreview, image }) => {

    let nameRef = null;

    const [name, setName] =                         useState('');
    const [cardInfoComplete, setCardInfoComplete] = useState(false);
    const [address, setAddress] =                   useState(null);
    const [error, setError] =                       useState('');
    const [cardLeft, setCardLeft] =                 useState(0);
    const [addressLeft, setAddressLeft] =           useState(window.screen.width);
    const [checking, setChecking] =                 useState(false);
    const [search, setSearch] =                     useState('');
    const [addresses, setAddresses] =               useState([]);
    const [loading, setLoading] =                   useState(false);
    const [tokens, setTokens] =                     useState([]);


    const stripe = useStripe();
    const elements = useElements();

    const reset = () => {
        setName('');
        setAddress(null);
        setError('');
        setCardLeft(0);
        setAddressLeft(window.screen.width);
        setSearch('');
        setAddresses([]);
        setLoading(false);
        setChecking(false);
    }

    const close = () => {
        reset();
        if (tokens.length > 0) {
            onChange(tokens);
        }
        onClose();
    }

    const canSave = () => {
        if (name && address && cardInfoComplete) {
            return true;
        }
        return false;
    }

    const createCardToken = async () => {
        const data = parseAddressForCard(address.address);
        data.name = name;
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card, data);
        return result;
    }

    const startChecking = () => {
        setChecking(true);
    }

    const endChecking = () => {
        setChecking(false);
    }

    const save = async () => {
        startChecking();
        const result = await createCardToken();
        if (result.error) {
            //error; something wrong with card
            setError(result.error.message)
            endChecking();
            return;
        }
        if (enforceDebit && result.token.card.funding !== DEBIT) {
            setError(DEBIT_ERROR);
            endChecking();
            return;
        }
        //no card format verification errors
        setError('');
        //lets test the card with a test charge
        const res = await testCard(result.token.id);
        if (res) {
            //card is good, generate a new card token because the one we used to test the card is consumed
            const tokens = [];
            for (let i = 0; i < tokenCount; i++) {
                const tokenResult = await createCardToken();
                tokens.push(tokenResult.token);
            }
            setTokens(tokens);
            endChecking();
            if (!showPreview) {
                onChange(tokens);
                onClose();
            }
        } else {
            //card is bad, test charge failed
            setError('The card you entered is invalid.')
            endChecking();
        }
    }

    const showAddress = () => {
        setCardLeft(-window.screen.width);
        setAddressLeft(0);
    }

    const addressChange = (address) => {
        setAddress(address);
        closeAddress();
    }

    const closeAddress = () => {
        setCardLeft(0);
        setAddressLeft(window.screen.width);   
    }

    const cardChange = async (el) => {
        setCardInfoComplete(el.complete);
    }

    const focusName = () => {
        if (nameRef) {
            nameRef.focus();
        }
    }

    const searchChange = (e) => {
        setSearch(e.target.value);
    }

    const onKeyUp = () => {
        //if a timer exists clear it
        if (timer) {
            clearTimeout(timer);
        }
        //create a new timer for 1500 ms, when timer runs out, run search
        timer = setTimeout(searchAddress, 500);
    }

    const resetError = () => {
        setError('');
    }

    const onKeyDown = () => {
        setLoading(true);
        if (timer) {
            clearTimeout(timer);
        }
    }

    const searchAddress = async () => {
        const addresses = await searchPlaces2(search, coords);
        setAddresses(addresses);
        setLoading(false);
    }

    const decideTitle = () => {
        if (addressLeft === 0) {
            return 'Search Your Address'
        }
        if (checking) {
            return '';
        }
        if (tokens.length > 0) {
            return '';
        }
        return message;
    }

    const renderSaveButton = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                onClick={save}
            />
        )
    }

    const renderName = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={focusName}>
                    <TransparentInput 
                        value={name}
                        onChange={setName}
                        placeholder='Cardholder Name'
                        passRefUp={ref => nameRef = ref}
                    />
                    {name && <InputLabel label='Cardholder Name' />}
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderAddressValue = () => {
        if (address && address.address) {
            return (
                <Value value={address.address} />
            )
        }
        return (
            <Placeholder placeholder='Cardholder Address' />
        )
    }

    const renderAddressButton = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={showAddress}>
                    {renderAddressValue()}
                    {address && <InputLabel label='Cardholder Addresss' style={styles.marginTop} />}
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderCardInput = () => {
        return (
            <FlexRow>
                <FlexRowButton>
                    <CardElement 
                        options={CARD_OPTIONS}
                        onChange={cardChange}
                    />
                    {cardInfoComplete && <InputLabel label='Card Info' />}
                </FlexRowButton>
            </FlexRow>
        )
    }

    const renderDecideCardContent = () => {
        if (tokens.length > 0) {
            return renderCardComplete();
        }
        return renderCardContent();
    }

    const renderCardComplete = () => {
        return (
            <Body>
                <Body>
                    <h2 className='CM-Success'>Your card has been saved</h2>
                    <Card 
                        card={tokens[0].card}
                        width={window.screen.width * .85}
                    />
                </Body>
                <BrandButton 
                    style={BOTTOM_BUTTON}
                    label='Okay'
                    onClick={close}
                    disabled={!canSave()}
                />
            </Body>
        )
    }

    const renderCardContent = () => {
        if (open) {
            return (
                <Body
                    absolute
                    style={{ left: cardLeft }}
                >
                    <Body>
                        {renderName()}
                        {renderAddressButton()}
                        {renderCardInput()}
                    </Body>
                    {renderSaveButton()}
                </Body>
            )
        }
    }

    const renderAddressInput = () => {
        return (
            <div className='CM-SearchRow'>
                <IoChevronBackOutline 
                    className='CM-Back'
                    onClick={closeAddress} 
                />
                <input 
                    className='CM-Search'
                    placeholder='Search Address...'
                    value={search}
                    onChange={searchChange}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                />
            </div>

        )
    }

    const renderLoading = () => {
        return (
            <ModalLoader />
        )
    }

    const renderAddress = (address, index) => {
        return (
            <Address 
                key={index}
                address={address}
                onClick={addressChange}
            />
        )
    }

    const renderAddresses = () => {
        return (
            <Scroll>
                {addresses.map(renderAddress)}
            </Scroll>
        )
    }

    const renderDecideAddressContent = () => {
        if (loading) {
            return renderLoading();
        }
        return renderAddresses();
    }

    const renderAddressContent = () => {
        if (open) {
            return (
                <Body
                    absolute
                    style={{ left: addressLeft }}
                    center
                >
                    <Body>
                        {renderAddressInput()}
                        {renderDecideAddressContent()}
                    </Body>
                </Body>
            )
        }
    }

    const renderChecking = () => {
        if (checking) {
            return (
                <Body
                    absolute
                    style={styles.zIndex}
                    center
                >
                    <Body relative>
                        <GradientBackground>
                            <Body center>
                                <ModalLoader 
                                    message='Creating Card...'
                                />
                            </Body>
                        </GradientBackground>
                    </Body>
                </Body>
            )
        }
    }

    const renderError = () => {
        if (error) {
            return (
                <Body
                    absolute
                    style={styles.zIndex}
                    center
                >
                    <Body relative>
                        <GradientBackground>
                            <Body center style={{ marginBottom: 70 }}>
                                <ModalError 
                                    message='The card you used was invalid.'
                                    onClick={resetError}
                                />
                            </Body>
                        </GradientBackground>
                    </Body>
                </Body>
            )
        }
    }

    return (
        <BottomUpModal
            open={open}
            onClose={close}
            height={0.85}
            title={decideTitle()}
            disableClose={checking}
            image={image}
            darken
        >
            <Body relative>
                {renderDecideCardContent()}
                {renderAddressContent()}
                {renderChecking()}
                {renderError()}
            </Body>
        </BottomUpModal>
    )
}

const styles = {
    marginTop: {
        marginTop: 5
    },
    zIndex: {
        zIndex: 2
    }
}

CardModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    enforceDebit: PropTypes.bool,
    showPreview: PropTypes.bool,
    message: PropTypes.string,
    tokenCount: PropTypes.number,
    coords: PropTypes.object,
    image: PropTypes.string
};

CardModal.defaultProps = {
    open: false,
    onClose: () => {},
    onChange: () => {},
    enforceDebit: false,
    showPreview: true,
    message: '',
    tokenCount: 1,
    coords: null,
    image: ''
}

export default CardModal;