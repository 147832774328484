import React from 'react';
import PropTypes from 'prop-types';
import './styles/InputLabel.css';

const InputLabel = ({ label, style }) => {
   return (
       <p  style={style} className='InputLabel-Label'>{label}</p>
   ) 
}

InputLabel.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object
};

InputLabel.defaultProps = {
    label: '',
    style: {}
}

export default InputLabel;