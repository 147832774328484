import React from 'react';
import PropTypes from 'prop-types';
import { 
    ARTIST_APPOINTMENT,
    ARTIST_REVIEW,
    ARTIST_CANCEL,
    ARTIST_NEW_CLIENT,
    ARTIST_RESCHEDULE,
    ARTIST_PAYMENT,
    ARTIST_PAYOUT,
    CLIENT_APPOINTMENT,
    CLIENT_CANCEL,
    CLIENT_RESCHEDULE,
    CLIENT_PAYMENT,
    CLIENT_NOSHOW
} from '../constants/NotiTypes';
import { 
    BsFillCalendarPlusFill,
    BsCalendarX,
    BsCalendar2Check,
    BsPersonPlusFill,
    BsCurrencyDollar,
    BsCreditCard,
    BsPersonXFill
} from 'react-icons/bs';
import { 
    AiFillLike
} from 'react-icons/ai';
import './styles/NotiIcon.css';

const NotiIcon = ({ type }) => {

    const renderAppointment = () => {
        return (
            <BsFillCalendarPlusFill className='NI-Icon' />
        )
    }

    const renderCancel = () => {
        return (
            <BsCalendarX className='NI-Icon' />
        )
    }

    const renderReschedule = () => {
        return (
            <BsCalendar2Check className='NI-Icon' />
        )
    }

    const renderNewClient = () => {
        return (
            <BsPersonPlusFill className='NI-Icon' />
        )
    }

    const renderNewReview = () => {
        return (
            <AiFillLike  className='NI-Icon' />
        )
    }

    const renderNewPayout = () => {
        return (
            <BsCurrencyDollar className='NI-Icon' />
        )
    }

    const renderNewPayment = () => {
        return (
            <BsCreditCard className='NI-Icon' />
        )
    }

    const renderNoShow = () => {
        return (
            <BsPersonXFill className='NI-Icon' />
        )
    }

    const decideIcon = () => {
        switch (type) {
            case ARTIST_APPOINTMENT:
            case CLIENT_APPOINTMENT:
                return renderAppointment();
            case ARTIST_REVIEW:
                return renderNewReview();
            case ARTIST_CANCEL:
            case CLIENT_CANCEL:
                return renderCancel();
            case ARTIST_NEW_CLIENT:
                return renderNewClient();
            case ARTIST_RESCHEDULE:
            case CLIENT_RESCHEDULE:
                return renderReschedule();
            case ARTIST_PAYMENT:
            case CLIENT_PAYMENT:
                return renderNewPayment();
            case ARTIST_PAYOUT:
                return renderNewPayout();
            case CLIENT_NOSHOW:
                return renderNoShow();
            default:
                return <div />
        }
    }

    return decideIcon();
}

NotiIcon.propTypes = {
    type: PropTypes.string,
};

NotiIcon.defaultProps = {
    type: ''
}

export default NotiIcon;
