import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    BrandButton,
    FlexRow,
    FlexRowButton,
    SettingsTitle
} from '.';
import { 
    doc, 
    getFirestore, 
    updateDoc 
} from 'firebase/firestore';
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import './styles/UpdateBio.css';

class UpdateBio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bio: props.bio
        }
    }

    saveBio = async () => {
        const db = getFirestore();
        const ref = doc(db, `users/${this.props.profile.id}`);
        await updateDoc(ref, {
            bio: this.state.bio
        });
        this.props.onBack();
    }

    bioChange = (e) => {
        this.setState({ bio: e.target.value });
    }

    focusBio = () => {
        if (this.bioRef) {
            this.bioRef.focus();
        }
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Bio'
            />  
        )
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Update Bio'
            />
        )
    }

    renderBio = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={this.focusBio}>
                    <textarea 
                        value={this.state.bio}
                        onChange={this.bioChange}
                        ref={ref => this.bioRef = ref}
                        className='UB-Bio'
                        placeholder='Describe Yourself...'
                    />
                </FlexRowButton>
            </FlexRow>
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                style={BOTTOM_BUTTON}
                label='Save'
                onClick={this.saveBio}
            />
        )
    }

    render = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderBio()}
                {this.renderSave()}
            </Body>
        )
    }
}

UpdateBio.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object,
};

UpdateBio.defaultProps = {
    onBack: () => {},
    profile: {}
};

export default UpdateBio;