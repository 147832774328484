import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    Body,
    ModalRowBack,
    SettingsTitle,
    BrandButton,
    EasyInput
} from '.'
import { BOTTOM_BUTTON } from '../universalStyles/Styles';
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    updateEmail 
} from '@firebase/auth';
import { 
    doc, 
    getFirestore, 
    updateDoc 
} from '@firebase/firestore';

const INVALID_EMAIL = 'Invalid Email';
const INVALID_PASSWORD = 'Invalid Password';

const INVALID_EMAIL_ERROR = 'Firebase: Error (auth/invalid-email).';
const INVALID_PASSWORD_ERROR = 'Firebase: Error (auth/wrong-password).';

class UpdateClientEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            errorMessage: ''
        }
    }

    emailChange = (email) => {
        this.setState({ email });
    }

    passwordChange = (password) => {
        this.setState({ password });
    }

    save = async () => {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, this.props.profile.email, this.state.password);
            await updateEmail(auth.currentUser, this.state.email);
            const db = getFirestore();
            const ref = doc(db, `clients/${this.props.profile.id}`);
            await updateDoc(ref, {
                email: this.state.email
            });
            this.props.onBack();
        } catch (ex) {
            let errorMessage = '';
            console.log(ex.message);
            if (ex.message === INVALID_EMAIL_ERROR) {
                errorMessage = INVALID_EMAIL;
            }
            if (ex.message === INVALID_PASSWORD_ERROR) {
                errorMessage = INVALID_PASSWORD;
            }
            this.setState({
                email: '',
                password: '',
                errorMessage
            })
        }
    }

    renderBack = () => {
        return (
            <ModalRowBack 
                onBack={this.props.onBack}
                title='Update Email'
            />
        )
    }

    renderTitle = () => {
        return (
            <SettingsTitle 
                title='Update Email'
            />
        )
    }

    renderError = () => {
        if (this.state.errorMessage) {
            return (
                <SettingsTitle 
                    error
                    title={this.state.errorMessage}
                />
            )
        }
    }

    renderEmail = () => {
        return (
            <EasyInput 
                value={this.state.email}
                onChange={this.emailChange}
                placeholder='New Email'
                label='New Email'
            />
        )
    }

    renderPassword = () => {
        return (
            <EasyInput 
                value={this.state.password}
                onChange={this.passwordChange}
                placeholder='Password'
                label='Password'
                type='password'
            />
        )
    }

    renderSave = () => {
        return (
            <BrandButton 
                label='Save'
                style={BOTTOM_BUTTON}
                onClick={this.save}
                disabled={!(this.state.email && this.state.password)}
            />
        )
    }

    render = () => {
        return (
            <Body>
                {this.renderBack()}
                {this.renderTitle()}
                {this.renderError()}
                {this.renderEmail()}
                {this.renderPassword()}
                {this.renderSave()}
            </Body>
        )
    }
}

UpdateClientEmail.propTypes = {
    onBack: PropTypes.func,
    profile: PropTypes.object
};

UpdateClientEmail.defaultProps = {
    onBack: () => {},
    profile: {}
}

export default UpdateClientEmail;