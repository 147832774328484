import React from 'react';
import PropTypes from 'prop-types';
import './styles/SubTitle.css';

const SubTitle = ({ title }) => {
    return (
        <h3 className='ST-Title'>{title}</h3>
    )
}

SubTitle.propTypes = {
    title: PropTypes.string
};

SubTitle.defaultProps = {
    title: ''
}

export default SubTitle;