import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
    BottomUpModal, 
    BrandButton,
    DurationControl,
    ButtonLabel,
    TabButtonRow,
    BrandTabButton,
    FlexRowButton,
    Body,
    BigTitle,
    ModalRowBack,
    Scroll,
    Value,
    FlexRow,
    GenOption
} from '../components';
import './styles/DurationPicker.css';
import { 
    ACTIVE_LABEL, 
    BOTTOM_BUTTON, 
    INACTIVE_LABEL 
} from '../universalStyles/Styles';
import { generateDurations, generateLabelForMins } from '../util/DurationUtil';

class DurationPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDouble: props.duration ? props.duration.isDouble : false,
            //all durations are in minutes
            singleStart: props.duration ? props.duration.singleStart : 0,
            doubleStart: props.duration ? props.duration.doubleStart : 0,
            doubleEnd: props.duration ? props.duration.doubleEnd : 0,
            doubleBreak: props.duration ? props.duration.doubleBreak : 0,
            left: 0,
            selectLeft: window.screen.width,

            singleStartChange: false,
            doubleStartChange: false,
            doubleBreakChange: false,
            doubleEndChange: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        const { open: prevOpen } = prevProps;
        const { open } = this.props;
        if (!prevOpen && open) {
            this.setState({
                isDouble: this.props.duration ? this.props.duration.isDouble : false,
                singleStart: this.props.duration ? this.props.duration.singleStart : 0,
                doubleStart: this.props.duration ? this.props.duration.doubleStart : 0,
                doubleEnd: this.props.duration ? this.props.duration.doubleEnd : 0,
                doubleBreak: this.props.duration ? this.props.duration.doubleBreak : 0
            });
        }
    }

    canSave = () => {
        const { isDouble, singleStart, doubleStart, doubleEnd, doubleBreak } = this.state;
        if (isDouble) {
            if (doubleStart && doubleEnd && doubleBreak) {
                return true;
            }
            return false;
        }
        if (singleStart) {
            return true;
        }
        return false;
    }

    navToSelect = () => {
        this.setState({ left: -window.screen.width, selectLeft: 0 });
    }

    startSingleStartChange = () => {
        this.navToSelect();
        this.setState({ singleStartChange: true });
    }

    startDoubleStartChange = () => {
        this.navToSelect();
        this.setState({ doubleStartChange: true });
    }

    startDoubleBreakChange = () => {
        this.navToSelect();
        this.setState({ doubleBreakChange: true });
    }

    startDoubleEndChange = () => {
        this.navToSelect();
        this.setState({ doubleEndChange: true });
    }

    navToBody = () => {
        this.setState({ 
            left: 0, 
            selectLeft: window.screen.width,
            singleStartChange: false,
            doubleStartChange: false,
            doubleBreakChange: false,
            doubleEndChange: false
        });
    }

    selectSingle = () => {
        this.setState({ isDouble: false });
    }

    durationChange = ({ value }) => {
        if (this.state.singleStartChange) {
            this.setState({ singleStart: value });
        }
        if (this.state.doubleStartChange) {
            this.setState({ doubleStart: value });
        }
        if (this.state.doubleEndChange) {
            this.setState({ doubleEnd: value });
        }
        if (this.state.doubleBreakChange) {
            this.setState({ doubleBreak: value });
        }
        this.navToBody();
    }

    doubleStartChange = (doubleStart) => {
        this.setState({ doubleStart });
    }

    doubleBreakChange = (doubleBreak) => {
        this.setState({ doubleBreak });
    }

    doubleEndChange = (doubleEnd) => {
        this.setState({ doubleEnd });
    }

    selectDouble = () => {
        this.setState({ isDouble: true });
    }

    onSave = () => {
        const { isDouble, singleStart, doubleStart, doubleEnd, doubleBreak } = this.state;
        const savePack = {
            isDouble, 
            singleStart,
            doubleStart,
            doubleEnd,
            doubleBreak
        }
        this.props.onSave(savePack);
        this.props.onClose();
    }

    renderNormalButton = (isDouble) => {
        return (
            <div 
                className={`DurationPicker-Button ${!isDouble && 'DurationPicker-Selected'}`}
                style={{ marginRight: 10, marginLeft: 10 }}
                onClick={this.selectSingle}
            >
                <ButtonLabel 
                    label='Normal'
                    style={!isDouble ? ACTIVE_LABEL : INACTIVE_LABEL}
                />
            </div>
        )
    }

    renderDoubleButton = (isDouble) => {
        return (
            <div 
                className={`DurationPicker-Button ${isDouble && 'DurationPicker-Selected'}`}
                style={{ marginRight: 10 }}
                onClick={this.selectDouble}
            >
                <ButtonLabel 
                    label='Double Book'
                    style={isDouble ? ACTIVE_LABEL : INACTIVE_LABEL}
                />
            </div>
        )
    }

    renderTopRow = () => {
        const { isDouble } = this.state;
        return (
            <TabButtonRow>
                <BrandTabButton 
                    label='Normal'
                    selected={!isDouble}
                    onClick={this.selectSingle}
                />
                <BrandTabButton 
                    label='Double Book'
                    selected={isDouble}
                    onClick={this.selectDouble}
                />
            </TabButtonRow>
        )
    }

    renderNormalBody = () => {
        const label = generateLabelForMins(this.state.singleStart);
        return (
                <FlexRowButton 
                    style={styles.button}
                    onClick={this.startSingleStartChange}
                >
                    <Value value={label} />
                    <ButtonLabel 
                        label='How long will this service take?'
                    />
                </FlexRowButton>
        )
    }

    renderDoubleBody = () => {
        const doubleStartLabel = generateLabelForMins(this.state.doubleStart);
        const doubleBreakLabel = generateLabelForMins(this.state.doubleBreak);
        const doubleEndLabel = generateLabelForMins(this.state.doubleEnd);
        return (
            <Body>
                <FlexRow>
                    <FlexRowButton 
                        style={styles.button}
                        onClick={this.startDoubleStartChange}
                    >
                        <Value value={doubleStartLabel} />
                        <ButtonLabel label='This is the duration of time where you initially meet with your client.' />
                    </FlexRowButton>
                </FlexRow>
                <FlexRow>
                    <FlexRowButton 
                        style={styles.button}
                        onClick={this.startDoubleBreakChange}
                    >
                        <Value value={doubleBreakLabel} />
                        <ButtonLabel label='This is the duration of time where your initial client is waiting and you can see a new one.' />
                    </FlexRowButton>
                </FlexRow>
                <FlexRow>
                    <FlexRowButton 
                        style={styles.button}
                        onClick={this.startDoubleEndChange}
                    >
                        <Value value={doubleEndLabel} />
                        <ButtonLabel label='This is the duration of time where you finish up with you initial client.' />
                    </FlexRowButton>
                </FlexRow>

            </Body>
        )
    }

    renderDoubleBody2 = () => {
        return (
            <div className='DurationPicker-Container2'>
                <div  
                    onClick={this.focusDoubleStartRef}  
                    className='DurationPicker-Container'
                    style={{ marginTop: '25%' }}
                >
                    <DurationControl 
                        value={this.state.doubleStart}
                        onChange={this.doubleStartChange}
                        passRefUp={ref => this.doubleStartRef = ref}
                        label='This is the duration of time where you initially meet with your client.'
                    />
                </div>
                <div  
                    onClick={this.focusDoubleBreakRef}  
                    className='DurationPicker-Container'
                >
                    <DurationControl 
                        value={this.state.doubleBreak}
                        onChange={this.doubleBreakChange}
                        passRefUp={ref => this.doubleBreakRef = ref}
                        label='This is the duration of time where your initial client is waiting and you can see a new one.'
                    />
                </div>
                <div  
                    onClick={this.focusDoubleEndRef}  
                    className='DurationPicker-Container'
                >
                    <DurationControl 
                        value={this.state.doubleEnd}
                        onChange={this.doubleEndChange}
                        passRefUp={ref => this.doubleEndRef = ref}
                        label='This is the duration of time where you finish up with you initial client.'
                    />
                </div>
            </div>
        )
    }

    renderDecideDurationType = () => {
        if (this.state.isDouble) {
            return this.renderDoubleBody()
        }
        return this.renderNormalBody();
    }

    renderDurationBody = () => {
        return (
            <Body 
                absolute
                style={{ left: this.state.left }}
            >
                <BigTitle style={styles.title} title='Decide the duration of this service' />
                {this.renderTopRow()}
                {this.renderDecideDurationType()}
                <BrandButton 
                    label='Save'
                    style={BOTTOM_BUTTON}
                    disabled={!this.canSave()}
                    onClick={this.onSave}
                />
            </Body>
        )
    }

    renderDuration = (duration, index) => {
        return (
            <GenOption 
                key={index}
                label={duration.label}
                option={duration}
                onClick={this.durationChange}
            />
        )
    }

    renderDurationsBody = () => {
        return (
            <Body
                absolute
                style={{ left: this.state.selectLeft }}
            >
                <ModalRowBack 
                    title='Back'
                    onBack={this.navToBody}
                />
                <BigTitle title='Pick a Duration' />
                <Scroll>
                    {generateDurations().map(this.renderDuration)}
                </Scroll>
            </Body>
        )
    }

    render = () => {
        return (
            <BottomUpModal
                open={this.props.open}
                onClose={this.props.onClose}
                height={.90}
                image={this.props.image}
                darken
            >
                <Body relative>
                    {this.renderDurationBody()}
                    {this.renderDurationsBody()}
                </Body>
            </BottomUpModal>
        )
    }
}

const styles = {
    title: {
        marginTop: 15
    },
    button: {
        width: '95%'
    }
}

DurationPicker.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    duration: PropTypes.object
};

DurationPicker.defaultProps = {
    open: false,
    onClose: () => {},
    onSave: () => {},
    duration: {}
};

export default DurationPicker;