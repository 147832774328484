import React from 'react';
import PropTypes from 'prop-types';
import { 
    FlexRow, 
    FlexRowButton, 
    ButtonLabel, 
    Service
} from '.';

const ServiceButton = ({ service, onClick, serviceHeightPercent }) => {

    const renderNoService = () => {
        return (
            <FlexRow>
                <FlexRowButton onClick={onClick}>
                    <ButtonLabel label='Pick a Service' />
                </FlexRowButton>
            </FlexRow>

        )
    }

    const renderService = () => {
        const serviceHeight = window.screen.height * serviceHeightPercent;
        return (
            <Service 
                service={service}
                onClick={onClick}
                overrideHeight={serviceHeight}
            />
        )
    }

    const renderDecide = () => {
        if (!service) {
            return renderNoService();
        }
        return renderService();
    }

    return renderDecide();    
}

ServiceButton.propTypes = {
    service: PropTypes.object,
    onClick: PropTypes.func,
    serviceHeightPercent: PropTypes.number
};

ServiceButton.defaultProps = {
    service: null,
    onClick: () => {},
    serviceHeightPercent: .35
}

export default ServiceButton;