export const BARBER = 'Barber';
export const HAIRDRESSER = 'Hair Dresser';
export const NAILTECH = 'Nail Technician';
export const ESTHETICIAN = 'Esthetician';
export const MAKEUPARTIST = 'Make Up Artist';
export const TATOOARTIST = 'Tattoo Artist';

export const PROFESSIONS = [
    BARBER,
    HAIRDRESSER,
    NAILTECH,
    ESTHETICIAN,
    MAKEUPARTIST,
    TATOOARTIST
];