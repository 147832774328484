import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Body,
    ArgendaLogo,
    BrandButton,
    BrandButton2,
    ModalLoader,
    Error,
    EasyInput
} from '../components';
import './styles/SignInForm.css';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

class SignInForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            error: ''
        }
    }

    close = () => {
        this.setState({
            email: '',
            password: '',
            loading: false,
            error: ''
        })
        this.props.close();
    }

    emailChange = (email) => {
        this.setState({ email, error: false });
    }

    reset = () => {
        this.setState({
            error: false,
            loading: false
        })
    }

    passwordChange = (password) => {
        this.setState({ password, error: false });
    }

    toggleLoading = () => {
        this.setState({ loading: !this.state.loading });
    }

    canSignIn = () => {
        if (this.state.email && this.state.password) {
            return true;
        }
        return false;
    }

    signIn = async () => {
        if (this.canSignIn()) {
            const auth = getAuth();
            try {
                this.toggleLoading();
                await signInWithEmailAndPassword(auth, this.state.email, this.state.password);
                this.toggleLoading();
                this.props.onSignIn();
                this.close();
            } catch (ex) {
                console.log(ex);
                this.setState({ error: true, loading: false });
            }
        }
    }

    renderLogo = () => {
        return (
            <ArgendaLogo style={{ marginBottom: 15 }} width={window.screen.width * .75} />
        )
    }

    renderError = () => {
        if (this.state.error) {
            return (
                <Error 
                    error='Incorrect Email/Password'
                    style={styles.error}
                    onClick={this.reset}
                />
            )
        }
    }
    
    renderEmail = () => {
        return (
            <EasyInput 
                label='Email'
                value={this.state.email}
                onChange={this.emailChange}
                placeholder='Email'
            />
        )
    }

    renderPassword = () => {
        return (
            <EasyInput 
                label='Password'
                value={this.state.password}
                onChange={this.passwordChange}
                type='password'
                placeholder='password'
            />
        )
    }

    decideClass = () => {
        if (!this.canSignIn()) {
            return 'SIF-Button SIF-Disable';
        }
        return 'SIF-Button';
    }

    renderSignIn = () => {
        return (
            <div
                className={this.decideClass()}
                style={styles.signIn}
                onClick={this.signIn}
            >
                <p>Sign In</p>
            </div>
        )
    }

    renderOr = () => {
        return (
            <div className='SIF-OrRow'>
                <div className='SIF-OrLine' />
                <p className='SIF-Or'>Or</p>
                <div className='SIF-OrLine' />
            </div>
        )
    }

    renderSignUp = () => {
        return (
            <BrandButton2 
                label='Sign Up'
                style={styles.signUp}
                onClick={this.props.signUp}
            />
        )
    }

    renderForget = () => {
        return (
            <p 
                onClick={this.props.forgetPassword} 
                className='SIF-Forgot'
            >
                Forget Password?
            </p>
        )
    }

    renderBody = () => {
        return (
            <Body>
                {this.renderLogo()}
                {this.renderError()}
                {this.renderEmail()}
                {this.renderPassword()}
                <div className='SIF-Bottom'>
                    {this.renderSignIn()}
                    {this.renderForget()}
                    {this.renderOr()}
                    {this.renderSignUp()}
                </div>
            </Body>
        )
    }

    renderLoader = () => {
        return (
            <ModalLoader 
                message='Signing In...'
            />
        )
    }

    renderDecide = () => {
        if (this.state.loading) {
            return this.renderLoader();
        }
        return this.renderBody();
    }

    render = () => {
        return this.renderDecide();
    }
}

const styles = {
    error: {
        marginTop: 15
    },
    email: {
        marginTop: 40 
    },
    signIn: {
        width: '95%', 
        marginTop: 40, 
        borderRadius: 0
    },
    signUp: {
        marginTop: 30
    },
}

SignInForm.propTypes = {
    forgetPassword: PropTypes.func,
    signUp: PropTypes.func,
    close: PropTypes.func,
    onSignIn: PropTypes.func,
};

SignInForm.defaultProps = {
    forgetPassword: () => {},
    signUp: () => {},
    close: () => {},
    onSignIn: () => {}
};

export default SignInForm;